import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosPublic from "utils/axiosPublic";
import Layout from "components/register/Layout";
import Card from "components/register/Card";
import AuthNum from "components/common/AuthNum";
import { Heading, Paragraph, Form, HelperWrapper } from "./styles";

import register_progress_two from "assets/register_progress_two.svg";

const EmailAuth = () => {
  let navigate = useNavigate();

  // 인증번호 만료 시간
  const [expireAt, setExpireAt] = useState();

  const [authData, setAuthData] = useState();

  const sendAuthNum = (data) => {
    const paramsData = {
      email: data.email,
      name: data.name,
    };

    axiosPublic
      .get("/api/v1/open/email/code-send", {
        params: paramsData,
      })
      .then((resp) => setExpireAt(resp.data.result.timeout))
      .catch((err) => {
        window.alert("인증번호 전송에 실패했습니다. 다시 시도해주세요.");
        console.log(err);
        // 이메일이나 이름이 없는 경우
        // 필수 요청값이 빠졌습니다.
        if (err.response.data.code === "COMMON_E_002") {
        }
      });
  };

  useEffect(() => {
    const data = JSON.parse(window.localStorage.getItem("register"));
    if (data) {
      setAuthData(data);
      sendAuthNum(data);
    } else {
      // TODO
      // 이메일, 이름이 존재하지 않는 경우
    }
  }, []);

  // 인증 번호 4자리
  const [authNum, setAuthNum] = useState();
  // 번호가 잘못된 경우
  const [isNumWrong, setIsNumWrong] = useState(false);
  // 시간이 만료된 경우
  const [isTimeoutFromServer, setIsTimeoutFromServer] = useState(false);

  useEffect(() => {
    if (authNum) {
      setIsNumWrong(false);
      setIsTimeoutFromServer(false);
      // 인증번호 확인
      axiosPublic
        .get("/api/v1/open/email/verify", {
          params: {
            email: authData.email,
            code: authNum,
          },
        })
        .then((response) => {
          const uid = response.data?.result?.uid;

          const data = JSON.parse(window.localStorage.getItem("register"));
          const newData = { ...data, uid };
          window.localStorage.setItem("register", JSON.stringify(newData));
          navigate("/register/password");
        })
        .catch((error) => {
          const errorCode = error.response.data.code;

          if (errorCode === "EMAIL_E_005") {
            // 이메일 인증 코드가 잘못되었습니다.
            setIsNumWrong(true);
          } else if (errorCode === "EMAIL_E_004") {
            // 이메일 인증 코드가 만료되었습니다.
            setIsTimeoutFromServer(true);
          } else if (errorCode === "EMAIL_E_006") {
            // TODO
            // 이메일 인증을 다시 시도하시기 바랍니다. ????
          }
        });
    }
  }, [authNum]);

  // 재전송 버튼 클릭
  const [reset, setReset] = useState(false);
  const onClickReset = () => {
    setIsNumWrong(false);
    setIsTimeoutFromServer(false);
    setReset(true);
    sendAuthNum(authData);
  };

  return (
    <Layout>
      <Card>
        <img src={register_progress_two} alt="second register progress" />
        <Heading>메일로 인증번호를 발송했습니다!</Heading>
        <Paragraph>{authData && authData?.email ? authData.email : "-"}</Paragraph>
        <Form>
          <AuthNum expireAt={expireAt} setAuthNum={setAuthNum} isNumWrong={isNumWrong} isTimeoutFromServer={isTimeoutFromServer} reset={reset} setReset={setReset} />
        </Form>
        <HelperWrapper>
          <p>혹시 이메일이 도착하지 않았나요?</p>
          <button type="button" onClick={onClickReset}>
            인증메일 재발송
          </button>
        </HelperWrapper>
      </Card>
    </Layout>
  );
};

export default EmailAuth;
