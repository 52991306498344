import styled from "styled-components";
import ic_app_icon_default from "assets/ic_app_icon_default.svg";
import ic_app_icon_delete from "assets/ic_app_icon_delete.svg";
import ic_app_icon_delete_active from "assets/ic_app_icon_delete_active.svg";

export const InputWrapper = styled.div`
  label {
    display: block;
    width: 84px;
    height: 84px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    background-image: url(${ic_app_icon_default});
    background-size: 49px 70px;
    background-position: center center;
    background-repeat: no-repeat;
    cursor: pointer;
  }
  input[type="file"] {
    position: absolute;
    width: 0;
    height: 0;
    padding: 0;
    overflow: hidden;
    border: 0;
  }

  input[type="file"]:disabled + label {
    cursor: default;
  }
`;

export const ImageWrapper = styled.div`
  position: relative;
  width: 84px;
  height: 84px;
  border-radius: 4px;
  border: 1px solid #e0e0e0;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
  }

  button {
    position: absolute;
    top: 6px;
    right: 5px;
    width: 9px;
    height: 9px;
    background-image: url(${ic_app_icon_delete});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;

    :hover {
      background-image: url(${ic_app_icon_delete_active});
    }
  }
`;
