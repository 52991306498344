import axios from "axios";

const axiosPublic = axios.create({
  baseURL: process.env.REACT_APP_API,
  //baseURL: "https://dev-toolkit-api.i2bcampus.com",
  headers: {
    "Content-Type": "application/json; charset=utf-8",
  },
});

export default axiosPublic;
