import { useState, useEffect, useContext } from "react";
import LinkInfo from "components/test/LinkInfo";
import { TabContainer } from "pages/test/form/styles";
import { Label } from "components/test/TabItems/Outro/styles";
import { IntroText } from "components/test/TabItems/Intro/styles";
import TesterPrevNextBtn from "components/test/TesterPrevNextBtn";

const outroPlaceholder = `귀하께서 보내주신 답변은 신규 프로덕트 개발을 위해 소중히 사용하겠습니다. 
감사합니다.
`;

const SurveyOutro = ({ isShowing, link, closingWords }) => {
  const [outroText, setOutroText] = useState();

  useEffect(() => {
    if (closingWords?.length > 0) {
      setOutroText(closingWords);
    } else {
      setOutroText(outroPlaceholder);
    }
  }, [closingWords]);

  return (
    <TabContainer isShowing={isShowing}>
      <LinkInfo link={link} />
      <Label htmlFor="finish">끝내는 말</Label>

      <IntroText>
        {outroText?.split("\n").map((line, i) => (
          <span key={i}>
            {line} <br />
          </span>
        ))}
      </IntroText>
      <TesterPrevNextBtn isOutro />
    </TabContainer>
  );
};

export default SurveyOutro;
