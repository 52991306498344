import styled from "styled-components";
import { flexbox, flexboxColumn, registerInputWrapperBase } from "styles/mixins";
import register_round from "assets/register_round.svg";

export const Form = styled.form`
  ${flexboxColumn("space-between", "center")};
  height: 374px;
`;

export const Heading = styled.h1`
  width: 100%;
  padding: 0 0 46px 0;
  background-image: url(${register_round});
  background-repeat: no-repeat;
  background-size: 217px 67px;
  background-position: 117px 30px;
`;

export const InputWrapper = styled.div``;

export const Input = styled.div`
  ${registerInputWrapperBase};

  input {
    border: 1px solid ${(props) => (props.hasError ? "#CE5E41" : "#e0e0e0")};
    &:focus,
    &:active {
      border: 1px solid ${(props) => (props.hasError ? "#CE5E41" : "#181818")};
    }
  }

  &.name {
    margin: 20px 0 0 0;
  }

  &.phone {
    margin: 20px 0 0 0;
  }
`;

export const EmailText = styled.p`
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  color: #ce5e41;
  margin: 4px 0 -5px 0;

  a {
    margin: 0 0 0 5px;
    font-weight: 900;
    font-size: 12px;
    line-height: 22px;
    color: #4556ee;
  }

  &.blue {
    color: #4556ee;
  }
`;

export const HelperWrapper = styled.div`
  ${flexbox("flex-start", "center")};
  align-self: flex-start;
  margin: 8px 0 0 0;

  p {
    margin: 0 5px 0 11px;
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    color: #808080;
  }

  a {
    font-weight: 900;
    font-size: 12px;
    line-height: 22px;
    color: #4556ee;
  }
`;
