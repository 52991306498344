import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axiosPrivate from "utils/axiosPrivate";
import { TabContainer } from "pages/test/form/styles";
import TabTag from "components/test/TabTag";
import ProjectInfo from "components/review/ProjectInfo";
import QuestionMain from "components/test/QuestionMain";
import ResponseBtn from "components/review/ResponseBtn";
import ResponseText from "components/review/ResponseText";
import { textDefaultData } from "utils/data";

const ReviewOpinion = ({ isShowing, testInfo, onClickMoreResponse }) => {
  const { id: projectId } = useParams();

  const [data, setData] = useState();

  useEffect(() => {
    axiosPrivate
      .get(`api/v1/projects/${projectId}/review/dashboard`, {
        params: {
          testSurveyType: "GENERAL_OPINION",
        },
      })
      .then((res) => {
        if (res.data?.result?.answers?.length > 0) {
          setData(res.data.result.answers);
        } else {
          setData(textDefaultData);
        }
      })
      .catch((err) => {
        console.log(err);
        setData(textDefaultData);
      });
  }, []);

  // 답변 전체보기 모달
  const onClickResponse = () => onClickMoreResponse("GENERAL_OPINION");

  return (
    <TabContainer isShowing={isShowing}>
      <ProjectInfo testInfo={testInfo} />
      <TabTag text="종합의견" />
      <QuestionMain text="제품/서비스의 개선해야 하는 점과 아쉬웠던 점, 좋았던 점에 대해 자유롭게 의견 기재해주세요!" />
      {data?.length > 0 && (
        <>
          <ResponseBtn onClick={onClickResponse} />
          <ResponseText data={data} />
        </>
      )}
    </TabContainer>
  );
};

export default ReviewOpinion;
