import { useState } from "react";
import Layout from "components/register/Layout";
import RegisterSlider from "components/register/Slider";
import { StyledCard, StyledLink, SliderWrapper, SliderHelper } from "./styles";
import register_progress_six from "assets/register_progress_six.svg";
import register_analysis from "assets/register_analysis.svg";
import register_concept from "assets/register_concept.svg";
import register_production from "assets/register_production.svg";
import register_test from "assets/register_test.svg";
import register_review from "assets/register_review.svg";

const mockArr = [
  {
    name: "분석",
    desc: "고객을 정의하고 경험 시나리오 분석을 통해 고객의 니즈를 발굴합니다.",
    img: `${register_analysis}`,
  },
  {
    name: "컨셉",
    desc: "벤치마킹 리서치를 통한 인사이트를 도출합니다.",
    img: `${register_concept}`,
  },
  {
    name: "제작",
    desc: "대표 화면의 프로토타입을 제작합니다.",
    img: `${register_production}`,
  },
  {
    name: "테스트",
    desc: "조사를 계획하고 테스트를 진행합니다.",
    img: `${register_test}`,
  },
  {
    name: "리뷰",
    desc: "테스트 결과를 논의합니다.",
    img: `${register_review}`,
  },
];

const Finish = () => {
  const [currentPage, setCurrentPage] = useState(0);

  const onChangeSlider = (index) => {
    setCurrentPage(index);
  };
  return (
    <Layout>
      <StyledCard>
        <img src={register_progress_six} alt="sixth register progress" />
        <h1>
          회원가입이 완료되었습니다!
          <br />
          <span className="eng">I2B Campus</span>를 바로 둘러보세요!
        </h1>
        <StyledLink to="/">시작하기</StyledLink>
        <div>
          <SliderWrapper>
            <RegisterSlider onChangeSlider={onChangeSlider} data={mockArr} />
          </SliderWrapper>
          <SliderHelper>
            <strong>{mockArr[currentPage]?.name}</strong>
            <div />
            <p>{mockArr[currentPage]?.desc}</p>
          </SliderHelper>
        </div>
      </StyledCard>
    </Layout>
  );
};

export default Finish;
