import { Wrapper, Status, Name, Duration } from "./styles";

const ProjectInfo = ({ testInfo }) => {
  const getStatus = (projectStatusType) => {
    if (projectStatusType === "BEFORE_PROCEEDING") {
      return "진행 전";
    } else if (projectStatusType === "END_OF_PROGRESS") {
      return "종료";
    } else {
      return "진행 중";
    }
  };
  return (
    <Wrapper>
      <Status>{testInfo?.projectStatusType ? getStatus(testInfo.projectStatusType) : ""}</Status>
      <Name>{testInfo?.name ?? ""} Project 테스트</Name>
      <Duration>
        {testInfo?.projectStartDate ?? ""} ~ {testInfo?.projectEndDate ?? ""}
      </Duration>
    </Wrapper>
  );
};

export default ProjectInfo;
