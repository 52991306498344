import { useState, useEffect, useRef } from "react";
import Layout from "layouts/Layout";
import Content from "layouts/Content";
import ContentHeading from "layouts/Content/ContentHeading";
import Aside from "layouts/Aside";
import AsideInfo from "layouts/Aside/AsideInfo";
import UploadPhoto from "components/analysis/UploadPhoto";
import Personality from "components/analysis/Personality";
import { WhiteCard, Left, Right, PersonalityWrapper, InputWrapper, TextareaWrapper } from "./styles";
import { ANALYSIS_SUMARRY, ANALYSIS_SCENARIO, personalityArr } from "utils/data";
import useWarnUnsavedChange from "hooks/useWarnUnsaveChange";
import { customerGuide } from "utils/data";
import popup_customer from "assets/popup_customer.svg";
import CustomModal from "components/common/CustomModal";
import HelpModalContent from "components/common/HelpModalContent";
import axiosPrivate from "utils/axiosPrivate";
import { useParams, useNavigate } from "react-router-dom";
import useSWR from "swr";
import { fetcherPrivate } from "utils/fetcher";
import ToastPopup from "components/common/ToastPopup";
import useViewMode from "hooks/useViewMode";
import { useReactToPrint } from "react-to-print";

import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

const progressData = {
  prev: ANALYSIS_SUMARRY,
  next: ANALYSIS_SCENARIO,
};

const PERSONALITY_MAX = 3;

const Customer = () => {
  const scrollRef = useRef(null);
  const { id } = useParams();
  let navigate = useNavigate();
  const [progressIng, setProgressIng] = useState(false);

  // 뷰모드
  const isViewMode = useViewMode();

  // pdf 추출
  const printRef = useRef();

  const onClickPdf = useReactToPrint({ content: () => printRef.current });

  // 도움말 팝업
  const [isHelpVisible, setIsHelpVisible] = useState(false);
  const onClickCloseModal = () => setIsHelpVisible(false);
  const onClickHelp = () => setIsHelpVisible(true);

  const {
    data: customerData,
    error: customerError,
    mutate: customerDataMutate,
  } = useSWR(`/api/v1/projects/${id}/analysis/customer-profile`, (url) => fetcherPrivate(url), { revalidateOnFocus: false });

  console.log(customerData, "데이터");
  const { onChanged, onSaved } = useWarnUnsavedChange();

  const [inputs, setInputs] = useState({
    customerName: "",
    customerGender: "",
    customerAge: undefined,
    customerJob: "",
    characteristic: "",
    motivation: "",
    target: "",
  });
  const [file, setFile] = useState();

  const { customerName, customerGender, customerAge, customerJob, characteristic, motivation, target } = inputs;

  const inputHandler = (e) => setInputs({ ...inputs, [e.target.name]: e.target.value });

  const [personalityTypes, setCustomerPersonality] = useState([]);
  const onChangePersonality = (checked, id) => {
    if (checked) {
      setCustomerPersonality([...personalityTypes, id]);
    } else {
      const oldArr = [...personalityTypes];
      const newArr = oldArr.filter((personality) => personality !== id);
      setCustomerPersonality(newArr);
    }
  };

  useEffect(() => {
    if (customerData) {
      const { result } = customerData;
      const { customerName, customerGender, customerAge, customerJob, characteristic, motivation, target, personalityTypes } = result;
      setInputs({
        customerName,
        customerGender,
        customerAge,
        customerJob,
        characteristic,
        motivation,
        target,
      });
      setCustomerPersonality(personalityTypes.map((value) => personalityArr.find((val) => val.id === value).name));
    }
  }, [customerData]);

  useEffect(() => {
    onChanged();
  }, [inputs, personalityTypes, file]);

  const [isMax, setIsMax] = useState(false);

  useEffect(() => {
    if (personalityTypes?.length >= PERSONALITY_MAX) {
      setIsMax(true);
    } else {
      setIsMax(false);
    }
  }, [personalityTypes]);

  const [isToastVisible, setIsToastVisible] = useState(false);

  const patchCustomerInfo = () => {
    const data = { ...inputs, personalityTypes: personalityTypes.map((value) => personalityArr.find((val) => val.name === value).id) };

    const formData = new FormData();

    const req = new Blob([JSON.stringify(data)], { type: "application/json" });

    formData.append("req", req);

    if (file) {
      console.log(file, "파일");
      formData.append("customerHeadshot", file);
    }

    return axiosPrivate.patch(`/api/v1/projects/${id}/analysis/customer-profile`, formData);
  };

  const deleteCustomerPhoto = async () => {
    return axiosPrivate.delete(`/api/v1/projects/${id}/analysis/customer-profile/headshot`).then((result) => {});
  };

  const onSubmit = async (e, item) => {
    //e.preventDefault();
    setProgressIng(true);
    try {
      const res = await patchCustomerInfo();

      if (res) {
        onSaved();
        setIsToastVisible(true);
        setProgressIng(false);
        customerDataMutate();
        navigate(item);
      }
    } catch (error) {
      console.warn(error);
      window.alert("고객 프로필 저장에 실패했습니다. 다시 시도해주세요!");
      setProgressIng(false);
      navigate(item);
    }
    /* 이전 코드 이미지 서버에서 내려온게 있고 file없을때 사용한건데 이상함.
    if (customerData?.result?.customerHeadshotImage && !file) {
      Promise.allSettled([patchCustomerInfo(), deleteCustomerPhoto()]).then((result) => {
        console.log("!!!!!!!!!!!!!");
        if (result[0].status === "fulfilled" && result[1].status === "fulfilled") {
          onSaved();
          setIsToastVisible(true);
          setProgressIng(false);
          customerDataMutate();
          navigate(item);
        } else {
          window.alert("고객 프로필 저장에 실패했습니다. 다시 시도해주세요!");
          setProgressIng(false);
          navigate(item);
        }
      });
    } else {
      try {
        const res = await patchCustomerInfo();

        if (res) {
          onSaved();
          setIsToastVisible(true);
          setProgressIng(false);
          customerDataMutate();
          navigate(item);
        }
      } catch (error) {
        console.warn(error);
        window.alert("고객 프로필 저장에 실패했습니다. 다시 시도해주세요!");
        setProgressIng(false);
        navigate(item);
      }
    }*/
  };

  return (
    <>
      {progressIng && (
        <Box sx={{ position: "fixed", zIndex: "1000", left: "50%", top: "50%" }}>
          <CircularProgress size="100px" />
          {/*<Box sx={{ marginTop: "10px", fontSize: "30px", fontWeight: "500", color: "white", display: "flex", justifyContent: "center" }}>저장중</Box>*/}
        </Box>
      )}
      {isToastVisible && (
        <ToastPopup isToastVisible={isToastVisible} setIsToastVisible={setIsToastVisible}>
          저장에 성공했습니다!
        </ToastPopup>
      )}
      <Layout onClickPdf={onClickPdf} onSave={onSubmit} isViewMode={isViewMode}>
        <Content progressData={progressData} ref={scrollRef} onSave={onSubmit} isViewMode={isViewMode}>
          <form onSubmit={onSubmit} ref={printRef}>
            <ContentHeading>
              <h1>
                <span>고객</span>은 누구인가요?
              </h1>
              {/* 페이지 이동시 자동 저장으로 주석처리
              <button type="submit" disabled={isViewMode}>
                저장
              </button>
              */}
            </ContentHeading>
            <WhiteCard>
              {/* 좌측 */}
              <Left>
                <UploadPhoto file={file} setFile={setFile} imgSrc={customerData?.result?.customerHeadshotImage} isViewMode={isViewMode} deleteCustomerPhoto={deleteCustomerPhoto} />
                <div>
                  <InputWrapper>
                    <label htmlFor="customerName">이름</label>
                    <input type="text" id="customerName" name="customerName" value={customerName} onChange={inputHandler} placeholder="이름을 입력해주세요." disabled={isViewMode} />
                  </InputWrapper>
                  <InputWrapper>
                    <label htmlFor="customerGender">성별</label>
                    <input type="text" id="customerGender" name="customerGender" value={customerGender} onChange={inputHandler} placeholder="남/여/기타" disabled={isViewMode} />
                  </InputWrapper>
                  <InputWrapper>
                    <label htmlFor="customerAge">나이</label>
                    <input type="number" id="customerAge" name="customerAge" value={customerAge} onChange={inputHandler} placeholder="나이를 입력해주세요." disabled={isViewMode} />
                  </InputWrapper>
                  <InputWrapper>
                    <label htmlFor="customerJob">직업</label>
                    <input type="text" id="customerJob" name="customerJob" value={customerJob} onChange={inputHandler} placeholder="직업을 입력해주세요." disabled={isViewMode} />
                  </InputWrapper>
                </div>
              </Left>
              {/* 우측 */}
              <Right>
                <PersonalityWrapper>
                  <label>
                    성격 키워드 <span>(최대 3개 선택)</span>
                  </label>
                  <div>
                    <Personality name="personalityTypes" selectedArr={personalityTypes} onChange={onChangePersonality} isMax={isMax} isViewMode={isViewMode} />
                  </div>
                </PersonalityWrapper>
                <TextareaWrapper>
                  <label htmlFor="characteristic">특징</label>
                  <textarea id="characteristic" name="characteristic" value={characteristic} onChange={inputHandler} placeholder="• 고객의 특징을 작성하세요." disabled={isViewMode}></textarea>
                </TextareaWrapper>
                <TextareaWrapper>
                  <label htmlFor="motivation">동기</label>
                  <textarea
                    id="motivation"
                    name="motivation"
                    value={motivation}
                    onChange={inputHandler}
                    placeholder="• 고객에게 제품/서비스가 필요하게 된 동기를 작성하세요."
                    disabled={isViewMode}
                  ></textarea>
                </TextareaWrapper>
                <TextareaWrapper>
                  <label htmlFor="target">목표</label>
                  <textarea
                    id="target"
                    name="target"
                    value={target}
                    onChange={inputHandler}
                    placeholder="• 고객이 얻고 싶은 것, 제품/서비스를 통해 하려고 하는 것을 작성하세요."
                    disabled={isViewMode}
                  ></textarea>
                </TextareaWrapper>
              </Right>
            </WhiteCard>
          </form>
        </Content>
        <Aside>
          <AsideInfo heading="프로필 정보 입력" onClickHelp={onClickHelp}>
            제품/서비스를 사용할 주요 사용자의 특징과 목표에 대해 정의하는 과정입니다.
          </AsideInfo>
        </Aside>
      </Layout>
      {isHelpVisible && (
        <CustomModal isVisible={isHelpVisible}>
          <HelpModalContent headingText="고객 프로필 템플릿 사용 방법" guideArr={customerGuide} img={popup_customer} onClick={onClickCloseModal} />
        </CustomModal>
      )}
    </>
  );
};

export default Customer;
