import styled from "styled-components";
import { flexbox } from "styles/mixins";
import ic_slider_left from "assets/ic_slider_left.svg";
import ic_pencil_grey from "assets/ic_pencil_grey.svg";
import ic_pencil_black from "assets/ic_pencil_black.svg";
import ic_close_grey from "assets/ic_close_grey.svg";
import ic_close_black from "assets/ic_close_black.svg";
import ic_plus_grey from "assets/ic_plus_grey.svg";
import ic_plus_black from "assets/ic_plus_black.svg";

export const Wrapper = styled.div`
  min-width: 1106px;
  height: 48px;
  background-color: #e0e0e0;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
`;

export const SliderWrapper = styled.div`
  padding: 6px 26px 0;

  .slick-track {
    margin: 0;
  }

  // 슬라이더 이전/다음 버튼
  .slick-prev:before,
  .slick-next:before {
    display: none;
  }

  .slick-prev {
    top: 50%;
    left: -22px;
    display: block;
    width: 20px;
    height: 20px;
    margin: auto;
    background-image: url(${ic_slider_left});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    transform: rotate(0deg) translateY(-50%);
  }

  .slick-next {
    top: 50%;
    right: -22px;
    display: block;
    width: 20px;
    height: 20px;
    margin: auto;
    background-image: url(${ic_slider_left});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    transform: rotate(180deg) translateY(50%);
  }

  // slick slide
  .slick-slide {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;

    > div > div {
      display: flex !important;
      justify-content: space-between;
    }
  }

  // 타이틀 영역 전체
  .title {
    flex-grow: 1;
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    min-width: 210px;
    height: 42px;
    padding: 0 0 0 20px;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    background-color: #e0e0e0;
    transition: background-color 300ms ease-in-out;
    cursor: pointer;

    &:hover {
      background-color: rgba(255, 255, 255, 0.5);
    }

    // active인 경우 타이틀 배경색 변경
    &.is-active {
      background-color: #fff;
      &:hover {
        background-color: #fff;
      }
    }

    // 디폴트 벤치마킹 명

    .name-wrapper {
      ${flexbox("flex-start", "center")};
    }
    span {
      // margin: 0 0 0 20px;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #000000;
    }

    .benchmark-icon {
      display: block;
      width: 20px;
      height: 20px;
      margin: 0 8px 0 0;
      border-radius: 3px;
    }

    // 수정, 삭제 버튼 - 디폴트
    .btn-wrapper {
      ${flexbox("flex-start", "center")};
      margin: 0 20px 0 0;

      button {
        width: 11px;
        height: 11px;
        margin: 0 5px 0 0;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
      }

      button:disabled {
        cursor: default;
      }

      // 수정 버튼
      button:first-of-type {
        display: none;
        background-image: url(${ic_pencil_grey});

        &:not(:disabled):hover,
        &:not(:disabled):active {
          background-image: url(${ic_pencil_black});
        }
      }

      // 삭제 버튼
      button:last-of-type {
        display: none;
        background-image: url(${ic_close_grey});

        &:not(:disabled):hover,
        &:not(:disabled):active {
          background-image: url(${ic_close_black});
        }
      }
    }

    // 현재 is-active인 타이틀명쪽에는 수정, 삭제버튼 모두 보인다!
    &.is-active .btn-wrapper {
      button:first-of-type {
        display: block;
      }
      button:last-of-type {
        display: block;
      }
    }

    // 현재 is-active가 아닌 경우에는 hover하면 삭제 버튼이 나타난다!
    &:not(.is-active):hover .btn-wrapper {
      button:last-of-type {
        display: block;
      }
    }

    //타이틀 클래스 끝!
  }

  // divider
  .divider {
    display: block;
    width: 1px;
    height: 20px;
    margin: auto 0;
    background-color: #a6a9ae;
    transition: background-color 300ms ease-in-out;
    &.is-active {
      display: none;
    }
  }

  .slick-slide:hover .divider {
    background-color: transparent;
  }
`;

export const PlusBtn = styled.div`
  ${flexbox("flex-start", "center")};
  height: 42px;
  padding: 0 0 0 20px;
  background-color: transparent;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  transition: background-color 300ms ease-in-out;
  &:hover {
    background-color: rgba(255, 255, 255, 0.5);
  }
  button {
    display: block;
    width: 20px;
    height: 20px;
    background-image: url(${ic_plus_grey});
    background-repeat: no-repeat;
    background-size: conatin;
    background-position: center center;

    &:not(:disabled):hover {
      background-image: url(${ic_plus_black});
    }

    &:disabled {
      cursor: default;
    }
  }
`;
