import styled from "styled-components";
import { flexbox } from "styles/mixins";
import ic_x_white from "assets/ic_x_white.svg";

export const Input = styled.input`
  width: 60px;
  height: 32px;
  padding: 0 12px;
  border: 1px solid #4556ee;
  border-radius: 20px;
  caret-color: #4556ee;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #000000;
  text-align: center;
`;

export const Button = styled.button`
  height: 32px;
  padding: 0 11px;
  border: 1px solid #181818;
  border-radius: 20px;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: #000000;

  &:disabled {
    opacity: 0.2;
    pointer-events: none;
  }
`;

export const Self = styled.div`
  ${flexbox("space-between", "center")};
  min-width: 70px;
  height: 32px;
  padding: 0 13px;
  border-radius: 20px;
  background-color: #4556ee;
  color: #fff;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;

  span {
    margin: 0 10px 0 0;
  }

  button {
    position: relative;
    top: 1px;
    width: 9px;
    height: 9px;
    background-image: url(${ic_x_white});
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;

    &:disabled {
      cursor: default;
    }
  }
`;
