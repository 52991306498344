import styled from "styled-components";
import ic_slider_dot from "assets/ic_slider_dot.svg";
import ic_slider_dot_active from "assets/ic_slider_dot_active.svg";

export const Wrapper = styled.div`
  // 이미지

  .slick-slider img {
    width: 480px;
    height: 340px;
    margin: 10px auto 0;
    filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.12));
    border-radius: 4px;
  }

  // 화살표
  .slick-arrow {
    display: none !important;
  }

  .slick-dots {
    bottom: -18px;
  }

  .slick-dots li {
    width: 6px;
    height: 6px;
    margin: 0 6px 0 0;

    &:last-of-type {
      margin: 0;
    }
  }

  .slick-dots li > div {
    width: 6px;
    height: 6px;
    background-image: url(${ic_slider_dot});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
  }

  .slick-dots li.slick-active > div {
    background-image: url(${ic_slider_dot_active});
  }
`;
