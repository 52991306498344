import styled, { keyframes } from "styled-components";
import { flexboxColumn } from "styles/mixins";

const fadeIn = keyframes`
0%{
	opacity:0.2;
	transform:translateX(20%);
}


100%{
	opacity:1;
	transform:translateX(0%);
}
`;

export const Container = styled.div`
  ${flexboxColumn("flex-start", "center")};
  width: 730px;
  height: 544px;
  padding: 80px 0 100px 0;
  background-color: #fff;
  border: 1px solid #dddddd;
  border-radius: 8px;
  animation: ${fadeIn} 400ms ease-in;
  transition: opacity 4000ms ease-in, transform 400ms ease-in;

  img {
    width: 332px;
    height: 41px;
    object-fit: contain;
    margin: 0 0 66px 0;
  }

  h1 {
    font-family: "Noto Sans KR", sans-serif;
    font-size: 32px;
    font-weight: 700;
    line-height: 44px;
    text-align: center;
    color: #000;

    span.eng {
      font-family: "Poppins", sans-serif;
      font-weight: 600;
    }
  }
`;
