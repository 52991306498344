import styled from "styled-components";
import { flexbox } from "styles/mixins";

export const Item = styled.div`
  ${flexbox("flex-start", "center")};
  margin: 0 0 8px 0;
  padding: 0 18px 0 12px;
  cursor: pointer;
  position: relative;

  > div {
    ${flexbox("flex-start", "center")};
    pointer-events: none;
  }

  // 문항 번호
  > div:first-of-type > p:first-of-type {
    ${flexbox("center", "center")};
    width: 56px;
    height: 26px;
    margin: 0 10px 0 6px;
    border-radius: 20px;
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    transition: font-weight 300ms ease-in-out, border-color 300ms ease-in-out, color 300ms ease-in-out, background-color 300ms ease-in-out;
  }

  &:not(.disabled) > div:first-of-type > p:first-of-type {
    border: 1px solid #4556ee;
    background-color: ${(props) => (props.isCurrent ? "#4556EE" : "#fff")};
    color: ${(props) => (props.isCurrent ? "#fff" : "#4556ee")};
    font-weight: ${(props) => (props.isCurrent ? "600" : "500")};
  }

  &.disabled > div:first-of-type > p:first-of-type {
    border: 1px solid ${(props) => (props.isCurrent ? "#4556ee" : "#e0e0e0;")};
    background-color: ${(props) => (props.isCurrent ? "#4556ee" : "#fff")};
    color: ${(props) => (props.isCurrent ? "#fff" : "#B1B1B1")};
    font-weight: 500;
  }

  //문항 이름
  > div:first-of-type > p:nth-of-type(2) {
    font-size: 14px;
    line-height: 22px;
    transition: font-weight 300ms ease-in-out, color 300ms ease-in-out;
  }

  &:not(.disabled) > div:first-of-type > p:nth-of-type(2) {
    font-weight: ${(props) => (props.isCurrent ? "700" : "500")};
    color: ${(props) => (props.isCurrent ? "#4556EE" : "#000000")};
  }

  &.disabled > div:first-of-type > p:nth-of-type(2) {
    font-weight: ${(props) => (props.isCurrent ? "700" : "500")};
    color: #b1b1b1;
  }

  &.review.disabled {
    pointer-events: none;
  }
`;
