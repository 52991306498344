import styled from "styled-components";
import { flexboxColumn } from "styles/mixins";

export const Container = styled.div`
  ${flexboxColumn("flex-start", "center")};
  width: 730px;
  height: 512px;
  padding: 70px 0 0 0;
  background-color: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 8px;

  h1 {
    padding: 10px 0 0 0;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 32px;
    line-height: 44px;
    text-align: center;
    color: #000000;
  }
`;
