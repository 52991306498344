import { Container, HeadingWrapper, Heading, HelpBtn, Paragraph } from "./styles";
const AsideInfo = ({ heading, onClickHelp, hasNoBorder, isReview, children }) => {
  return (
    <Container hasNoBorder={hasNoBorder}>
      <HeadingWrapper>
        <Heading>{heading}</Heading>
        {!isReview && (
          <HelpBtn type="button" onClick={onClickHelp}>
            도움말
          </HelpBtn>
        )}
      </HeadingWrapper>
      <Paragraph>{children}</Paragraph>
    </Container>
  );
};

export default AsideInfo;
