import React, { useReducer } from "react";

export const defaultTextState = {
  introText: "",
  outroText: "",
  linkText: "",
};

export const textReducer = (state, action) => {
  if (action.type === "intro") {
    const newText = action.text;
    return { ...state, introText: newText };
  } else if (action.type === "outro") {
    const newText = action.text;
    return { ...state, outroText: newText };
  } else if (action.type === "link") {
    const newText = action.text;
    return { ...state, linkText: newText };
  }
  return defaultTextState;
};

export const TextContext = React.createContext({
  introText: "",
  outroText: "",
  linkText: "",
  setIntroText: (str) => {},
  setOutroText: (str) => {},
  setLinkText: (str) => {},
});

export const TextProvider = ({ children }) => {
  const [textState, dispatchTextAction] = useReducer(textReducer, defaultTextState);

  const setIntroTextHandler = (value) => {
    dispatchTextAction({
      type: "intro",
      text: value,
    });
  };

  const setOutroTextHandler = (value) => {
    dispatchTextAction({
      type: "outro",
      text: value,
    });
  };

  const setLinkTextHandler = (value) => {
    dispatchTextAction({
      type: "link",
      text: value,
    });
  };

  const textContext = {
    introText: textState.introText,
    outroText: textState.outroText,
    linkText: textState.linkText,
    setIntroText: setIntroTextHandler,
    setOutroText: setOutroTextHandler,
    setLinkText: setLinkTextHandler,
  };

  return <TextContext.Provider value={textContext}>{children}</TextContext.Provider>;
};
