import styled from "styled-components";

const Heading = styled.h3`
  margin: 0 0 8px 0;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #000000;
`;

const QuestionSub = ({ text }) => {
  return <Heading>{text}</Heading>;
};

export default QuestionSub;
