import styled from "styled-components";
import ic_reset_round from "assets/ic_reset_round.svg";
import { flexboxColumn, registerInputWrapperBase } from "styles/mixins";

export const Heading = styled.h1`
  width: 100%;
  background-image: url(${ic_reset_round});
  background-repeat: no-repeat;
  background-size: 99px 54px;
  background-position: 340px;
`;

export const Form = styled.form`
  ${flexboxColumn("space-between", "center")};
  height: 268px;
  margin: 60px 0 0 0;
`;

export const Input = styled.div`
  ${registerInputWrapperBase};
`;

export const ErrorText = styled.p`
  margin: 4px 0 0 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  color: #ce5e41;
`;
