import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axiosPublic from "utils/axiosPublic";
import Layout from "components/register/Layout";
import Card from "components/register/CertificationCard";
import NextBtn from "components/register/NextBtn";
import {
  Form,
  Heading,
  InputWrapper,
  Input,
  EmailText,
  HelperWrapper,
  Paragraph,
  BtnWrapper,
  ConfirmBtn,
} from "./styles";
import CustomModal from "components/common/CustomModal";
import { ModalContent, ModalDesc } from "pages/home/styles";
import axiosPrivate from "utils/axiosPrivate";

const Certification = () => {
  let navigate = useNavigate();
  let regExp = /[-]/gi;
  const [isModalVisible, setIsModalVisible] = useState(false);
  // 인풋값 상태 관리
  const [state, setState] = useState({
    code: "",
  });
  const { code } = state;
  const onChangeInput = (e) => {
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  // 모든 인풋창에 값을 입력해야 '다음으로'버튼 클릭 가능
  const [isDisabled, setIsDisabled] = useState(true);
  useEffect(() => {
    if (code.length > 0) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [code]);

  const onClickConfirm = () => setIsModalVisible(false);

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axiosPrivate.get(
        `/api/v1/open/auth-codes/${code}/info`
      );

      if (res) {
        window.localStorage.setItem("code", res.data.result?.authCode);
        window.localStorage.setItem("logoImage", res.data.result?.logoImage);
        window.localStorage.setItem(
          "companyName",
          res.data.result?.companyName
        );
        navigate("/register/baseInfo");
      }
    } catch (error) {
      console.warn(error);
      setIsModalVisible(true);
    }
  };

  return (
    <>
      <Layout>
        <Card>
          <Heading>
            안녕하세요! <br />
            <span className="eng">I2B Campus</span>에 오신걸 환영합니다!
          </Heading>
          <Paragraph>
            {
              "초대 코드를 입력하시면 일정 기간 동안 베타 테스트 이용할 수 있습니다."
            }
          </Paragraph>
          <Form onSubmit={onSubmit}>
            <div>
              <InputWrapper>
                <Input className="name">
                  <label>베타 테스트를 위한 초대 코드를 입력하세요.</label>
                  <input
                    type="text"
                    name="code"
                    value={code}
                    onChange={onChangeInput}
                    maxLength="50"
                  />
                </Input>
              </InputWrapper>
            </div>
            <NextBtn type="submit" disabled={isDisabled}>
              다음으로
            </NextBtn>
            <HelperWrapper>
              <Link style={{ color: "#808080" }} to="/">
                {"인증 코드에 문제가 있으신가요?"}
              </Link>
            </HelperWrapper>
          </Form>
        </Card>
      </Layout>
      {isModalVisible && (
        <CustomModal isVisible={isModalVisible}>
          <ModalContent>
            <h2>유효하지 않은 인증 코드입니다.</h2>
            <ModalDesc>
              <p>
                인증 코드를 잊으셨나요?
                <br />
                I2B Campus에 문의해보세요.
              </p>
              <BtnWrapper>
                <ConfirmBtn type="button" onClick={onClickConfirm}>
                  확인
                </ConfirmBtn>
              </BtnWrapper>
            </ModalDesc>
          </ModalContent>
        </CustomModal>
      )}
    </>
  );
};

export default Certification;
