import { useState, useEffect } from "react";
import axiosPrivate from "utils/axiosPrivate";
import Radios from "components/register/Radios";
import Checkbox from "components/common/Checkbox";
import { companySize, series, businessType, teamSize } from "utils/data";
import { Form, Label, FlexWrapper, AreaWrapper, RadioWrapper, CheckboxWrapper } from "./styles";
import { TabCard, TextInput, SaveBtn } from "../styles";
import getRandomColor from "utils/getRandomColor";

const CompanyTab = ({ uid, setIsToastVisible, setHasSaveError }) => {
  // 라디오, 체크 배경색 랜덤 설정
  const [randomBgArr, setRandomBgArr] = useState([]);
  useEffect(() => {
    setRandomBgArr(getRandomColor(businessType.length));
  }, []);

  // 인풋값
  const [area, setArea] = useState([]);
  const [areaFromServer, setAreaFromServer] = useState([]);

  const onChangeArea = (checked, id) => {
    if (checked) {
      setArea([...area, id]);
    } else {
      const oldAreaArr = [...area];
      const newAreaArr = oldAreaArr.filter((area) => area !== id);
      setArea(newAreaArr);
    }
  };

  const [companyName, setCompanyName] = useState("");
  const [companyNameFromServer, setCompanyNameFromServer] = useState("");

  const [compSize, setCompSize] = useState();
  const [compSizeFromServer, setCompSizeFromServer] = useState();

  const [level, setLevel] = useState();
  const [levelFromServer, setLevelFromServer] = useState();

  const [investor, setInvestor] = useState("");
  const [investorFromServer, setInvestorFromServer] = useState("");

  const [size, setSize] = useState();
  const [sizeFromServer, setSizeFromServer] = useState();

  const onChangeCompanyName = (e) => setCompanyName(e.target.value);
  const onChangeCompSize = (size) => setCompSize(size);
  const onChangeLevel = (level) => setLevel(level);
  const onChangeInvestor = (e) => setInvestor(e.target.value);
  const onChangeSize = (num) => setSize(num);

  useEffect(() => {
    axiosPrivate
      .get(`/api/v1/members/${uid}/company`)
      .then((res) => {
        const { businessTypes, companyName, companySize: serverCompanySize, mainVc, series: serverSeries, teamSize: serverTeamSize } = res.data.result;
        setCompanyName(companyName);
        setCompanyNameFromServer(companyName);

        // 스타트업, 중소기업, 대기업
        setCompSize(serverCompanySize);
        setCompSizeFromServer(serverCompanySize);

        // 투자 단계
        setLevel(serverSeries);
        setLevelFromServer(serverSeries);

        //메인 투자사
        setInvestor(mainVc);
        setInvestorFromServer(mainVc);

        // 비즈니스 분야
        setArea(businessTypes);
        setAreaFromServer(businessTypes);

        // 팀 규모
        setSize(serverTeamSize);
        setSizeFromServer(serverTeamSize);
      })
      .catch((err) => {
        console.log(err);
        const errorCode = err.response.data.code;
        if (errorCode === "MEMBER_E_001") {
          // 자신의 회원 정보만 조회, 수정 가능
        } else if (errorCode === "MEMBER_E_002") {
          // 등록되지 않은 회원
          // 회원을 등록하거나 다시 로그인해라.
        }
      });
  }, []);

  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    if (
      companyName !== companyNameFromServer ||
      compSize !== compSizeFromServer ||
      level !== levelFromServer ||
      investor !== investorFromServer ||
      size !== sizeFromServer ||
      JSON.stringify(area) != JSON.stringify(areaFromServer)
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [companyName, companyNameFromServer, compSize, compSizeFromServer, level, levelFromServer, investor, investorFromServer, size, sizeFromServer, area, areaFromServer]);

  // 회사 정보 수정
  const onSubmit = (e) => {
    e.preventDefault();

    setIsToastVisible(false);
    setHasSaveError(false);

    const data = {
      companyName,
      companySize: compSize,
      series: level,
      mainVc: investor,
      businessTypes: area,
      teamSize: size,
    };

    axiosPrivate
      .patch(`api/v1/members/${uid}/company`, data)
      .then((res) => {
        console.log(res);
        setHasSaveError(false);
        setIsToastVisible(true);
      })
      .catch((error) => {
        console.log(error);
        setHasSaveError(true);
        setIsToastVisible(true);
      });
  };

  return (
    <TabCard>
      <Form onSubmit={onSubmit}>
        <div>
          <FlexWrapper>
            <TextInput>
              <label htmlFor="comanyName">회사명</label>
              <input type="text" value={companyName} onChange={onChangeCompanyName} />
            </TextInput>
          </FlexWrapper>

          <RadioWrapper style={{ margin: "8px 0 0 92px" }}>
            <Radios name="type" array={companySize} selectedValue={compSize} onChange={onChangeCompSize} isBlue />
          </RadioWrapper>
        </div>
        <div>
          <FlexWrapper>
            <Label htmlFor="level">투자단계</Label>
            <RadioWrapper>
              <Radios id="level" name="level" array={series} selectedValue={level} onChange={onChangeLevel} />
            </RadioWrapper>
          </FlexWrapper>
        </div>
        <div>
          <TextInput>
            <label htmlFor="investor">메인 투자사</label>
            <input type="text" value={investor} onChange={onChangeInvestor} />
          </TextInput>
        </div>
        <AreaWrapper>
          <Label htmlFor="area">비즈니스 분야</Label>
          <CheckboxWrapper>{randomBgArr && <Checkbox name="area" id="area" array={businessType} randomBgArr={randomBgArr} selectedArr={area} onChange={onChangeArea} />}</CheckboxWrapper>
        </AreaWrapper>
        <div>
          <FlexWrapper>
            <Label htmlFor="size">팀 규모</Label>
            <RadioWrapper>
              <Radios name="size" array={teamSize} selectedValue={size} onChange={onChangeSize} />
            </RadioWrapper>
          </FlexWrapper>
        </div>
        <SaveBtn type="submit" disabled={isDisabled}>
          저장
        </SaveBtn>
      </Form>
    </TabCard>
  );
};

export default CompanyTab;
