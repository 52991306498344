import styled from "styled-components";
import { flexbox } from "styles/mixins";

export const Wrapper = styled.div`
  input[type="checkbox"] {
    width: 0;
    height: 0;
    position: absolute;
    left: -9999px;
  }

  input[type="checkbox"] + label {
    position: relative;
    ${flexbox("center", "center")};
    height: 32px;
    padding: 0 12px;
    border: 1px solid #d6d6d6;
    border-radius: 20px;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    background: #ffffff;
    color: #000000;
    transition: border-color 300ms ease-in-out, background-color 300ms ease-in-out, opacity 300ms ease-in-out, font-weight 300ms ease-in-out;
    cursor: pointer;
  }

  input[type="checkbox"]:checked + label {
    background-color: ${(props) => props.bg};
    border-color: ${(props) => props.bg};
    font-weight: 700;
    z-index: 1;
  }

  input[type="checkbox"]:not(:checked) + label {
    pointer-events: ${(props) => (props.isMax ? "none" : "initial")};
    opacity: ${(props) => (props.isMax ? "0.2" : "1")};
  }

  input[type="checkbox"]:disabled + label {
    cursor: default;
  }
`;
