import styled from "styled-components";
import { testTextareaBase } from "styles/mixins";
import { TESTER } from "utils/data";
const Textarea = styled.textarea`
  ${testTextareaBase};
  height: 250px;
`;

const TestTextarea = ({ placeholder, status, name, value, onChange }) => {
  const isDisabled = status === TESTER ? false : true;
  return <Textarea id={name} placeholder={placeholder} disabled={isDisabled} value={value} onChange={onChange}></Textarea>;
};

export default TestTextarea;
