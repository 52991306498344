import React, { useRef, useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { TextContext } from "context/text-context";
import axiosPrivate from "utils/axiosPrivate";
import Layout from "layouts/Layout";
import Content from "layouts/Content";
import ContentHeading from "layouts/Content/ContentHeading";
import Aside from "layouts/Aside";
import AsideInfo from "layouts/Aside/AsideInfo";
import TabList from "components/test/TabList";
import TabOutlet from "components/test/TabOutlet";
import popup_form from "assets/popup_form.svg";
import CustomModal from "components/common/CustomModal";
import HelpModalContent from "components/common/HelpModalContent";
import { PreviewLink, TestListHeader, DefaultItem, Form, ScrollArea } from "./styles";
import { PRODUCTION, TEST_RESULT, formGuide } from "utils/data";
import { USER } from "utils/data";
import useSWR from "swr";
import { fetcherPrivate } from "utils/fetcher";
import ToastPopup from "components/common/ToastPopup";
import { testStepIdDic } from "utils/data";
import useViewMode from "hooks/useViewMode";
import { useReactToPrint } from "react-to-print";

import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

const progressData = {
  prev: PRODUCTION,
  next: TEST_RESULT,
};

export const defaultList = [
  { id: 2, name: "제품/서비스 사용 의향", testSurveyType: "PRODUCT_USE_INTENTION", blocked: false },
  { id: 3, name: "비용 지불 의향", testSurveyType: "PAY_INTENTION", blocked: false },
  { id: 4, name: "예상 사용 빈도", testSurveyType: "EXPECTED_FREQUENCY_USE", blocked: false },
  { id: 5, name: "디자인 만족도", testSurveyType: "DESIGN_SATISFACTION", blocked: false },
  { id: 6, name: "콘텐츠 만족도", testSurveyType: "CONTENT_SATISFACTION", blocked: false },
  { id: 7, name: "기능 만족도", testSurveyType: "FUNCTIONAL_SATISFACTION", blocked: false },
  { id: 8, name: "유사 서비스", testSurveyType: "SIMILAR_SERVICE", blocked: false },
];

const TestForm = () => {
  const scrollRef = useRef(null);
  let navigate = useNavigate();
  const { id: projectId } = useParams();
  const [progressIng, setProgressIng] = useState(false);
  const isViewMode = useViewMode();

  // pdf 추출
  const printRef = useRef();

  const onClickPdf = useReactToPrint({ content: () => printRef.current });

  // 도움말 팝업
  const [isHelpVisible, setIsHelpVisible] = useState(false);
  const onClickCloseModal = () => setIsHelpVisible(false);
  const onClickHelp = () => setIsHelpVisible(true);

  // '제작>화면구성' 데이터 -> TabOutlet -> 캐러셀에 전달
  const { data: layoutData, error: layoutError, mutate: layoutMutate } = useSWR(`/api/v1/projects/${projectId}/produce/layout`, (url) => fetcherPrivate(url), { revalidateOnFocus: false });
  const [layoutArr, setLayoutArr] = useState([]);
  useEffect(() => {
    if (layoutData?.result?.produceLayouts?.length > 0) {
      setLayoutArr(layoutData.result.produceLayouts);
    }
  }, [layoutData]);

  // 테스트 정보 -> TabOutlet
  const [tabs, setTabs] = useState([]);

  //intro탭, outro탭, LinkInput에서 작성한 값 가져오기
  const textCtx = useContext(TextContext);
  const { introText, outroText, linkText, setIntroText, setOutroText, setLinkText } = textCtx;

  const { data: surveyData, error: surveyError, mutate: surveyMutate } = useSWR(`/api/v1/projects/${projectId}/test/survey`, (url) => fetcherPrivate(url), { revalidateOnFocus: false });
  useEffect(() => {
    if (surveyData) {
      const { greetings, testSurveyIndices, closingWords } = surveyData.result;
      if (testSurveyIndices?.length > 0) {
        const targetTabs = [...testSurveyIndices].slice(0, testSurveyIndices.length - 1);
        const newTabs = targetTabs.map((item, i) => {
          const { testSurveyType, use } = item;
          const id = testStepIdDic[testSurveyType];
          return { id, testSurveyType, blocked: !use };
        });
        setTabs(newTabs);
      } else {
        setTabs(defaultList);
      }

      // greetings 있는 경우
      if (greetings?.length > 0) {
        setIntroText(greetings);
      }

      // closingWords 있는 경우
      if (closingWords?.length > 0) {
        setOutroText(closingWords);
      }
    }
  }, [surveyData]);

  // 링크 데이터
  const { data: linkData, error: linkError, mutate: linkMutate } = useSWR(`/api/v1/projects/${projectId}/test/prototype/url`, (url) => fetcherPrivate(url), { revalidateOnFocus: false });
  useEffect(() => {
    if (linkData?.result?.length > 0) {
      setLinkText(linkData.result);
    }
  }, [linkData]);

  // 사용자가 인사한 인사말/끝내는말 설문 미리보기에 바로 보일 수 있도록 local에 저장
  useEffect(() => {
    window.localStorage.setItem("intro", JSON.stringify(introText));
  }, [introText]);

  useEffect(() => {
    window.localStorage.setItem("outro", JSON.stringify(outroText));
  }, [outroText]);

  useEffect(() => {
    window.localStorage.setItem("link", JSON.stringify(linkText));
  }, [linkText]);

  // tab 전환
  const [currentTabId, setCurrentTabId] = useState(0);
  const onClickTab = (e) => {
    const id = +e.target.id;
    setCurrentTabId(id);
  };

  // 새 탭에서 열리는 '설문 미리보기' 페이지에 토글된 탭과 바뀐 탭 순서를 담은 상태를 전달하기 위해 localStorage 사용
  useEffect(() => {
    window.localStorage.setItem("tabs", JSON.stringify(tabs));
  }, [tabs]);

  // 토글 버튼 이용하여 설문 사용/미사용 적용
  const onToggleTab = (id, blocked) => {
    const oldTabs = [...tabs];
    const targetTab = oldTabs.find((item) => item.id === id);
    const newTargetTab = { ...targetTab, blocked };
    const newTabs = oldTabs.map((item) => (item.id === id ? newTargetTab : item));
    setTabs(newTabs);
  };

  //--------------------------------------------------------------------------------------------------
  // 저장 보내기
  const patchSurveryInfo = () => {
    const surveyIndices = tabs.map((item, i) => {
      const { id, testSurveyType, blocked } = item;
      const priorityNum = i + 1;
      const priority = priorityNum.toString();
      let used;
      if (blocked) {
        used = "FALSE";
      } else {
        used = "TRUE";
      }
      return {
        testSurveyType,
        priority,
        used,
      };
    });
    const data = {
      greetings: introText,
      personInfoTos: true,
      surveyIndices,
      closingWords: outroText,
    };

    return axiosPrivate.patch(`/api/v1/projects/${projectId}/test/survey`, data);
  };

  const patchProtoLink = () => {
    const data = {
      prototypeUrl: linkText,
    };
    return axiosPrivate.put(`/api/v1/projects/${projectId}/test/prototype/url`, data);
  };

  // 토스트 팝업
  const [isToastVisible, setIsToastVisible] = useState(false);

  const onSubmit = (e, item) => {
    //e.preventDefault();
    const surveyIndices = tabs.map((item, i) => {
      const { id, testSurveyType, blocked } = item;
      const priorityNum = i + 1;
      const priority = priorityNum.toString();
      let used;
      if (blocked) {
        used = "FALSE";
      } else {
        used = "TRUE";
      }
      return {
        testSurveyType,
        priority,
        used,
      };
    });
    console.log(introText, "입력데이터");
    const data = {
      greetings: introText ? introText : "안녕하세요. 제품/서비스의 시장성 검증을 위한 테스트를 실시하고자 합니다.\n제품/서비스에 대한 솔직한 의견을 상세하게 작성해주세요.",
      personInfoTos: true,
      surveyIndices,
      closingWords: outroText ? outroText : "귀하께서 보내주신 답변은 신규 프로덕트 개발을 위해 소중히 사용하겠습니다.\n감사합니다.",
    };
    axiosPrivate
      .patch(`/api/v1/projects/${projectId}/test/survey`, data)
      .then((res) => {
        const data = {
          prototypeUrl: linkText,
        };
        axiosPrivate.put(`/api/v1/projects/${projectId}/test/prototype/url`, data).then((res) => {
          surveyMutate();
          linkMutate();
          setIsToastVisible(true);
          navigate(item);
        });
      })
      .catch((err) => {
        window.alert("테스트 설문 정보 저장에 실패했습니다. 다시 시도해주주세요!");
        //navigate(item);
      });
  };
  {
    /* 기존 버튼 사용시 소스
  const onSubmit = (e) => {
    //e.preventDefault();
    const axiosList = linkText?.length > 0 ? [patchSurveryInfo(), patchProtoLink()] : [patchSurveryInfo()];
    Promise.all([axiosList])
      .then((res) => {
        console.log(res);
        surveyMutate();
        linkMutate();
        setIsToastVisible(true);
      })
      .catch((err) => {
        console.log(err);
        window.alert("테스트 설문 정보 저장에 실패했습니다. 다시 시도해주주세요!");
      });
  }; */
  }

  return (
    <>
      {progressIng && (
        <Box sx={{ position: "fixed", zIndex: "1000", left: "50%", top: "50%" }}>
          <CircularProgress size="100px" />
          {/*<Box sx={{ marginTop: "10px", fontSize: "30px", fontWeight: "500", color: "white", display: "flex", justifyContent: "center" }}>저장중</Box>*/}
        </Box>
      )}
      {isToastVisible && (
        <ToastPopup isToastVisible={isToastVisible} setIsToastVisible={setIsToastVisible}>
          저장에 성공했습니다!
        </ToastPopup>
      )}
      <Layout onClickPdf={onClickPdf} onSave={onSubmit} isViewMode={isViewMode}>
        <Content progressData={progressData} ref={scrollRef} onSave={onSubmit} isViewMode={isViewMode}>
          <Form onSubmit={onSubmit} ref={printRef}>
            <ContentHeading>
              <h1>
                제품/서비스를 테스트하기 위한 <span>설문을 작성</span>하세요
              </h1>
              {/* 페이지 이동시 자동 저장으로 주석처리
              <button type="submit" disabled={isViewMode}>
                저장
              </button>
              */}
            </ContentHeading>
            {/* 탭 들어갈 자리 */}
            <TabOutlet currentTabId={currentTabId} status={USER} layoutArr={layoutArr} isViewMode={isViewMode} />
          </Form>
        </Content>
        <Aside>
          <AsideInfo heading="설문 입력" onClickHelp={onClickHelp}>
            실제 잠재 고객을 대상으로 제품/서비스를 테스트하기 위한 설문작성 과정입니다.
          </AsideInfo>
          <PreviewLink to={`/test/preview/${projectId}`} target="_blank" rel="noopener noreferrer">
            설문 미리보기
          </PreviewLink>
          <TestListHeader>설문 목록</TestListHeader>
          <ScrollArea>
            <DefaultItem id="0" isActive={currentTabId === 0} onClick={onClickTab}>
              <p>시작</p>
              <p>인사말</p>
            </DefaultItem>
            <TabList tabs={tabs} setTabs={setTabs} onClick={onClickTab} currentTabId={currentTabId} onToggleTab={onToggleTab} isViewMode={isViewMode} />
            <DefaultItem id="10" isActive={currentTabId === 10} onClick={onClickTab}>
              <p>맺음</p>
              <p>끝내는 말</p>
            </DefaultItem>
          </ScrollArea>
        </Aside>
      </Layout>
      {isHelpVisible && (
        <CustomModal isVisible={isHelpVisible}>
          <HelpModalContent headingText="설문 작성 템플릿 사용 방법" guideArr={formGuide} img={popup_form} onClick={onClickCloseModal} />
        </CustomModal>
      )}
    </>
  );
};

export default TestForm;
