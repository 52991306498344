import axiosPrivate from "utils/axiosPrivate";
import { useNavigate } from "react-router-dom";

const useLogout = () => {
  let navigate = useNavigate();

  const logout = () => {
    axiosPrivate
      .delete("/api/v1/auth/sign-out")
      .then((resp) => {
        console.log(resp);
        window.localStorage.clear();
        // 로그인 페이지로
        navigate("/");
      })
      .catch((err) => {
        console.log(err.response.data.code);
        const errorCode = err.response.data.code;
        if (errorCode === "AUTH_E_005") {
          // 마지막에 발행된 토큰을 사용하세요.
        } else if (errorCode === "AUTH_E_004") {
          // 인증이 필요한 요청입니다.
        }
      });
  };

  return { logout };
};

export default useLogout;
