import { useState, useEffect } from "react";
import LinkInfo from "components/test/LinkInfo";
import LinkInput from "components/test/LinkInput";
import TabTag from "components/test/TabTag";
import QuestionMain from "components/test/QuestionMain";
import YesNoRadio from "components/test/YesNoRadio";
import QuestionSub from "components/test/QuestionSub";
import TestTextarea from "components/test/TestTextarea";
import PrevNextBtn from "components/test/PrevNextBtn";
import { TabContainer } from "../../../../pages/test/form/styles";
import { PREVIEW, USER, TESTER } from "utils/data";
import TesterPrevNextBtn from "components/test/TesterPrevNextBtn";
import patchTesterAnswer from "utils/patchTesterAnswer";

const Use = ({ isShowing, status, link, projectId, testerUid, savedAnswer }) => {
  const [use, setUse] = useState();
  const [text, setText] = useState();

  useEffect(() => {
    if (status === TESTER && savedAnswer) {
      const { productUseIntentionAnswer, productUseIntentionYn } = savedAnswer;
      if (productUseIntentionYn?.length > 0) setUse(productUseIntentionYn);
      if (productUseIntentionAnswer?.length > 0) setText(productUseIntentionAnswer);
    }
  }, [savedAnswer]);

  const onChangeUse = (e) => {
    setUse(e.target.value);
    if (status === TESTER) {
      patchTesterAnswer(projectId, testerUid, "productUseIntentionYn", e.target.value, "PRODUCT_USE_INTENTION")
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
          window.alert("제품/서비스 사용 의향 저장에 실패했습니다. 관리자에게 문의 부탁드립니다.");
        });
    }
  };

  const onChangeText = (e) => {
    setText(e.target.value);
    if (status === TESTER) {
      patchTesterAnswer(projectId, testerUid, "productUseIntentionAnswer", e.target.value, "PRODUCT_USE_INTENTION")
        .then((res) => console.log(res))
        .catch((err) => {
          console.log(err);
          window.alert("제품/서비스 사용 의향 이유 저장에 실패했습니다. 관리자에게 문의 부탁드립니다.");
        });
    }
  };

  return (
    <TabContainer isShowing={isShowing}>
      {status !== USER ? <LinkInfo link={link} /> : <LinkInput />}
      <TabTag text="제품/서비스 사용 의향" />
      <QuestionMain text="본 제품/서비스가 출시된다면 사용하실 의향이 있으신가요?" />
      <YesNoRadio name="use" status={status} selectedValue={use} onChange={onChangeUse} />
      <QuestionSub text="위와 같이 선택하신 이유가 무엇인가요?" />
      <TestTextarea name="use" placeholder="테스터의 작성이 이곳에 표시됩니다." status={status} value={text} onChange={onChangeText} />
      {status === PREVIEW && <PrevNextBtn />}
      {status === TESTER && <TesterPrevNextBtn />}
    </TabContainer>
  );
};

export default Use;
