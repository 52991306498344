import React from "react";
import styled from "styled-components";
import ic_test_drag from "assets/ic_test_drag.svg";
import ic_test_drag_active from "assets/ic_test_drag_active.svg";

const HandleWrapper = styled.button`
  display: block;
  width: 6px;
  height: 14px;
  cursor: ${({ isDragging }) => (isDragging ? "grabbing" : "grab")};
  background-image: url(${(props) => (props.isCurrent ? ic_test_drag_active : ic_test_drag)});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;

  &:disabled {
    pointer-events: none;
    cursor: default;
  }
`;

const DragHandle = (props) => {
  return <HandleWrapper {...props} disabled={props.isViewMode}></HandleWrapper>;
};

export default DragHandle;
