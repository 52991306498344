import styled from "styled-components";
import { flexbox, positionCenter } from "styles/mixins";
import { Link } from "react-router-dom";
import ic_home from "assets/ic_home.svg";
import ic_triangle_down from "assets/ic_triangle_down.svg";
import ic_analysis from "assets/ic_analysis.svg";
import ic_concept from "assets/ic_concept.svg";
import ic_prod from "assets/ic_prod.svg";
import ic_test from "assets/ic_test.svg";
import ic_review from "assets/ic_review.svg";
import ic_analysis_active from "assets/ic_analysis_active.svg";
import ic_concept_active from "assets/ic_concept_active.svg";
import ic_prod_active from "assets/ic_prod_active.svg";
import ic_test_active from "assets/ic_test_active.svg";
import ic_review_active from "assets/ic_review_active.svg";
import ic_alarm from "assets/ic_alarm.svg";
import home_avatar_1 from "assets/home_avatar_1.png";

// 1단 header 높이 36px
// 2단 nav 높이 40px
// 3단 각 페이지 nav 높이 40px

//  1단
export const Header = styled.header`
  ${flexbox("center", "center")};
  width: 100%;
  height: 36px;
  padding: 0 16px;
  background-color: #222;
  color: #fff;
  position: relative;
`;

export const HomeLink = styled(Link)`
  position: absolute;
  top: 8px;
  left: 16px;
  width: 18px;
  height: 18px;
  background-image: url(${ic_home});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
`;

export const HeadingWrapper = styled.div`
  ${flexbox("flex-start", "center")};

  h1 {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    color: #fff;
    margin: 0 9px 0 0;
  }

  p {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 12px;
    text-align: center;
    line-height: 20px;
    color: #727272;
    border: 1px solid #727272;
    border-radius: 100px;
    padding: 1px 12px;
    margin: 0 10px 0 0;
  }
`;

export const ProjectMenu = styled.div`
  position: relative;
  ${flexbox("center", "center")};
`;

export const DropdownBtn = styled.button`
  width: 18px;
  height: 18px;
  background-color: transparent;
  background-image: url(${ic_triangle_down});
  background-repeat: no-repeat;
  background-size: 10px 5px;
  background-position: center 7px;
  border-radius: 4px;
  transition: background-color 400ms ease-in-out;

  &:hover,
  &:focus {
    background-color: #4e4e4e;
  }
`;

export const ProjectMenuList = styled.ul`
  position: absolute;
  top: 7px;
  left: 64px;

  width: 128px;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.12));

  opacity: ${(props) => (props.isActive ? "1" : "0")};
  visibility: ${(props) => (props.isActive ? "visible" : "hidden")};
  transform: ${(props) => (props.isActive ? "translate3d(-50%, 20px, 0)" : "translate3d(-50%, -10px, 0)")};
  transition: opacity 300ms ease-in-out, transform 300ms ease-in-out, visibility 300ms ease-in-out;
  z-index: 999;

  li {
    &:not(:last-of-type) {
      margin: 0 0 2px 0;
    }

    a,
    button {
      ${flexbox("flex-start", "center")};
      width: 100%;
      height: 36px;
      padding: 0 11px;
      background-color: #fff;
      font-weight: 500;
      font-size: 12px;
      line-height: 24px;
      border-radius: 4px;
      color: #000000;
      transition: background-color 300ms ease-in-out, color 300ms ease-in-out;

      &:disabled {
        cursor: not-allowed;
      }
    }

    a:hover,
    button:hover {
      background-color: #eceefd;
      color: #4556ee;
    }
  }

  ul:first-of-type {
    padding: 3px 3px 6px;
    border-bottom: 1px solid #e0e0e0;
  }

  ul:last-of-type {
    padding: 6px 3px 3px;
  }
`;

// 2단

export const NavWrapper = styled.div`
  ${flexbox("space-between", "center")};
  position: relative;
  height: 40px;
  border-bottom: 1px solid #f3f3f3;

  // 아바타 버튼
  > div {
    position: absolute !important;
    top: 5px !important;
    right: 14px !important;
  }
`;

export const StyledNav = styled.nav`
  ul {
    ${flexbox};
    margin: 0 0 -1px 0;
    overflow: hidden;
  }

  a {
    ${flexbox("flex-start", "center")};
    height: 40px;
    padding: 0 18px 0 33px;
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    color: #a6a9ae;
    background-size: 15px 15px;
    background-repeat: no-repeat;
    background-position: 14px center;
    border-right: 1px solid #f3f3f3;
    border-bottom: 1px solid #f3f3f3;
  }

  a.is-active {
    color: #000;
    border-bottom: 1px solid #fff;
  }

  a.analysis {
    background-image: url(${ic_analysis});
    &.is-active {
      background-image: url(${ic_analysis_active});
    }
  }

  a.concept {
    background-image: url(${ic_concept});

    &.is-active {
      background-image: url(${ic_concept_active});
    }
  }

  a.production {
    background-image: url(${ic_prod});
    &.is-active {
      background-image: url(${ic_prod_active});
    }
  }

  a.test {
    background-image: url(${ic_test});
    &.is-active {
      background-image: url(${ic_test_active});
    }
  }

  a.review {
    background-image: url(${ic_review});
    &.is-active {
      background-image: url(${ic_review_active});
    }
  }
`;

export const ProfileMenu = styled.div`
  ${flexbox("flex-start", "center")};

  position: relative;
  padding: 0 13px 0 0;
`;

export const ShareBtn = styled.button`
  ${flexbox("center", "center")};
  height: 26px;
  padding: 0 20px;
  margin: 0 14px 0 0;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 11px;
  line-height: 22px;
  border-radius: 100px;
  background-color: #181818;
  color: #fff;
`;

export const AlarmBtn = styled.button`
  width: 24px;
  height: 24px;
  margin: 0 14px 0 0;
  background-image: url(${ic_alarm});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
`;

export const ProfileBtn = styled.button`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid #fff;
  background-image: url(${home_avatar_1});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.12));
  img {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: contain;
  }
`;

export const ProfileMenuList = styled.ul`
  ${positionCenter("absolute")};
  opacity: ${(props) => (props.isActive ? "1" : "0")};
  visibility: ${(props) => (props.isActive ? "visible" : "hidden")};
  transform: ${(props) => (props.isActive ? "translate3d(-50%, 20px, 0)" : "translate3d(-50%, -10px, 0)")};
  transition: opacity 300ms ease-in-out, transform 300ms ease-in-out, visibility 300ms ease-in-out;

  //임시
  width: 180px;
`;
