import axiosPublic from "./axiosPublic";

const patchTesterAnswer = (projectId, testerUid, dataKey, dataValue, step) => {
  const data = {
    [dataKey]: dataValue,
    testCaseAnswerStepType: step,
  };
  return axiosPublic.patch(`/api/v1/open/projects/${projectId}/test/case/${testerUid}`, data);
};

export default patchTesterAnswer;
