import styled from "styled-components";
import ic_ellipsis from "assets/ic_ellipsis.svg";
import { flexbox, truncate } from "styles/mixins";
import { BEFORE_PROCEEDING, PROCEEDING, COMPLETION } from "utils/data";

export const Item = styled.li`
  width: 220px;
  height: 250px;
  border-radius: 6px;
`;

export const ItemLink = styled.div`
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  cursor: pointer;
`;

export const ItemButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  width: 220px;
  height: 250px;
  border-radius: 6px;
  cursor: pointer;
`;

export const ItemHeader = styled.header`
  position: relative;
  height: 55px;
  padding: 7px 0 0 12px;
  background-color: ${(props) => (props.projectStatusType === PROCEEDING ? "#619F54" : props.projectStatusType === COMPLETION ? " #70BECD" : "#CE5E41")};
  border-left: 1px solid ${(props) => (props.projectStatusType === PROCEEDING ? "#619F54" : props.projectStatusType === COMPLETION ? " #70BECD" : "#CE5E41")};
  border-top: 1px solid ${(props) => (props.projectStatusType === PROCEEDING ? "#619F54" : props.projectStatusType === COMPLETION ? " #70BECD" : "#CE5E41")};
  border-right: 1px solid ${(props) => (props.projectStatusType === PROCEEDING ? "#619F54" : props.projectStatusType === COMPLETION ? " #70BECD" : "#CE5E41")};
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.6);
`;

// 멤버
export const MemberWrapper = styled.ul`
  ${flexbox("flex-start", "center")};
  position: absolute;
  top: 34px;
  left: 22px;
  width: 100%;
`;

export const Member = styled.li`
  width: 38px;
  height: 38px;
  border-radius: 50%;
  border: 2px solid #fff;
  margin: 0 0 0 -10px;
  z-index: ${(props) => props.index};
  img {
    width: 100%;
    height: 100%;
    // object-fit: contain;
    border-radius: 50%;
  }
`;

export const ItemContent = styled.div`
  height: 194px;
  padding: 30px 18px 18px;
  border-right: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  border-left: 1px solid #e0e0e0;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
`;

export const Version = styled.p`
  display: inline-block;
  height: 26px;
  padding: 3px 15px;
  margin: 0 0 6px 0;
  background-color: #f7f7f7;
  border-radius: 100px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #4556ee;
`;

export const Title = styled.h3`
  ${truncate};
  margin: 0 0 4px 0;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
`;

export const Duration = styled.p`
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.4);
`;

export const DefList = styled.dl`
  margin: 26px 0 0 0;

  > div {
    ${flexbox("flex-start", "center")};
  }

  dt {
    min-width: 45px;
    margin: 0 44px 0 0;
    font-weight: 700;
    font-size: 12px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.6);
  }

  dd {
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.6);
  }
`;

// 드롭다운
export const MenuWrapper = styled.div`
  position: absolute;
  top: 6px;
  right: 6px;
`;

export const MenuBtn = styled.button`
  width: 23px;
  height: 23px;
  background-color: transparent;
  background-image: url(${ic_ellipsis});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 15px 3px;
  border-radius: 4px;
  transition: background-color 300ms ease-in-out;

  &:hover,
  &:focus {
    background-color: rgba(0, 0, 0, 0.2);
  }
`;

export const MenuList = styled.ul`
  position: absolute;
  top: 10px;
  left: 64px;
  width: 128px;
  padding: 3px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.12));
  z-index: 9999;
  opacity: ${(props) => (props.isActive ? "1" : "0")};
  visibility: ${(props) => (props.isActive ? "visible" : "hidden")};
  transform: ${(props) => (props.isActive ? "translate3d(-50%, 20px, 0)" : "translate3d(-50%, -10px, 0)")};
  transition: opacity 300ms ease-in-out, transform 300ms ease-in-out, visibility 300ms ease-in-out;
`;

export const MenuItem = styled.li`
  button,
  a {
    ${flexbox("flex-start", "center")};
    width: 100%;
    height: 36px;
    padding: 0 11px;
    border-radius: 4px;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    text-align: left;
    color: #000000;
    background-color: #fff;
    transition: background-color 300ms ease-in-out, color 300ms ease-in-out;

    &:hover,
    &:focus {
      color: #4556ee;
      background-color: #eceefd;
    }
  }
`;
