import styled from "styled-components";
import { testTextareaBase } from "styles/mixins";

export const Label = styled.label`
  display: block;
  margin: 50px 0 10px;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
`;

export const Textarea = styled.textarea`
  ${testTextareaBase};
  height: 300px;
  margin: 0 0 28px 0;
`;
