import { TemplateHeading, TemplateList, BtnWrapper, ImgBtn, TemplateItem } from "./styles";

const TemplateInfo = ({ templateArr, currentTemplate, onClickTemplateExample, isViewMode }) => {
  const onClick = (item) => {
    if (item.templateId === currentTemplate.templateId) {
      onClickTemplateExample(item, false);
    } else {
      onClickTemplateExample(item, true);
    }
  };

  return (
    <>
      <TemplateHeading>템플릿 선택하기</TemplateHeading>
      <div>
        <TemplateList>
          {templateArr.map((item, i) =>
            item.name === "템플릿 2" ? (
              ""
            ) : (
              <TemplateItem key={item.name}>
                {/* <BtnWrapper isChecked={currentTemplate.registerType === "ADMIN" && currentTemplate.id === item.id}> */}
                <BtnWrapper isChecked={currentTemplate.templateId === item.id}>
                  <button type="button" onClick={() => onClick(item)} disabled={isViewMode}></button>
                  <h3>{item.name === "템플릿 3" ? "템플릿 2" : item.name}</h3>
                </BtnWrapper>
                {/* <ImgBtn isChecked={currentTemplate.registerType === "ADMIN" && currentTemplate.id === item.id}> */}
                <ImgBtn isChecked={currentTemplate.templateId === item.id}>
                  <button type="button" onClick={() => onClick(item)} disabled={isViewMode}></button>
                  <img src={item.image} alt={item.name} referrerPolicy="no-referrer" />
                </ImgBtn>
              </TemplateItem>
            )
          )}
        </TemplateList>
      </div>
    </>
  );
};

export default TemplateInfo;
