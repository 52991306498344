import styled from "styled-components";
import { flexbox } from "styles/mixins";

export const List = styled.ul`
  ${flexbox("flex-start", "center")};
  flex-wrap: wrap;
  gap: 6px;
`;

export const Item = styled.li`
  ${flexbox("center", "center")};
  height: 32px;
  padding: 0 12px;

  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  border-radius: 20px;
  color: #000000;
  background-color: ${(props) => props.bg};
`;
