import React, { useEffect } from "react";
import Progress from "layouts/Progress";
import { StyledContent, Canvas } from "./styles";

const Content = React.forwardRef(({ progressData, onClickFinish, isViewMode, children, onSave }, ref) => {
  let timeout;

  const addClass = () => {
    if (!document.querySelector(".canvas").classList.contains("scrolling")) {
      document.querySelector(".canvas")?.classList.add("scrolling");
    } else {
      timeout = setTimeout(() => {
        document.querySelector(".canvas")?.classList.remove("scrolling");
      }, 3000);
    }
  };

  useEffect(() => {
    ref.current.addEventListener("scroll", addClass);

    return () => {
      if (ref.current) {
        clearTimeout(timeout);
        ref.current.removeEventListener("scroll", addClass);
      }
    };
  });

  return (
    <StyledContent>
      <Canvas className="canvas" ref={ref}>
        {children}
      </Canvas>
      <Progress progressData={progressData} onClickFinish={onClickFinish} isViewMode={isViewMode} onSave={onSave} />
    </StyledContent>
  );
});

export default Content;
