import styled from "styled-components";
import { canvasBase, sectionBase } from "styles/mixins";

export const StyledContent = styled.section`
  ${sectionBase};
  position: relative;
`;

export const Canvas = styled.div`
  ${canvasBase};
  padding: 31px 22px 0 32px;
  margin: 0 4px 4px 0;

  &.scrolling {
    ::-webkit-scrollbar-thumb {
      background-color: rgba(144, 144, 144, 0.3);
    }
  }
`;
