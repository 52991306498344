import styled from "styled-components";
import { flexbox, flexboxColumn } from "styles/mixins";
import ic_add_plus_blue from "assets/ic_add_plus_blue.svg";
import ic_scenario_delete from "assets/ic_scenario_delete.svg";

export const Wrapper = styled.div`
  // width: calc(100vw - 276px);
  height: calc(100vh - 36px - 40px - 40px - 97px - 70px);
  padding: 6px 0 0 0;
`;

export const LineWrapper = styled.div`
  position: relative;
  width: max-content;
  min-width: 100%;
  padding: 11px 0 0 0;
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: #cfcfcf;
`;

export const BtnWrapper = styled.div`
  ${flexbox};
  position: relative;
  top: -16px;
  button {
    flex-shrink: 0;
    z-index: 1;
    width: 32px;
    height: 32px;
    margin: 0 364px 0 0;
    border-radius: 50%;
    border: 1px solid #d6d6d6;
    background-color: #fff;
    background-image: url(${ic_add_plus_blue});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 12px 12px;
    filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.12));

    &:first-of-type {
      margin: 0 364px 0 67px;
    }

    &:last-of-type {
      margin: 0 75px 0 0;
    }

    &:disabled {
      cursor: default;
    }
  }
`;

export const CardWrapper = styled.div`
  ${flexbox};
  position: relative;
  top: -33px;
  padding: 0 0 0 121px;
  cursor: ${(props) => (props.isViewMode ? "default" : "pointer")};
`;

// 시나리오 카드 관련
export const Card = styled.div`
  ${flexboxColumn("center", "center")};
  width: 320px;
  background-color: #fff;
  position: relative;
  margin: 34px 76px 0 0;
  border: 1px solid ${(props) => (props.isFocused ? "#4556EE" : "transparent")};
  border-radius: 4px;
  transition: border-color 300ms ease-in-out;
  &:last-of-type {
    margin: 34px 0 0 0;
  }
`;

export const Step = styled.p`
  ${flexbox("center", "center")};
  position: absolute;
  top: -51px;
  min-width: 78px;
  max-width: 90px;
  height: 32px;
  padding: 0 8px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  border-radius: 20px;
  border: 1px solid ${(props) => (props.isFocused ? "#4556EE" : "#D6D6D6")};
  color: ${(props) => (props.isFocused ? "#fff" : "#353535")};
  background-color: ${(props) => (props.isFocused ? "#4556EE" : "#fff")};
  transition: border-color 300ms ease-in-out, color 300ms ease-in-out, background-color 300ms ease-in-out;
`;

export const Content = styled.div`
  width: 100%;
  padding: 18px 16px;
  position: relative;
`;

export const Div = styled.div`
  position: relative;
  top: -21px;
  left: 50%;
  width: 14px;
  height: 14px;
  transform: rotate(45deg) translateX(-50%);
  background-color: #fff;
  border-top: 1px solid ${(props) => (props.isFocused ? "#4556EE" : "#fff")};
  border-left: 1px solid ${(props) => (props.isFocused ? "#4556EE" : "#fff")};
  transition: border-color 300ms ease-in-out;
  & + div {
    margin: -14px 0 0 0;
  }
`;

export const DelBtn = styled.button`
  position: absolute;
  top: 12px;
  right: 12px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #f3f3f3;
  background-image: url(${ic_scenario_delete});
  background-repeat: no-repeat;
  background-size: 8px 8px;
  background-position: center center;
  visibility: ${(props) => (props.isFocused ? "visible" : "hidden")};
  opacity: ${(props) => (props.isFocused ? "1" : "0")};
  transition: visibility 300ms ease-in-out, opacity 300ms ease-in-out;

  &:disabled {
    cursor: default;
  }
`;

export const FeelingWrapper = styled.div`
  margin: -14px 0 0 0;
`;

export const Label = styled.label`
  margin: 0 0 8px 0;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #000000;
`;

export const FeelingList = styled.ul`
  ${flexbox};
  padding: 15px 4px 0;
  margin: 0 0 15px 0;
`;

export const FeelingItem = styled.li`
  margin: 0 10px 0 0;
  &:last-of-type {
    margin: 0;
  }
  input[type="radio"] {
    width: 0;
    height: 0;
    position: absolute;
    left: -9999px;
    visibility: hidden;
  }

  input[type="radio"] + label {
    ${flexbox("center", "center")};
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border: 1px solid #d6d6d6;
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
    color: #b1b1b1;
    cursor: pointer;
  }

  input[type="radio"]:checked + label {
    background-image: url(${(props) => props.bg});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    color: transparent;
    border: 1px solid transparent;
  }

  input[type="radio"]:disabled + label {
    cursor: default;
  }
`;

export const FlexWrapper = styled.div`
  position: relative;
  ${flexboxColumn};
  margin: 0 0 15px 0;
  &:last-of-type {
    margin: 0;
  }
`;

export const Textarea = styled.textarea`
  resize: none;
  overflow: hidden;
  width: 288px;
  height: 122px;
  padding: 16px;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  font-family: "Noto Sans KR", sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
  transition: border-color 300ms ease-in-out;

  ::placeholder {
    color: #b1b1b1;
  }

  &:focus {
    border: 1px solid #181818;
  }
`;

export const LengthCounter = styled.p`
  position: absolute;
  right: 8px;
  bottom: 7px;
  font-weight: 500;
  font-size: 11px;
  line-height: 10px;
  color: #b1b1b1;
`;
