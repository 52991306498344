import styled from "styled-components";
import ic_x_modal_close from "assets/ic_x_modal_close.svg";
import ic_export from "assets/ic_export.svg";
import ic_step_last from "assets/ic_step_last.svg";
import { flexbox, flexboxColumn } from "styles/mixins";

export const Container = styled.div`
  ${flexboxColumn("center", "center")};
  width: 1106px;
  padding: 134px 28px 25px;
  background-color: #ffffff;
  border-radius: 8px;
  position: relative;
  background-image: url(${ic_step_last});
  background-size: 27px 34px;
  background-repeat: no-repeat;
  background-position: center 80px;
`;

export const CloseBtn = styled.button`
  display: block;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 28px;
  right: 28px;
  background-image: url(${ic_x_modal_close});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
`;

export const Heading = styled.h1`
  font-weight: 700;
  font-size: 32px;
  line-height: 22px;
  color: #000000;
  margin: 0 0 19px 0;
`;

export const InfoText = styled.p`
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #727272;
  margin: 0 0 47px 0;
`;

export const InputWrapper = styled.div`
  &:not(:last-of-type) {
    margin: 0 0 20px 0;
  }

  &:last-of-type {
    margin: 0 0 51px 0;
  }

  input[type="radio"] {
    width: 0;
    height: 0;
    position: absolute;
    left: -9999px;
  }

  input[type="radio"] + label {
    ${flexboxColumn("center", "center")};
    width: 814px;
    height: 100px;
    background-color: #f2f2f2;
    border-radius: 50px;

    span {
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      color: rgba(0, 0, 0, 0.7);
      transition:color 300ms ease-in-out;
    }

    strong {
      font-weight: 700;
      font-size: 24px;
      line-height: 24px;
      color: #000000;
      margin: 0 0 10px 0;
      transition:color 300ms ease-in-out;
    }

    cursor: pointer;
    transition: background-color 300ms ease-in-out;


    &:hover{
      background-color  #4556EE;        

      span{
        color:rgba(255,255,255,0.7);
      }

      strong{
        color:#fff;
      }
    }
  }

    input[type="radio"]:checked + label{
      background-color  #4556EE;        
      span{
        color:rgba(255,255,255,0.7);
      }
      strong{
        color:#fff;
      }
    }
`;

export const ButtonWrapper = styled.div`
  ${flexbox("flex-start", "center")};
  align-self: end;
`;

export const PDFBtn = styled.button`
  display: block;
  padding: 0 0 0 26px;
  margin: 0 26px 0 0;
  background-image: url(${ic_export});
  background-repeat: no-repeat;
  background-size: 16px 18px;
  background-position: left center;

  span {
    font-weight: 700;
    font-size: 15px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.7);
  }
`;

export const ConfirmBtn = styled.button`
  ${flexbox("center", "center")};
  height: 44px;
  width: 125px;
  background-color: #000000;
  border-radius: 24px;
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  color: #ffffff;
  transition: background-color 300ms ease-in-out, color 300ms ease-in-out;

  &:disabled {
    background-color: #f3f3f3;
    color: #d6d6d6;
  }
`;
