import styled from "styled-components";
import { flexbox, flexboxColumn } from "styles/mixins";
import ic_checkbox_unchecked from "assets/ic_checkbox_unchecked.svg";
import ic_checkbox_checked from "assets/ic_checkbox_checked.svg";

export const Paragraph = styled.p`
  margin: 8px 0 0 0;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #808080;
`;

export const Form = styled.form`
  ${flexboxColumn("space-between", "center")};
  height: 374px;
  margin: 60px 0 0 0;
`;

export const HelperText = styled.p`
  margin: 4px 0 0 0;
  font-weight: 500;
  font-size: 9px;
  line-height: 22px;
  color: ${(props) => (props.isValid ? "#4556ee" : "#CE5E41")};
  transition: color 300ms ease-in-out;

  &:first-of-type {
    margin: 0 0 8px 0;
  }
`;

export const CheckboxWrapper = styled.div`
  margin: 37px 0 0;
`;

export const Checkbox = styled.div`
  ${flexbox("flex-start", "center")};

  input[type="checkbox"] {
    width: 0;
    height: 0;
    position: absolute;
    left: -9999px;
  }

  input[type="checkbox"] + label {
    display: inline-block;
    cursor: pointer;
    width: 12px;
    height: 12px;
    background-image: url(${ic_checkbox_unchecked});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
  }

  input[type="checkbox"]:checked + label {
    background-image: url(${ic_checkbox_checked});
  }

  p {
    margin: 0 0 0 6px;
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    color: #808080;

    a {
      font-weight: 500;
      font-size: 12px;
      line-height: 22px;
      color: #4556ee;
      text-decoration: underline;
    }
  }
`;
