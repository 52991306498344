import styled from "styled-components";
import { flexbox } from "styles/mixins";

export const Wrapper = styled.div`
  ${flexbox};

  p {
    margin: 0 10px 0 0;
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    color: #808080;
  }

  a {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    text-decoration-line: underline;
    color: #4556ee;
  }
`;
