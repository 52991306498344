import styled from "styled-components";
import { flexbox, flexboxColumn } from "styles/mixins";
import ic_question from "assets/ic_question.svg";
import login_image from "assets/login_image.svg";

export const Main = styled.main`
  ${flexbox("center", "center")};
  position: relative;
  width: 100vw;
  height: 100vh;
  background-color: #01c2d2;
  background-image: url(${login_image});
  background-repeat: no-repeat;
  background-size: auto;
  overflow: hidden;
`;

export const ContentWrapper = styled.div`
  ${flexbox("flex-start", "center")};
`;

export const Form = styled.form`
  height: 512px;
  padding: 65px 43px 0 44px;
  background-color: #fff;
  border-radius: 4px;

  position: absolute;
  top: 23.43vh;
  right: 13vw;

  h1 {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 34px;
    line-height: 44px;
    color: #000000;
    margin: 0 0 30px 0;
  }

  a.password {
    display: block;
    margin: 0 0 10px 11px;
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    text-decoration-line: underline;
    color: #808080;
  }

  button {
    width: 320px;
    height: 44px;
    margin: 18px 0 18px 0;
    background: #181818;
    border-radius: 100px;
    font-weight: 700;
    font-size: 12px;
    line-height: 22px;
    text-align: center;
    color: #ffffff;
  }
`;

export const InputWrapper = styled.div`
  ${flexboxColumn("flex-start", "center")};

  input {
    caret-color: #4556ee;
    height: 44px;
    width: 320px;
    padding: 0 18px;
    border-radius: 4px;
    border: 1px solid #e0e0e0;
    transition: border-color 300ms ease-in-out;
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    color: #000000;

    &:first-of-type {
      margin: 0 0 18px 0;
    }

    &:nth-of-type(2) {
      margin: 0 0 8px 0;
    }

    &:focus,
    &:active {
      border: 1px solid #181818;
    }

    ::placeholder {
      font-family: "Noto Sans KR", sans-serif;
      font-weight: 500;
      font-size: 12px;
      line-height: 22px;
      color: #b1b1b1;
    }
  }
`;

export const HelperText = styled.p`
  margin: -2px 0 0 0;
  padding: 10px 11px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #ce5e41;
  background-color: #f3f3f3;
`;

export const Register = styled.div`
  ${flexbox("center", "center")};

  p {
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    color: #808080;
    margin: 0 14px 0 0;
  }

  a {
    font-weight: 900;
    font-size: 12px;
    line-height: 22px;
    color: #4556ee;
  }
`;

export const Question = styled.div`
  position: absolute;
  right: 3.4%;
  bottom: 4%;

  a {
    ${flexbox("flex-start", "center")};
    height: 54px;
    padding: 0 66px 0 0;
    background-image: url(${ic_question});
    background-size: 54px 54px;
    background-repeat: no-repeat;
    background-position: right center;

    span {
      font-weight: 500;
      font-size: 12px;
      line-height: 22px;
      color: #000000;
    }
  }

  @media only screen and (max-height: 770px) {
    right: 1%;
    bottom: 1%;
  }
`;

export const BtnWrapper = styled.div`
  padding: 0 0 16px 0;
`;

export const ConfirmBtn = styled.button`
  ${flexbox("center", "center")};
  width: 320px;
  height: 44px;
  margin: 0 auto;
  font-weight: 700;
  font-size: 15px;
  line-height: 22px;
  background-color: #4556ee;
  color: #ffffff;
  border-radius: 100px;
`;
