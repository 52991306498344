import styled from "styled-components";

const Tag = styled.div`
  margin: 46px 0 0;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #4556ee;
`;

const TabTag = ({ text }) => {
  return <Tag>{text}</Tag>;
};

export default TabTag;
