import { ResponsiveBar } from "@nivo/bar";
import BarCustomTick from "../BarCustomTick";
import BarComponent from "../BarComponent";
import { Container, Wrapper, NumberWrapper } from "./styles";

// 기존 mock data
// {
//   response: "no",
//   no: 21,
//   noColor: "hsl(12, 59%, 53%)",
// },
// {
//   response: "yes",
//   yes: 64,
//   yesColor: "hsl(217, 90%, 61%)",
// },

// 서버 데이터
// [{answer:"Y", answerCount:1, answerRatio:50},{answer:"N", answerCount:1, answerRatio:50}]
const Bar = ({ data }) => {
  let modifiedData = [...data].map((item) =>
    item.answer === "Y"
      ? { response: "yes", yes: item.answerCount, ratio: item.answerRatio, yesColor: "hsl(217, 90%, 61%)" }
      : {
          response: "no",
          no: item.answerCount,
          ratio: item.answerRatio,
          noColor: "hsl(12, 59%, 53%)",
        }
  );

  let temp = modifiedData[1];
  modifiedData[1] = modifiedData[0];
  modifiedData[0] = temp;

  const totalNum = data.reduce((acc, cur) => acc + cur.answerCount, 0);

  return (
    <Container>
      <Wrapper>
        {data && (
          <ResponsiveBar
            data={modifiedData}
            keys={["yes", "no"]}
            indexBy="response"
            margin={{ top: 0, right: 0, bottom: 30, left: 29 }}
            padding={0.3}
            layout="horizontal"
            valueScale={{ type: "linear" }}
            indexScale={{ type: "band", round: true }}
            maxValue={totalNum}
            colors={({ id, data }) => String(data[`${id}Color`])}
            enableGridX={totalNum !== 0 ? true : false}
            enableGridY={false}
            animate={true}
            theme={{
              axis: { ticks: { text: { fontSize: 13, fontWeight: 400, lineHeight: 22, fill: "hsl(0, 0%, 69%)" } } },
              grid: { line: { stroke: "#ddd", strokeDasharray: "4 4" } },
            }}
            borderColor={{
              from: "color",
            }}
            axisTop={null}
            axisRight={null}
            axisBottom={
              totalNum !== 0
                ? {
                    tickSize: 0,
                    tickPadding: 8,
                    tickRotation: 0,
                    tickValues: 3,
                  }
                : null
            }
            axisLeft={{
              renderTick: BarCustomTick,
            }}
            role="application"
            barComponent={BarComponent}
          />
        )}
      </Wrapper>
      <NumberWrapper>
        <p>{data[0].answerRatio}%</p>
        <p>{data[1].answerRatio}%</p>
      </NumberWrapper>
    </Container>
  );
};

export default Bar;
