import { useEffect, useRef, forwardRef } from "react";
import { Wrapper, LineWrapper, Line, BtnWrapper, CardWrapper, Card, Step, Content, Label, Div, DelBtn, FeelingWrapper, FeelingList, FeelingItem, FlexWrapper, Textarea, LengthCounter } from "./styles";
import ic_emoji_one from "assets/ic_emoji_one.svg";
import ic_emoji_two from "assets/ic_emoji_two.svg";
import ic_emoji_three from "assets/ic_emoji_three.svg";
import ic_emoji_four from "assets/ic_emoji_four.svg";
import ic_emoji_five from "assets/ic_emoji_five.svg";

const feelingList = [
  {
    code: "ANGER",
    value: "분노",
  },
  {
    code: "SAD",
    value: "슬픔",
  },
  {
    code: "FRUSTRATION",
    value: "좌절",
  },
  {
    code: "EMBARRASSMENT",
    value: "당황",
  },
  {
    code: "SATISFIED",
    value: "만족",
  },
];

const situationPlaceholder = `・ 고객이 처한 상황을 입력해주세요.`;

const reviewPlaceholder = `・ 상황에서 고객이 느낀점을 입력하세요.
・ 부정적인 문제나, 긍정적인 느낌을 작성하세요.
`;

const needsPlaceholder = `・ 상황에서 고객이 원하는 것을 입력하세요.`;

const ScenarioList = forwardRef(({ inputs, btnNum, onChangeBtnNum, onChangeTextInput, onClickDeleteCard, currentCardId, setCurrentCardId, currentRef, setCurrentRef, isViewMode }, ref) => {
  const onClickAddBtn = (e) => onChangeBtnNum(e.target.id);

  const setFeelingBg = (name) => {
    switch (name) {
      case "ANGER":
        return ic_emoji_one;
      case "FRUSTRATION":
        return ic_emoji_two;
      case "EMBARRASSMENT":
        return ic_emoji_three;
      case "SAD":
        return ic_emoji_four;
      case "SATISFIED":
        return ic_emoji_five;
      default:
        return ic_emoji_one;
    }
  };

  const onChangeInput = (id, e) => onChangeTextInput(id, e.target.name, e.target.value);
  const onChangeFeeling = (id, e) => {
    onChangeTextInput(id, "customerMoodType", e.target.value);
  };

  // 현재 focus 된 카드 관리
  const cardRefs = useRef([]);
  useEffect(() => {
    if (inputs?.length > 0 && cardRefs.current?.length > 0) {
      setCurrentCardId(inputs[0].id);
      setCurrentRef(cardRefs?.current[0]);
    }
  }, []);

  const onClickCard = (id) => {
    const targetRef = cardRefs.current.find((item) => item.id === id);
    setCurrentCardId(id);
    setCurrentRef(targetRef);
  };

  // 카드 바깥 영역 클릭 시 focus 스타일링 없애기
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (cardRefs.current && currentRef && !currentRef.contains(e.target)) {
        setCurrentCardId();
        setCurrentRef();
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [currentRef]);

  return (
    <Wrapper className="wrapper">
      {/* 라인 */}
      <LineWrapper>
        <Line />
        <BtnWrapper>
          {Array(btnNum)
            .fill()
            .map((_, i) => (
              <button key={i} type="button" id={i} onClick={onClickAddBtn} disabled={isViewMode}></button>
            ))}
        </BtnWrapper>
      </LineWrapper>
      {/* 인풋 카드 */}
      <CardWrapper isViewMode={isViewMode} ref={ref}>
        {inputs?.map((item, i) => (
          <Card
            key={item.id}
            id={item.id}
            className={i % 3 === 0 ? "next-page" : ""}
            ref={(el) => (cardRefs.current[i] = el)}
            onClick={() => onClickCard(item.id)}
            isFocused={item.id === currentCardId}
          >
            <Step isFocused={item.id === currentCardId}>Step {i + 1}</Step>
            <Content>
              <Div isFocused={item.id === currentCardId} />
              <DelBtn type="button" onClick={() => onClickDeleteCard(item.id)} isFocused={item.id === currentCardId} disabled={isViewMode} />
              <FeelingWrapper>
                <Label>고객의 기분 상태</Label>
                <FeelingList>
                  {feelingList?.map((feeling, i) => (
                    <FeelingItem key={`${i}${item.id}`} bg={setFeelingBg(feeling.code)}>
                      <input
                        type="radio"
                        name={`feeling${i}${item.id}`}
                        id={`${i}${item.id}`}
                        value={feeling.code}
                        checked={item.customerMoodType === feeling.code}
                        onChange={(e) => onChangeFeeling(item.id, e)}
                        disabled={isViewMode}
                      />
                      <label htmlFor={`${i}${item.id}`}>{feeling.value}</label>
                    </FeelingItem>
                  ))}
                </FeelingList>
              </FeelingWrapper>
              <FlexWrapper>
                <Label htmlFor={`${item.id}situation`}>상황</Label>
                <Textarea
                  id={`${item.id}situation`}
                  name="situation"
                  placeholder={situationPlaceholder}
                  value={item.situation}
                  onChange={(e) => onChangeInput(item.id, e)}
                  maxLength={115}
                  disabled={isViewMode}
                ></Textarea>
                <LengthCounter>{item.situation.length}/115</LengthCounter>
              </FlexWrapper>
              <FlexWrapper>
                <Label htmlFor={`${item.id}experience`}>느낀점</Label>
                <Textarea
                  id={`${item.id}experience`}
                  name="experience"
                  placeholder={reviewPlaceholder}
                  value={item.experience}
                  onChange={(e) => onChangeInput(item.id, e)}
                  maxLenght={115}
                  disabled={isViewMode}
                ></Textarea>
                <LengthCounter>{item.experience?.length}/115</LengthCounter>
              </FlexWrapper>
              <FlexWrapper>
                <Label htmlFor={`${item.id}needs`}>니즈</Label>
                <Textarea
                  id={`${item.id}needs`}
                  name="needs"
                  placeholder={needsPlaceholder}
                  value={item.needs}
                  onChange={(e) => onChangeInput(item.id, e)}
                  maxLenght={115}
                  disabled={isViewMode}
                ></Textarea>
                <LengthCounter>{item.needs.length}/115</LengthCounter>
              </FlexWrapper>
            </Content>
          </Card>
        ))}
      </CardWrapper>
    </Wrapper>
  );
});
// const ScenarioList = ({ inputs, btnNum, onChangeBtnNum, onChangeTextInput, onClickDeleteCard, currentCardId, setCurrentCardId, currentRef, setCurrentRef, isViewMode }) => {
//   const onClickAddBtn = (e) => onChangeBtnNum(e.target.id);

//   const setFeelingBg = (name) => {
//     switch (name) {
//       case "ANGER":
//         return ic_emoji_one;
//       case "FRUSTRATION":
//         return ic_emoji_two;
//       case "EMBARRASSMENT":
//         return ic_emoji_three;
//       case "SAD":
//         return ic_emoji_four;
//       case "SATISFIED":
//         return ic_emoji_five;
//       default:
//         return ic_emoji_one;
//     }
//   };

//   const onChangeInput = (id, e) => onChangeTextInput(id, e.target.name, e.target.value);
//   const onChangeFeeling = (id, e) => {
//     onChangeTextInput(id, "customerMoodType", e.target.value);
//   };

//   // 현재 focus 된 카드 관리
//   const cardRefs = useRef([]);
//   useEffect(() => {
//     if (inputs?.length > 0 && cardRefs.current?.length > 0) {
//       setCurrentCardId(inputs[0].id);
//       setCurrentRef(cardRefs?.current[0]);
//     }
//   }, []);

//   const onClickCard = (id) => {
//     const targetRef = cardRefs.current.find((item) => item.id === id);
//     setCurrentCardId(id);
//     setCurrentRef(targetRef);
//   };

//   // 카드 바깥 영역 클릭 시 focus 스타일링 없애기
//   useEffect(() => {
//     const checkIfClickedOutside = (e) => {
//       if (cardRefs.current && currentRef && !currentRef.contains(e.target)) {
//         setCurrentCardId();
//         setCurrentRef();
//       }
//     };
//     document.addEventListener("mousedown", checkIfClickedOutside);
//     return () => {
//       document.removeEventListener("mousedown", checkIfClickedOutside);
//     };
//   }, [currentRef]);

//   return (
//     <Wrapper className="wrapper ">
//       {/* 라인 */}
//       <LineWrapper>
//         <Line />
//         <BtnWrapper>
//           {Array(btnNum)
//             .fill()
//             .map((_, i) => (
//               <button key={i} type="button" id={i} onClick={onClickAddBtn} disabled={isViewMode}></button>
//             ))}
//         </BtnWrapper>
//       </LineWrapper>
//       {/* 인풋 카드 */}
//       <CardWrapper isViewMode={isViewMode}>
//         {inputs?.map((item, i) => (
//           <Card
//             key={item.id}
//             id={item.id}
//             className={i % 3 === 0 ? "next-page" : ""}
//             ref={(el) => (cardRefs.current[i] = el)}
//             onClick={() => onClickCard(item.id)}
//             isFocused={item.id === currentCardId}
//           >
//             <Step isFocused={item.id === currentCardId}>Step {i + 1}</Step>
//             <Content>
//               <Div isFocused={item.id === currentCardId} />
//               <DelBtn type="button" onClick={() => onClickDeleteCard(item.id)} isFocused={item.id === currentCardId} disabled={isViewMode} />
//               <FeelingWrapper>
//                 <Label>고객의 기분 상태</Label>
//                 <FeelingList>
//                   {feelingList?.map((feeling, i) => (
//                     <FeelingItem key={`${i}${item.id}`} bg={setFeelingBg(feeling.code)}>
//                       <input
//                         type="radio"
//                         name={`feeling${i}${item.id}`}
//                         id={`${i}${item.id}`}
//                         value={feeling.code}
//                         checked={item.customerMoodType === feeling.code}
//                         onChange={(e) => onChangeFeeling(item.id, e)}
//                         disabled={isViewMode}
//                       />
//                       <label htmlFor={`${i}${item.id}`}>{feeling.value}</label>
//                     </FeelingItem>
//                   ))}
//                 </FeelingList>
//               </FeelingWrapper>
//               <FlexWrapper>
//                 <Label htmlFor={`${item.id}situation`}>상황</Label>
//                 <Textarea
//                   id={`${item.id}situation`}
//                   name="situation"
//                   placeholder={situationPlaceholder}
//                   value={item.situation}
//                   onChange={(e) => onChangeInput(item.id, e)}
//                   maxLength={115}
//                   disabled={isViewMode}
//                 ></Textarea>
//                 <LengthCounter>{item.situation.length}/115</LengthCounter>
//               </FlexWrapper>
//               <FlexWrapper>
//                 <Label htmlFor={`${item.id}experience`}>느낀점</Label>
//                 <Textarea
//                   id={`${item.id}experience`}
//                   name="experience"
//                   placeholder={reviewPlaceholder}
//                   value={item.experience}
//                   onChange={(e) => onChangeInput(item.id, e)}
//                   maxLenght={115}
//                   disabled={isViewMode}
//                 ></Textarea>
//                 <LengthCounter>{item.experience?.length}/115</LengthCounter>
//               </FlexWrapper>
//               <FlexWrapper>
//                 <Label htmlFor={`${item.id}needs`}>니즈</Label>
//                 <Textarea
//                   id={`${item.id}needs`}
//                   name="needs"
//                   placeholder={needsPlaceholder}
//                   value={item.needs}
//                   onChange={(e) => onChangeInput(item.id, e)}
//                   maxLenght={115}
//                   disabled={isViewMode}
//                 ></Textarea>
//                 <LengthCounter>{item.needs.length}/115</LengthCounter>
//               </FlexWrapper>
//             </Content>
//           </Card>
//         ))}
//       </CardWrapper>
//     </Wrapper>
//   );
// };

export default ScenarioList;
