import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "components/register/Layout";
import Card from "components/register/Card";
import Radios from "components/register/Radios";
import Checkbox from "components/common/Checkbox";
import NextBtn from "components/register/NextBtn";
import { Heading, Form, AreaLabel, CheckWrapper } from "./styles";
import { businessType, teamSize } from "utils/data";
import register_progress_five from "assets/register_progress_five.svg";
import getRandomColor from "utils/getRandomColor";
import axiosPublic from "utils/axiosPublic";
import axios from "axios";

const CompanyInfoSec = () => {
  let navigate = useNavigate();

  // 버튼 랜덤 배경색
  const [randomBgArr, setRandomBgArr] = useState([]);
  useEffect(() => {
    setRandomBgArr(getRandomColor(businessType?.length));
  }, [businessType]);

  // 비즈니스 영역
  const [area, setArea] = useState([]);

  const onChangeArea = (checked, id) => {
    if (checked) {
      setArea([...area, id]);
    } else {
      const oldAreaArr = [...area];
      const newAreaArr = oldAreaArr.filter((area) => area !== id);
      setArea(newAreaArr);
    }
  };

  // 팀 규모
  const [size, setSize] = useState();
  const onChangeSize = (num) => setSize(num);

  // 다음으로 버튼
  const [isDisabled, setIsDisabled] = useState(true);
  useEffect(() => {
    if (area?.length > 0 && size) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [area, size]);

  const onSubmit = (e) => {
    e.preventDefault();

    const data = JSON.parse(window.localStorage.getItem("register"));
    if (data) {
      const companyData = { ...data.company };
      const newCompanyData = { ...companyData, businessTypes: area, teamSize: size };
      const newData = { ...data, company: newCompanyData, authCode: window.localStorage.getItem("code") };

      const formData = new FormData();

      const blob = new Blob([JSON.stringify(newData)], { type: "application/json" });
      formData.append("req", blob);
      formData.append("headshot", null);

      axios({
        method: "post",
        baseURL: process.env.REACT_APP_API,
        url: "/api/v1/open/sign-up",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      })
        .then((resp) => {
          if (resp.data.code === "MEMBER_S_001") {
            window.localStorage.removeItem("register");
            navigate("/register/finish");
          }
        })
        .catch((err) => {
          const errorCode = err.response.data.code;
          if (errorCode === "EMAIL_E_006") {
            // TODO
            // 앞에서 인증 받은 이메일 유효 기간 (60분)이 지난 경우
          }
          window.alert("회원 가입에 실패했습니다. 다시 시도해주세요");
          console.log(err);
        });
    } else {
      // TODO
    }
  };

  return (
    <Layout>
      <Card>
        <img src={register_progress_five} alt="fifth register progress" />
        <Heading>마지막 질문입니다!</Heading>
        <Form onSubmit={onSubmit}>
          <div>
            <AreaLabel>
              비지니스 분야를 선택하세요. <span>(최소 1개 이상)</span>
            </AreaLabel>
            <CheckWrapper>
              {businessType?.length > 0 && randomBgArr && <Checkbox name="businessType" id="businessType" array={businessType} randomBgArr={randomBgArr} selectedArr={area} onChange={onChangeArea} />}
            </CheckWrapper>

            {teamSize?.length > 0 && <Radios name="teamSize" label="팀 규모를 선택하세요." array={teamSize} selectedValue={size} onChange={onChangeSize} />}
          </div>
          <NextBtn type="submit" disabled={isDisabled}>
            다음으로
          </NextBtn>
        </Form>
      </Card>
    </Layout>
  );
};

export default CompanyInfoSec;
