import styled from "styled-components";
import { MenuList } from "../ProjectCard/styles";

export const MenuWrapper = styled.div`
  position: fixed;
  top: 20px;
  right: 1.8vw;

  @media screen and (min-width: 1440px) {
    // position:fixed;
    right: 24px;
  }
`;

export const AvatarMenuList = styled(MenuList)`
  left: -33px;
  top: 18px;
`;

export const Button = styled.button`
  display: block;
  width: 30px;
  height: 30px;
  border: 2px solid #fff;
  border-radius: 50%;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.12));
  transition: border 300ms ease-in-out;

  &:hover,
  &:active,
  &:focus {
    border: 2px solid #4556ee;
  }

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
`;
