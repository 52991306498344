import { useState, useEffect } from "react";
import { Wrapper, Content, Checkbox, Heading, KeyList, InfoList, Update, StarIcon } from "./styles";
// TODO : mock
import mock_bench_kakao from "assets/mock_bench_kakao.svg";
import ic_star from "assets/ic_star.svg";

// TODO : isChecked
// 이미지 파일
// 키워드

const getOSString = (android, ios) => {
  if (android && ios) {
    return "AOS, IOS";
  } else if (android && !ios) {
    return "AOS";
  } else if (!android && ios) {
    return "IOS";
  } else {
    return "-";
  }
};

const isInList = (targetBenchId, currentBenchId, list) => {
  if (targetBenchId === currentBenchId) {
    return false;
  } else {
    const item = list.find((obj) => obj.benchmarkId === targetBenchId);
    if (item) {
      return true;
    } else {
      return false;
    }
  }
};

const BenchmarkingCard = ({ item, onClickBenchExample, currentBench, combinedBenchList, isViewMode }) => {
  const { benchmarkIcon, benchmarkId, name, android, ios, starRating, downloadCount, modifiedDate, benchmarkKeywords } = item;
  // 지금 currentBench는 아니지만 benchmarkId가 이미 combinedBenchList에 포함되어 있다면 클릭이 불가능해야 한다.
  const isDisabled = isInList(benchmarkId, currentBench.benchmarkId, combinedBenchList);

  const onClick = () => {
    if (benchmarkId === currentBench.benchmarkId) {
      onClickBenchExample(item, false);
    } else {
      onClickBenchExample(item, true);
    }
  };

  return (
    <Wrapper onClick={onClick} disabled={isDisabled || isViewMode}>
      <Content isChecked={benchmarkId === currentBench.benchmarkId}>
        <div className="checkbox"></div>
        <Heading>
          <img src={benchmarkIcon} alt={name} referrerPolicy="no-referrer" />
          <h5>{name}</h5>
        </Heading>
        <KeyList>{benchmarkKeywords?.length > 0 && benchmarkKeywords.map((item, i) => <li key={i}>{item.keywordName}</li>)}</KeyList>
        <InfoList>
          <div>
            <div>
              <dt>OS</dt>
              <dd>{getOSString(android, ios)}</dd>
            </div>
          </div>
          <div>
            <div>
              <dt>평점</dt>
              <dd>{starRating}</dd>
            </div>
          </div>
          <div>
            <div>
              <dt>다운로드</dt>
              <dd>
                {downloadCount}
                <StarIcon src={ic_star} alt="black star" />
              </dd>
            </div>
          </div>
        </InfoList>
        <Update>{modifiedDate.replaceAll("-", ".")} 업데이트 기준</Update>
      </Content>
    </Wrapper>
  );
};

export default BenchmarkingCard;
