import { Wrapper, Radio, Label } from "./styles";

const Radios = ({ name, label, array, selectedValue, onChange, isBlue }) => {
  const onChangeValue = (item) => onChange(item);

  return (
    <Wrapper>
      {label && <Label>{label}</Label>}
      <Radio isBlue={isBlue}>
        {array.map((item, i) => (
          <div key={i}>
            <input type="radio" name={name} id={item.code} checked={selectedValue === item.code} onChange={() => onChangeValue(item.code)} />
            <label htmlFor={item.code}>{item.value}</label>
          </div>
        ))}
      </Radio>
    </Wrapper>
  );
};

export default Radios;
