import styled from "styled-components";
import { flexbox } from "styles/mixins";
import ic_scenario_delete from "assets/ic_scenario_delete.svg";
import ic_arrow_enter_white from "assets/ic_arrow_enter_white.svg";

// insight 코멘트 박스
export const InsightBox = styled.div`
  position: absolute;
  top: 12px;
  left: ${(props) => (props.isLast ? "-112px" : "178px")};
  width: 320px;
  padding: 12px 12px 18px 12px;
  background-color: #ffffff;
  border-radius: 4px;
  filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.12));
  opacity: ${(props) => (props.isActive ? "1" : "0")};
  visibility: ${(props) => (props.isActive ? "visible" : "hidden")};
  transform: ${(props) => (props.isActive ? "translate3d(-50%, 20px, 0)" : "translate3d(-50%, -10px, 0)")};
  transition: opacity 300ms ease-in-out, transform 300ms ease-in-out, visibility 300ms ease-in-out;
  z-index: 9999;
`;

// 인사이트 코멘트 박스 - 헤딩
export const InsightBoxHeading = styled.div`
  ${flexbox("space-between", "center")};
  padding: 0 0 8px 2px;
  border-bottom: 1px solid #f3f3f3;

  h5 {
    font-weight: 700;
    font-size: 15px;
    line-height: 24px;
    color: #000000;
  }

  button {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #f3f3f3;
    background-image: url(${ic_scenario_delete});
    background-repeat: no-repeat;
    background-size: 8px 8px;
    background-position: center center;
  }
`;

// 인사이트 코멘트 박스 - 코멘트 리스트
export const InsightList = styled.ul`
  padding: 15px 4px 0;
`;

// 인사이트 코멘트 박스 - 코멘트 달기
export const InsightInput = styled.div`
  ${flexbox};
  padding: 0 4px;

  img {
    width: 24px;
    height: 24px;
    margin: 5px 10px 0 0;
    border-radius: 50%;
  }

  > div {
    ${flexbox};
    border-radius: 4px;
    border: 1px solid ${(props) => (props.focused ? "#181818" : "transparent")};
    transition: border-color 300ms ease-in-out;
    overflow: hidden;
  }

  textarea {
    min-height: 34px;
    width: 220px;
    overflow: hidden;
    resize: none;
    caret-color: #4556ee;
    padding: 8px 13px 8px 16px;
    background-color: #f5f5f5;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #000000;
    transition: border-color 300ms ease-in-out;

    ::placeholder {
      font-family: "Poppins", sans-serif;
      font-weight: 500;
      font-size: 12px;
      color: #b1b1b1;
    }

    ::-webkit-scrollbar {
      width: 8px;
    }
    ::-webkit-scrollbar-thumb {
      border: 2px solid #f5f5f5;
      background-clip: padding-box;
      border-radius: 6px;
      background-color: rgba(144, 144, 144, 0.3);
    }
  }

  button {
    width: 34px;
    height: 100%;
    background-color: #181818;
    background-image: url(${ic_arrow_enter_white});
    background-repeat: no-repeat;
    background-size: 11px 10px;
    background-position: center center;
    transition: background-color 300ms ease-in-out;

    &:disabled {
      cursor: default;
      background-color: #b1b1b1;
    }
  }
`;
