import styled from "styled-components";
import { Link } from "react-router-dom";
import { flexbox } from "styles/mixins";
import ic_link_arrow from "assets/ic_link_arrow.svg";

export const StyledLink = styled(Link)`
  ${flexbox("flex-start", "center")};
  height: 32px;
  padding: 0 28px 0 14px;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  color: #000;
  border: 1px solid #d6d6d6;
  border-radius: 20px;
  background-image: url(${ic_link_arrow});
  background-position: 183px 8px;
  background-repeat: no-repeat;
  background-size: 16px 16px;

  &.is-design {
    background-position: 194px 8px;
  }

  &:hover {
    color: #000;
  }
`;

export const TagWrapper = styled.div`
  margin: 40px 0 52px;
  padding: 0 0 0 14px;
`;

export const Tag = styled.div`
  ${flexbox("flex-start", "center")};
  margin: 0 0 19px 0;
  &:last-of-type {
    margin: 0;
  }

  h4 {
    min-width: 118px;
    margin: 0 18px 0 0;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    color: #808080;
  }
`;

export const CardWrapper = styled.div`
  ${flexbox("space-around", "center")};
  min-width: 1106px;
  width: 100%;
  padding: 0 11px 55px 13px;
`;
