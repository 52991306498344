import React, { useReducer } from "react";

export const defaultTabState = {
  currentTabId: 0,
  modifiedTabArr: [],
};

export const tabReducer = (state, action) => {
  if (action.type === "prev") {
    if (state.currentTabId !== 0) {
      const currentTabIndexInArr = state.modifiedTabArr.findIndex((item) => item.id === state.currentTabId);
      const nextTabIndexInArr = currentTabIndexInArr - 1;
      const nextTabId = state.modifiedTabArr[nextTabIndexInArr].id;
      return { ...state, currentTabId: nextTabId };
    }
  } else if (action.type === "next") {
    const currentTabIndexInArr = state.modifiedTabArr.findIndex((item) => item.id === state.currentTabId);
    const nextTabIndexInArr = currentTabIndexInArr + 1;
    const nextTabId = state.modifiedTabArr[nextTabIndexInArr].id;
    return { ...state, currentTabId: nextTabId };
  } else if (action.type === "complete") {
    return { ...state, currentTabId: 11 };
  } else if (action.type === "set") {
    return { ...state, modifiedTabArr: action.data };
  }
  return defaultTabState;
};

export const TabContext = React.createContext({
  currentTabId: 0,
  modifiedTabArr: [],
  setModifiedTabArr: () => {},
  goPrev: () => {},
  goNext: () => {},
  goComplete: () => {},
});

export const TabProvider = ({ children }) => {
  const [tabState, dispatchTabAction] = useReducer(tabReducer, defaultTabState);

  const setModifiedTabArrHandler = (tabArr) => {
    dispatchTabAction({ type: "set", data: tabArr });
  };

  const goPrevHandler = () => {
    dispatchTabAction({ type: "prev" });
  };
  const goNextHandler = () => {
    dispatchTabAction({ type: "next" });
  };

  const goCompleteHandler = () => {
    dispatchTabAction({ type: "complete" });
  };

  const tabContext = {
    currentTabId: tabState.currentTabId,
    modifiedTabArr: tabState.modifiedTabArr,
    setModifiedTabArr: setModifiedTabArrHandler,
    goPrev: goPrevHandler,
    goNext: goNextHandler,
    goComplete: goCompleteHandler,
  };

  return <TabContext.Provider value={tabContext}>{children}</TabContext.Provider>;
};
