import styled from "styled-components";
import { flexbox } from "styles/mixins";

export const Keywords = styled.div`
  ${flexbox("flex-start", "center")};
  padding: 0 15px;
  margin: 0 0 53px 0;

  h3 {
    margin: 0 12px 0 0;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    color: #000000;
    opacity: 0.7;
  }

  > div {
    ${flexbox("flex-start", "center")};
    gap: 6px;

    ul {
      ${flexbox("flex-start", "center")};
      gap: 6px;
    }
  }
`;

export const Keyword = styled.li`
  height: 32px;
  border-radius: 20px;
  padding: 0 12px;
`;

export const SelectedKeyword = styled(Keyword)`
  ${flexbox("center", "center")};
  background-color: ${(props) => props.bg};
  border: 1px solid ${(props) => props.bg};
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: ${(props) => (props.isTyped ? "#fff" : "#000")};
`;

export const VacantKeyword = styled(Keyword)`
  display: block;
  width: 92px;
  border: 1px solid #d6d6d6;
  :first-of-type {
    border: 1px solid #353535;
  }
`;

export const ImageWrapper = styled.div`
  ${flexbox("space-around", "center")};
`;
