import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";
import axiosPublic from "utils/axiosPublic";
import axiosPrivate from "utils/axiosPrivate";
import { fetcherPrivate } from "utils/fetcher";
import { emailReg, passwordReg, phoneReg } from "utils/regex";
import AuthNum from "components/common/AuthNum";
import { TabCard, TextInput, EmailInput, SaveBtn } from "../styles";
import {
  FlexWrapper,
  HeadingWrapper,
  Avatar,
  AvatarDelBtn,
  UserName,
  UploadBtn,
  PwdChangeBtn,
  Plan,
  DeleteBtn,
  EmailChangeBtn,
  AuthRequestBtn,
  AuthNumBtnWrapper,
  EmailBtnWrapper,
  EmailHelperText,
  PasswordInputBox,
  PasswordInputWrapper,
  PwdHelperText,
  PhoneHelperText,
} from "./styles";
import { ModalContent, ModalDesc, ModalBtnWrapper } from "pages/home/styles";
import CustomModal from "components/common/CustomModal";
import PwdInput from "components/home/PwdInput";
import ic_startup_small from "assets/ic_default_startup_big.svg";
import ic_default_manager_small from "assets/ic_default_manager_big.svg";
import ic_default_disigner_small from "assets/ic_default_disigner_big.svg";
import ic_default_mento_small from "assets/ic_default_mento_big.svg";
import convertImgToFile from "utils/convertImgToFile";

const UserTab = ({ uid, setIsToastVisible, setHasSaveError, role }) => {
  const navigate = useNavigate();

  // 사진
  const [photo, setPhoto] = useState("");
  const [hasPhotoChanged, setHasPhotoChanged] = useState(false);

  // 이메일
  const [email, setEmail] = useState("");

  // 이름
  const [name, setName] = useState("");
  const [nameFromServer, setNameFromServer] = useState("");

  // 핸드폰
  const [phone, setPhone] = useState("");
  const [phoneFromServer, setPhoneFromServer] = useState("");

  const {
    data: userData,
    error: userDataError,
    mutate: userDataMutate,
  } = useSWR(`/api/v1/experts/${window.localStorage.getItem("uid")}/info`, (url) => fetcherPrivate(url), { revalidateOnFocus: false });

  // 서버에 저장해둔 사용자 정보 받아오기
  useEffect(() => {
    if (userData) {
      const { expertHeadshot, email, name, phone } = userData.result;
      setPhoto(expertHeadshot);
      setEmail(email);
      setName(name);
      setNameFromServer(name);
      setPhone(phone);
      setPhoneFromServer(phone);
    }
  }, [userData]);

  // -----------------------------------------------------------------------

  // 이메일
  const [newEmail, setNewEmail] = useState("");
  const [isNewEmailVisible, setIsNewEmailVisible] = useState(false);
  const [showAuthNum, setShowAuthNum] = useState(false);

  // 이메일 - 저장하기 버튼 able/disable에 사용
  const [hasEmailChanged, setHasEmailChanged] = useState(false);

  const onClickChangeEmail = () => {
    setIsNewEmailVisible(true);
    setHasEmailChanged(false);
  };

  const onClickCancelEmail = () => {
    setNewEmail("");
    setIsNewEmailVisible(false);
    setShowAuthNum(false);
  };

  const onChangeEmail = (e) => setNewEmail(e.target.value);

  // 이메일 형태 regex test
  const [isEmailValid, setIsEmailValid] = useState(false);
  useEffect(() => {
    setHasEmailDuplicationChecked(false);
    setIsEmailPresent(false);
    if (newEmail?.length > 0) {
      if (emailReg.test(newEmail)) {
        setIsEmailValid(true);
      } else {
        setIsEmailValid(false);
      }
    } else {
      setIsEmailValid(false);
    }
  }, [newEmail]);

  // 이메일 중복 체크
  const [hasEmailDuplicationChecked, setHasEmailDuplicationChecked] = useState(false);
  const [isEmailPresent, setIsEmailPresent] = useState(false);
  const onBlurEmail = (e) => {
    // 이메일 형식이 유효한 경우에만 중복 확인  체크
    if (isEmailValid) {
      setHasEmailDuplicationChecked(false);
      setIsEmailPresent(false);

      axiosPublic
        .get("/api/v1/open/email/duplicate-check", {
          params: {
            email: e.target.value,
          },
        })
        .then((resp) => setIsEmailPresent(false))
        .catch((err) => setIsEmailPresent(true))
        .finally(() => {
          setHasEmailDuplicationChecked(true);
        });
    }
  };

  // 인증번호 발송 버튼 disable 상태 확인 위함
  const [isSendAuthPossible, setIsSendAuthPossible] = useState(false);

  useEffect(() => {
    // 이메일 중복 체크를 했고 존재하지 않는 이메일인 경우에만 auth num을 보낼 수 있다.
    if (hasEmailDuplicationChecked && !isEmailPresent) {
      setIsSendAuthPossible(true);
    } else {
      setIsSendAuthPossible(false);
    }
  }, [hasEmailDuplicationChecked, isEmailPresent]);

  // 이메일 변경을 위해 인증 번호 요청 및 인증
  const [expireAt, setExpireAt] = useState();

  const sendAuthNum = () => {
    axiosPublic
      .get("/api/v1/open/email/code-send", {
        params: {
          email: newEmail,
          name: nameFromServer,
        },
      })
      .then((resp) => {
        setExpireAt(resp.data.result.timeout);
      })
      .catch((err) => {
        console.log(err);
        window.alert("인증번호 전송에 실패했습니다. 관리주에게 문의해주세요!");
      });
  };

  // 이메일로 인증번호 전송하기
  const onClickEmailAuth = () => {
    setShowAuthNum(true);
    sendAuthNum();
  };

  // -----------------------------------------------------------------------
  // 인증 번호 입력
  // 인증 번호 4자리
  const [authNum, setAuthNum] = useState();
  // 번호가 잘못된 경우
  const [isNumWrong, setIsNumWrong] = useState(false);
  // 시간이 만료된 경우
  const [isTimeoutFromServer, setIsTimeoutFromServer] = useState(false);

  useEffect(() => {
    if (authNum) {
      setIsNumWrong(false);
      setIsTimeoutFromServer(false);
      // 인증번호 확인
      axiosPrivate
        .get("/api/v1/email/verify", {
          params: {
            email: newEmail,
            code: authNum,
          },
        })
        .then((response) => {
          // 이메일 인증 성공
          console.log(response);
          setEmail(newEmail);
          setNewEmail("");
          setIsNewEmailVisible(false);
          setShowAuthNum(false);
          setIsEmailValid(false);
          setHasEmailDuplicationChecked(false);
          setIsEmailPresent(false);
          setHasEmailChanged(true);
        })
        .catch((error) => {
          const errorCode = error.response.data.code;
          if (errorCode === "EMAIL_E_005") {
            // 이메일 인증 코드가 잘못되었습니다.
            window.alert("이메일 인증 코드가 잘못되었습니다. 다시 시도해주세요.");
            setIsNumWrong(true);
          } else if (errorCode === "EMAIL_E_004") {
            // 이메일 인증 코드가 만료되었습니다.
            setIsTimeoutFromServer(true);
            window.alert("이메일 인증 코드가 만료되었습니다. 다시 시도해주세요.");
          }
        });
    }
  }, [authNum]);

  // 재전송 버튼 클릭
  const [reset, setReset] = useState(false);
  const onClickReset = () => {
    setIsNumWrong(false);
    setIsTimeoutFromServer(false);
    setReset(true);
    sendAuthNum();
  };

  const onClickCancelEmailChange = () => {
    setNewEmail("");
    setIsNumWrong(false);
    setIsTimeoutFromServer(false);
    setIsNewEmailVisible(false);
    setShowAuthNum(false);
    setIsEmailValid(false);
    setHasEmailDuplicationChecked(false);
    setIsEmailPresent(false);
    setHasEmailChanged(false);
  };

  //---------------------------------------------------------------------------------------

  // 비밀번호
  const [password, setPassword] = useState("");
  const [newPwd, setNewPwd] = useState("");
  const [newPwdConfirm, setNewPwdConfirm] = useState("");
  const [isNewPwdValid, setIsNewPwdValid] = useState(false);
  const [arePwdsSame, setArePwdsSame] = useState(false);
  const [isPwdChangeVisible, setIsPwdChangeVisible] = useState(false);

  const onChangePassword = (e) => setPassword(e.target.value);
  const onChangeNewPWd = (e) => setNewPwd(e.target.value);
  const onChangeNewPwdConfirm = (e) => setNewPwdConfirm(e.target.value);

  const onClickChangePassword = () => setIsPwdChangeVisible(true);
  const onClickCancelPassword = () => {
    setIsPwdChangeVisible(false);
    setPassword("");
    setNewPwd("");
    setNewPwdConfirm("");
    setIsNewPwdValid(false);
    setArePwdsSame(false);
  };

  // 비밀번호 validation
  useEffect(() => {
    if (newPwd?.length > 0) {
      if (passwordReg.test(newPwd)) {
        setIsNewPwdValid(true);
      } else {
        setIsNewPwdValid(false);
      }
    } else {
      setIsNewPwdValid(false);
    }
  }, [newPwd]);

  // 비밀번호 일치 여부
  useEffect(() => {
    if (newPwdConfirm?.length > 0) {
      if (newPwd === newPwdConfirm) {
        setArePwdsSame(true);
      } else {
        setArePwdsSame(false);
      }
    } else {
      setArePwdsSame(false);
    }
  }, [newPwd, newPwdConfirm]);

  //---------------------------------------------------------------------------------------

  // 이름 변경
  const onChangeName = (e) => setName(e.target.value);

  // 핸드폰 번호 변경
  const [isPhoneValid, setIsPhoneValid] = useState();
  const onChangePhone = (e) => setPhone(e.target.value);
  // 연락처 하이픈 없애기
  const onBlurPhone = (e) => {
    const inputValue = e.target.value;
    const trimmedValue = inputValue.replace(phoneReg, "");
    if (trimmedValue?.length >= 10) {
      setIsPhoneValid(true);
      setPhone(trimmedValue);
    } else {
      setIsPhoneValid(false);
    }
  };

  //---------------------------------------------------------------------------------------
  // 대표 사진
  const fileRef = useRef();
  const [file, setFile] = useState(null);
  const [fileDataURL, setFileDataURL] = useState();
  const imageMimeType = /image\/(png|jpg|jpeg)/i;

  // 사진 변경
  const onChangeAvatar = (e) => {
    const file = e.target.files[0];
    if (!file.type.match(imageMimeType)) {
      // 파일 체크
      return;
    }
    setFile(file);
    setHasPhotoChanged(true);
  };

  useEffect(() => {
    let fileReader,
      isCancel = false;
    if (file) {
      fileReader = new FileReader();
      fileReader.onload = (e) => {
        const { result } = e.target;
        if (result && !isCancel) {
          setFileDataURL(result);
        }
      };
      fileReader.onabort = () => {
        console.log("abort!");
        // reject(new Error("File reading aborted"));
      };
      fileReader.onerror = () => {
        console.log("error!");
        // reject(new Error("Failed to read file"));
      };

      fileReader.readAsDataURL(file);
    }
    return () => {
      isCancel = true;
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort();
      }
    };
  }, [file]);

  // 사진 삭제
  const onClickDeleteAvatar = async () => {
    fileRef.current.value = "";

    try {
      const data = {
        layoutImage: ic_startup_small,
        fileName: "ic_startup_small.jpg",
        type: "image/jpg",
      };
      const newFile = await convertImgToFile(data);
      setFile(newFile);
    } catch (err) {
      console.log(err);
      window.alert("사진 삭제에 실패했습니다. 다시 시도해주세요!");
      setHasPhotoChanged(false);
    }
  };

  //------------------------------------------------------------------------
  const [isSaveAble, setIsSaveAble] = useState(false);

  useEffect(() => {
    if (hasPhotoChanged || hasEmailChanged || name !== nameFromServer || phone !== phoneFromServer || isNewPwdValid) {
      setIsSaveAble(true);
    } else {
      setIsSaveAble(false);
    }
  }, [hasPhotoChanged, hasEmailChanged, name, nameFromServer, phone, phoneFromServer, isNewPwdValid]);

  //---------------------------------------------------------------------------------------
  // 회원 탈퇴 팝업
  const [isModalVisible, setIsModalVisible] = useState(false);
  const onClickDelModal = () => setIsModalVisible(true);
  const onCancelDelAccount = () => setIsModalVisible(false);
  const onConfirmDelAccount = () => {
    axiosPrivate
      .delete(`/api/v1/members/${window.localStorage.getItem("uid")}`)
      .then((res) => {
        console.log(res);
        navigate("/");
      })
      .catch((err) => {
        console.log(err);
        window.alert("회원 탈퇴에 실패했습니다. 관리자에게 문의해주세요!");
      })
      .finally(() => {
        setIsModalVisible(false);
      });
  };

  //---------------------------------------------------------------------------------------

  const onSubmit = (e) => {
    e.preventDefault();
    setIsToastVisible(false);
    setHasSaveError(false);

    const formData = new FormData();

    // TODO : 2차 개발 -> 개별 저장
    let data;
    if (hasEmailChanged && isNewPwdValid) {
      data = {
        name,
        phone,
        email,
        password: newPwd,
      };
    } else if (hasEmailChanged && !isNewPwdValid) {
      data = {
        name,
        phone,
        email,
      };
    } else if (!hasEmailChanged && isNewPwdValid) {
      data = {
        name,
        phone,
        password: newPwd,
      };
    } else {
      data = {
        name,
        phone,
      };
    }

    const req = new Blob([JSON.stringify(data)], { type: "application/json" });
    formData.append("req", req);
    if (file) {
      formData.append("headshot", file);
    }

    axiosPrivate
      .patch(`/api/v1/members/${uid}/info`, formData)
      .then((resp) => {
        setIsToastVisible(true);
        // onSubmit 성공 후 상태 초기화
        setFileDataURL();
        setFile();
        setIsPhoneValid();
        setHasPhotoChanged(false);
        setShowAuthNum(false);
        setHasEmailChanged(false);
        setIsNewEmailVisible(false);
        setIsNewPwdValid(false);
        setIsPwdChangeVisible(false);
        userDataMutate();
      })
      .catch((err) => {
        console.log(err);
        window.alert("회원 정보 변경에 실패했습니다. 관리자에게 문의해주세요!");
      });
  };

  return (
    <>
      <TabCard>
        <form onSubmit={onSubmit}>
          <HeadingWrapper>
            <Avatar>
              {photo && !file ? (
                <img src={photo} alt="already saved photo" referrerPolicy="no-referrer" />
              ) : !photo && !file ? (
                <img
                  src={
                    userData?.result?.expertType === "MANAGER"
                      ? ic_default_manager_small
                      : userData?.result?.expertType === "DESIGNER"
                      ? ic_default_disigner_small
                      : userData?.result?.expertType === "MENTOR"
                      ? ic_default_mento_small
                      : ic_startup_small
                  }
                  alt="user default avatar"
                  referrerPolicy="no-referrer"
                />
              ) : (
                <img src={fileDataURL} alt="newly uploaded photo " referrerPolicy="no-referrer" />
              )}
            </Avatar>

            <div>
              <UserName>{nameFromServer}</UserName>
              <FlexWrapper></FlexWrapper>
            </div>
          </HeadingWrapper>

          <div>
            {/* 이메일 */}
            {isNewEmailVisible ? (
              <EmailInput>
                <label htmlFor="new-email">이메일</label>
                <input type="text" id="new-email" placeholder="새 이메일을 입력해주세요" value={newEmail} onChange={onChangeEmail} onBlur={onBlurEmail} />
              </EmailInput>
            ) : !isNewEmailVisible && !showAuthNum ? (
              <EmailInput>
                <label htmlFor="email">이메일</label>
                <input type="text" id="email" value={email} readOnly />
              </EmailInput>
            ) : (
              ""
            )}
            {newEmail?.length > 0 && !isEmailValid && !hasEmailDuplicationChecked && <EmailHelperText>잘못된 형식입니다.</EmailHelperText>}
            {hasEmailDuplicationChecked && !isEmailPresent && <EmailHelperText isValid>사용 가능한 이메일입니다.</EmailHelperText>}
            {isEmailValid && hasEmailDuplicationChecked && isEmailPresent && <EmailHelperText>이미 사용중인 이메일입니다.</EmailHelperText>}
            {showAuthNum && (
              <form>
                <AuthNum expireAt={expireAt} setAuthNum={setAuthNum} isNumWrong={isNumWrong} isTimeoutFromServer={isTimeoutFromServer} reset={reset} setReset={setReset} isProfile />
              </form>
            )}
            {isNumWrong && <EmailHelperText>인증번호를 다시 확인해주세요.</EmailHelperText>}
            {isTimeoutFromServer && <EmailHelperText>입력시간이 초과되었습니다.</EmailHelperText>}
            <TextInput>
              <label htmlFor="name">이름</label>
              <input type="text" id="name" value={name} onChange={onChangeName} readOnly />
            </TextInput>
            <TextInput>
              <label htmlFor="phone">연락처</label>
              <input type="text" id="phone" value={phone} onChange={onChangePhone} onBlur={onBlurPhone} readOnly />
            </TextInput>
            {phone !== phoneFromServer && !isPhoneValid && <PhoneHelperText>잘못된 형식입니다. (최소 10자리)</PhoneHelperText>}
          </div>

          <Plan>
            <p>이용 플랜</p>
            <div>
              <strong>Free Trial</strong>
              <span>무료 이용</span>
            </div>
          </Plan>
          <SaveBtn onClick={() => navigate(`/home`)}>돌아가기</SaveBtn>
        </form>
      </TabCard>
    </>
  );
};

export default UserTab;
