import { useState, useEffect } from "react";
import LNB from "components/home/LNB";
import AvatarBtn from "components/home/AvatarBtn";
import CustomModal from "components/common/CustomModal";
import useLogout from "hooks/useLogout";
import { Layout, ModalContent, ModalDesc, ModalBtnWrapper } from "pages/home/styles";
import { ExpertMain, PageHeading, ExpertList, ImgWrapper, RoleTag } from "./styles";
import axiosPrivate from "utils/axiosPrivate";

import expert_image_one from "assets/expert_image_one_new.svg"; //매니저
import expert_image_two from "assets/expert_image_two_new.svg"; //디자이너
import expert_image_three from "assets/expert_image_three_new.svg"; //멘토

const roleObj = {
  MANAGER: "매니저",
  MENTOR: "멘토",
  DESIGNER: "디자이너",
};

const Expert = () => {
  const { logout } = useLogout();

  // 상단 아바타 클릭
  const [isLogoutVisible, setIsLogoutVisible] = useState(false);
  const onClickLogout = () => {
    setIsLogoutVisible(true);
  };

  const onCancelLogout = () => setIsLogoutVisible(false);
  const onConfirmLogout = () => {
    setIsLogoutVisible(false);
    logout();
  };

  // 전문가 데이터 관리
  const [expertArr, setExpertArr] = useState([]);

  useEffect(() => {
    axiosPrivate
      .get(`/api/v1/experts/introduce`)
      .then((res) => {
        if (res?.data?.result?.length > 0) {
          setExpertArr(res.data.result);
        }
      })
      .catch((err) => {
        console.log(err);
        window.alert("전문가 목록 조회에 실패했습니다. 관리자에게 문의해주세요!");
      });
  }, []);

  return (
    <>
      <Layout>
        <LNB />
        <ExpertMain>
          <AvatarBtn onClickLogout={onClickLogout} />
          <PageHeading>I2B 캠퍼스의 전문가를 소개합니다.</PageHeading>
          <ExpertList>
            {expertArr?.map((item, i) => (
              <li key={`expert${i}`}>
                <ImgWrapper>
                  {item.expertHeadshot ? (
                    <img src={item.expertHeadshot} alt="expert image" referrerPolicy="no-referrer" />
                  ) : (
                    <img
                      src={item.expertType === "MANAGER" ? expert_image_one : item.expertType === "DESIGNER" ? expert_image_two : expert_image_three}
                      alt="default expert image"
                      referrerPolicy="no-referrer"
                    />
                  )}
                </ImgWrapper>
                <h2>{item.expertNick}</h2>
                <RoleTag role={item.expertType}>{roleObj[item.expertType]}</RoleTag>
                <small>{item.introduction}</small>
              </li>
            ))}
          </ExpertList>
        </ExpertMain>
      </Layout>
      {isLogoutVisible && (
        <CustomModal isVisible={isLogoutVisible}>
          <ModalContent>
            <h2>로그아웃</h2>
            <ModalDesc>
              <p>
                로그아웃 시 현재 페이지에서 나가게 됩니다.
                <br />
                <strong>로그아웃 하시겠습니까?</strong>
              </p>
              <ModalBtnWrapper>
                <button type="button" onClick={onCancelLogout}>
                  취소
                </button>
                <button type="button" onClick={onConfirmLogout}>
                  로그아웃
                </button>
              </ModalBtnWrapper>
            </ModalDesc>
          </ModalContent>
        </CustomModal>
      )}
    </>
  );
};

export default Expert;
