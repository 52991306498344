import { useEffect } from "react";
import axiosPublic from "utils/axiosPublic";
import { TESTER } from "utils/data";
import { Wrapper } from "./styles";
const Complete = ({ isShowing, status, projectId, testerUid }) => {
  useEffect(() => {
    if (status === TESTER) {
      const data = {
        testCaseAnswerStepType: "TEST_CASE_FINISHED",
      };
      axiosPublic
        .patch(`/api/v1/open/projects/${projectId}/test/case/${testerUid}`, data)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  });
  return (
    <Wrapper isShowing={isShowing}>
      <h1>설문 응답이 완료되었습니다.</h1>

      <p>설문지는 한 번만 작성할 수 있습니다.</p>
    </Wrapper>
  );
};

export default Complete;
