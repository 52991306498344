import styled from "styled-components";
import { flexbox } from "styles/mixins";

export const Wrapper = styled.div`
	${flexbox("flex-start", "center")};
	margin: 2px 0 0 0;
`;

export const Status = styled.p`
	${flexbox("center", "center")};
	padding: 0 8px;
	margin: 0 8px 0 0;
	height: 28px;
	border: 1px solid #181818;
	border-radius: 4px;
	font-weight: 500;
	font-size: 12px;
	line-height: 20px;
	color: #000000;
`;

export const Name = styled.p`
	font-weight: 500;
	font-size: 15px;
	line-height: 24px;
	color: #000000;
`;

export const Duration = styled.p`
	${flexbox("flex-start", "center")};
	font-weight: 500;
	font-size: 15px;
	line-height: 24px;
	color: #808080;

	::before {
		content: "";
		display: block;
		width: 1px;
		height: 13px;
		background-color: #727272;
		margin: auto 16px;
	}
`;
