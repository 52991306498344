const getSelectedIndexArr = (originalArr, watchArr) => {
  let indexArr = [];
  if (watchArr?.length > 0) {
    watchArr.forEach((item) => {
      const index = originalArr?.findIndex((feature) => feature.keywordName === item.keywordName);
      indexArr.push(index);
    });
  }
  return indexArr;
};

const getSelectedBgArr = (originalArr, watchArr, randomBgArr) => {
  const indexArr = getSelectedIndexArr(originalArr, watchArr);
  let bgArr = [];
  if (randomBgArr?.length > 0 && indexArr?.length > 0) {
    indexArr.forEach((item) => {
      const bg = randomBgArr[item];
      bgArr.push(bg);
    });

    return bgArr;
  }
};

export default getSelectedBgArr;
