import styled from "styled-components";
import { Item } from "../SortableItem/styles";
export const Wrapper = styled.div`
  position: relative;

  &::before,
  &::after {
    content: "";
    display: block;
    height: 1px;
    width: 240px;
    margin: 0 auto;
    background-color: #e3e3e3;
  }

  &::before {
    margin: 0 auto 18px;
  }

  &::after {
    margin: 18px auto 0;
  }
`;

export const OpinionItem = styled(Item)`
  cursor: pointer;

  > div:first-of-type > p:first-of-type {
    margin: 0 10px 0 12px;
  }
  &.is-active {
    background-image: none;
  }
`;
