import { useState } from "react";
import useSWR from "swr";
import { fetcherPrivate } from "utils/fetcher";
import { NavLink, useNavigate } from "react-router-dom";
import { BtnWrapper, ConfirmBtn, Nav, StyledLink } from "./styles";
import CustomModal from "components/common/CustomModal";
import { ModalContent, ModalDesc } from "pages/home/styles";

const LNB = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const {
    data: myinfo,
    error: myinfotError,
    mutate: myinfotMutate,
  } = useSWR(
    `/api/v1/members/${window.localStorage.getItem("uid")}/info`,
    (url) => fetcherPrivate(url)
  );
  let navigate = useNavigate();
  // 임시 팝업
  const onClickDNA = () => setIsModalVisible(true);

  const onClickConfirm = () => setIsModalVisible(false);

  return (
    <>
      <Nav>
        <h1>
          {myinfo?.result.authCode?.logoImage == null ? (
            <StyledLink to="/home" />
          ) : (
            <img
              width="210px"
              height="60px"
              style={{
                objectFit: "cover",
                margin: "0 0 30px 0px",
                cursor: "pointer",
              }}
              src={myinfo?.result.authCode.logoImage}
              referrerpolicy="no-referrer"
              onClick={() => {
                navigate("/home");
              }}></img>
          )}
        </h1>
        <ul>
          <li>
            <NavLink
              to="/home"
              className={({ isActive }) =>
                isActive ? "is-active" : undefined
              }>
              MVP Kit
            </NavLink>
          </li>
          {/* TODO : 중간 점검 후 제거 */}
          <li>
            <button type="button" onClick={onClickDNA}>
              DNA Kit
            </button>
          </li>
          {/* <li style={{ pointerEvents: "none" }}>
          <NavLink to="/dna" className={({ isActive }) => (isActive ? "is-active" : undefined)}>
            DNA Kit
          </NavLink>
        </li> */}
          <li>
            <NavLink
              to="/expert"
              className={({ isActive }) =>
                isActive ? "is-active" : undefined
              }>
              Expert
            </NavLink>
          </li>
        </ul>
      </Nav>

      {isModalVisible && (
        <CustomModal isVisible={isModalVisible}>
          <ModalContent>
            <h2>DNA Kit</h2>
            <ModalDesc>
              <p>
                여러분의 브랜드를 강화할 수 있는
                <br />
                최고의 키트를 열심히 준비 중이에요!
              </p>
              <BtnWrapper>
                <ConfirmBtn type="button" onClick={onClickConfirm}>
                  확인
                </ConfirmBtn>
              </BtnWrapper>
            </ModalDesc>
          </ModalContent>
        </CustomModal>
      )}
    </>
  );
};

export default LNB;
