import { useState, useEffect } from "react";
import { InputWrapper, ImageWrapper } from "./styles";
const imageMimeType = /image\/(svg|png|jpg|jpeg)/i;

const UploadIcon = ({ file, setFile, imgSrc, isViewMode }) => {
  const [fileDataURL, setFileDataURL] = useState(null);
  const [isImg, setIsImg] = useState(imgSrc);

  useEffect(() => {
    if (imgSrc) {
      setIsImg(imgSrc);
    }
  }, [imgSrc]);

  const onChange = (e) => {
    const file = e.target.files[0];
    if (!file.type.match(imageMimeType)) {
      // 파일 체크
      return;
    }
    setFile(file);
  };

  useEffect(() => {
    let fileReader,
      isCancel = false;
    if (file) {
      fileReader = new FileReader();
      fileReader.onload = (e) => {
        const { result } = e.target;
        if (result && !isCancel) {
          setFileDataURL(result);
        }
      };
      fileReader.onabort = () => {
        console.log("abort!");
      };
      fileReader.onerror = () => {
        console.log("error!");
      };

      fileReader.readAsDataURL(file);
    }
    return () => {
      isCancel = true;
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort();
      }
    };
  }, [file]);

  const onClickDelete = () => setFile(null);
  const setFileInput = () => setIsImg(false);
  return (
    <>
      {!file && isImg ? (
        <ImageWrapper>
          <img src={isImg} alt="preview" referrerPolicy="no-referrer" />
          <button type="button" onClick={setFileInput}></button>
        </ImageWrapper>
      ) : file ? (
        <ImageWrapper>
          <img src={fileDataURL} alt="preview" referrerPolicy="no-referrer" />
          <button type="button" onClick={onClickDelete}></button>
        </ImageWrapper>
      ) : (
        <InputWrapper>
          <input type="file" id="file" onChange={onChange} accept="image/*" disabled={isViewMode} />
          <label htmlFor="file"></label>
        </InputWrapper>
      )}
    </>
  );
};

export default UploadIcon;
