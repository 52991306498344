import { useRef, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Title from "./Title";
import { Wrapper, SliderWrapper, PlusBtn } from "./styles";

const TitleSlider = ({ combinedBenchList, currentBench, onChangeCurrentBench, onChangeBenchName, onClickAddBench, onClickDeleteBench, isViewMode }) => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 4,
  };

  const sliderRef = useRef();
  let temp = "";
  combinedBenchList.map((item, index) => {
    if (item.benchmarkId == currentBench.benchmarkId) {
      if (combinedBenchList.length - 1 == index) {
        temp = "";
      } else {
        temp = index + 1;
      }
    }
  });
  useEffect(() => {}, []);

  //combinedBenchList[index + 1].benchmarkId)
  const onClickAdd = () => {
    if (combinedBenchList.length > 3) {
      sliderRef.current.slickNext();
    }
    onClickAddBench();
  };

  return (
    <Wrapper>
      <SliderWrapper>
        {combinedBenchList?.length > 0 && (
          <Slider {...settings} ref={sliderRef}>
            {combinedBenchList?.map((item, i) => (
              <Title
                key={`${item.id}${i}`}
                index={i}
                nextIndex={temp && combinedBenchList[temp].benchmarkId}
                item={item}
                currentBench={currentBench}
                onChangeCurrentBench={onChangeCurrentBench}
                onChangeBenchName={onChangeBenchName}
                onClickDeleteBench={onClickDeleteBench}
                isViewMode={isViewMode}
                combinedBenchListLength={combinedBenchList.length}
              />
            ))}

            {/* 마지막 플러스 버튼 */}
            <PlusBtn>
              <button type="button" onClick={onClickAdd} disabled={isViewMode}></button>
            </PlusBtn>
          </Slider>
        )}
      </SliderWrapper>
    </Wrapper>
  );
};

export default TitleSlider;
