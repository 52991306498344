import { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import useSWR from "swr";
import { fetcherPrivate } from "utils/fetcher";
import axiosPrivate from "utils/axiosPrivate";
import Layout from "layouts/Layout";
import Content from "layouts/Content";
import ContentHeading from "layouts/Content/ContentHeading";
import DesignCard from "components/concept/DesignCard";
import Aside from "layouts/Aside";
import AsideInfo from "layouts/Aside/AsideInfo";
import AsideKeyword from "components/concept/AsideKeyword";
import useWarnUnsavedChange from "hooks/useWarnUnsaveChange";
import { WhiteCard, AsideFeatureWrapper, Form, CardList, HeadingWrapper } from "../feature/styles";
import getRandomColor from "utils/getRandomColor";
import getSelectedBgArr from "utils/getSelectedBgArr";
import { designGuide } from "utils/data";
import popup_design from "assets/popup_design.svg";
import CustomModal from "components/common/CustomModal";
import HelpModalContent from "components/common/HelpModalContent";
import { CONCEPT_FEATURE, PRODUCTION } from "utils/data";

import ToastPopup from "components/common/ToastPopup";
import useViewMode from "hooks/useViewMode";
import { useReactToPrint } from "react-to-print";

const progressData = {
  prev: CONCEPT_FEATURE,
  next: PRODUCTION,
};

const DESIGN_MAX = 3;

const Design = () => {
  const scrollRef = useRef();
  const { id } = useParams();
  let navigate = useNavigate();

  const isViewMode = useViewMode();

  // pdf 추출
  const printRef = useRef();

  const onClickPdf = useReactToPrint({ content: () => printRef.current });
  const {
    data: designData,
    error: designError,
    mutate: designDataMutate,
  } = useSWR(`/api/v1/projects/${id}/concept/keywords?keywordType=DESIGN_CONCEPT`, (url) => fetcherPrivate(url), { revalidateOnFocus: false });

  const { data: designKeywordData, error: designKeyworError } = useSWR(`/api/v1/projects/keywords/attach?keywordType=DESIGN_CONCEPT&size=100`, (url) => fetcherPrivate(url), {
    revalidateOnFocus: false,
  });

  // 도움말 팝업
  const [isHelpVisible, setIsHelpVisible] = useState(false);
  const onClickCloseModal = () => setIsHelpVisible(false);
  const onClickHelp = () => setIsHelpVisible(true);

  const { onChanged, onSaved } = useWarnUnsavedChange();

  // aside 키워드 색상 배열
  const [randomBgArr, setRandomBgArr] = useState([]);
  useEffect(() => {
    setRandomBgArr(getRandomColor(designKeywordData?.result?.length));
  }, [designKeywordData]);

  // 선택한 키워드 배열
  const [selectedArr, setSelectedArr] = useState([]);

  const onChangeKeyword = (checked, id) => {
    if (checked) {
      const targetData = designKeywordData?.result?.find((item) => item.keywordId === id);
      setSelectedArr([...selectedArr, targetData]);
    } else {
      const oldArr = [...selectedArr];
      const newArr = oldArr.filter((item) => item.keywordId !== id);
      setSelectedArr(newArr);
    }
  };

  useEffect(() => {
    if (designData?.result) {
      setSelectedArr(designData.result);
    }
  }, [designData]);

  // 3개 초과 선택 불가
  const [isMax, setIsMax] = useState(false);

  useEffect(() => {
    if (selectedArr?.length >= DESIGN_MAX) {
      setIsMax(true);
    } else {
      setIsMax(false);
    }
  }, [selectedArr]);

  // 좌-우 선택한 키워드 배경색 맞춰주기
  const [selectedBgArr, setSelectedBgArr] = useState([]);

  useEffect(() => {
    const bgArr = getSelectedBgArr(designKeywordData?.result, selectedArr, randomBgArr);
    setSelectedBgArr(bgArr);
  }, [selectedArr]);

  // 이탈 방지
  useEffect(() => {
    onChanged();
  }, [selectedArr]);

  const [isToastVisible, setIsToastVisible] = useState(false);

  const onSubmit = async (e, item) => {
    //e.preventDefault();

    const idArr = selectedArr.map((item) => item.keywordId);
    const data = { keywordType: "DESIGN_CONCEPT", conceptKeywordIds: idArr };

    try {
      const res = await axiosPrivate.patch(`api/v1/projects/${id}/concept/keywords`, data);
      if (res) {
        onSaved();
        setIsToastVisible(true);
        designDataMutate();
        navigate(item);
      }
    } catch (error) {
      console.warn(error);
      if (error.response.data.resultMsg == "잘못된 입력 값 입니다.") {
        window.alert("저장에 실패하였습니다. 디자인 컨셉 키워드를 한 개 이상 선택해 주세요.");
        navigate(item);
      } else {
        window.alert("디자인컨셉 키워드 저장에 실패했습니다. 다시 시도해주세요!");
        navigate(item);
      }
    }
  };
  const getImg = (item) => designKeywordData?.result?.find((value) => value.keywordId === item.keywordId).keywordImage;

  return (
    <>
      {isToastVisible && (
        <ToastPopup isToastVisible={isToastVisible} setIsToastVisible={setIsToastVisible}>
          저장에 성공했습니다!
        </ToastPopup>
      )}
      <Layout onClickPdf={onClickPdf} onSave={onSubmit} isViewMode={isViewMode}>
        <Content progressData={progressData} ref={scrollRef} onSave={onSubmit} isViewMode={isViewMode}>
          <Form onSubmit={onSubmit} ref={printRef}>
            <ContentHeading>
              <h1>
                우리 제품/서비스는 이런 <span>컨셉</span>이에요
              </h1>
              {/* 페이지 이동시 자동 저장으로 주석처리
              <button type="submit" disabled={isViewMode}>
                저장
              </button>
              */}
            </ContentHeading>
            <WhiteCard>
              <HeadingWrapper>
                <h2>디자인 컨셉 키워드</h2>
                <p>
                  제품/ 서비스의 이미지를 결정할 디자인 컨셉 키워드입니다. <br />
                  오른쪽에서 원하는 분위기를 선택하세요 (최대 3개)
                </p>
              </HeadingWrapper>
              <CardList>
                {Array(DESIGN_MAX)
                  .fill()
                  .map((_, i) =>
                    selectedArr && selectedArr[i] && selectedBgArr && selectedBgArr[i] ? (
                      <DesignCard key={i} item={selectedArr[i]} bg={selectedBgArr[i]} src={getImg(selectedArr[i])} />
                    ) : (
                      <DesignCard key={i} />
                    )
                  )}
              </CardList>
            </WhiteCard>
          </Form>
        </Content>
        <Aside>
          <AsideInfo heading="디자인 컨셉 키워드 입력" onClickHelp={onClickHelp}>
            제품/서비스의 원하는 분위기를 떠올리며 디자인 컨셉을 정의하는 과정입니다.
          </AsideInfo>
          <AsideFeatureWrapper>
            <p>디자인 컨셉 키워드</p>
            {designKeywordData && (
              <AsideKeyword
                name="designKeyword"
                array={designKeywordData?.result ?? []}
                selectedArr={selectedArr}
                onChange={onChangeKeyword}
                isMax={isMax}
                randomBgArr={randomBgArr}
                isViewMode={isViewMode}
              />
            )}
          </AsideFeatureWrapper>
        </Aside>
      </Layout>
      {isHelpVisible && (
        <CustomModal isVisible={isHelpVisible}>
          <HelpModalContent headingText="디자인 컨셉 키워드 템플릿 사용 방법" guideArr={designGuide} img={popup_design} onClick={onClickCloseModal} />
        </CustomModal>
      )}
    </>
  );
};

export default Design;
