import { atom, selector } from "recoil";

const initialState = 0;

export const recoilTestState = atom({
  key: "recoilTestState",
  default: initialState,
});

export const recoilTestSelector = selector({
  key: "recoilTestSelector",
  get: ({ get }) => {
    const { data } = get(recoilTestState);
    return data;
  },
});
