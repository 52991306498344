import { useState } from "react";
import styled from "styled-components";
import { InputWrapper, ToggleBtn } from "components/register/PasswordInput/styles";

const Wrapper = styled(InputWrapper)`
  margin: 12px 0 0 0;

  &:first-of-type {
    margin: 0;
  }

  input {
    width: 300px;
    padding: 0 32px 0 18px;
  }
`;

const PwdInput = ({ id, placeholder, value, onChange, isValid }) => {
  const [isPwdVisible, setIsPwdVisible] = useState(false);
  const togglePassword = () => setIsPwdVisible((prev) => !prev);

  return (
    <Wrapper isValid={isValid}>
      <input type={isPwdVisible ? "text" : "password"} id={id} placeholder={placeholder} value={value} onChange={onChange} />
      <ToggleBtn type="button" isPwdVisible={isPwdVisible} onClick={togglePassword}></ToggleBtn>
    </Wrapper>
  );
};

export default PwdInput;
