import { useState } from "react";
import { Routes, Route } from "react-router-dom";
import ProtectedRoute from "utils/ProtectedRoute";
import Login from "pages/login";
import ResetEmail from "pages/reset-password/ResetEmail";
import ResetAuth from "pages/reset-password/ResetAuth";
import ResetPassword from "pages/reset-password/ResetPassword";
import ResetComplete from "pages/reset-password/ResetComplete";
import BaseInfo from "pages/register/BaseInfo";
import EmailAuth from "pages/register/EmailAuth";
import Password from "pages/register/Password";
import CompanyInfoFirst from "pages/register/CompanyInfoFirst";
import CompanyInfoSec from "pages/register/CompanyInfoSec";
import Finish from "pages/register/Finish";
import Home from "pages/home";
import Profile from "pages/profile";
import Expert from "pages/expert";
import Summary from "pages/analysis/summary";
import Customer from "pages/analysis/customer";
import Scenario from "pages/analysis/scenario";
import Model from "pages/analysis/model";
import Benchmarking from "pages/concept/benchmarking";
import Feature from "pages/concept/feature";
import Design from "pages/concept/design";
import Production from "pages/production";
import TestForm from "pages/test/form";
import TestFeedback from "pages/test/feedback";
import TestPreview from "pages/test/TestPreview";
import Review from "pages/review";
import Survey from "pages/test/survey";
import { TabProvider } from "context/tab-context";
import { TestTabProvider } from "context/test-tab-context";
import { TextProvider } from "context/text-context";
import ViewModeInit from "pages/ViewModeInit";
import RouteChangeTracker from "utils/RouteChangeTracker";
import Certification from "pages/register/Certification";

function App() {
  RouteChangeTracker();
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/reset/email" element={<ResetEmail />} />
      <Route path="/reset/auth" element={<ResetAuth />} />
      <Route path="/reset/change" element={<ResetPassword />} />
      <Route path="/reset/complete" element={<ResetComplete />} />
      <Route path="/register" element={<Certification />} />
      <Route path="/register/baseInfo" element={<BaseInfo />} />
      <Route path="/register/auth" element={<EmailAuth />} />
      <Route path="/register/password" element={<Password />} />
      <Route path="/register/company-first" element={<CompanyInfoFirst />} />
      <Route path="/register/company-second" element={<CompanyInfoSec />} />
      <Route path="/register/finish" element={<Finish />} />
      <Route
        path="/viewModeInit"
        element={
          <ProtectedRoute>
            <ViewModeInit />
          </ProtectedRoute>
        }
      />
      <Route
        path="/viewModeInit:id"
        element={
          <ProtectedRoute>
            <ViewModeInit />
          </ProtectedRoute>
        }
      />
      <Route
        path="/home"
        element={
          <ProtectedRoute>
            <Home />
          </ProtectedRoute>
        }
      />

      <Route
        path="/expert"
        element={
          <ProtectedRoute>
            <Expert />
          </ProtectedRoute>
        }
      />
      <Route
        path="/profile"
        element={
          <ProtectedRoute>
            <Profile />
          </ProtectedRoute>
        }
      />

      <Route
        path="/analysis/summary"
        element={
          <ProtectedRoute>
            <Summary />
          </ProtectedRoute>
        }
      />

      <Route
        path="/analysis/summary/:id"
        element={
          <ProtectedRoute>
            <Summary />
          </ProtectedRoute>
        }
      />

      <Route
        path="/analysis/customer"
        element={
          <ProtectedRoute>
            <Customer />
          </ProtectedRoute>
        }
      />
      <Route
        path="/analysis/customer/:id"
        element={
          <ProtectedRoute>
            <Customer />
          </ProtectedRoute>
        }
      />
      <Route
        path="/analysis/scenario"
        element={
          <ProtectedRoute>
            <Scenario />
          </ProtectedRoute>
        }
      />
      <Route
        path="/analysis/scenario/:id"
        element={
          <ProtectedRoute>
            <Scenario />
          </ProtectedRoute>
        }
      />
      <Route
        path="/analysis/model"
        element={
          <ProtectedRoute>
            <Model />
          </ProtectedRoute>
        }
      />
      <Route
        path="/analysis/model/:id"
        element={
          <ProtectedRoute>
            <Model />
          </ProtectedRoute>
        }
      />
      <Route
        path="/concept/benchmarking"
        element={
          <ProtectedRoute>
            <Benchmarking />
          </ProtectedRoute>
        }
      />
      <Route
        path="/concept/benchmarking/:id"
        element={
          <ProtectedRoute>
            <Benchmarking />
          </ProtectedRoute>
        }
      />
      <Route
        path="/concept/feature"
        element={
          <ProtectedRoute>
            <Feature />
          </ProtectedRoute>
        }
      />
      <Route
        path="/concept/feature/:id"
        element={
          <ProtectedRoute>
            <Feature />
          </ProtectedRoute>
        }
      />
      <Route
        path="/concept/design/:id"
        element={
          <ProtectedRoute>
            <Design />
          </ProtectedRoute>
        }
      />
      <Route
        path="/production"
        element={
          <ProtectedRoute>
            <Production />
          </ProtectedRoute>
        }
      />
      <Route
        path="/production/:id"
        element={
          <ProtectedRoute>
            <Production />
          </ProtectedRoute>
        }
      />
      <Route
        path="/test/form"
        element={
          <ProtectedRoute>
            <TextProvider>
              <TestForm />
            </TextProvider>
          </ProtectedRoute>
        }
      />
      <Route
        path="/test/form/:id"
        element={
          <ProtectedRoute>
            <TextProvider>
              <TestForm />
            </TextProvider>
          </ProtectedRoute>
        }
      />
      <Route
        path="/test/preview/:id"
        element={
          <ProtectedRoute>
            <TabProvider>
              <TestPreview />
            </TabProvider>
          </ProtectedRoute>
        }
      />
      <Route
        path="/test/feedback"
        element={
          <ProtectedRoute>
            <TestFeedback />
          </ProtectedRoute>
        }
      />
      <Route
        path="/test/feedback/:id"
        element={
          <ProtectedRoute>
            <TestFeedback />
          </ProtectedRoute>
        }
      />
      <Route
        path="/review/:id"
        element={
          <ProtectedRoute>
            <Review />
          </ProtectedRoute>
        }
      />
      <Route
        path="/review"
        element={
          <ProtectedRoute>
            <Review />
          </ProtectedRoute>
        }
      />
      <Route
        path="/survey"
        element={
          <TestTabProvider>
            <Survey />
          </TestTabProvider>
        }
      />
    </Routes>
  );
}

export default App;
