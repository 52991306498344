import styled from "styled-components";
import { flexbox } from "styles/mixins";

export const Wrapper = styled.div`
  ${flexbox("space-between", "center")};
  padding: 0 0 0 12px;
  margin: 0 0 24px 0;

  h1 {
    font-weight: 700;
    font-size: 26px;
    line-height: 36px;
    color: #000;
    span {
      color: #4556ee;
    }
  }

  button {
    width: 104px;
    height: 38px;
    padding: 0 10px;
    background-color: #181818;
    color: #fff;
    border-radius: 100px;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;

    &:disabled {
      pointer-events: none;
      cursor: default;
    }

    @media print {
      display: none;
    }
  }
`;
