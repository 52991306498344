import styled from "styled-components";
import { flexbox, flexboxColumn } from "styles/mixins";
import ic_review_pie_line from "assets/ic_review_pie_line.svg";
import ic_test_yes from "assets/ic_test_yes.svg";
import ic_test_yes_active from "assets/ic_test_yes_active.svg";
import ic_test_no from "assets/ic_test_no.svg";
import ic_test_no_active from "assets/ic_test_no_active.svg";
import ic_test_radio_one from "assets/ic_test_radio_one.svg";
import ic_test_radio_one_active from "assets/ic_test_radio_one_active.svg";
import ic_test_radio_two from "assets/ic_test_radio_two.svg";
import ic_test_radio_two_active from "assets/ic_test_radio_two_active.svg";
import ic_test_radio_three from "assets/ic_test_radio_three.svg";
import ic_test_radio_three_active from "assets/ic_test_radio_three_active.svg";

export const Container = styled.div`
  ${flexbox("flex-start", "center")};
  margin: 67px 0 0 0;
`;

export const PieWrapper = styled.div`
  width: 263px;
  height: 263px;
  position: relative;
  flex-shrink: 0;
  flex-grow: 0;
`;

export const TotalNum = styled.div`
  ${flexbox("center", "center")};
  width: 196px;
  height: 196px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.05);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  > div {
    ${flexboxColumn("center", "center")};
    width: 169px;
    height: 169px;
    border-radius: 50%;
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-image: url(${ic_review_pie_line});
    background-repeat: no-repeat;
    background-size: 64px 11px;
    background-position: center 109px;

    p {
      font-weight: 500;
      font-size: 13px;
      line-height: 22px;
      color: rgba(0, 0, 0, 0.4);
      transition: color 300ms ease-in-out;
    }

    strong {
      font-weight: 700;
      font-size: 24px;
      line-height: 22px;
      color: #000000;
      transition: color 300ms ease-in-out;
    }
  }
`;

export const CustomLegend = styled.ul`
  margin: 0 0 0 120px;
`;

export const CustomLegendItem = styled.li`
  ${flexbox("space-between", "center")};
  padding: 0 0 0 23px;
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 18px 18px;

  &:not(:last-of-type) {
    margin: 0 0 16px 0;
  }

  &:first-of-type {
    background-image: url(${(props) => (props.isActive ? ic_test_yes_active : props.isHovering ? ic_test_yes : ic_test_yes_active)});
  }

  &:nth-of-type(2) {
    background-image: url(${(props) => (props.isActive ? ic_test_radio_one_active : props.isHovering ? ic_test_radio_one : ic_test_radio_one_active)});
  }

  &:nth-of-type(3) {
    background-image: url(${(props) => (props.isActive ? ic_test_radio_two_active : props.isHovering ? ic_test_radio_two : ic_test_radio_two_active)});
  }

  &:nth-of-type(4) {
    background-image: url(${(props) => (props.isActive ? ic_test_radio_three_active : props.isHovering ? ic_test_radio_three : ic_test_radio_three_active)});
  }

  &:nth-of-type(5) {
    background-image: url(${(props) => (props.isActive ? ic_test_no_active : props.isHovering ? ic_test_no : ic_test_no_active)});
  }

  p:first-of-type {
    ${flexbox("space-between", "center")};
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    color: ${(props) => (props.isActive ? "#000000" : "#808080")};
    min-width: 81px;
    transition: color 300ms ease-in;
    ::after {
      content: "";
      width: 1px;
      height: 10px;
      margin: 0 9px 0 0;
      background-color: #b1b1b1;
    }
  }

  p:last-of-type {
    font-weight: 700;
    font-size: 13px;
    line-height: 22px;
    color: ${(props) => (props.isActive ? "#808080" : "#b1b1b1")};
    transition: color 300ms ease-in;

    text-align: right;
  }
`;
