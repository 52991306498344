import { useState, useEffect } from "react";

const useWarnUnsavedChange = (msg = "저장 플리즈..저장..") => {
  const [changed, setChanged] = useState(false);

  useEffect(() => {
    window.onbeforeunload = changed ? () => changed && !!msg : null;

    return () => {
      window.removeEventListener("beforeunload", () => {});
    };
  }, [changed, msg]);

  const onChanged = () => setChanged(true);
  const onSaved = () => setChanged(false);

  return { onChanged, onSaved };
};

export default useWarnUnsavedChange;
