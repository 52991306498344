import styled from "styled-components";
import { buttonBlackFilledBase } from "styles/mixins";

export const Button = styled.button`
	${buttonBlackFilledBase};
	width: 320px;
	font-size: 12px;
	font-weight: 700;
	line-height: 22px;
`;
