import { useState, useEffect } from "react";
import LinkInfo from "components/test/LinkInfo";
import LinkInput from "components/test/LinkInput";
import TabTag from "components/test/TabTag";
import QuestionMain from "components/test/QuestionMain";
import TestTextarea from "components/test/TestTextarea";
import PrevNextBtn from "components/test/PrevNextBtn";
import TesterPrevNextBtn from "components/test/TesterPrevNextBtn";
import { TabContainer } from "../../../../pages/test/form/styles";
import { USER, TESTER, PREVIEW } from "utils/data";
import patchTesterAnswer from "utils/patchTesterAnswer";

const Opinion = ({ isShowing, status, link, projectId, testerUid, savedAnswer }) => {
  const [text, setText] = useState();

  useEffect(() => {
    if (status === TESTER && savedAnswer) {
      const { generalOpinionAnswer } = savedAnswer;
      if (generalOpinionAnswer?.length > 0) setText(generalOpinionAnswer);
    }
  }, [savedAnswer]);

  const onChangeText = (e) => {
    setText(e.target.value);
    if (status === TESTER) {
      patchTesterAnswer(projectId, testerUid, "generalOpinionAnswer", e.target.value, "GENERAL_OPINION")
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
          window.alert("종합 의견 저장에 실패했습니다. 관리자에게 문의 부탁드립니다.");
        });
    }
  };

  return (
    <TabContainer isShowing={isShowing}>
      {status !== USER ? <LinkInfo link={link} /> : <LinkInput />}
      <TabTag text="종합 의견" />
      <QuestionMain text="제품/서비스의 개선해야 하는 점과 아쉬웠던 점, 좋았던 점에 대해 자유롭게 의견 기재해주세요!" />
      <div style={{ margin: "16px 0 0 0" }}>
        <TestTextarea name="opinion" placeholder="테스터의 작성이 이곳에 표시됩니다." status={status} value={text} onChange={onChangeText} />
      </div>
      {status === PREVIEW && <PrevNextBtn />}
      {status === TESTER && <TesterPrevNextBtn />}
    </TabContainer>
  );
};

export default Opinion;
