import { useRecoilState } from "recoil";
import { recoilTestState } from "states/recoilTestState";
import { Btn } from "./styles";

const ResponseBtn = ({ onClick }) => {
  const [totalTesterNum, _] = useRecoilState(recoilTestState);

  return (
    <Btn type="button" onClick={onClick} disabled={totalTesterNum === 0}>
      답변 전체 보기({totalTesterNum})
    </Btn>
  );
};

export default ResponseBtn;
