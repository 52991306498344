import { useState, useContext } from "react";
import LinkInfo from "components/test/LinkInfo";
import { Label, IntroText, Guide } from "components/test/TabItems/Intro/styles";
import { TabContainer } from "pages/test/form/styles";
import { useEffect } from "react";
import { TESTER } from "utils/data";
import TesterPrevNextBtn from "components/test/TesterPrevNextBtn";

const introPlaceholder = `안녕하세요. 제품/서비스의 시장성 검증을 위한 테스트를 실시하고자 합니다. 
제품/서비스에 대한 솔직한 의견을 상세하게 작성해주세요.
`;

const SurveyIntro = ({ isShowing, link, greetings }) => {
  const [introText, setIntroText] = useState();
  useEffect(() => {
    if (greetings?.length > 0) {
      setIntroText(greetings);
    } else {
      setIntroText(introPlaceholder);
    }
  }, [greetings]);

  return (
    <TabContainer isShowing={isShowing}>
      <LinkInfo link={link} />
      <Label htmlFor="intro">인사말</Label>

      <IntroText>
        {introText?.split("\n").map((line, i) => (
          <span key={i}>
            {line} <br />
          </span>
        ))}
      </IntroText>
      <Guide>
        <h4>개인정보 수집에 대한 안내</h4>
        <ul>
          <li>• 수집하는 개인 정보 항목 : 성명, 나이, 성별, 이메일</li>
          <li>• 이용 목적 : 해당 설문에 작성하신 개인 정보는 MVP 테스트 결과 산출을 위해 사용합니다.</li>
          <li>• 수집 기간 : 수집일로부터 1년 (단 타 법령에 의하여 저장할 수 있음) </li>
          <li>
            • 본 설문 내용은 대외비 사항으로 <span>외부 유출 및 설문 외 목적으로 사용 시 법적 불이익을 받을 수 있습니다.</span>
          </li>
        </ul>
      </Guide>
      <TesterPrevNextBtn isIntro />
    </TabContainer>
  );
};

export default SurveyIntro;
