import styled from "styled-components";
import { containerBase, flexbox } from "styles/mixins";

export const Container = styled.div`
	${containerBase};
`;

export const ContentWrapper = styled.div`
	${flexbox};
`;
