import { useState, useEffect, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import useSWR from "swr";
import { fetcherPrivate } from "utils/fetcher";
import Layout from "layouts/Layout";
import Content from "layouts/Content";
import ContentHeading from "layouts/Content/ContentHeading";
import BenchmarkingItem from "components/concept/BenchmarkingItem";
import Aside from "layouts/Aside";
import AsideInfo from "layouts/Aside/AsideInfo";
import AsideAccordion from "components/concept/AsideAccordion";
import { Form, WhiteCard } from "./styles";
import { benchGuide } from "utils/data";
import popup_bench from "assets/popup_bench.svg";
import CustomModal from "components/common/CustomModal";
import HelpModalContent from "components/common/HelpModalContent";
import getRandomColor from "utils/getRandomColor";
import { ANALYSIS_MODEL, CONCEPT_FEATURE } from "utils/data";

import TitleSlider from "components/concept/TitleSlider";
import getSelectedBgArr from "utils/getSelectedBgArr";
import { useParams, useNavigate } from "react-router-dom";
import axiosPrivate from "utils/axiosPrivate";
import ToastPopup from "components/common/ToastPopup";
import useViewMode from "hooks/useViewMode";
import { useReactToPrint } from "react-to-print";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import defaultImgBench from "assets/defaultImgBench.png";
import convertImgToFile from "utils/convertImgToFile";

const progressData = {
  prev: ANALYSIS_MODEL,
  next: CONCEPT_FEATURE,
};

const KEYWORD_MAX = 3;

const defaultBench = {
  isNew: true,
  isModified: false,
  benchmarkId: 0,
  name: "벤치마킹 명",
  benchmarkName: "벤치마킹 명",
  registerType: "MEMBER",
  benchmarkImages: [
    {
      imageId: 1,
      priority: 1,
      image: "",
      imageName: "",
      fileDataUrl: "",
    },
    {
      imageId: 2,
      priority: 2,
      image: "",
      imageName: "",
      fileDataUrl: "",
    },
    {
      imageId: 3,
      priority: 3,
      image: "",
      imageName: "",
      fileDataUrl: "",
    },
    {
      imageId: 4,
      priority: 4,
      image: "",
      imageName: "",
      fileDataUrl: "",
    },
    {
      imageId: 5,
      priority: 5,
      image: "",
      imageName: "",
      fileDataUrl: "",
    },
  ],
  benchmarkKeywords: [],
};

const Benchmarking = () => {
  const scrollRef = useRef();
  const { id } = useParams();
  const [progressIng, setProgressIng] = useState(false);
  const isViewMode = useViewMode();
  let navigate = useNavigate();

  // pdf 추출
  const printRef = useRef();

  const onClickPdf = useReactToPrint({ content: () => printRef.current });

  // 도움말 팝업
  const [isHelpVisible, setIsHelpVisible] = useState(false);
  const onClickCloseModal = () => setIsHelpVisible(false);
  const onClickHelp = () => setIsHelpVisible(true);

  //----------------------------------------------

  const [page, setPage] = useState(1);

  //디폴트 이미지 저장
  const [file, setFile] = useState(null);
  const defultImage = async () => {
    try {
      const data = {
        layoutImage: defaultImgBench,
        fileName: "defaultImgBench.png",
        type: "image/jpg",
      };
      const newFile = await convertImgToFile(data);
      setFile(newFile);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    defultImage();
  }, []);

  //키워드 목록
  const { data: keywordData, error: keywordError } = useSWR(`/api/v1/projects/keywords/attach?keywordType=PRODUCT&size=27`, (url) => fetcherPrivate(url), { revalidateOnFocus: false });

  /*
  디폴트 벤치리소스 목록
  const { data: benchExampleData, error: benchExampleError } = useSWR("/api/v1/projects/concept/attach-benchmarks", (url) => fetcherPrivate(url), { revalidateOnFocus: false });
  console.log("디폴트 벤치리스트 내용", benchExampleData);
  */

  //관리자가 매칭 시킨 벤치리소스 목록 - 매칭데이터가 있을경우 매칭리소스 내려옴 매칭데이터가 없으면 전체 리소스 데이터 내려옴
  const { data: benchExampleData, error: benchExampleError } = useSWR(`/api/v1/projects/${id}/concept/attach-benchmarks`, (url) => fetcherPrivate(url), { revalidateOnFocus: false });
  const {
    data: benchListData,
    error: benchListError,
    mutate: benchListMutate,
  } = useSWR(`/api/v1/projects/${id}/concept/benchmarks?page=${page}&size=100&direction=ASC`, (url) => fetcherPrivate(url), { revalidateOnFocus: false });

  console.log(benchListData, "benchListData");
  // 사용자가 새롭게 추가한 벤치마킹 리스트
  const [newBenchList, setNewBenchList] = useState([]);
  // 서버에 저장되어 있던 리스트애서 생성 -> 수정사항 생기면 반영 후 다시 서버로 보낼 때 사용할 리스트
  const [modifiedBenchList, setModifiedBenchList] = useState([]);
  // 수정 리스트 + 새롭게 생성한 리스트
  const [combinedBenchList, setCombinedBenchList] = useState([]);
  // 삭제할 벤치마킹 리스트
  const [deletedBenchList, setDeletedBenchList] = useState([]);
  const [currentBench, setCurrentBench] = useState({ ...defaultBench });

  // aside - accordion 직접입력/선택입력 collapse pannel
  const [activeKey, setActiveKey] = useState("2");
  const onClickSelf = () => setActiveKey("1");

  // aside  - 직접 입력 > 서버에서 받아오는 기본 키워드 배열
  const [keywordArr, setKeywordArr] = useState([]);
  useEffect(() => {
    if (keywordData) {
      setKeywordArr(keywordData.result);
    }
  }, [keywordData]);

  // 키워드 리스트
  const [selectedKeywordArr, setSelectedKeywordArr] = useState([]);
  const [typedKeywordArr, setTypedKeywordArr] = useState([]);

  // 서버에 이미 저장해둔 벤치마킹 호출하기
  const getSavedBenchDataFromServer = (benchmarkId) => {
    axiosPrivate
      .get(`/api/v1/projects/${id}/concept/benchmarks/${benchmarkId}`)
      .then((res) => {
        // 키워드 정리
        if (res.data?.result?.registerType === "ADMIN") {
          setTypedKeywordArr([]);
          setSelectedKeywordArr([]);
          setExampleKeywordArr(res.data.result.benchmarkKeywords);
        } else if (res.data?.result?.registerType === "MEMBER") {
          // 호출한 벤치마킹의 키워드 중 관리자에서 등록한 키워드
          const adminKeywords = res.data.result.benchmarkKeywords?.filter((item) => {
            if (keywordData?.result?.find((key) => key.keywordName === item.keywordName)) {
              return true;
            } else {
              return false;
            }
          });
          // 호출한 벤치마킹의 키워드 중 사용자가 직접 입력한 키워드
          const userKeywords = res.data.result.benchmarkKeywords
            ?.filter((item) => {
              if (!keywordData?.result?.find((key) => key.keywordName === item.keywordName)) {
                return true;
              } else {
                return false;
              }
            })
            ?.map((item) => ({ ...item, isTyped: true }));
          setSelectedKeywordArr(adminKeywords == undefined ? [] : adminKeywords);
          setTypedKeywordArr(userKeywords);
          setExampleKeywordArr([]);
        }

        // currentBench 정리
        const modifiedKeywordArr = res.data.result.benchmarkKeywords
          ? [...res.data.result.benchmarkKeywords].map((item) => {
              if (!keywordData?.result?.find((key) => key.keywordName === item.keywordName)) {
                return { ...item, isTyped: true };
              } else {
                return item;
              }
            })
          : [];
        const modifiedBench = { ...res.data.result, benchmarkName: res.data.result?.name, benchmarkKeywords: modifiedKeywordArr };
        if (modifiedBench.registerType === "ADMIN") {
          setActiveKey("2");
        } else {
          setActiveKey("1");
        }
        setCurrentBench(modifiedBench);
      })
      .catch((err) => {
        console.log(err);
        window.alert("저장한 벤치마킹 정보를 불러오는데 실패했습니다. 관리자에게 문의해주세요!");
      });
  };

  // 서버에서 받아온 이미 저장된 벤치마킹 리스트
  useEffect(() => {
    if (benchListData && benchListData.result.content?.length > 0) {
      const revisedList = [...benchListData.result.content].map((item) => {
        return {
          ...item,
          name: item.benchmarkName,
        };
      });
      setNewBenchList([]);
      setModifiedBenchList(revisedList);
      setCombinedBenchList(revisedList);
      getSavedBenchDataFromServer(benchListData.result.content[0].benchmarkId);
    } else {
      setNewBenchList([{ ...defaultBench }]);
      setModifiedBenchList([]);
      setCombinedBenchList([{ ...defaultBench }]);
      setCurrentBench({ ...defaultBench });
    }
  }, [benchListData]);

  // 상단 slider에서 벤치마킹 선택하는 경우 ------ 조회
  const onChangeCurrentBench = (item) => {
    if (item.isNew) {
      // 새로 생성한 아이템 조회
      if (item.registerType === "ADMIN") {
        setActiveKey("2");
        setExampleKeywordArr(item.benchmarkKeywords);
        setSelectedKeywordArr([]);
        setTypedKeywordArr([]);
      } else {
        let selectedKeys = [];
        let typedKeys = [];
        item.benchmarkKeywords.forEach((obj) => {
          if (obj.isTyped) {
            typedKeys.push(obj);
          } else {
            selectedKeys.push(obj);
          }
        });
        setActiveKey("1");
        setExampleKeywordArr([]);
        setSelectedKeywordArr([...selectedKeys]);
        setTypedKeywordArr([...typedKeys]);
      }
      setCurrentBench(item);
    } else {
      // 서버에 저장된 아이템 조회
      if (item.isModified) {
        // 서버에 저장됐던 아이템 + 수정 했음
        const targetBench = modifiedBenchList.find((obj) => obj.benchmarkId === item.benchmarkId);
        // registerType==="MEMBER";
        if (targetBench.registerType === "MEMBER") {
          const keywordArr = targetBench.benchmarkKeywords;
          let selectedKeys = [];
          let typedKeys = [];
          keywordArr.forEach((obj) => {
            if (obj.isTyped) {
              typedKeys.push(obj);
            } else {
              selectedKeys.push(obj);
            }
          });
          setSelectedKeywordArr([...selectedKeys]);
          setTypedKeywordArr([...typedKeys]);
          setExampleKeywordArr([]);
          setActiveKey("1");
        }
        // registerType==="ADMIN"은 필요 없는 게
        // 현재 백엔드 구조상 ADMIN -> ADMIN 으로 변경 시 기존 벤치마킹은 삭제
        // 새로 선택한 ADMIN 벤치마킹은 modifiedBenchList가 아니라 newBenchList에 있음
        // 즉 item.isNew이기 때문에 위에서 이미 검사
        setCurrentBench(targetBench);
      } else {
        // 서버에 저장했던 아이템 + 수정 안했음
        // -> 서버에 저장된 해당 벤치마킹 아이템 내용을 불러온다.
        getSavedBenchDataFromServer(item.benchmarkId);
      }
    }
  };

  // aside -------------------------------------------------------------------------------------------------
  // 직접 추가 ---------------------------------------------------------------------------------------------------------
  // 직접 추가 - 선택 입력 키워드
  const onChangeSelectedKeyword = (checked, item) => {
    if (checked) {
      //키워드 추가 -------------------------------------------------
      setSelectedKeywordArr((prev) => [...prev, item]);
      if (currentBench.isNew) {
        // newBenchList에 반영
        const copiedNewBenchList = [...newBenchList];
        const targetNewBench = copiedNewBenchList.find((item) => item.benchmarkId === currentBench.benchmarkId);
        const targetKeywords = targetNewBench.benchmarkKeywords;
        const modifiedKeywords = [...targetKeywords, item];
        const modifiedNewBench = { ...targetNewBench, benchmarkKeywords: modifiedKeywords, isModified: true };
        const modifiedNewBenchList = copiedNewBenchList.map((item) => (item.benchmarkId === currentBench.benchmarkId ? modifiedNewBench : item));
        setCurrentBench(modifiedNewBench);
        setNewBenchList(modifiedNewBenchList);
      } else {
        // modifiedBenchList에 반영
        const copiedModifiedBenchList = [...modifiedBenchList];
        const newKeywordArr = [...currentBench.benchmarkKeywords, item];
        const newBench = { ...currentBench, benchmarkKeywords: newKeywordArr, isModified: true, isKeywordModified: true };
        const newBenchList = copiedModifiedBenchList.map((item) => (item.benchmarkId === currentBench.benchmarkId ? newBench : item));
        setCurrentBench(newBench);
        setModifiedBenchList(newBenchList);
      }
      // combinedBenchList에 반영
      const copiedCombinedList = [...combinedBenchList];
      const newKeywordArr = [...currentBench.benchmarkKeywords, item];
      const newBench = { ...currentBench, benchmarkKeywords: newKeywordArr, isModified: true, isKeywordModified: true };
      const newCombinedBenchList = copiedCombinedList.map((item) => (item.benchmarkId === currentBench.benchmarkId ? newBench : item));
      setCombinedBenchList(newCombinedBenchList);
    } else {
      // 키워드 삭제-----------------------------------------
      const oldArr = [...selectedKeywordArr];
      const newArr = oldArr.filter((oldItem) => oldItem.keywordName !== item.keywordName);
      setSelectedKeywordArr(newArr);
      if (currentBench.isNew) {
        // newBenchList에 반영
        const copiedNewBenchList = [...newBenchList];
        const targetNewBench = copiedNewBenchList.find((item) => item.benchmarkId === currentBench.benchmarkId);
        const targetKeywords = targetNewBench.benchmarkKeywords;
        const modifiedKeywords = targetKeywords.filter((obj) => obj.keywordName !== item.keywordName);
        const modifiedNewBench = { ...targetNewBench, benchmarkKeywords: modifiedKeywords, isModified: true };
        const modifiedNewBenchList = copiedNewBenchList.map((item) => (item.benchmarkId === currentBench.benchmarkId ? modifiedNewBench : item));
        setCurrentBench(modifiedNewBench);
        setNewBenchList(modifiedNewBenchList);
      } else {
        // modifiedBenchList에 반영
        // 저장해둔 벤치마킹 아이템의 키워드 배열 - 키워드 삭제
        const copiedModifiedBenchList = [...modifiedBenchList];
        const targetKeywordArr = [...currentBench.benchmarkKeywords];
        const modifiedKeywordArr = targetKeywordArr.filter((obj) => obj.keywordName !== item.keywordName);
        const modifiedBench = { ...currentBench, benchmarkKeywords: modifiedKeywordArr, isModified: true, isKeywordModified: true };
        const modifiedList = copiedModifiedBenchList.map((item) => (item.benchmarkId === currentBench.benchmarkId ? modifiedBench : item));
        setCurrentBench(modifiedBench);
        setModifiedBenchList(modifiedList);
      }
      //combinedBenchList에서 해당 벤치마킹 찾아서 키워드 수정
      const copiedCombinedList = [...combinedBenchList];
      const keywordArr = [...currentBench.benchmarkKeywords];
      const newKeywordArr = keywordArr.filter((obj) => obj.keywordName !== item.keywordName);
      const newBench = { ...currentBench, benchmarkKeywords: newKeywordArr, isModified: true, isKeywordModified: true };
      const newList = copiedCombinedList.map((item) => (item.benchmarkId === currentBench.benchmarkId ? newBench : item));
      setCombinedBenchList(newList);
    }
  };

  // 직접 추가 - 직접 입력 키워드
  const onChangeTypedKeyword = (newArr, keyword, isDel) => {
    if (!isDel) {
      // 추가하는 경우
      const copiedTypedArr = [...typedKeywordArr];
      const newTypedKeywordArr = [...copiedTypedArr, { keywordName: keyword, isTyped: true }];
      setTypedKeywordArr(newTypedKeywordArr);

      const keywordArr = [...currentBench.benchmarkKeywords];
      const modifiedKeywordArr = [...keywordArr, { keywordName: keyword, isTyped: true }];

      if (currentBench.isNew) {
        // newBenchList
        const copiedNewBenchList = [...newBenchList];
        const modifiedBench = { ...currentBench, benchmarkKeywords: modifiedKeywordArr, isModified: true };
        const modifiedBenchList = copiedNewBenchList.map((item) => (item.benchmarkId === currentBench.benchmarkId ? modifiedBench : item));
        setCurrentBench(modifiedBench);
        setNewBenchList(modifiedBenchList);
      } else {
        // modifiedBenchList
        const copiedModifiedList = [...modifiedBenchList];
        const modifiedBench = { ...currentBench, benchmarkKeywords: modifiedKeywordArr, isModified: true, isKeywordModified: true };
        const modifiedList = copiedModifiedList.map((item) => (item.benchmarkId === currentBench.benchmarkId ? modifiedBench : item));
        setCurrentBench(modifiedBench);
        setModifiedBenchList(modifiedList);
      }
      // combinedBenchList
      const copiedCombinedList = [...combinedBenchList];
      const modifiedBench = { ...currentBench, benchmarkKeywords: modifiedKeywordArr, isModified: true, isKeywordModified: true };
      const modifiedList = copiedCombinedList.map((item) => (item.benchmarkId === currentBench.benchmarkId ? modifiedBench : item));
      setCombinedBenchList(modifiedList);
    } else {
      // 삭제 하는 경우
      const oldTypedKeywordArr = [...typedKeywordArr];
      const newTypedKeywordArr = oldTypedKeywordArr.filter((item) => item.keywordName !== keyword);
      setTypedKeywordArr(newTypedKeywordArr);

      const keywordArr = [...currentBench.benchmarkKeywords];
      const modifiedKeywordArr = keywordArr.filter((item) => item.keywordName !== keyword);

      if (currentBench.isNew) {
        //newBenchList
        const copiedNewBenchList = [...newBenchList];
        const modifiedBench = { ...currentBench, benchmarkKeywords: modifiedKeywordArr, isModified: true };
        const modifiedNewBenchList = copiedNewBenchList.map((item) => (item.benchmarkId === currentBench.benchmarkId ? modifiedBench : item));
        setCurrentBench(modifiedBench);
        setNewBenchList(modifiedNewBenchList);
      } else {
        //modifiedBenchList
        const copiedModifiedBenchList = [...modifiedBenchList];
        const modifiedBench = { ...currentBench, benchmarkKeywords: modifiedKeywordArr, isModified: true, isKeywordModified: true };
        const modifiedList = copiedModifiedBenchList.map((item) => (item.benchmarkId === currentBench.benchmarkId ? modifiedBench : item));
        setCurrentBench(modifiedBench);
        setModifiedBenchList(modifiedList);
      }
      //combinedBenchList
      const copiedCombinedList = [...combinedBenchList];
      const modifiedBench = { ...currentBench, benchmarkKeywords: modifiedKeywordArr, isModified: true, isKeywordModified: true };
      const modifiedList = copiedCombinedList.map((item) => (item.benchmarkId === currentBench.benchmarkId ? modifiedBench : item));
      setCombinedBenchList(modifiedList);
    }
  };

  //  ------------------------------------------------------------------------------------------------------------------

  // registerType==="ADMIN"은 변경 불가
  const onChangeBenchName = (targetBenchId, name) => {
    if (currentBench.isNew) {
      // newBenchList
      const copiedNewBenchList = [...newBenchList];
      const targetBench = copiedNewBenchList.find((item) => item.benchmarkId === targetBenchId);
      const modifiedBench = { ...targetBench, name, benchmarkName: name, isModified: true };
      const modifiedBenchList = copiedNewBenchList.map((item) => (item.benchmarkId === targetBenchId ? modifiedBench : item));
      setNewBenchList(modifiedBenchList);
    } else {
      // modifiedBenchList
      const copiedModifiedList = [...modifiedBenchList];
      const modifiedBench = { ...currentBench, name, benchmarkName: name, isModified: true, isNameModified: true };
      const modifiedList = copiedModifiedList.map((item) => (item.benchmarkId === targetBenchId ? modifiedBench : item));
      setModifiedBenchList(modifiedList);
    }

    // combinedBenchList
    const copiedCombinedBenchList = [...combinedBenchList];
    const modifiedCombinedBench = { ...currentBench, name, benchmarkName: name, isModified: true, isNameModified: true };
    const modifiedCombinedList = copiedCombinedBenchList.map((item) => (item.benchmarkId === targetBenchId ? modifiedCombinedBench : item));
    setCombinedBenchList(modifiedCombinedList);
  };

  // 벤치마킹 개별 이미지 화면 명

  const onChangeViewName = (imageId, newName) => {
    // currentBench
    const currentBenchImageArr = [...currentBench.benchmarkImages];
    const targetCurrentImageObj = currentBenchImageArr.find((item) => item.imageId === imageId);
    const modifiedCurrentImageObj = { ...targetCurrentImageObj, imageName: newName, isModified: true };
    const modifiedCurrentImageArr = currentBenchImageArr.map((item) => (item.imageId === imageId ? modifiedCurrentImageObj : item));
    const modifiedCurrentBench = { ...currentBench, benchmarkImages: modifiedCurrentImageArr, isModified: true, isImageNameModified: true };
    setCurrentBench(modifiedCurrentBench);

    if (currentBench.isNew) {
      // newBenchList
      const copiedList = [...newBenchList];
      const targetBench = copiedList.find((item) => item.benchmarkId === currentBench.benchmarkId);
      const copiedImageArr = [...targetBench.benchmarkImages];
      const targetImageObj = copiedImageArr.find((item) => item.imageId === imageId);
      const modifiedImageObj = { ...targetImageObj, imageName: newName };
      const newImageArr = copiedImageArr.map((item) => (item.imageId === imageId ? modifiedImageObj : item));
      const modifiedBench = { ...targetBench, benchmarkImages: newImageArr, isModified: true };
      const modifiedList = copiedList.map((item) => (item.benchmarkId === currentBench.benchmarkId ? modifiedBench : item));
      setNewBenchList(modifiedList);
    } else {
      //modifiedBenchList
      const copiedModifiedList = [...modifiedBenchList];
      const newModifiedList = copiedModifiedList.map((item) => (item.benchmarkId === currentBench.benchmarkId ? modifiedCurrentBench : item));
      setModifiedBenchList(newModifiedList);
    }

    // combinedBenchList
    const copiedCombinedList = [...combinedBenchList];
    const newCombinedList = copiedCombinedList.map((item) => (item.benchmarkId === currentBench.benchmarkId ? modifiedCurrentBench : item));
    setCombinedBenchList(newCombinedList);
  };

  // 벤치마킹 개별 이미지 변경
  const onChangeFile = (imageId, file, fileDataUrl) => {
    if (currentBench.registerType === "ADMIN") return;

    const copiedCurrentBench = { ...currentBench };
    const copiedCurrentImageArr = [...copiedCurrentBench.benchmarkImages];
    const copiedCurrentImageObj = copiedCurrentImageArr.find((item) => item.imageId === imageId);
    const modifiedCurrentImageObj = { ...copiedCurrentImageObj, image: file, fileDataUrl };
    const modifiedTargetImageArr = copiedCurrentImageArr.map((item) => (item.imageId === imageId ? modifiedCurrentImageObj : item));
    const modifiedCurrentBench = { ...copiedCurrentBench, benchmarkImages: modifiedTargetImageArr, isModified: true, isImageModified: true };
    setCurrentBench(modifiedCurrentBench);

    if (currentBench.isNew) {
      //newBenchList
      const copiedBenchList = [...newBenchList];
      const targetBench = copiedBenchList.find((item) => item.benchmarkId === currentBench.benchmarkId);
      const targetImageArr = [...targetBench.benchmarkImages];
      const targetImgObj = targetImageArr.find((item) => item.imageId === imageId);
      const modifiedImgObj = { ...targetImgObj, image: file, fileDataUrl };
      const modifiedImageArr = targetImageArr.map((item) => (item.imageId === imageId ? modifiedImgObj : item));
      const modifiedBench = { ...targetBench, benchmarkImages: modifiedImageArr, isModified: true };
      const modifiedBenchList = copiedBenchList.map((item) => (item.benchmarkId === currentBench.benchmarkId ? modifiedBench : item));
      setNewBenchList(modifiedBenchList);
    } else {
      //modifiedBenchList
      const copiedList = [...modifiedBenchList];
      const modifiedList = copiedList.map((item) => (item.benchmarkId === currentBench.benchmarkId ? modifiedCurrentBench : item));
      setModifiedBenchList(modifiedList);
    }

    // combinedBenchList
    const copiedCombinedList = [...combinedBenchList];
    const modifiedCombinedList = copiedCombinedList.map((item) => (item.benchmarkId === currentBench.benchmarkId ? modifiedCurrentBench : item));
    setCombinedBenchList(modifiedCombinedList);
  };

  // --------------------------------------------------------------------------------------------------

  // 벤치마킹 추가
  const onClickAddBench = () => {
    const newId = uuidv4();
    const oldDefault = { ...defaultBench };
    const newDefault = { ...oldDefault, benchmarkId: newId };
    setNewBenchList((prev) => [...prev, newDefault]);
    setCombinedBenchList((prev) => [...prev, newDefault]);
    setCurrentBench({ ...newDefault });
    setExampleKeywordArr([]);
    setSelectedKeywordArr([]);
    setTypedKeywordArr([]);
    setActiveKey("2");
  };

  // 벤치마킹 삭제
  const onClickDeleteBench = (targetBench, indexInDataArray) => {
    if (targetBench.benchmarkId !== currentBench.benchmarkId) {
      // 현재 보고 있지 않은 벤치마킹 삭제
      //-----------------------------------------
    } else {
      //현재 보고 있는 벤치마킹을 삭제
      if (indexInDataArray === 0) {
        // 현재 보고 있는 아이템 삭제 +  배열의 가장 첫 아이템인 경우
        if (combinedBenchList.length === 1) {
          // 현재 보고 있는 아이템 삭제 + 배열 첫 아이템 + 배열에서 혼자
          const oldDefault = { ...defaultBench };
          const newId = uuidv4();
          const newDefault = { ...oldDefault, benchmarkId: newId };
          setSelectedKeywordArr([]);
          setTypedKeywordArr([]);
          setExampleKeywordArr([]);
          setCurrentBench({ ...newDefault });
        } else {
          // 현재 보고 있는 아이템 삭제 + 배열 첫 아이템 + 뒤에 아이템 있는 경우
          const copiedCombinedBenchList = [...combinedBenchList];
          const nextItem = copiedCombinedBenchList[indexInDataArray + 1];
          onChangeCurrentBench(nextItem);
        }
      } else {
        // 현재 보고 있는 아이템 삭제 + 배열의 가장 첫 아이템은 아닌 경우
        const copiedCombinedBenchList = [...combinedBenchList];
        const prevItem = copiedCombinedBenchList[indexInDataArray - 1];
        onChangeCurrentBench(prevItem);
      }
    }

    if (targetBench.isNew) {
      // newBechList에서 삭제
      const copiedNewBenchList = [...newBenchList];
      const modifiedNewBenchList = copiedNewBenchList.filter((item) => item.benchmarkId !== targetBench.benchmarkId);
      setNewBenchList(modifiedNewBenchList);
    } else {
      // deltedBenchList에 추가

      //
      if (!deletedBenchList?.includes(targetBench.benchmarkId)) {
        setDeletedBenchList((prev) => [...prev, targetBench.benchmarkId]);
      }

      // modifiedBenchList에서 삭제
      const copiedModifiedBenchList = [...modifiedBenchList];
      const newlyModifiedBenchList = copiedModifiedBenchList.filter((item) => item.benchmarkId !== targetBench.benchmarkId);
      setModifiedBenchList(newlyModifiedBenchList);
    }
    // combinedBenchList에서 삭제
    const copiedCombinedBenchList = [...combinedBenchList];
    const modifiedCombinedBenchList = copiedCombinedBenchList.filter((item) => item.benchmarkId !== targetBench.benchmarkId);
    setCombinedBenchList(modifiedCombinedBenchList);
  };

  // 선택 추가 ---------------------------------------------------------------------------------------------------------

  // 선택 추가 시 관리자가 지정해준 키워드 배열
  const [exampleKeywordArr, setExampleKeywordArr] = useState([]);

  // aside의 선택 추가에서 벤치마킹 클릭하는 경우
  const getExampleBenchData = (exampleBenchId, benchmarkIcon) => {
    if (exampleBenchId !== 0) {
      axiosPrivate
        .get(`/api/v1/projects/benchmarks/${exampleBenchId}`)
        .then((res) => {
          const resultBench = res.data.result;
          const newBench = { ...resultBench, benchmarkIcon, isNew: true, isModified: false, registerType: "ADMIN" };

          // 새로 입력하는 경우
          if (currentBench.isNew) {
            const currentNewBenchList = [...newBenchList];
            const revisedNewBenchList = currentNewBenchList.map((item) => (item.benchmarkId === currentBench.benchmarkId ? newBench : item));
            setNewBenchList(revisedNewBenchList);
            const currentCombinedBenchList = [...combinedBenchList];
            const revisedCombinedBenchList = currentCombinedBenchList.map((item) => (item.benchmarkId === currentBench.benchmarkId ? newBench : item));
            setCombinedBenchList(revisedCombinedBenchList);
          } else {
            // 기존에 서버에 저장해둔 아이템을 변경하는 경우
            // 현재 아이템은 나중에 삭제
            setDeletedBenchList((prev) => [...prev, currentBench.benchmarkId]);
            // 새로운 벤치 리스트에 추가
            setNewBenchList((prev) => [...prev, newBench]);
            // 수정 벤치 리스트에서 해당 아이템 제거 (그래야 나중에 onSubmitd에서 해당 아이템 patch 요청 보내지 않음)
            const copiedModifiedBenchList = [...modifiedBenchList];
            const newModifiedBenchList = copiedModifiedBenchList.filter((item) => item.benchmarkId !== currentBench.benchmarkId);
            setModifiedBenchList(newModifiedBenchList);
            // 전체 리스트에서 아이템 교체
            const currentCombinedBenchList = [...combinedBenchList];
            const revisedCombinedBenchList = currentCombinedBenchList.map((item) => (item.benchmarkId === currentBench.benchmarkId ? newBench : item));
            setCombinedBenchList(revisedCombinedBenchList);
          }
          setCurrentBench(newBench);

          //키워드
          setExampleKeywordArr(res.data.result.benchmarkKeywords);
          setSelectedKeywordArr([]);
          setTypedKeywordArr([]);
        })
        .catch((err) => {
          console.log(err);
          // window.alert(err);
        });
    }
  };

  // aside의 선택 추가에서 벤치마킹 클릭하는 경우
  const onClickBenchExample = (item, isChecked) => {
    if (isChecked) {
      // 다른 벤치마킹 선택
      if (currentBench.isNew) {
        getExampleBenchData(item.benchmarkId, item.benchmarkIcon);
      } else {
        if (window.confirm("벤치마킹 변경 시 기존에 입력한 데이터가 모두 삭제됩니다. 계속 진행하시겠습니까?")) {
          getExampleBenchData(item.benchmarkId, item.benchmarkIcon);
        } else {
          return;
        }
      }
    } else {
      // 다시 자기 자신 선택 -> 선택 해제 -> default 상태로
      if (window.confirm("벤치마킹 선택 해제 시 기존에 입력한 데이터가 모두 삭제됩니다. 계속 진행하시겠습니까")) {
        const newId = uuidv4();
        const defaultBench = {
          ...currentBench,
          benchmarkId: newId,
          benchmarkImages: [
            {
              imageId: 1,
              priority: 1,
              image: "",
              imageName: "",
              fileDataUrl: "",
            },
            {
              imageId: 2,
              priority: 2,
              image: "",
              imageName: "",
              fileDataUrl: "",
            },
            {
              imageId: 3,
              priority: 3,
              image: "",
              imageName: "",
              fileDataUrl: "",
            },
            {
              imageId: 4,
              priority: 4,
              image: "",
              imageName: "",
              fileDataUrl: "",
            },
            {
              imageId: 5,
              priority: 5,
              image: "",
              imageName: "",
              fileDataUrl: "",
            },
          ],
          benchmarkKeywords: [],
          isNew: true,
          isModified: false,
          name: "벤치마킹 명",
          registerType: "MEMBER",
        };

        if (currentBench.isNew) {
          // 새로 입력한 아이템 초기화
          //newBenchList
          const copiedNewList = [...newBenchList];
          const modifiedNewList = copiedNewList.map((item) => (item.benchmarkId === currentBench.benchmarkId ? defaultBench : item));
          setNewBenchList(modifiedNewList);
        } else {
          // 서버에 이미 저장되어 있던 아이템을 초기화하는 경우
          // 삭제 할 아이템 목록에 추가
          setDeletedBenchList((prev) => [...prev, currentBench.benchmarkId]);
          // 새 목록에 추가
          setNewBenchList((prev) => [...prev, defaultBench]);
          // 변경 목록에서는 제거
          const copiedModifiedBenchList = [...modifiedBenchList];
          const newModifiedBenchList = copiedModifiedBenchList.filter((item) => item.benchmarkId !== currentBench.benchmarkId);
          setModifiedBenchList(newModifiedBenchList);
        }
        //combinedBenchList
        // 리스트에서 자기 자신을 찾아서 reset
        const copiedCombinedList = [...combinedBenchList];
        const modifiedCombinedList = copiedCombinedList.map((item) => (item.benchmarkId === currentBench.benchmarkId ? defaultBench : item));
        setCombinedBenchList(modifiedCombinedList);

        setExampleKeywordArr([]);
        setSelectedKeywordArr([]);
        setTypedKeywordArr([]);
        setCurrentBench(defaultBench);
      }
    }
  };

  // aside - 선택 입력 > 서버에서 받아오는 기본 벤치마킹 배열 init
  const [exampleBenchArr, setExampleBenchArr] = useState([]);

  useEffect(() => {
    if (benchExampleData) {
      setExampleBenchArr(benchExampleData.result);
    }
  }, [benchExampleData]);

  // 토스트 팝업
  const [isToastVisible, setIsToastVisible] = useState(false);

  const onSubmit = (e, item) => {
    //e.preventDefault();
    setProgressIng(true);
    const formData = new FormData();

    // 새로 입력한 벤치마킹
    /* 모니터링용
    console.log(newBenchList, "newBenchList");
    console.log(modifiedBenchList, "수정리스트");
    */
    if (newBenchList?.length > 0) {
      const newAdminBenchList = newBenchList.filter((item) => item.registerType === "ADMIN");
      const newMemberBenchList = newBenchList.filter((item) => item.registerType !== "ADMIN" && item.isModified);
      if (newAdminBenchList?.length > 0) {
        newAdminBenchList.forEach((item) => {
          formData.append("addAdminBenchmarkIds", item.benchmarkId);
        });
      }

      if (newMemberBenchList?.length > 0) {
        newMemberBenchList.forEach((item, benchmarkIndex) => {
          const { name, benchmarkImages, benchmarkKeywords, isModified } = item;
          if (!isModified) return;
          // 벤치마킹 명
          if (name) {
            formData.append(`memberBenchmarks[${benchmarkIndex}].name`, name);
          }
          // 각 화면 우선순위, 이미지, 화면 명
          benchmarkImages.forEach((item, imageIndex) => {
            const { priority, image, imageName } = item;
            formData.append(`memberBenchmarks[${benchmarkIndex}].images[${imageIndex}].priority`, priority);
            if (imageName) formData.append(`memberBenchmarks[${benchmarkIndex}].images[${imageIndex}].imageName`, imageName);
            //신규 이미지 선택한 경우 저장
            if (image) {
              formData.append(`memberBenchmarks[${benchmarkIndex}].images[${imageIndex}].image`, image);
            }
            //이미지 선택 없이 저장 할 경우 디폴트 이미지 저장
            else {
              formData.append(`memberBenchmarks[${benchmarkIndex}].images[${imageIndex}].image`, file);
            }
          });
          // 키워드
          benchmarkKeywords.forEach((item) => {
            if (item.isTyped) {
              //직접 입력 키워드
              formData.append(`memberBenchmarks[${benchmarkIndex}].newKeywords`, item.keywordName);
            } else {
              //선택 입력 키워드
              formData.append(`memberBenchmarks[${benchmarkIndex}].keywordIds`, item.keywordId);
            }
          });
        });
      }
    }

    // TODO : 이미 저장된 벤치마킹 수정 후 등록. 잘 되는지 확인하기.
    if (modifiedBenchList?.length > 0) {
      let memberIndex = 0;
      modifiedBenchList.forEach((item, i) => {
        // if (deletedBenchList.includes(item.benchmarkId)) return;
        if (item.isModified) {
          const { benchmarkId, name, benchmarkName, benchmarkImages, benchmarkKeywords } = item;
          // TODO
          const getLength = () => {
            if (newBenchList?.length > 0) {
              const newMemberList = newBenchList.filter((item) => item.registerType !== "ADMIN" && item.isModified);
              if (newMemberList.length > 0) {
                return newMemberList.length + i;
              } else {
                return i;
              }
            } else {
              return i;
            }
          };
          const length = getLength();
          formData.append(`memberBenchmarks[${memberIndex}].benchmarkId`, benchmarkId);
          // 벤치마킹 전체 이름 변경한 경우
          if (item.isNameModified) formData.append(`memberBenchmarks[${memberIndex}].name`, benchmarkName);
          // 화면 이미지, 화면 명 변경 되었을경우
          if (item.isImageModified || item.isImageNameModified) {
            benchmarkImages.forEach((imgObj, imageIndex) => {
              const { priority, imageId, image, imageName } = imgObj;
              formData.append(`memberBenchmarks[${memberIndex}].images[${imageIndex}].priority`, priority);
              formData.append(`memberBenchmarks[${memberIndex}].images[${imageIndex}].imageId`, imageId);
              if (imageName) formData.append(`memberBenchmarks[${memberIndex}].images[${imageIndex}].imageName`, imageName);
              //현재 저장된 이미지가 없을 경우 디폴트 이미지 저장
              if (image === "") {
                formData.append(`memberBenchmarks[${memberIndex}].images[${imageIndex}].image`, file);
              }
              //이미지 수정 된 이력이 있을경우
              if (item.isImageModified && typeof image !== "string") {
                //수정 된 이미지가 저장 되어있으면 formData추가
                if (image) {
                  formData.append(`memberBenchmarks[${memberIndex}].images[${imageIndex}].image`, image);
                }
              }
            });
          }

          // 키워드 변경
          if (item.isKeywordModified) {
            benchmarkKeywords.forEach((item) => {
              if (item.isTyped) {
                //직접 입력 키워드
                formData.append(`memberBenchmarks[${memberIndex}].newKeywords`, item.keywordName);
              } else {
                //선택 입력 키워드
                formData.append(`memberBenchmarks[${memberIndex}].keywordIds`, item.id !== undefined ? item.id : item.keywordId);
              } // 키워드가 id로 내려오거나 keywordId 로 내려올수 있음
            });
          }
          memberIndex++;
        }
      });
    }

    // 삭제할 벤치마킹
    if (deletedBenchList?.length > 0) {
      deletedBenchList.forEach((item) => {
        formData.append("deleteBenchmarkIds", item);
      });
    }
    /* 데이터 모니터링용*/
    for (let key of formData.keys()) {
      console.log(key, ":", formData.get(key));
    }

    axiosPrivate
      .patch(`/api/v1/projects/${id}/concept/benchmarks`, formData)
      .then((res) => {
        setDeletedBenchList([]);
        setIsToastVisible(true);
        setProgressIng(false);
        benchListMutate();
        navigate(item);
      })
      .catch((err) => {
        setProgressIng(false);
        console.log("저장 에러 결과", err);
        alert(`[윤미: QA 시에만 보여줄 에러메시지입니다] resultMsg: ${err?.response?.data?.resultMsg} reason: ${err?.response?.data?.errors[0]?.reason}`);
      });
  };

  // ui --------------------------------------------------------------------------------------------------
  // aside - 직접 입력 키워드 랜덤 배경색
  //keywordArr: 서버에서 받아온 직접입력 속 추천 키워드
  const [typeRandomBgArr, setTypeRandomBgArr] = useState([]);
  useEffect(() => {
    setTypeRandomBgArr(getRandomColor(keywordArr?.length));
  }, [keywordArr]);

  // aside - 좌/우 키워드 색상 맞추기
  const [selectedBgArr, setSelectedBgArr] = useState([]);
  useEffect(() => {
    const bgArr = getSelectedBgArr(keywordArr, selectedKeywordArr, typeRandomBgArr);
    setSelectedBgArr(bgArr);
  }, [selectedKeywordArr]);

  // aside - 직접 입력 > 선택된 키워드 개수 관리
  const [isKeywordMax, setIsKeywordMax] = useState(false);
  useEffect(() => {
    if (selectedKeywordArr?.length + typedKeywordArr?.length >= KEYWORD_MAX) {
      setIsKeywordMax(true);
    } else {
      setIsKeywordMax(false);
    }
  }, [selectedKeywordArr, typedKeywordArr]);

  return (
    <>
      {progressIng && (
        <Box sx={{ position: "fixed", zIndex: "1000", left: "50%", top: "50%" }}>
          <CircularProgress size="100px" />
          {/*<Box sx={{ marginTop: "10px", fontSize: "30px", fontWeight: "500", color: "white", display: "flex", justifyContent: "center" }}>저장중</Box>*/}
        </Box>
      )}
      {isToastVisible && (
        <ToastPopup isToastVisible={isToastVisible} setIsToastVisible={setIsToastVisible}>
          저장에 성공했습니다!
        </ToastPopup>
      )}
      <Layout onClickPdf={onClickPdf} onSave={onSubmit} isViewMode={isViewMode}>
        <Content progressData={progressData} ref={scrollRef} onSave={onSubmit} isViewMode={isViewMode}>
          <Form onSubmit={onSubmit} ref={printRef}>
            <ContentHeading>
              <h1>
                다른 제품/서비스는 어떻게 생겼는지 <span>비교</span>해보세요.
              </h1>
              {/* 페이지 이동시 자동 저장으로 주석처리
              <button type="submit" disabled={isViewMode}>
                저장
              </button>
              */}
            </ContentHeading>

            <>
              <TitleSlider
                combinedBenchList={combinedBenchList}
                currentBench={currentBench}
                onChangeCurrentBench={onChangeCurrentBench}
                onChangeBenchName={onChangeBenchName}
                onClickAddBench={onClickAddBench}
                onClickDeleteBench={onClickDeleteBench}
                isViewMode={isViewMode}
              />
              <WhiteCard>
                <BenchmarkingItem
                  currentBench={currentBench}
                  onChangeViewName={onChangeViewName}
                  onChangeFile={onChangeFile}
                  selectedBgArr={selectedBgArr}
                  selectedKeywordArr={selectedKeywordArr}
                  typedKeywordArr={typedKeywordArr}
                  exampleKeywordArr={exampleKeywordArr}
                  onClickSelf={onClickSelf}
                  isViewMode={isViewMode}
                />
              </WhiteCard>
            </>
          </Form>
        </Content>
        <Aside>
          <AsideInfo heading="벤치마킹 프로덕트 입력" onClickHelp={onClickHelp}>
            다른 제품/서비스를 통해 마케팅 전략, 기능, 디자인 컨셉을 적용해보는 과정입니다.
          </AsideInfo>
          <AsideAccordion
            activeKey={activeKey}
            setActiveKey={setActiveKey}
            keywordArr={keywordArr}
            selectedKeywordArr={selectedKeywordArr}
            isMax={isKeywordMax}
            typedKeywordArr={typedKeywordArr}
            onChangeTypedKeyword={onChangeTypedKeyword}
            onChangeSelectedKeyword={onChangeSelectedKeyword}
            exampleBenchArr={exampleBenchArr}
            typeRandomBgArr={typeRandomBgArr}
            onClickBenchExample={onClickBenchExample}
            currentBench={currentBench}
            combinedBenchList={combinedBenchList}
            isViewMode={isViewMode}
          />
        </Aside>
      </Layout>
      {isHelpVisible && (
        <CustomModal isVisible={isHelpVisible}>
          <HelpModalContent headingText="벤치마킹 템플릿 사용 방법" guideArr={benchGuide} img={popup_bench} onClick={onClickCloseModal} />
        </CustomModal>
      )}
    </>
  );
};

export default Benchmarking;
