import styled from "styled-components";
import { flexbox } from "styles/mixins";
import ic_arrow_left_grey from "assets/ic_arrow_left_grey.svg";
import ic_arrow_right_white from "assets/ic_arrow_right_white.svg";
import ic_arrow_right_disabled from "assets/ic_arrow_right_disabled.svg";

export const Wrapper = styled.div`
	${flexbox("flex-start", "center")};
	position: absolute;
	bottom: 25px;
	right: 57px;
`;

export const PrevBtn = styled.button`
	display: block;
	padding: 0 0 0 17px;
	margin: 0 32px 0 0;
	background-image: url(${ic_arrow_left_grey});
	background-repeat: no-repeat;
	background-position: left 5px;
	background-size: 7px 15px;
	font-weight: 700;
	font-size: 15px;
	line-height: 24px;
	color: #000;
	opacity: 0.7;

	:hover {
		color: #000;
	}
`;

export const NextBtn = styled.button`
	${flexbox("flex-start", "center")};
	height: 44px;
	padding: 0 53px 0 38px;
	padding: ${(props) => (props.isOutro ? "0  32px" : "0 53px 0 38px")};
	font-weight: 700;
	font-size: 15px;
	line-height: 24px;
	color: #fff;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.12);
	border-radius: 24px;
	background-color: #4556ee;
	background-image: url(${(props) => (props.isOutro ? "none" : ic_arrow_right_white)});
	background-repeat: no-repeat;
	background-size: 7px 15px;
	background-position: 78px 16px;
	box-shadow: unset;
	transition: background-color 300ms ease-in-out, box-shadow 300ms ease-in-out, color 300ms ease-in-out;

	:hover {
		color: #fff;
	}

	&:disabled {
		background: #f3f3f3;
		box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.12);
		background-image: url(${(props) => (props.isOutro ? "none" : ic_arrow_right_disabled)});
		background-repeat: no-repeat;
		background-size: 7px 15px;
		background-position: 78px 16px;
		color: #d6d6d6;
		pointer-events: none;
	}
`;
