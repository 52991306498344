import React, { useRef, useState, useEffect } from "react";
import useSWR from "swr";
import { useParams } from "react-router-dom";
import axiosPrivate from "utils/axiosPrivate";
import { fetcherPrivate } from "utils/fetcher";
import Layout from "layouts/Layout";
import Content from "layouts/Content";
import ContentHeading from "layouts/Content/ContentHeading";
import Aside from "layouts/Aside";
import AsideInfo from "layouts/Aside/AsideInfo";
import CustomModal from "components/common/CustomModal";
import HelpModalContent from "components/common/HelpModalContent";
import AnswerModalContent from "components/test/AnswerModalContent";
import FeedbackTable from "components/test/FeedbackTable";
import popup_feedback from "assets/popup_feedback.svg";
import { ProjectInfo, Progress, Duration, Name, Count, AsideHeading, AsideContent, Form, FileBtn, SendBtn, EmailInput, TerminateBtn, EmailPopupBody, LinkInfo, FormDownload } from "./styles";
import { TEST_FORM, REVIEW, feedbackGuide } from "utils/data";
import { ModalBtnWrapper, ModalContent, ModalDesc, ModalForm } from "pages/home/styles";
import { emailReg } from "utils/regex";
import { getSortingCriteria } from "utils/getSortingCriteria";
import { DEFAULT, MAN, WOMAN, UNDEFINED, OLDEST, BEFORE_PROCEEDING, PROCEEDING, END_OF_PROGRESS } from "utils/data";
import useViewMode from "hooks/useViewMode";
import { utils, writeFileXLSX } from "xlsx";
import tester_form from "assets/tester_form.xlsx";

const progressData = {
  prev: TEST_FORM,
  next: REVIEW,
};

// 1장당 1mb 이하
const MAX_EXCEL_FILE_SIZE = 1024 * 1024 * 10;

const TestFeedback = () => {
  const scrollRef = useRef(null);

  // 도움말 팝업
  const [isHelpVisible, setIsHelpVisible] = useState(false);
  const onClickCloseModal = () => setIsHelpVisible(false);
  const onClickHelp = () => setIsHelpVisible(true);

  const { id: projectId } = useParams();

  const isViewMode = useViewMode();

  // 테스트 정보 데이터
  const [feedbackInfo, setFeedbackInfo] = useState([]);
  const { data: feedInfoData, mutate: feedInfoDataMutate } = useSWR(`/api/v1/projects/${projectId}/test/info`, (url) => fetcherPrivate(url), { revalidateOnFocus: false });
  const [isFinishSurveyDisabled, setIsFinishSurveyDisabled] = useState(feedbackInfo?.result?.projectTestStatus === "종료" ? true : false);
  useEffect(() => {
    //테스트정보 조회 state적용
    setFeedbackInfo(feedInfoData);
  }, [feedInfoData]);

  // 테이블 데이터
  // default가 최신순 정렬인데 백에서 오래된 순으로 보내주고 있기 때문에 reverse해서 써야한다.
  const { data: feedbackData, error: feedbackError, mutate: feedbackMutate } = useSWR(`/api/v1/projects/${projectId}/test/cases`, (url) => fetcherPrivate(url), { revalidateOnFocus: false });
  const [feedback, setFeedback] = useState([]);

  useEffect(() => {
    if (feedbackData && feedbackData?.result?.length > 0) {
      const data = [...feedbackData.result];
      setFeedback(data.reverse());
      setIsFinishSurveyDisabled(false);
      // 테스트 종료 관련
      // const proceedingTester = data.find((item) => item.testCaseStepType === "PROCEEDING");
      // if (proceedingTester) {
      //   setIsFinishSurveyDisabled(false);
      // } else {
      //   setIsFinishSurveyDisabled(true);
      // }
    } else {
      setIsFinishSurveyDisabled(true);
    }
  }, [feedbackData]);

  // 스크롤 -> 테이블 데이터 더 로딩
  const target = useRef(null);
  const [itemCount, setItemCount] = useState(15);

  // 무한스크롤링하는척
  const getMoreItem = async () => {
    await new Promise((resolve) => setTimeout(resolve, 100));
    setItemCount((prev) => prev + 15);
  };

  const onIntersect = async ([entry], observer) => {
    if (entry.isIntersecting) {
      observer.unobserve(entry.target);
      await getMoreItem();
      observer.observe(entry.target);
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(onIntersect, { threshold: 0.5 });
    observer.observe(target.current);
    return () => observer.disconnect();
  }, [target]);

  // 테스터 정보 엑셀 추출
  const onClickPdf = () => {
    const modifiedList = feedback?.map((item) => {
      const gender = item.genderType === MAN ? "남성" : item.genderType === WOMAN ? "여성" : "구분없음";
      const status = item.testCaseStepType === BEFORE_PROCEEDING ? "진행 전" : item.testCaseStepType === PROCEEDING ? "진행 중" : "진행 완료";
      return {
        name: item.testerName,
        gender,
        age: item.testerAge,
        email: item.testerEmail,
        date: item.testCaseEmailDate,
        status,
      };
    });
    const ws = utils.json_to_sheet(modifiedList);
    const wb = utils.book_new();
    utils.sheet_add_aoa(ws, [["이름", "성별", "연령", "이메일", "발송일자", "상태"]], { origin: "A1" });
    utils.book_append_sheet(wb, ws, "TesterList");
    writeFileXLSX(wb, "TesterList.xlsx");
  };

  //  테이블 정렬
  const genderSortArr = [DEFAULT, MAN, WOMAN, UNDEFINED];
  const [genderSortType, setGenderSortType] = useState(DEFAULT);
  const onClickGender = () => {
    // itemCount 다시 15로
    const nextSortType = getSortingCriteria(genderSortType, genderSortArr);
    setGenderSortType(nextSortType);
  };

  const dateSortArr = [DEFAULT, OLDEST];
  const [dateSortType, setDateSortType] = useState(DEFAULT);
  const onClickDate = () => {
    const nextSortType = getSortingCriteria(dateSortType, dateSortArr);
    setDateSortType(nextSortType);
  };

  const statusSortArr = [DEFAULT, PROCEEDING, END_OF_PROGRESS, BEFORE_PROCEEDING];
  const [statusSortType, setStatusSortType] = useState(DEFAULT);
  const onClickStatus = () => {
    const nextSortType = getSortingCriteria(statusSortType, statusSortArr);
    setStatusSortType(nextSortType);
  };

  // 하나의 sort type이 바뀌면 나머지 sort type들은 초기화, 리스트 아이템은 15개

  const getSortedArr = (key, value) => {
    const originalData = [...feedbackData.result].reverse();
    const targetArr = originalData.filter((item) => item[key] === value);
    const restArr = originalData.filter((item) => item[key] !== value);
    return [...targetArr, ...restArr];
  };

  useEffect(() => {
    if (feedbackData && feedbackData?.result?.length > 0) {
      const originalData = [...feedbackData.result].reverse();
      if (genderSortType === DEFAULT) {
        setFeedback(originalData);
      } else if (genderSortType === MAN) {
        const sortedArr = getSortedArr("genderType", MAN);
        setFeedback(sortedArr);
      } else if (genderSortType === WOMAN) {
        const sortedArr = getSortedArr("genderType", WOMAN);
        setFeedback(sortedArr);
      } else if (genderSortType === UNDEFINED) {
        const sortedArr = getSortedArr("genderType", UNDEFINED);
        setFeedback(sortedArr);
      }
      setDateSortType(DEFAULT);
      setStatusSortType(DEFAULT);
      setItemCount(15);
    }
  }, [genderSortType]);

  useEffect(() => {
    if (feedbackData && feedbackData?.result?.length > 0) {
      const originalData = [...feedbackData.result].reverse();
      if (dateSortType === DEFAULT) {
        setFeedback(originalData);
      } else if (dateSortType === OLDEST) {
        const sortedArr = [...originalData].reverse();
        setFeedback(sortedArr);
      }
    }
    setGenderSortType(DEFAULT);
    setStatusSortType(DEFAULT);
    setItemCount(15);
  }, [dateSortType]);

  useEffect(() => {
    if (feedbackData && feedbackData?.result?.length > 0) {
      const originalData = [...feedbackData.result].reverse();
      if (statusSortType === DEFAULT) {
        setFeedback(originalData);
      } else if (statusSortType === PROCEEDING) {
        const sortedArr = getSortedArr("testCaseStepType", PROCEEDING);
        setFeedback(sortedArr);
      } else if (statusSortType === END_OF_PROGRESS) {
        const sortedArr = getSortedArr("testCaseStepType", END_OF_PROGRESS);
        setFeedback(sortedArr);
      } else if (statusSortType === BEFORE_PROCEEDING) {
        const sortedArr = getSortedArr("testCaseStepType", BEFORE_PROCEEDING);
        setFeedback(sortedArr);
      }
      setDateSortType(DEFAULT);
      setGenderSortType(DEFAULT);
      setItemCount(15);
    }
  }, [statusSortType]);

  // 테스트 정보 조회
  const [projectStatus, setProjectStatus] = useState();
  const [testInfo, setTestInfo] = useState();
  const { data: testInfoData } = useSWR(`/api/v1/projects/${projectId}/info`, (url) => fetcherPrivate(url), { revalidateOnFocus: false });
  useEffect(() => {
    if (testInfoData?.result) {
      setTestInfo(testInfoData.result);
      setProjectStatus(testInfoData.result.projectStatusType);
    }
  }, [testInfoData]);

  // 사용한 설문 목록 조회
  const [questionList, setQuestionList] = useState([]);
  useEffect(() => {
    axiosPrivate
      .get(`/api/v1/projects/${projectId}/test/survey`)
      .then((res) => {
        if (res.data.result?.testSurveyIndices?.length > 0) {
          setQuestionList(res.data.result.testSurveyIndices);
        }
      })
      .catch((err) => {
        window.alert("설문 목록 조회에 실패했습니다. 관리자에게 문의해주세요!");
      });
  }, []);

  // 엑셀 파일 전송
  const [excelFile, setExcelFile] = useState();
  const [excelFileName, setExcelFileName] = useState();
  const onChangeExcel = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file?.size > MAX_EXCEL_FILE_SIZE) {
        return alert("파일 사이즈가 10MB를 초과했습니다.");
      } else {
        setExcelFile(file);
        setExcelFileName(file.name);
      }
    }
  };

  const onClickExcelSend = () => {
    if (excelFile) {
      const formData = new FormData();
      formData.append("testerList", excelFile);
      axiosPrivate
        .post(`/api/v1/projects/${projectId}/test/cases/excel`, formData)
        .then((res) => {
          feedbackMutate();
          feedInfoDataMutate();
          window.alert("테스터에게 메일을 전송하였습니다.");
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setExcelFile();
          setExcelFileName();
        });
    }
  };

  // 이메일 전송
  const [email, setEmail] = useState("");
  const onChangeEmail = (e) => setEmail(e.target.value);

  const [isEmailSendAble, setIsEmailSendAble] = useState(false);
  useEffect(() => {
    if (emailReg.test(email)) {
      setIsEmailSendAble(true);
    } else {
      setIsEmailSendAble(false);
    }
  }, [email]);

  const [isEmailModalVisible, setIsEmailModalVisible] = useState(false);

  const onClickEmailSend = () => setIsEmailModalVisible(true);
  const onConfirmEmail = () => {
    const data = {
      testerEmail: email,
    };
    axiosPrivate
      .post(`/api/v1/projects/${projectId}/test/cases`, data)
      .then((res) => {
        console.log(res);
        setEmail("");
        setIsEmailModalVisible(false);
        feedbackMutate();
        feedInfoDataMutate();
        window.alert("테스터에게 메일을 전송하였습니다.");
      })
      .catch((err) => {
        console.log(err);
        setIsEmailModalVisible(false);
        window.alert("메일 전송에 실패했습니다. 다시 시도해주세요!");
      });
  };
  const onCancelEmail = () => setIsEmailModalVisible(false);

  // 응답 모달
  const [isAnswerModalVisible, setIsAnswerModalVisible] = useState(false);
  const [testerResponse, setTesterResponse] = useState();
  const onClickAnswerModalOpen = (testerUid) => {
    setTesterResponse();

    axiosPrivate
      .get(`/api/v1/open/projects/${projectId}/test/case/${testerUid}`)
      .then((res) => {
        setIsAnswerModalVisible(true);
        setTesterResponse(res.data.result);
      })
      .catch((err) => {
        console.log(err);
        setTesterResponse();
        window.alert("테스터 응답 조회에 실패했습니다. 관리자에게 문의해주세요!");
      });
  };
  const onClickAnswerModalClose = () => {
    setIsAnswerModalVisible(false);
    setTesterResponse();
  };
  // 테스트 종료
  const [isSurveyFinished, setIsSurveyFinished] = useState(false);
  const [isSurveyFinishModalVisible, setIsSurveyFinishModalVisible] = useState(false);
  const onClickSurveyFinish = () => setIsSurveyFinishModalVisible(true);
  const onClickConfirmFinish = () => {
    axiosPrivate
      .put(`/api/v1/projects/${projectId}/test/close`)
      .then(() => {
        setIsFinishSurveyDisabled(true);
        //setIsSurveyFinished(true);
        feedInfoDataMutate();
      })
      .catch(() => {
        window.alert("테스트 종료에 실패했습니다. 다시 시도해주세요!");
      })
      .finally(() => {
        setIsSurveyFinishModalVisible(false);
      });
  };
  const onClickCancelFinish = () => setIsSurveyFinishModalVisible(false);

  return (
    <>
      <Layout onClickPdf={onClickPdf}>
        <Content progressData={progressData} ref={scrollRef}>
          <div>
            <ContentHeading>
              <h1>
                제품/서비스에 대해 솔직한 <span>피드백</span>을 받습니다.
              </h1>
            </ContentHeading>
            {/* project info와 테이플을 감싸는 div. 다른 페이지의 form처럼 너비 관리 */}
            <div>
              <ProjectInfo>
                <div>
                  <Progress status={projectStatus}>
                    {feedbackInfo?.result?.projectTestStartDate == null ? "진행 전" : feedbackInfo?.result?.projectTestStatus === "진행중" ? "진행 중" : "진행 완료"}
                  </Progress>
                  <Name>{testInfo && testInfo?.name ? testInfo.name : ""} 테스트</Name>
                  <Duration>
                    {feedbackInfo?.result?.projectTestStartDate == null ? "" : feedbackInfo.result.projectTestStartDate + " ~ "}
                    {feedbackInfo?.result?.projectTestEndDate == null ? "" : feedbackInfo?.result?.projectTestEndDate}
                  </Duration>
                </div>
                <Count>총 {feedback?.length}명</Count>
              </ProjectInfo>
              <FeedbackTable
                data={feedback}
                itemCount={itemCount}
                onClickGender={onClickGender}
                onClickDate={onClickDate}
                onClickStatus={onClickStatus}
                onClickAnswerModalOpen={onClickAnswerModalOpen}
                ref={target}
              />
            </div>
          </div>
        </Content>
        <Aside>
          <AsideInfo heading="테스트 정보 입력" onClickHelp={onClickHelp}>
            제품/서비스에 대한 의견을 작성할 테스터에게 테스트를 전송하고 진행 현황을 확인하는 과정입니다.
          </AsideInfo>
          <AsideHeading>테스터 모집</AsideHeading>
          <LinkInfo>
            설문 링크의 유효기간은 30일이며, 이후에는 <br />
            설문작성이 불가합니다.
          </LinkInfo>
          <AsideContent>
            <div>
              <h5>테스터 입력 (일괄 등록)</h5>
              <p>모집한 인원이 있다면 간편하게 등록하세요</p>
              <Form>
                <FileBtn>
                  <input type="file" id="tester-file" accept=".xls, .xlsx" onChange={onChangeExcel} disabled={isViewMode || feedbackInfo?.result?.projectTestStatus === "종료"} />
                  <label htmlFor="tester-file">{excelFile && excelFileName?.length > 0 ? <span>{excelFileName}</span> : "입력 가능 파일: 엑셀xlsx"}</label>
                </FileBtn>
                <SendBtn type="button" onClick={onClickExcelSend} disabled={!excelFile || isViewMode || feedbackInfo?.result?.projectTestStatus === "종료"}>
                  전송
                </SendBtn>
              </Form>
              {/* 디자인적용필요 */}
              <FormDownload href={tester_form} download="I2B Toolkit_테스터 입력 양식">
                I2B Toolkit_테스터 입력 양식
              </FormDownload>
            </div>
            <div>
              <h5>테스터 입력(직접입력)</h5>
              <p>텍스트로 전송할 이메일을 입력하세요</p>
              <Form>
                <EmailInput type="text" placeholder="ex@email.com" value={email} onChange={onChangeEmail} disabled={isViewMode || feedbackInfo?.result?.projectTestStatus === "종료"} />
                <SendBtn type="button" onClick={onClickEmailSend} disabled={!isEmailSendAble || isViewMode || feedbackInfo?.result?.projectTestStatus === "종료"}>
                  전송
                </SendBtn>
              </Form>
            </div>
          </AsideContent>
          <TerminateBtn type="button" disabled={isViewMode || feedbackInfo?.result?.projectTestStatus === "종료" || feedbackData?.result?.length < 1} onClick={onClickSurveyFinish}>
            테스트 종료
          </TerminateBtn>
        </Aside>
      </Layout>
      {isHelpVisible && (
        <CustomModal isVisible={isHelpVisible}>
          <HelpModalContent headingText="설문 작성 템플릿 사용 방법" guideArr={feedbackGuide} img={popup_feedback} onClick={onClickCloseModal} />
        </CustomModal>
      )}
      {isEmailModalVisible && (
        <CustomModal isVisible={isEmailModalVisible}>
          <ModalContent>
            <h2>이메일로 테스트 전송</h2>
            <div>
              <div>
                <EmailPopupBody>
                  <p>{email}</p>
                  <p>위 이메일 주소로 테스트를 전송합니다.</p>
                </EmailPopupBody>
                <ModalBtnWrapper hasInput>
                  <button type="button" onClick={onCancelEmail}>
                    취소
                  </button>
                  <button type="button" onClick={onConfirmEmail}>
                    확인
                  </button>
                </ModalBtnWrapper>
              </div>
            </div>
          </ModalContent>
        </CustomModal>
      )}
      {isSurveyFinishModalVisible && (
        <CustomModal isVisible={isSurveyFinishModalVisible}>
          <ModalContent>
            <h2>테스트 종료</h2>
            <ModalDesc>
              <p>
                아직 완료되지 않은 테스트가 있습니다.
                <br />
                <strong>그래도 테스트를 종료하시겠습니까?</strong>
                <span>종료 이전 등록된 응답만 확인 가능합니다.</span>
              </p>
              <ModalBtnWrapper>
                <button type="button" onClick={onClickCancelFinish}>
                  취소
                </button>
                <button type="button" onClick={onClickConfirmFinish}>
                  종료
                </button>
              </ModalBtnWrapper>
            </ModalDesc>
          </ModalContent>
        </CustomModal>
      )}
      {isAnswerModalVisible && (
        <CustomModal isVisible={isAnswerModalVisible}>
          <AnswerModalContent testerResponse={testerResponse} onClickClose={onClickAnswerModalClose} questionList={questionList} />
        </CustomModal>
      )}
    </>
  );
};

export default TestFeedback;
