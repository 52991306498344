import { useState, useEffect, useContext } from "react";
import { TextContext } from "context/text-context";
import LinkInfo from "components/test/LinkInfo";
import LinkInput from "components/test/LinkInput";
import PrevNextBtn from "components/test/PrevNextBtn";
import { TabContainer } from "../../../../pages/test/form/styles";
import { Label, Textarea } from "./styles";
import { IntroText } from "../Intro/styles";
import { USER, PREVIEW, USER_REVIEW } from "utils/data";

const textareaPlaceholder = `ex) 귀하께서 보내주신 답변은 신규 프로덕트 개발을 위해 소중히 사용하겠습니다. 
앞서 작성해주신 이메일을 통해 소정의 보답을 드립니다. 
감사합니다.
`;

const outroPlaceholder = `귀하께서 보내주신 답변은 신규 프로덕트 개발을 위해 소중히 사용하겠습니다. 
감사합니다.
`;

const Outro = ({ isShowing, status, link, closingWords, isViewMode }) => {
  const textCtx = useContext(TextContext);
  const onChangeText = (e) => {
    textCtx.setOutroText(e.target.value);
  };

  //TODO
  const [outroText, setOutroText] = useState(outroPlaceholder);

  useEffect(() => {
    if (status === PREVIEW) {
      const text = window.localStorage.getItem("outro");
      if (text && JSON.parse(text).length > 0) {
        setOutroText(JSON.parse(text));
      } else if (status === USER_REVIEW) {
        setOutroText(closingWords);
      } else {
        setOutroText(outroPlaceholder);
      }
    }
  }, [status, closingWords]);

  return (
    <TabContainer isShowing={isShowing}>
      {status !== USER ? <LinkInfo link={link} /> : <LinkInput />}
      <Label htmlFor="finish">끝내는 말</Label>
      {status === USER ? (
        <Textarea id="intro" placeholder={textareaPlaceholder} value={textCtx.outroText} onChange={onChangeText} disabled={isViewMode}></Textarea>
      ) : (
        <IntroText>
          {outroText.split("\n").map((line, i) => (
            <span key={i}>
              {line} <br />
            </span>
          ))}
        </IntroText>
      )}
      {status === PREVIEW && <PrevNextBtn isOutro />}
    </TabContainer>
  );
};

export default Outro;
