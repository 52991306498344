import { useState, useRef } from "react";
import { Carousel } from "antd";
import "antd/dist/antd.css";
import { Wrapper, ButtonWrapper, ImageWrapper, NoLayout } from "./styles";
import ic_no_layout from "assets/ic_no_layout.svg";

// layoutId, layoutImage, layoutName, priority
const CarouselComp = ({ layoutArr }) => {
  const [current, setCurrent] = useState(0);

  const carouselRef = useRef();

  const onClickPrev = () => {
    if (carouselRef.current) {
      carouselRef.current.prev();

      if (current !== 0) {
        setCurrent((prev) => prev - 1);
      }
    }
  };

  const onClickNext = () => {
    if (carouselRef.current) {
      carouselRef.current.next();
      if (current !== layoutArr.length - 1) {
        setCurrent((prev) => prev + 1);
      }
    }
  };
  return (
    <Wrapper>
      <ButtonWrapper>
        <button type="button" disabled={current === 0} onClick={onClickPrev}></button>
        <p>{layoutArr?.length > 0 ? layoutArr[current].layoutName : "홈"}</p>
        <button type="button" disabled={layoutArr?.length > 0 ? current === layoutArr.length - 1 : true} onClick={onClickNext}></button>
      </ButtonWrapper>
      {layoutArr?.length > 0 ? (
        <Carousel ref={carouselRef}>
          {layoutArr.map((item, i) => (
            <ImageWrapper key={i}>
              <img src={item.layoutImage} referrerPolicy="no-referrer" />
            </ImageWrapper>
          ))}
        </Carousel>
      ) : (
        <NoLayout>
          <img src={ic_no_layout} alt="image" referrerPolicy="no-referrer" />
          <p>테스트에 활용할 화면이 없네요.</p>
          <p>
            <strong>제작 단계</strong>를 통해 화면을 구성해보세요!
          </p>
        </NoLayout>
      )}
    </Wrapper>
  );
};

export default CarouselComp;
