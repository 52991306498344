import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axiosPublic from "utils/axiosPublic";
import Layout from "components/register/Layout";
import ResetCard from "components/reset-password/ResetCard";
import AuthNum from "components/common/AuthNum";
import { Email } from "./styles";
import { HelperWrapper } from "pages/register/EmailAuth/styles";

const ResetAuth = () => {
  let navigate = useNavigate();

  const [email, setEmail] = useState("");

  const [expireAt, setExpireAt] = useState();

  const sendAuthNum = (email) => {
    axiosPublic
      .get("/api/v1/open/email/send-code/password", {
        params: {
          email: email,
        },
      })
      .then((res) => {
        console.log(res);
        setExpireAt(res.data.result.timeout);
      })
      .catch((err) => {
        console.log(err);
        window.alert("인증번호 전송에 실패했습니다. 다시 시도해주세요!");
      });
  };

  useEffect(() => {
    const data = window.localStorage.getItem("reset-email");
    if (data) {
      setEmail(data);
      sendAuthNum(data);
    } else {
      window.alert("이메일 확인에 실패했습니다. 이메일을 다시 입력해주세요!");
    }
  }, []);

  const [authNum, setAuthNum] = useState();

  // 번호가 잘못된 경우
  const [isNumWrong, setIsNumWrong] = useState(false);

  // 시간이 만료된 경우
  const [isTimeoutFromServer, setIsTimeoutFromServer] = useState(false);

  useEffect(() => {
    if (authNum) {
      setIsNumWrong(false);
      setIsTimeoutFromServer(false);
      // 인증번호 확인
      axiosPublic
        .get("/api/v1/open/email/verify/password", {
          params: {
            email,
            code: authNum,
          },
        })
        .then((response) => {
          const uid = response.data?.result?.uid;
          window.localStorage.setItem("uid", uid);
          navigate("/reset/change");
        })
        .catch((error) => {
          const errorCode = error.response.data.code;
          if (errorCode === "EMAIL_E_005") {
            // 이메일 인증 코드가 잘못되었습니다.
            setIsNumWrong(true);
          } else if (errorCode === "EMAIL_E_004") {
            // 이메일 인증 코드가 만료되었습니다.
            setIsTimeoutFromServer(true);
          } else if (errorCode === "EMAIL_E_006") {
            // TODO
            // 이메일 인증을 다시 시도하시기 바랍니다. ????
          }
        });
    }
  }, [authNum]);

  // 재전송 버튼 클릭
  const [reset, setReset] = useState(false);
  const onClickReset = () => {
    setIsNumWrong(false);
    setIsTimeoutFromServer(false);
    setReset(true);
    sendAuthNum(email);
  };

  return (
    <Layout>
      <ResetCard>
        <h1>메일로 인증번호를 발송했습니다!</h1>
        <Email>{email ? email : ""}</Email>
        <form>
          <AuthNum expireAt={expireAt} setAuthNum={setAuthNum} isNumWrong={isNumWrong} isTimeoutFromServer={isTimeoutFromServer} reset={reset} setReset={setReset} />
        </form>
        <HelperWrapper>
          <p>혹시 이메일이 도착하지 않았나요?</p>
          <button type="button" onClick={onClickReset}>
            인증메일 재발송
          </button>
        </HelperWrapper>
      </ResetCard>
    </Layout>
  );
};

export default ResetAuth;
