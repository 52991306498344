import { Link } from "react-router-dom";
import styled from "styled-components";
import logo from "assets/logo.svg";
import { flexbox } from "styles/mixins";

export const Nav = styled.nav`
  flex-shrink: 0;
  width: 242px;
  max-width: 242px;
  padding: 24px 16px;
  border-right: 1px solid #e0e0e0;

  li a,
  li button {
    display: block;
    width: 100%;
    text-align: left;
    padding: 0 12px;
    margin: 0 0 2px 0;
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 35px;
    color: #000;
    background-color: transparent;
    border-radius: 8px;
    transition: background-color 300ms ease-in-out, font-weight 300ms ease-in-out;

    &:hover {
      color: #000;
      background-color: #e9e9e9;
    }

    &.is-active {
      font-weight: 600;
      background-color: #e9e9e9;
    }
  }
`;

export const StyledLink = styled(Link)`
  // pointer-events: none;
  display: block;
  width: 120px;
  height: 40px;
  margin: 0 0 30px 8px;
  background-image: url(${logo});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 120px 40px;
`;

export const BtnWrapper = styled.div`
  padding: 0 0 16px 0;
`;

export const ConfirmBtn = styled.button`
  ${flexbox("center", "center")};
  width: 320px;
  height: 44px;
  margin: 0 auto;
  font-weight: 700;
  font-size: 15px;
  line-height: 22px;
  background-color: #4556ee;
  color: #ffffff;
  border-radius: 100px;
`;
