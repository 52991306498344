import styled from "styled-components";
import { Wrapper } from "layouts/Content/ContentHeading/styles";
import { scrollBase } from "styles/mixins";

export const ScenarioContent = styled.div`
  width: calc(100% - 276px);
  background-color: #f2f2f2;
`;

export const ScenarioCanvas = styled.div`
  ${scrollBase};
  position: relative;
  // width: calc(100vw - 276px);
  height: calc(100vh - 32px - 40px - 40px - 70px);
  overflow-y: auto;
  overflow-x: auto;

  &.scrolling {
    ::-webkit-scrollbar-thumb {
      background-color: rgba(144, 144, 144, 0.3);
    }
  }
`;

export const Form = styled.form`
  min-width: 100%;
  width: max-content;
`;

export const FixedHeading = styled(Wrapper)`
  // width: calc(100vw - 276px);
  padding: 31px 28px 28px 44px;
  margin: 0;
  background-color: #f2f2f2;

  button {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #ffffff;
  }
`;
