import { useState, useEffect } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import useCurrentLocation from "hooks/useCurrentLocation";
import { progressImg } from "utils/data";
import { Wrapper, ProgressContainer, LinkWrapper, PrevLink, NextLink, CompleteBtn } from "./styles";
import ic_arrow_right_white from "assets/ic_arrow_right_white.svg";
import ic_arrow_left_grey from "assets/ic_arrow_left_grey.svg";

const Progress = ({ progressData, onClickFinish, isViewMode, onSave }) => {
  const { id: projectId } = useParams();

  const { prev, next } = progressData;

  const { pathname } = useLocation();

  const [isPrevDisabled, setIsPrevDisabled] = useState(false);

  let navigate = useNavigate();
  useEffect(() => {
    if (pathname.includes("/analysis/summary")) {
      setIsPrevDisabled(true);
    } else {
      setIsPrevDisabled(false);
    }
  }, [pathname]);

  const currentLocation = useCurrentLocation();

  const [img, setImg] = useState();

  useEffect(() => {
    if (pathname.includes("/analysis")) {
      setImg(progressImg["analysis"]);
    } else if (pathname.includes("/concept")) {
      setImg(progressImg["concept"]);
    } else if (pathname.includes("/production")) {
      setImg(progressImg["production"]);
    } else if (pathname.includes("/test")) {
      setImg(progressImg["test"]);
    } else {
      setImg(progressImg["review"]);
    }
  }, []);

  function onChangeNext() {
    const pathFlag = pathname.includes("feedback");
    if (isViewMode) {
      navigate(`${next}/${projectId}`);
    } else {
      pathFlag ? navigate(`${next}/${projectId}`) : pathname.includes("review") ? navigate(`${next}/${projectId}`) : onSave("", `${next}/${projectId}`);
      /*
      setTimeout(() => {
        navigate(`${next}/${projectId}`);
      }, 300);*/
    }
  }

  function onChangePrev() {
    const pathFlag = pathname.includes("feedback");
    if (isViewMode) {
      isPrevDisabled ? navigate(`/home`) : navigate(`${prev}/${projectId}`);
    } else {
      pathFlag ? navigate(`${prev}/${projectId}`) : pathname.includes("review") ? navigate(`${prev}/${projectId}`) : onSave("", `${prev}/${projectId}`);
      /*
      setTimeout(() => {
        isPrevDisabled ? navigate(`/home`) : navigate(`${prev}/${projectId}`);
      }, 300);*/
    }
  }

  return (
    <Wrapper className="not-print">
      <ProgressContainer>
        <img src={img} alt="" referrerPolicy="no-referrer" />
      </ProgressContainer>

      {/* 버튼 */}
      <LinkWrapper>
        <div style={{ display: "flex" }} onClick={() => onChangePrev()}>
          <button
            style={{
              height: "44px",
              padding: "0px 0px 0px 17px",
              marginRight: "32px",
              fontWeight: "700",
              fontSize: "15px",
              color: isPrevDisabled ? "#d0d0d0" : "#000000",
              borderRadius: "24px",
            }}

            //className={({ isActive }) => (isActive ? "is-active" : undefined)}
          >
            <div style={{ display: "flex" }}>
              <div>
                <img style={{ width: "auto", height: "13px", marginRight: "13px", color: "white" }} src={isPrevDisabled ? "" : ic_arrow_left_grey}></img>
              </div>
              <div>이전</div>
            </div>
          </button>
        </div>
        {currentLocation === "review" ? (
          <CompleteBtn type="button" onClick={onClickFinish} disabled={isViewMode}>
            프로젝트 종료
          </CompleteBtn>
        ) : (
          <div style={{ display: "flex" }} onClick={() => onChangeNext()}>
            <button
              style={{
                height: "44px",
                padding: "0 34px 0 36px",
                fontWeight: "700",
                fontSize: "15px",
                color: "#fff",
                borderRadius: "24px",
                backgroundColor: "#4556ee",
              }}

              //className={({ isActive }) => (isActive ? "is-active" : undefined)}
            >
              <div style={{ display: "flex" }}>
                <div>다음</div>
                <div style={{ paddingTop: "1px" }}>
                  <img style={{ marginLeft: "13px", width: "auto", height: "13px" }} src={ic_arrow_right_white}></img>
                </div>
              </div>
            </button>
          </div>
        )}
      </LinkWrapper>
    </Wrapper>
  );
};

export default Progress;
