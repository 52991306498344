import { useState, useEffect } from "react";
import LinkInfo from "components/test/LinkInfo";
import LinkInput from "components/test/LinkInput";
import TabTag from "components/test/TabTag";
import QuestionMain from "components/test/QuestionMain";
import FiveRadio from "components/test/FiveRadio";
import QuestionSub from "components/test/QuestionSub";
import TextTextarea from "components/test/TestTextarea";
import PrevNextBtn from "components/test/PrevNextBtn";
import TesterPrevNextBtn from "components/test/TesterPrevNextBtn";
import { TabContainer } from "../../../../pages/test/form/styles";
import { USER, PREVIEW, TESTER } from "utils/data";
import patchTesterAnswer from "utils/patchTesterAnswer";

export const answerArr = [
  { text: "매우 만족", value: "VERY_SATISFIED" },
  { text: "만족", value: "SOMEWHAT_SATISFIED" },
  { text: "보통", value: "NEITHER_SATISFIED_NOR_DISSATISFIED" },
  { text: "불만족", value: "SOMEWHAT_DISSATISFIED" },
  { text: "매우 불만족", value: "VERY_DISSATISFIED" },
];
const Design = ({ isShowing, status, link, projectId, testerUid, savedAnswer }) => {
  const [design, setDesign] = useState();
  const [text, setText] = useState();

  useEffect(() => {
    if (status === TESTER && savedAnswer) {
      const { designSatisfactionType, designSatisfactionAnswer } = savedAnswer;
      if (designSatisfactionType?.length > 0) setDesign(designSatisfactionType);
      if (designSatisfactionAnswer?.length > 0) setText(designSatisfactionAnswer);
    }
  }, [savedAnswer]);

  const onChangeDesign = (e) => {
    setDesign(e.target.value);
    if (status === TESTER) {
      patchTesterAnswer(projectId, testerUid, "designSatisfactionType", e.target.value, "DESIGN_SATISFACTION")
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
          window.alert("제품/서비스 디자인 만족도 저장에 실패했습니다. 관리자에게 문의 부탁드립니다.");
        });
    }
  };

  const onChangeText = (e) => {
    setText(e.target.value);
    if (status === TESTER) {
      patchTesterAnswer(projectId, testerUid, "designSatisfactionAnswer", e.target.value, "DESIGN_SATISFACTION")
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
          window.alert("제품/서비스 디자인 만족도 이유 저장에 실패했습니다. 관리자에게 문의 부탁드립니다.");
        });
    }
  };

  return (
    <TabContainer isShowing={isShowing}>
      {status !== USER ? <LinkInfo link={link} /> : <LinkInput />}
      <TabTag text="디자인 만족도" />
      <QuestionMain text="본 제품/서비스의 디자인은 얼마나 만족스러우신가요?" />
      <FiveRadio name="design" arr={answerArr} status={status} selectedValue={design} onChange={onChangeDesign} />
      <QuestionSub text="위와 같이 선택하신 이유가 무엇인가요?" />
      <TextTextarea name="design" placeholder="테스터의 작성이 이곳에 표시됩니다." status={status} value={text} onChange={onChangeText} />
      {status === PREVIEW && <PrevNextBtn />}
      {status === TESTER && <TesterPrevNextBtn />}
    </TabContainer>
  );
};

export default Design;
