import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axiosPublic from "utils/axiosPublic";

const ViewModeInit = () => {
  const [isview, setIsview] = useState("true");
  const url = new URL(window.location.href);
  const urlParams = url.searchParams;

  const urlViewMode = urlParams.get("viewMode");
  const urlId = urlParams.get("id");
  useEffect(() => {
    if (isview === "true") {
      setTimeout(() => {
        initData();
      }, 200);
      //window.location.href = "/analysis/summary/105?viewMode=true";
    } else {
      window.location.href = "/analysis/summary/105?viewMode=true";
    }
  }, [isview]);
  const initData = () => {
    console.log("인잇데이터", urlViewMode);
    if (urlViewMode === "true") {
      const data = {
        email: "i2badmin@olivestonelab.com", //임시테스트용으로 사용
        password: "admini2b128#", //token을 받을수 있는 별도의 api개발로 진행예정
      };
      axiosPublic
        .post("/api/v1/open/sign-in", data)
        .then((resp) => {
          if (resp.data.code === "AUTH_S_001") {
            const uid = resp.data.result.uid;
            const token = resp.data.result.token;
            const role = resp.data.result.roleCodes[0];
            window.localStorage.setItem("uid", uid);
            window.localStorage.setItem("accessToken", token);
            window.localStorage.setItem("role", role);
            window.localStorage.setItem("mode", "true");
            window.location.href = `/analysis/summary/${urlId}?viewMode=true`;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return <></>;
};

export default ViewModeInit;
