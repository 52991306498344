import { Heading, Content, Image, Name, InfoList, PersonalityList, InputWrapper } from "./styles";

//mock
import profile_image_box from "assets/profile_image_box.svg";
import ic_personal_0_active from "assets/ic_personal_0_active.svg";
import ic_personal_1_active from "assets/ic_personal_1_active.svg";
import ic_personal_2_active from "assets/ic_personal_2_active.svg";
import ic_personal_3_active from "assets/ic_personal_3_active.svg";
import ic_personal_4_active from "assets/ic_personal_4_active.svg";
import ic_personal_5_active from "assets/ic_personal_5_active.svg";
import ic_personal_6_active from "assets/ic_personal_6_active.svg";
import ic_personal_7_active from "assets/ic_personal_7_active.svg";
import ic_personal_8_active from "assets/ic_personal_8_active.svg";
import ic_personal_9_active from "assets/ic_personal_9_active.svg";
import ic_personal_10_active from "assets/ic_personal_10_active.svg";
import ic_personal_11_active from "assets/ic_personal_11_active.svg";
import { personalityArr } from "utils/data";
import UploadPhoto from "../UploadPhoto";

const CustomerAside = (props) => {
  const { customer, customerInputs, onChangeCustomer, file, setFile, imgSrc, isViewMode } = props;
  const { customerName, customerGender, customerAge, customerJob, personalityTypes, customerHeadshotImage } = customer;
  const iconArr = [
    ic_personal_0_active,
    ic_personal_1_active,
    ic_personal_2_active,
    ic_personal_3_active,
    ic_personal_4_active,
    ic_personal_5_active,
    ic_personal_6_active,
    ic_personal_7_active,
    ic_personal_8_active,
    ic_personal_9_active,
    ic_personal_10_active,
    ic_personal_11_active,
  ];
  const personalityArray = personalityArr.map((value, idx) => (personalityTypes.find((personality) => personality === value.id) ? { ...value, id: idx } : null)).filter((val) => val !== null);
  const deleteCustomerPhoto = async () => {
    props.deleteCustomerPhoto();
  };
  return (
    <div>
      <Heading>고객 프로필</Heading>
      {customer && (
        <Content>
          {/* 수정 전 내용 추후 문제 없을 시 삭제
          <Image>
            <img src={customerHeadshotImage ? customerHeadshotImage : profile_image_box} alt="user image" referrerPolicy="no-referrer" />
          </Image>
          */}
          <UploadPhoto file={file} setFile={setFile} imgSrc={customerHeadshotImage} isViewMode={isViewMode} deleteCustomerPhoto={deleteCustomerPhoto} profile={true} />
          <div>
            {/*<Name>{customerName ?? "고객 이름"}</Name> 수정 전 내용 추후 문제 없을 시 삭제*/}
            <InputWrapper>
              <label htmlFor="customerName">이름</label>
              <input type="text" id="customerName" name="customerName" value={customerInputs.customerName} onChange={onChangeCustomer} placeholder="이름을 입력해주세요." disabled={isViewMode} />
            </InputWrapper>
            <InputWrapper>
              <label htmlFor="customerGender">성별</label>
              <input type="text" id="customerGender" name="customerGender" value={customerInputs.customerGender} onChange={onChangeCustomer} placeholder="남/여/기타" disabled={isViewMode} />
            </InputWrapper>
            <InputWrapper>
              <label htmlFor="customerAge">나이</label>
              <input type="number" id="customerAge" name="customerAge" value={customerInputs.customerAge} onChange={onChangeCustomer} placeholder="나이를 입력해주세요." disabled={isViewMode} />
            </InputWrapper>
            <InputWrapper>
              <label htmlFor="customerJob">직업</label>
              <input type="text" id="customerJob" name="customerJob" value={customerInputs.customerJob} onChange={onChangeCustomer} placeholder="직업을 입력해주세요." disabled={isViewMode} />
            </InputWrapper>
            {/* 수정 전 내용 추후 문제 없을 시 삭제
            <InfoList>
              <div>
                <dt>성별</dt>
                <dd>{customerGender ?? "고객 성별이 이곳에 표시됩니다."}</dd>
              </div>
              <div>
                <dt>나이</dt>
                <dd>{customerAge ?? "고객 나이가 이곳에 표시됩니다."}</dd>
              </div>
              <div>
                <dt>직업</dt>
                <dd>{customerJob ?? "고객 직업이 이곳에 표시됩니다."}</dd>
              </div>
            </InfoList>
            */}
          </div>
          <PersonalityList>
            {personalityArray.map((item, i) => (
              <li key={i}>
                <img src={iconArr[item.id]} alt="personality" referrerPolicy="no-referrer" />
                <span>{item.name}</span>
              </li>
            ))}
          </PersonalityList>
        </Content>
      )}
    </div>
  );
};

export default CustomerAside;
