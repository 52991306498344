import styled from "styled-components";
import { flexbox } from "styles/mixins";
export const Time = styled.div`
  ${flexbox("space-between", "center")};
  margin: 0 0 4px 0;
  font-size: 13px;
  line-height: 22px;

  p:first-of-type {
    font-weight: 500;
    color: #000000;
  }

  p:nth-of-type(2) {
    font-weight: 700;
    color: #353535;
  }
`;

export const InputWrapper = styled.div`
  input {
    caret-color: #4556ee;
    width: 72px;
    height: 88px;
    margin: 0 11px 0 0;
    border: 1px solid ${(props) => (props.isNumWrong ? "#CE5E41" : "#E0E0E0")};
    border-radius: 4px;
    text-align: center;
    color: #000;
    font-weight: 700;
    font-size: 52px;
    padding: 5px 0;
    //line-height: 2.5;
    transition: border-color 300ms ease-in-out;

    ::placeholder {
      color: rgba(0, 0, 0, 0.2);
    }

    &:last-of-type {
      margin: 0;
    }
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
`;

export const ErrorText = styled.p`
  height: 22px;
  margin: 4px 0 -10px 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  color: #ce5e41;
`;

// 프로필 페이지 auth num
export const AuthBox = styled.div`
  width: 302px;
  border-radius: 4px;
  padding: 8px 13px 13px;
  margin: 6px 0 12px auto !important;
  background-color: #f3f3f3;
`;

export const ProfileTime = styled.div`
  ${flexbox("space-between", "center")};
  margin: 0 0 4px 0;

  p:first-of-type {
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    color: #000000;
  }

  p:nth-of-type(2) {
    font-weight: 700;
    font-size: 13px;
    line-height: 22px;
    color: #353535;
  }
`;

export const ProfileInputWrapper = styled.div`
  input {
    caret-color: #4556ee;
    width: 63px;
    height: 80px;
    margin: 0 8px 0 0;
    border: 1px solid ${(props) => (props.isNumWrong ? "#CE5E41" : "#E0E0E0")};
    border-radius: 4px;
    text-align: center;
    font-weight: 500;
    font-size: 48px;
    line-height: 40px;
    background-color: #ffffff;
    color: #000;
    transition: border-color 300ms ease-in-out;

    ::placeholder {
      color: rgba(0, 0, 0, 0.2);
    }

    &:last-of-type {
      margin: 0;
    }
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
`;
