import { useContext } from "react";
import { TextContext } from "context/text-context";
import { Input } from "./styles";
import useViewMode from "hooks/useViewMode";

const LinkInput = () => {
  const textCtx = useContext(TextContext);
  const onChangeText = (e) => {
    textCtx.setLinkText(e.target.value);
  };
  const currentValue = textCtx.linkText;
  const isViewMode = useViewMode();

  return <Input type="text" placeholder="프로토타입 링크를 입력하세요" value={currentValue} onChange={onChangeText} disabled={isViewMode} />;
};

export default LinkInput;
