import { useEffect } from "react";
import { useState } from "react";
import { Button, Circle } from "./styles";

const ToggleBtn = ({ id, onToggleTab, blocked: blockedFromServer, isViewMode }) => {
  const [blocked, setBlocked] = useState(blockedFromServer);
  const onClickToggle = (e) => {
    e.stopPropagation();
    setBlocked((prev) => !prev);
  };

  useEffect(() => {
    onToggleTab(id, blocked);
  }, [blocked]);

  return (
    <>
      <Button type="button" id={id} onClick={onClickToggle} blocked={blocked} disabled={isViewMode}>
        <Circle blocked={blocked} />
      </Button>
    </>
  );
};

export default ToggleBtn;
