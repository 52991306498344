import { useState, useEffect } from "react";
import { Time, InputWrapper, ErrorText, AuthBox, ProfileTime, ProfileInputWrapper } from "./styles";
const regex = /^[+-]?\d*(?:[.,]\d*)?$/;

const AuthNum = ({ expireAt, setAuthNum, isNumWrong, isTimeoutFromServer, reset, setReset, isProfile }) => {
  const [time, setTime] = useState(299);
  const [isTimeout, setIsTimeout] = useState(false);
  useEffect(() => {
    if (expireAt) {
      const yy = expireAt.substr(0, 4);
      const mm = expireAt.substr(5, 2);
      const dd = expireAt.substr(8, 2);
      const hour = expireAt.substr(11, 2);
      const min = expireAt.substr(14, 2);
      const second = expireAt.substr(17, 2);
      if (time > 0) {
        const Counter = setInterval(() => {
          const gap = Math.floor((new Date(yy, mm - 1, dd, hour, min, second).getTime() - new Date().getTime()) / 1000);
          setTime(gap);
        }, 1000);
        return () => clearInterval(Counter);
      } else if (time === 0) {
        setTime(0);
        setIsTimeout(true);
      }
    }
  }, [expireAt, time]);

  const timeFormat = (time) => {
    const m = Math.floor(time / 60).toString();
    let s = (time % 60).toString();
    if (s.length === 1) s = `0${s}`;
    return `${m}:${s}`;
  };

  const [num1, setNum1] = useState("");
  const [num2, setNum2] = useState("");
  const [num3, setNum3] = useState("");
  const [num4, setNum4] = useState("");

  const setNewNum = (num, index) => {
    switch (index) {
      case 0:
        setNum1(num);
        break;
      case 1:
        setNum2(num);
        break;
      case 2:
        setNum3(num);
        break;
      case 3:
        setNum4(num);
        break;
      default:
        break;
    }
  };

  // 숫자 입력
  const onChange = (e) => {
    if (regex.test(e.target.value)) {
      const form = e.target.form;
      const index = [...form].indexOf(e.target);

      setNewNum(e.target.value, index);

      if (index === form.elements.length - 1) {
        //마지막 칸인 경우
        return;
      } else {
        // 숫자를 지우는 경우에는 다음 인풋 창으로 넘어가지 않도록
        if (e.target.value.length < 1) return;
        form.elements[index + 1].focus();
      }
    }
  };

  useEffect(() => {
    if (num1 && num2 && num3 && num4) {
      // const authNum = parseInt(num1 + num2 + num3 + num4);
      const authNum = num1 + num2 + num3 + num4;
      setAuthNum(authNum);
    }
  }, [num1, num2, num3, num4]);

  // reset
  useEffect(() => {
    if (reset) {
      setNum1("");
      setNum2("");
      setNum3("");
      setNum4("");
      setReset(false);
    }
  }, [reset]);

  if (isProfile) {
    return (
      <>
        <AuthBox>
          <ProfileTime>
            <p>인증번호를 입력하세요.</p>
            <p>{timeFormat(time)}</p>
          </ProfileTime>
          <ProfileInputWrapper isNumWrong={isNumWrong}>
            <input type="text" maxLength="1" onChange={onChange} value={num1} autoFocus placeholder="0" />
            <input type="text" maxLength="1" onChange={onChange} value={num2} placeholder="0" />
            <input type="text" maxLength="1" onChange={onChange} value={num3} placeholder="0" />
            <input type="text" maxLength="1" onChange={onChange} value={num4} placeholder="0" />
          </ProfileInputWrapper>
        </AuthBox>
      </>
    );
  }

  return (
    <>
      <Time>
        <p>인증번호를 입력하세요.</p>
        <p>{timeFormat(time)}</p>
      </Time>
      <InputWrapper isNumWrong={isNumWrong}>
        <input type="text" maxLength="1" onChange={onChange} value={num1} autoFocus placeholder="0" />
        <input type="text" maxLength="1" onChange={onChange} value={num2} placeholder="0" />
        <input type="text" maxLength="1" onChange={onChange} value={num3} placeholder="0" />
        <input type="text" maxLength="1" onChange={onChange} value={num4} placeholder="0" />
      </InputWrapper>
      {isNumWrong && <ErrorText>인증번호를 다시 확인해주세요.</ErrorText>}
      {isTimeout || isTimeoutFromServer ? <ErrorText>입력 시간이 초과되었습니다.</ErrorText> : null}
    </>
  );
};

export default AuthNum;
