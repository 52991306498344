import styled from "styled-components";
import { flexboxColumn } from "styles/mixins";

export const Form = styled.form`
	width: 100%;
`;

export const WhiteCard = styled.div`
	${flexboxColumn};
	min-width: 1106px;
	width: 100%;
	padding: 15px 0 55px;
	background-color: #fff;
	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;
`;
