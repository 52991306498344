import { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, MenuWrapper, AvatarMenuList } from "./styles";
import { MenuItem } from "../ProjectCard/styles";
import ic_startup_small from "assets/ic_default_startup_big.svg";
import ic_default_manager_small from "assets/ic_default_manager_big.svg";
import ic_default_disigner_small from "assets/ic_default_disigner_big.svg";
import ic_default_mento_small from "assets/ic_default_mento_big.svg";
import axiosPrivate from "utils/axiosPrivate";

const AvatarBtn = ({ onClickLogout }) => {
  // 드롭다운 메뉴 열고 닫기
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [avatarImg, setAvatarImg] = useState();

  useEffect(() => {
    if (window.localStorage.getItem("role") === "ROLE_USER") {
      axiosPrivate
        .get(`/api/v1/members/${window.localStorage.getItem("uid")}/info`)
        .then((res) => {
          if (res.data?.result?.memberHeadshot) {
            setAvatarImg(res.data.result.memberHeadshot);
          } else {
            setAvatarImg(ic_startup_small);
          }
        })
        .catch((e) => {
          setAvatarImg(ic_startup_small);
          console.log("e", e);
        });
    } else {
      axiosPrivate
        .get(`/api/v1/experts/${window.localStorage.getItem("uid")}/info`)
        .then((res) => {
          if (res.data?.result?.expertHeadshot) {
            setAvatarImg(res.data.result.expertHeadshot);
          } else {
            res.data?.result?.expertType === "MANAGER"
              ? setAvatarImg(ic_default_manager_small)
              : res.data?.result?.expertType === "DESIGNER"
              ? setAvatarImg(ic_default_disigner_small)
              : res.data?.result?.expertType === "MENTOR"
              ? setAvatarImg(ic_default_mento_small)
              : setAvatarImg(ic_startup_small);
          }
        })
        .catch((e) => {
          setAvatarImg(ic_startup_small);
          console.log("e", e);
        });
    }
  }, []);

  const onClickAvatar = (e) => {
    e.preventDefault();
    setIsMenuVisible((prev) => !prev);
  };

  // 드롭다운 외부 영역 클릭 시 드롭다운 닫기
  const menuRef = useRef(null);
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isMenuVisible && menuRef.current && !menuRef.current.contains(e.target)) {
        setIsMenuVisible(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isMenuVisible]);

  const onClickLogoutMenu = () => {
    setIsMenuVisible(false);
    onClickLogout();
  };

  return (
    <>
      <MenuWrapper ref={menuRef}>
        <Button type="button" onClick={onClickAvatar}>
          <img src={avatarImg} alt="user avatar" referrerPolicy="no-referrer" />
        </Button>

        <AvatarMenuList isActive={isMenuVisible}>
          <MenuItem>
            <Link to="/profile">계정 설정</Link>
          </MenuItem>
          <MenuItem>
            <button type="button" onClick={onClickLogoutMenu}>
              로그아웃
            </button>
          </MenuItem>
        </AvatarMenuList>
      </MenuWrapper>
    </>
  );
};

export default AvatarBtn;
