import styled from "styled-components";

export const Btn = styled.button`
  display: block;
  margin: 8px 0 0 0;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #808080;

  &:disabled {
    cursor: default;
  }
`;
