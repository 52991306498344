import { useState } from "react";
import { Wrapper, Label, InputWrapper, ToggleBtn } from "./styles";

const PasswordInput = ({ label, value, onChange, isValid }) => {
  const [isPwdVisible, setIsPwdVisible] = useState(false);
  const togglePassword = () => setIsPwdVisible((prev) => !prev);

  return (
    <Wrapper>
      <Label>{label}</Label>
      <InputWrapper isValid={isValid}>
        <input type={isPwdVisible ? "text" : "password"} value={value} onChange={onChange} />
        <ToggleBtn type="button" isPwdVisible={isPwdVisible} onClick={togglePassword}></ToggleBtn>
      </InputWrapper>
    </Wrapper>
  );
};

export default PasswordInput;
