import { useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import Layout from "layouts/Layout";
import ScenarioList from "components/analysis/ScenarioList";
import Progress from "layouts/Progress";
import Aside from "layouts/Aside";
import AsideInfo from "layouts/Aside/AsideInfo";
import CustomerAside from "components/analysis/CustomerAside";
import { ScenarioContent, FixedHeading, Form, ScenarioCanvas } from "./styles";
import { ANALYSIS_CUSTOMER, ANALYSIS_MODEL } from "utils/data";
import { scenarioGuide } from "utils/data";
import popup_scenario from "assets/popup_scenario.svg";
import CustomModal from "components/common/CustomModal";
import HelpModalContent from "components/common/HelpModalContent";
import ToastPopup from "components/common/ToastPopup";

import axiosPrivate from "utils/axiosPrivate";
import { useParams, useNavigate } from "react-router-dom";
import useSWR from "swr";
import { fetcherPrivate } from "utils/fetcher";
import useViewMode from "hooks/useViewMode";
import { useReactToPrint } from "react-to-print";
import useWarnUnsavedChange from "hooks/useWarnUnsaveChange";

const progressData = {
  prev: ANALYSIS_CUSTOMER,
  next: ANALYSIS_MODEL,
};

const Scenario = () => {
  // 스크롤 관련
  const scrollRef = useRef(null);

  const { id } = useParams();
  let navigate = useNavigate();
  // 뷰모드
  const isViewMode = useViewMode();

  // pdf 추출
  const printRef = useRef();

  const onClickPdf = useReactToPrint({ content: () => printRef?.current });

  const { data: scenarioData, error: scenarioDataError, mutate: scenarioDataMutate } = useSWR(`/api/v1/projects/${id}/analysis/customer-experience-scenario`, (url) => fetcherPrivate(url));

  const { data: customerData, error: customerError } = useSWR(`/api/v1/projects/${id}/analysis/customer-profile`, (url) => fetcherPrivate(url), { revalidateOnFocus: false });

  // 상단 라인 버튼 관리
  const [btnNum, setBtnNum] = useState(2);
  const [addedIndex, setAddedIndex] = useState();

  let timeout;

  // CustomerData profile 데이터 초기화
  const [customerInputs, setCustomerInputs] = useState({
    customerName: "",
    customerGender: "",
    customerAge: undefined,
    customerJob: "",
    characteristic: "",
    motivation: "",
    target: "",
    personalityTypes: [],
  });
  const [file, setFile] = useState();

  useEffect(() => {
    if (customerData) {
      const { result } = customerData;
      const { customerName, customerGender, customerAge, customerJob, characteristic, motivation, target, personalityTypes } = result;
      setCustomerInputs({
        customerName,
        customerGender,
        customerAge,
        customerJob,
        characteristic,
        motivation,
        target,
        personalityTypes,
      });
    }
  }, [customerData]);

  useEffect(() => {
    onChanged();
  }, [file, customerInputs]);

  const { onChanged } = useWarnUnsavedChange();

  //side customerData input onChange
  const onChangeCustomer = (e) => {
    const { value, name } = e.target; // 우선 e.target 에서 name 과 value 를 추출

    setCustomerInputs({
      ...customerInputs, // 기존의 input 객체를 복사한 뒤
      [name]: value, // name 키를 가진 값을 value 로 설정
    });
  };

  const deleteCustomerPhoto = async () => {
    return axiosPrivate.delete(`/api/v1/projects/${id}/analysis/customer-profile/headshot`).then((result) => {});
  };

  const addClass = () => {
    if (!document.querySelector(".canvas").classList.contains("scrolling")) {
      document.querySelector(".canvas")?.classList.add("scrolling");
    } else {
      timeout = setTimeout(() => {
        document.querySelector(".canvas")?.classList.remove("scrolling");
      }, 2000);
    }
  };

  useEffect(() => {
    scrollRef.current.addEventListener("scroll", addClass);

    return () => {
      if (scrollRef.current) {
        clearTimeout(timeout);
        scrollRef.current.removeEventListener("scroll", addClass);
      }
    };
  });

  // 우측 카드 추가되는 경우, 스크롤 밀기
  const scrollToRight = () => {
    scrollRef.current.scrollLeft += 500;
  };

  // 도움말 팝업
  const [isHelpVisible, setIsHelpVisible] = useState(false);
  const onClickCloseModal = () => setIsHelpVisible(false);
  const onClickHelp = () => setIsHelpVisible(true);

  // -----------------------------------------------------------------------------
  // 인풋값 관리
  const [inputs, setInputs] = useState([]);

  useEffect(() => {
    if (inputs?.length === 0) {
      setInputs([
        {
          id: uuidv4(),
          customerMoodType: "FRUSTRATION",
          situation: "",
          experience: "",
          needs: "",
        },
      ]);
      setBtnNum(2);
    }
  }, [inputs]);

  useEffect(() => {
    if (scenarioData?.result) {
      const newInput = scenarioData.result.scenarios.map((value) => ({ ...value, id: uuidv4() }));
      setInputs(newInput);
      setBtnNum(newInput.length + 1);
    }
  }, [scenarioData]);

  // 인풋값변화
  const onChangeTextInput = (id, name, value) => {
    const oldInputArr = [...inputs];
    const targetInput = oldInputArr.find((item) => item.id === id);
    const newInput = { ...targetInput, [name]: value };
    const newInputArr = oldInputArr.map((item) => (item.id !== id ? item : newInput));
    setInputs(newInputArr);
  };

  // -----------------------------------------------------------------------------

  // 현재 focus 된 카드 관리
  const [currentCardId, setCurrentCardId] = useState();
  const [currentRef, setCurrentRef] = useState();

  // 카드 추가
  const addMoreCard = (index) => {
    const defaultInput = {
      id: uuidv4(),
      customerMoodType: "",
      situation: "",
      experience: "",
      needs: "",
    };

    const oldInputs = [...inputs];
    const firstPart = oldInputs.slice(0, index);
    const secondPart = oldInputs.slice(index);
    const newInputs = [...firstPart, defaultInput, ...secondPart];
    setInputs(newInputs);
    setCurrentCardId(defaultInput.id);
    // TODO
    // currentRef는 어떻게 설정..?
  };

  const onChangeBtnNum = (index) => {
    setBtnNum((prev) => prev + 1);
    addMoreCard(index);
    setAddedIndex(index);
  };

  useEffect(() => {
    if (addedIndex >= 2) {
      scrollToRight();
    }
  }, [addedIndex]);

  // 카드 삭제
  const onClickDeleteCard = (id) => {
    const oldInputs = [...inputs];
    const newInputs = oldInputs.filter((item) => item.id !== id);
    setInputs(newInputs);
    setBtnNum((prev) => prev - 1);
  };

  // 모든 카드를 다 삭제하는 경우, 디폴트 카드 및 버튼 보여주기

  // 토스트 팝업
  const [isToastVisible, setIsToastVisible] = useState(false);
  const patchCustomerInfo = async () => {
    const formData = new FormData();
    const req = new Blob([JSON.stringify(customerInputs)], { type: "application/json" });
    formData.append("req", req);
    if (file) {
      console.log(file, "파일");
      formData.append("customerHeadshot", file);
    }

    return axiosPrivate
      .patch(`/api/v1/projects/${id}/analysis/customer-profile`, formData)
      .then((res) => {})
      .catch((err) => {
        window.alert("프로필저장에 실패했습니다.");
      });
  };

  const onSubmit = async (e, item) => {
    //e.preventDefault();
    console.log(customerInputs, "customerInputs");
    console.log(file, "file");
    const data = JSON.stringify({
      scenarios: inputs.map((value, idx) => ({ customerMoodType: value.customerMoodType, situation: value.situation, experience: value.experience, needs: value.needs, scenarioStep: idx + 1 })),
    });

    try {
      const res = await axiosPrivate.put(`api/v1/projects/${id}/analysis/customer-experience-scenario`, data);
      if (res) {
        const res = await patchCustomerInfo();
        setIsToastVisible(true);
        scenarioDataMutate();
        navigate(item);
      }
    } catch (error) {
      console.warn(error);
      if (error.response.data.resultMsg == "잘못된 입력 값 입니다.") {
        window.alert("저장이 실패했습니다. (상태를 입력해주세요.)");
        navigate(item);
      } else {
        window.alert("고객 경험 시나리오 저장에 실패했습니다. 다시 시도해주세요!");
        navigate(item);
      }
    }
  };

  return (
    <>
      {isToastVisible && (
        <ToastPopup isToastVisible={isToastVisible} setIsToastVisible={setIsToastVisible}>
          저장에 성공했습니다!
        </ToastPopup>
      )}
      <Layout onClickPdf={onClickPdf} onSave={onSubmit} isViewMode={isViewMode}>
        <ScenarioContent>
          <ScenarioCanvas className="canvas" ref={scrollRef}>
            <Form onSubmit={onSubmit} ref={printRef} className="print-width">
              <FixedHeading>
                <h1>
                  <span>{customerData?.result?.customerName ?? "고객 이름"}</span>님이 불편을 겪는 상황을 작성하세요.
                </h1>
                {/* 페이지 이동시 자동 저장으로 주석처리
                <button type="submit" disabled={isViewMode}>
                  저장
                </button>
                */}
              </FixedHeading>
              {inputs?.length > 0 && (
                <ScenarioList
                  inputs={inputs}
                  btnNum={btnNum}
                  onChangeBtnNum={onChangeBtnNum}
                  onChangeTextInput={onChangeTextInput}
                  onClickDeleteCard={onClickDeleteCard}
                  currentCardId={currentCardId}
                  setCurrentCardId={setCurrentCardId}
                  currentRef={currentRef}
                  setCurrentRef={setCurrentRef}
                  isViewMode={isViewMode}
                />
              )}
            </Form>
          </ScenarioCanvas>
          <Progress progressData={progressData} onSave={onSubmit} isViewMode={isViewMode} />
        </ScenarioContent>

        <Aside>
          <AsideInfo heading="고객경험 시나리오" onClickHelp={onClickHelp}>
            고객이 어떤 과정을 거쳐 목표에 달성하는 지 추론하고 제품/서비스가 해결할 수 있는 불편점과 만족도를 확인하는 과정입니다.
          </AsideInfo>
          {customerData && (
            <CustomerAside
              customer={customerData?.result}
              customerInputs={customerInputs}
              onChangeCustomer={onChangeCustomer}
              file={file}
              setFile={setFile}
              imgSrc={customerData?.result?.customerHeadshotImage}
              isViewMode={isViewMode}
              deleteCustomerPhoto={deleteCustomerPhoto}
            />
          )}
        </Aside>
      </Layout>
      {isHelpVisible && (
        <CustomModal isVisible={isHelpVisible}>
          <HelpModalContent headingText="고객 경험 시나리오 템플릿 사용 방법" guideArr={scenarioGuide} img={popup_scenario} onClick={onClickCloseModal} />
        </CustomModal>
      )}
    </>
  );
};

export default Scenario;
