import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axiosPrivate from "utils/axiosPrivate";
import { TabContainer } from "pages/test/form/styles";
import TabTag from "components/test/TabTag";
import ProjectInfo from "components/review/ProjectInfo";
import QuestionMain from "components/test/QuestionMain";
import ResponseBtn from "components/review/ResponseBtn";
import Bar from "components/review/Graph/Bar";
import { barDefaultData } from "utils/data";

// 기존 mock data
// {
//   response: "no",
//   no: 21,
//   noColor: "hsl(12, 59%, 53%)",
// },
// {
//   response: "yes",
//   yes: 64,
//   yesColor: "hsl(217, 90%, 61%)",
// },

// 서버 데이터
// [{answer:"Y", answerCount:1, answerRatio:100}]

const ReviewUse = ({ isShowing, testInfo, onClickMoreResponse }) => {
  const { id: projectId } = useParams();

  const [data, setData] = useState();

  useEffect(() => {
    axiosPrivate
      .get(`api/v1/projects/${projectId}/review/dashboard`, {
        params: {
          testSurveyType: "PRODUCT_USE_INTENTION",
        },
      })
      .then((res) => {
        if (res.data?.result?.answers?.length > 0) {
          setData(res.data.result.answers);
        } else {
          setData(barDefaultData);
        }
      })
      .catch((err) => {
        console.log(err);
        setData(barDefaultData);
        // window.alert("사용 의향 설문 결과를 불러오는데 실패했습니다. 관리자에게 문의해주세요!");
      });
  }, []);
  //

  // 답변 전체보기 모달
  const onClickResponse = () => {
    onClickMoreResponse("PRODUCT_USE_INTENTION");
  };

  return (
    <TabContainer isShowing={isShowing}>
      <ProjectInfo testInfo={testInfo} />
      <TabTag text="제품/서비스 사용 의향" />
      <QuestionMain text="본 제품/서비스가 출시된다면 사용하실 의향이 있으신가요?" />
      <ResponseBtn onClick={onClickResponse} />
      {data && <Bar data={data} />}
    </TabContainer>
  );
};

export default ReviewUse;
