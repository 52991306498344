import styled from "styled-components";
import { flexbox } from "styles/mixins";
import ic_test_yes from "assets/ic_test_yes.svg";
import ic_test_yes_active from "assets/ic_test_yes_active.svg";
import ic_test_no from "assets/ic_test_no.svg";
import ic_test_no_active from "assets/ic_test_no_active.svg";

export const Wrapper = styled.div`
  ${flexbox("flex-start", "center")};
  margin: 10px 0 28px 0;
`;

const Base = styled.div`
  input[type="radio"] {
    width: 0;
    height: 0;
    position: absolute;
    left: -9999px;
  }

  input[type="radio"] + label {
    ${flexbox("flex-start", "center")};
    width: 79px;
    height: 44px;
    padding: 0 0 0 37px;
    border-radius: 4px;
    border: 1px solid #e0e0e0;
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    color: #808080;
    background-repeat: no-repeat;
    background-position: 7px 8px;

    background-size: 28px 28px;
    transition: border-color 300ms ease-in-out, font-weight 300ms ease-in-out;
    cursor: pointer;
  }

  input[type="radio"]:checked + label {
    font-weight: 700;
    color: #000000;
  }

  input[type="radio"]:disabled + label {
    cursor: unset;
  }
`;

export const Yes = styled(Base)`
  margin: 0 8px 0 0;

  input[type="radio"] + label {
    background-image: url(${ic_test_yes});
  }
  input[type="radio"]:checked + label {
    background-image: url(${ic_test_yes_active});
    border: 1px solid #5a7cf8;
  }
`;

export const No = styled(Base)`
  input[type="radio"] + label {
    background-image: url(${ic_test_no});
  }
  input[type="radio"]:checked + label {
    background-image: url(${ic_test_no_active});
    border: 1px solid #ce5e41;
  }
`;
