import { useContext } from "react";
import { Wrapper, PrevBtn, NextBtn } from "../PrevNextBtn/styles";
import { TestTabContext } from "context/test-tab-context";

const TesterPrevNextBtn = ({ isIntro, isOutro, isNextDisabled }) => {
  const tabCtx = useContext(TestTabContext);

  const onClickPrev = () => {
    tabCtx.goPrev();
  };

  const onClickNext = () => {
    tabCtx.goNext();
  };

  const nextText = isIntro ? "시작" : isOutro ? "설문 완료" : "다음";

  return (
    <Wrapper>
      {!isIntro && <PrevBtn onClick={onClickPrev}>이전</PrevBtn>}
      <NextBtn isOutro={isOutro} onClick={onClickNext} disabled={isNextDisabled}>
        {nextText}
      </NextBtn>
    </Wrapper>
  );
};

export default TesterPrevNextBtn;
