import styled from "styled-components";
import { flexboxColumn, registerInputWrapperBase } from "styles/mixins";

export const Form = styled.form`
  ${flexboxColumn("space-between", "center")};
  height: 100%;
  width: 320px;
  margin: 60px 0 0 0;
`;

export const GroupOne = styled.div`
  margin: 0 0 20px 0;
  > div:first-of-type {
    margin: 0 0 6px 0;
  }
`;

export const GroupTwo = styled.div`
  margin: 20px 0;
`;

export const Input = styled.div`
  ${registerInputWrapperBase};
`;
