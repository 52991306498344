import styled from "styled-components";
import { flexboxColumn } from "styles/mixins";
import ic_expire_questionmark from "assets/ic_expire_questionmark.svg";

const Wrapper = styled.div`
  ${flexboxColumn("flex-start", "center")};
  width: 700px;
  height: 300px;
  background: #ffffff;
  border-radius: 8px;
  padding: 132px 0 0 0;
  background-image: url(${ic_expire_questionmark});
  background-repeat: no-repeat;
  background-size: 25px 50px;
  background-position: top 72px left 337px;
  font-family: "Noto Sans KR", sans-serif;

  h1 {
    font-weight: 700;
    font-size: 28px;
    line-height: 38px;
    color: #000000;
    margin: 0 0 10px 0;
  }

  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #353535;
  }
`;

const Expired = () => {
  return (
    <Wrapper>
      <h1>만료된 테스트입니다.</h1>
      <p>테스트의 유효기간이 지났거나 이미 종료된 프로젝트의 테스트입니다.</p>
    </Wrapper>
  );
};

export default Expired;
