import { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import useSWR from "swr";
import axiosPrivate from "utils/axiosPrivate";
import { fetcherPrivate } from "utils/fetcher";
import Layout from "layouts/Layout";
import Content from "layouts/Content";
import ContentHeading from "layouts/Content/ContentHeading";
import Checkbox from "components/common/Checkbox";
import UploadIcon from "components/analysis/UploadIcon";
import Aside from "layouts/Aside";
import AsideInfo from "layouts/Aside/AsideInfo";
import SelfInput from "components/analysis/SelfInput";
import { WhiteCard, Row, LabelWrapper, CheckboxWrapper, Mobile, Ready, FlexWrapper, Name, Icon, DefWrapper } from "./styles";
import { ANALYSIS_CUSTOMER, summaryGuide } from "utils/data";
import getRandomColor from "utils/getRandomColor";
import useWarnUnsavedChange from "hooks/useWarnUnsaveChange";
import popup_base from "assets/popup_base.svg";
import CustomModal from "components/common/CustomModal";
import HelpModalContent from "components/common/HelpModalContent";
import ToastPopup from "components/common/ToastPopup";
import useViewMode from "hooks/useViewMode";
import { useReactToPrint } from "react-to-print";

const TYPE_MAX = 3;
const AREA_MAX = 3;
const progressData = {
  prev: "",
  next: ANALYSIS_CUSTOMER,
};

const Summary = () => {
  let navigate = useNavigate();
  const scrollRef = useRef();
  const totalData = {
    businessData: "",
    productData: "",
  };
  const { id } = useParams();

  const { onChanged, onSaved } = useWarnUnsavedChange();

  // 뷰모드
  const isViewMode = useViewMode();

  // 도움말 팝업
  const [isHelpVisible, setIsHelpVisible] = useState(false);
  const onClickCloseModal = () => setIsHelpVisible(false);
  const onClickHelp = () => setIsHelpVisible(true);

  // pdf 추출
  const printRef = useRef();

  const onClickPdf = useReactToPrint({ content: () => printRef.current });

  // 입력값
  const [type, setType] = useState([]);
  const [typeFromServer, setTypeFromServer] = useState([]);
  const [area, setArea] = useState([]);
  const [areaFromServer, setAreaFromServer] = useState([]);

  const [name, setName] = useState("");
  const [defOne, setDefOne] = useState("");
  const [defTwo, setDefTwo] = useState("");
  const [selfArrType, setSelfArrType] = useState([]);
  const [selfArrArea, setSelfArrArea] = useState([]);
  const [file, setFile] = useState(null);

  // 비즈니스 유형
  const { data: businessData } = useSWR(`/api/v1/projects/keywords/attach?keywordType=BUSINESS&size=50`, (url) => fetcherPrivate(url), { revalidateOnFocus: false });

  // 분야
  const { data: productData } = useSWR(`/api/v1/projects/keywords/attach?keywordType=PRODUCT&size=50`, (url) => fetcherPrivate(url), { revalidateOnFocus: false });

  // 사용자가 저장한 데이터
  const { data: summaryData, mutate: summaryDataMutate } = useSWR(`/api/v1/projects/${id}/analysis/base-info`, (url) => fetcherPrivate(url));
  console.log(summaryData, "썸머리데이터");
  useEffect(() => {
    if (summaryData) {
      const { result } = summaryData;
      const { appName, baseInfoBusinessKeywords, baseInfoProductKeywords, definitionA, definitionB } = result;
      setName(appName);
      setDefOne(definitionA);
      setDefTwo(definitionB);
      setTypeFromServer(baseInfoBusinessKeywords ?? []);
      setAreaFromServer(baseInfoProductKeywords ?? []);
    }
  }, [summaryData]);

  //비즈니스 유형

  const getChosenArr = (userTyped, givenChoices) => {
    return userTyped.filter((item) => {
      if (givenChoices.find((choice) => choice.keywordId === item.keywordId)) {
        return true;
      } else {
        return false;
      }
    });
  };

  const getTypedArr = (userTyped, givenChoices) => {
    return userTyped.filter((item) => {
      if (givenChoices.find((choice) => choice.keywordId === item.keywordId)) {
        return false;
      } else {
        return true;
      }
    });
  };

  useEffect(() => {
    if (businessData?.result?.length > 0 && typeFromServer?.length > 0) {
      const givenChoices = [...businessData.result];
      const userTyped = [...typeFromServer];

      const userChosenArr = getChosenArr(userTyped, givenChoices);
      const userSelfTypedArr = getTypedArr(userTyped, givenChoices);

      setType(userChosenArr.map((val) => val.keywordId));
      setSelfArrType(userSelfTypedArr.map((val) => val.keywordName));
    }
  }, [businessData, typeFromServer]);

  // 분야
  useEffect(() => {
    if (productData?.result?.length > 0 && areaFromServer?.length > 0) {
      const givenChoices = [...productData.result];
      const userTyped = [...areaFromServer];

      const userChosenArr = getChosenArr(userTyped, givenChoices);
      const userSelfTypedArr = getTypedArr(userTyped, givenChoices);

      setArea(userChosenArr.map((val) => val.keywordId));
      setSelfArrArea(userSelfTypedArr.map((val) => val.keywordName));
    }
  }, [productData, areaFromServer]);

  // -------------------------------

  // 분야
  const onChangeType = (checked, id) => {
    if (checked) {
      setType([...type, id]);
    } else {
      const oldTypeArr = [...type];
      const newTypeArr = oldTypeArr.filter((type) => type !== id);
      setType(newTypeArr);
    }
  };

  const onChangeArea = (checked, id) => {
    if (checked) {
      setArea([...area, id]);
    } else {
      const oldAreaArr = [...area];
      const newAreaArr = oldAreaArr.filter((area) => area !== id);
      setArea(newAreaArr);
    }
  };

  const onChangeName = (e) => {
    if (e.target.value.length >= 25) {
      return alert("띄어쓰기 포함 최대 24자까지 입력 가능합니다");
    }
    return setName(e.target.value);
  };

  const onChangeDefOne = (e) => {
    if (e.target.value.length >= 37) {
      return alert("띄어쓰기 포함 최대 36자까지 입력 가능합니다");
    }
    return setDefOne(e.target.value);
  };

  const onChangeDefTwo = (e) => {
    if (e.target.value.length >= 37) {
      return alert("띄어쓰기 포함 최대 36자까지 입력 가능합니다");
    }
    return setDefTwo(e.target.value);
  };

  useEffect(() => {
    onChanged();
  }, [type, area, name, file, defOne, defTwo]);

  // 최대 선택 개수 제한
  const [isTypeMax, setIsTypeMax] = useState(false);
  const [isAreaMax, setIsAreaMax] = useState(false);

  useEffect(() => {
    if (type?.length + selfArrType?.length >= TYPE_MAX) {
      setIsTypeMax(true);
    } else {
      setIsTypeMax(false);
    }
  }, [type, selfArrType]);

  useEffect(() => {
    if (area?.length + selfArrArea?.length >= AREA_MAX) {
      setIsAreaMax(true);
    } else {
      setIsAreaMax(false);
    }
  }, [area, selfArrArea]);

  // 랜덤 색상 배열
  const [typeRandomBgArr, setTypeRandomBgArr] = useState([]);
  const [areaRandomBgArr, setAreaRandomBgArr] = useState([]);

  useEffect(() => {
    if (businessData?.result?.length > 0) {
      setTypeRandomBgArr(getRandomColor(businessData.result.length));
    }
  }, [businessData]);

  useEffect(() => {
    if (productData?.result?.length > 0) {
      setAreaRandomBgArr(getRandomColor(productData.result.length));
    }
  }, [productData]);

  // 토스트 팝업
  const [isToastVisible, setIsToastVisible] = useState(false);

  // 저장
  const onSubmit = async (e, item) => {
    //e.preventDefault();

    const formData = new FormData();

    const data = {
      mobile: false,
      web: false,
      businessKeywordIds: type,
      productKeywordIds: area,
      appName: name,
      newBusinessKeywords: selfArrType,
      newProductKeywords: selfArrArea,
      definitionA: defOne,
      definitionB: defTwo,
    };

    const req = new Blob([JSON.stringify(data)], { type: "application/json" });

    formData.append("req", req);

    if (file) {
      formData.append("appIcon", file);
    }

    try {
      const res = await axiosPrivate.patch(`/api/v1/projects/${id}/analysis/base-info`, formData);

      if (res) {
        onSaved();
        setIsToastVisible(true);
        summaryDataMutate();
        navigate(item);
      }
    } catch (error) {
      console.warn(error);
      window.alert("기본 정보 저장에 실패했습니다. 다시 시도해주세요!");
      navigate(item);
    }
  };

  {
    /* 페이지 이동시 변경사항이 있을경우 저장물어볼때
  const testResult = () => {
    console.log("시작type", type);
    console.log("시작area", area);
    console.log("시작2", summaryData);
    console.log("결과1", type == summaryData.result.baseInfoBusinessKeywords);
  }; */
  }

  return (
    <>
      {isToastVisible && (
        <ToastPopup isToastVisible={isToastVisible} setIsToastVisible={setIsToastVisible}>
          저장에 성공했습니다!
        </ToastPopup>
      )}
      <Layout onClickPdf={onClickPdf} onSave={onSubmit} isViewMode={isViewMode}>
        <Content progressData={progressData} ref={scrollRef} onSave={onSubmit} isViewMode={isViewMode}>
          <form onSubmit={onSubmit} ref={printRef}>
            <ContentHeading>
              <h1>
                제품/서비스의 <span>기본 정보</span>를 입력하세요
              </h1>
              {/* 페이지 이동시 자동 저장으로 주석처리
              <button type="submit" disabled={isViewMode}>
                저장
              </button>
              */}
            </ContentHeading>

            <WhiteCard>
              {/* 1 */}
              <Row>
                <LabelWrapper>
                  <label>제품/서비스는 어떤 유형으로 제작하시나요?</label>
                </LabelWrapper>
                <CheckboxWrapper>
                  <Mobile></Mobile>
                  <Ready>준비 중입니다</Ready>
                </CheckboxWrapper>
              </Row>
              {/* 2 */}
              <Row>
                <LabelWrapper>
                  <label>제품/서비스의 비즈니스 유형은 무엇인가요?</label>
                  <p>최대 3개 선택</p>
                </LabelWrapper>
                <CheckboxWrapper>
                  <Checkbox
                    id="bizType"
                    name="bizType"
                    // array={summaryData ? summaryData.result.baseInfoBusinessKeywords : businessData?.result ?? []}
                    array={businessData?.result ?? []}
                    isMax={isTypeMax}
                    randomBgArr={typeRandomBgArr}
                    selectedArr={type}
                    onChange={onChangeType}
                    isViewMode={isViewMode}
                  />
                  <SelfInput selfArr={selfArrType} setSelfArr={setSelfArrType} isMax={isTypeMax} isViewMode={isViewMode} />
                </CheckboxWrapper>
              </Row>
              {/* 3 */}
              <Row>
                <LabelWrapper>
                  <label>제품/서비스는 무슨 분야인가요?</label>
                  <p>최대 3개 선택</p>
                </LabelWrapper>
                <CheckboxWrapper>
                  <Checkbox
                    id="bizArea"
                    name="bizArea"
                    // array={summaryData ? summaryData.result.baseInfoProductKeywords : productData?.result ?? []}
                    array={productData?.result ?? []}
                    isMax={isAreaMax}
                    randomBgArr={areaRandomBgArr}
                    selectedArr={area}
                    onChange={onChangeArea}
                    isViewMode={isViewMode}
                  />
                  <SelfInput selfArr={selfArrArea} setSelfArr={setSelfArrArea} isMax={isAreaMax} isViewMode={isViewMode} />
                </CheckboxWrapper>
              </Row>

              {/* 4 */}
              <Row>
                <LabelWrapper>
                  <label>제품/서비스의 이름과 앱 아이콘이 있나요?</label>
                </LabelWrapper>

                <FlexWrapper>
                  <Name>
                    <p>이름</p>
                    <input value={name} onChange={onChangeName} type="text" placeholder="이름을 적어주세요" maxLength={24} disabled={isViewMode} />
                  </Name>
                  <Icon>
                    <p>앱 아이콘</p>
                    <UploadIcon file={file} setFile={setFile} imgSrc={summaryData?.result?.appIconImage} isViewMode={isViewMode} />
                  </Icon>
                </FlexWrapper>
              </Row>

              {/* 5 */}
              <Row>
                <LabelWrapper>
                  <label>제품/서비스의 정의?</label>
                  <p>MVP를 제작하고자 하는 제품/서비스를 한마디로 정의해 보세요.</p>
                </LabelWrapper>
                <DefWrapper>
                  <div>
                    <input value={defOne} onChange={onChangeDefOne} type="text" placeholder="예시) 시간이 부족한 N잡러" maxLength={37} disabled={isViewMode} />
                    을/를 위한
                  </div>
                  <div>
                    <input value={defTwo} onChange={onChangeDefTwo} type="text" placeholder="예시) 업무관리" maxLength={37} disabled={isViewMode} />
                    서비스/플랫폼
                  </div>
                </DefWrapper>
              </Row>
            </WhiteCard>
          </form>
        </Content>
        <Aside>
          <AsideInfo heading="제품/서비스 기본 정보" onClickHelp={onClickHelp}>
            제품/서비스의 산업 분야와 비즈니스 유형을 설정하고 간결하게 요약해 한 문장으로 정의하는 과정입니다.
          </AsideInfo>
        </Aside>
      </Layout>
      {isHelpVisible && (
        <CustomModal isVisible={isHelpVisible}>
          <HelpModalContent headingText="기본정보 템플릿 사용 방법" guideArr={summaryGuide} img={popup_base} onClick={onClickCloseModal} />
        </CustomModal>
      )}
    </>
  );
};

export default Summary;
