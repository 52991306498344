import styled from "styled-components";
import { flexbox } from "styles/mixins";
import { ScrollArea } from "pages/test/form/styles";

export const ScrollAside = styled(ScrollArea)`
  height: calc(100vh - 36px - 40px - 40px - 145px - 33px - 44px);
  position: relative;
`;

export const FeedbackBtn = styled.button`
  ${flexbox("center", "center")};
  height: 44px;
  width: 230px;
  margin: 0 auto;
  border-radius: 100px;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: #fff;
  background-color: #181818;

  :disabled {
    color: #b1b1b1;
    background-color: #f3f3f3;
    cursor: default;
  }
`;
