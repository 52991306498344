import { projectFilter } from "utils/data";
import { Radio } from "./styles";

const ProjectFilter = ({ checkedItem, setChecked }) => {
  return (
    <div>
      <Radio>
        {projectFilter.map((item, i) => (
          <div key={i}>
            <input type="radio" name="filter" id={item.filter} value={item.filter} checked={checkedItem === item.filter} onChange={setChecked} />
            <label htmlFor={item.filter}>{item.name}</label>
          </div>
        ))}
      </Radio>
    </div>
  );
};

export default ProjectFilter;
