import { useState, useEffect } from "react";
import LinkInfo from "components/test/LinkInfo";
import LinkInput from "components/test/LinkInput";
import TabTag from "components/test/TabTag";
import QuestionMain from "components/test/QuestionMain";
import YesNoRadio from "components/test/YesNoRadio";
import QuestionSub from "components/test/QuestionSub";
import TestTextarea from "components/test/TestTextarea";
import PrevNextBtn from "components/test/PrevNextBtn";
import { TabContainer } from "../../../../pages/test/form/styles";
import { PREVIEW, TESTER, USER } from "utils/data";
import TesterPrevNextBtn from "components/test/TesterPrevNextBtn";
import patchTesterAnswer from "utils/patchTesterAnswer";
const Pay = ({ isShowing, status, link, projectId, testerUid, savedAnswer }) => {
  const [pay, setPay] = useState();
  const [text, setText] = useState();

  useEffect(() => {
    if (status === TESTER && savedAnswer) {
      const { payIntentionYn, payIntentionAnswer } = savedAnswer;
      if (payIntentionYn?.length > 0) setPay(payIntentionYn);
      if (payIntentionAnswer?.length > 0) setText(payIntentionAnswer);
    }
  }, [savedAnswer]);

  const onChangePay = (e) => {
    setPay(e.target.value);
    if (status === TESTER) {
      patchTesterAnswer(projectId, testerUid, "payIntentionYn", e.target.value, "PAY_INTENTION")
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
          window.alert("제품/서비스 비용 지불 의향 저장에 실패했습니다. 관리자에게 문의 부탁드립니다.");
        });
    }
  };

  const onChangeText = (e) => {
    setText(e.target.value);
    if (status === TESTER) {
      patchTesterAnswer(projectId, testerUid, "payIntentionAnswer", e.target.value, "PAY_INTENTION")
        .then((res) => console.log(res))
        .catch((err) => {
          console.log(err);
          window.alert("제품/서비스 비용 지불 의향 이유 저장에 실패했습니다. 관리자에게 문의 부탁드립니다.");
        });
    }
  };

  return (
    <TabContainer isShowing={isShowing}>
      {status !== USER ? <LinkInfo link={link} /> : <LinkInput />}
      <TabTag text="비용 지불 의향" />
      <QuestionMain text="본 제품/서비스를 사용하기 위해 비용을 지불할 의향이 있으신가요?" />
      <YesNoRadio name="pay" status={status} selectedValue={pay} onChange={onChangePay} />
      <QuestionSub text="얼마까지 지불하실 수 있나요? 이유와 함께 작성해주세요." />
      <TestTextarea name="pay" placeholder="테스터의 작성이 이곳에 표시됩니다." status={status} value={text} onChange={onChangeText} />
      {status === PREVIEW && <PrevNextBtn />}
      {status === TESTER && <TesterPrevNextBtn />}
    </TabContainer>
  );
};

export default Pay;
