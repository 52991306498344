import { useState, useEffect, useRef } from "react";
import CommentItem from "./CommentItem";
import { InsightBox, InsightBoxHeading, InsightList, InsightInput } from "./styles";
import axiosPrivate from "utils/axiosPrivate";
import ic_startup_small from "assets/ic_default_startup_big.svg";
import ic_default_manager_small from "assets/ic_default_manager_big.svg";
import ic_default_disigner_small from "assets/ic_default_disigner_big.svg";
import ic_default_mento_small from "assets/ic_default_mento_big.svg";

const Comment = ({
  commentArr,
  isInsightActive,
  setIsInsightActive,
  insight,
  isTyped,
  onChangeInsight,
  onClickSaveInsight,
  onClickEditInsight,
  onClickDeleteInsight,
  onClickCloseInsight,
  isLast,
  isViewMode,
}) => {
  // ui --------------------------------------------------
  // 인사이트 코멘트 입력 - textarea height auto resize
  const textareaRef = useRef();
  useEffect(() => {
    textareaRef.current.style.height = "34px";
    const scrollHeight = textareaRef.current.scrollHeight;
    if (scrollHeight >= 88) {
      textareaRef.current.style.height = "88px";
      textareaRef.current.style.overflow = "auto";
    } else {
      textareaRef.current.style.height = scrollHeight + "px";
      textareaRef.current.style.overflow = "hidden";
    }
  }, [insight]);

  // 사용자 아바타
  const [avatarImg, setAvatarImg] = useState();
  const [userName, setUserName] = useState(""); // 사용자 이름
  useEffect(() => {
    window.localStorage.getItem("role") === "ROLE_USER"
      ? axiosPrivate
          .get(`/api/v1/members/${window.localStorage.getItem("uid")}/info`)
          .then((res) => {
            setUserName(res.data?.result?.name);
            if (res.data?.result?.memberHeadshot) {
              setAvatarImg(res.data.result.memberHeadshot);
            } else {
              setAvatarImg(ic_startup_small);
            }
          })
          .catch((e) => {
            //임시 디폴트 이미지 처리
            setAvatarImg(ic_startup_small);
          })
      : axiosPrivate
          .get(`/api/v1/experts/${window.localStorage.getItem("uid")}/info`)
          .then((res) => {
            setUserName(res.data?.result?.name);
            if (res.data?.result?.expertHeadshot) {
              setAvatarImg(res.data.result.expertHeadshot);
            } else {
              res.data?.result?.expertType === "MANAGER"
                ? setAvatarImg(ic_default_manager_small)
                : res.data?.result?.expertType === "DESIGNER"
                ? setAvatarImg(ic_default_disigner_small)
                : res.data?.result?.expertType === "MENTOR"
                ? setAvatarImg(ic_default_mento_small)
                : setAvatarImg(ic_startup_small);
            }
          })
          .catch((e) => {
            //임시 디폴트 이미지 처리
            setAvatarImg(ic_startup_small);
          });
  }, []);

  // 인사이트 코멘트 - textarea 포커스 시 보더 생성
  const [isFocused, setIsFocused] = useState(false);
  const onFocusTextarea = () => setIsFocused(true);
  const onBlurTextarea = () => setIsFocused(false);

  // 인사이트 코멘트 외부 영역 클릭 시 닫기
  const insightCommentRef = useRef(null);
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isInsightActive && insightCommentRef.current && !insightCommentRef.current.contains(e.target)) {
        setIsInsightActive(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isInsightActive]);

  return (
    <InsightBox isActive={isInsightActive} isLast={isLast} ref={insightCommentRef}>
      <InsightBoxHeading>
        <h5>인사이트</h5>
        <button type="button" onClick={onClickCloseInsight}></button>
      </InsightBoxHeading>
      {/* 인사이트 리스트 */}
      <InsightList style={{ maxHeight: "300px", overflow: "auto" }}>
        {commentArr?.length > 0 &&
          commentArr.map((item, i) => (
            <CommentItem
              key={`comment${i}${item.modifiedTime}`}
              item={item}
              onClickInsightEditSave={onClickEditInsight}
              onClickInsightDelete={onClickDeleteInsight}
              isViewMode={isViewMode}
              userName={userName}
            />
          ))}
      </InsightList>
      {/* 인사이트 인풋 */}
      <InsightInput focused={isFocused}>
        <img src={avatarImg} alt="avatar image" referrerPolicy="no-referrer" />
        <div>
          <textarea ref={textareaRef} placeholder="Reply" value={insight} onChange={onChangeInsight} onFocus={onFocusTextarea} onBlur={onBlurTextarea} disabled={isViewMode}></textarea>
          <button type="button" disabled={!isTyped || isViewMode} onClick={onClickSaveInsight}></button>
        </div>
      </InsightInput>
    </InsightBox>
  );
};

export default Comment;
