// progress bar
import progress_one from "assets/progress_one.svg";
import progress_two from "assets/progress_two.svg";
import progress_three from "assets/progress_three.svg";
import progress_four from "assets/progress_four.svg";
import progress_five from "assets/progress_five.svg";

import listbook_btn_img from "assets/listbook_btn_img.svg";
import unitas_btn_img from "assets/unitas_btn_img.svg";
import pickdoc_btn_img from "assets/pickdoc_btn_img.svg";

import prod_listbook_contents from "assets/prod_listbook_contents.png";
import prod_listbook_detail from "assets/prod_listbook_detail.png";
import prod_listbook_feed from "assets/prod_listbook_feed.jpeg";
import prod_listbook_market from "assets/prod_listbook_market.png";
import prod_listbook_mylist from "assets/prod_listbook_mylist.png";

import prod_pick_detail from "assets/prod_pick_detail.png";
import prod_pick_home from "assets/prod_pick_home.png";
import prod_pick_purchase from "assets/prod_pick_purchase.png";
import prod_pick_review from "assets/prod_pick_review.png";
import prod_pick_search from "assets/prod_pick_search.png";

import prod_uni_contents from "assets/prod_uni_contents.png";
import prod_uni_detail from "assets/prod_uni_detail.png";
import prod_uni_home from "assets/prod_uni_home.png";
import prod_uni_magazine from "assets/prod_uni_magazine.png";
import prod_uni_search from "assets/prod_uni_search.png";

// expert mock image
import expert_image_one from "assets/expert_image_one.svg";
import expert_image_two from "assets/expert_image_two.svg";
import expert_image_three from "assets/expert_image_three.svg";
import expert_image_four_mock from "assets/expert_image_four_mock.jpg";

import ic_test_yes_active from "assets/ic_test_yes_active.svg";
import ic_test_no_active from "assets/ic_test_no_active.svg";
import ic_test_radio_one_active from "assets/ic_test_radio_one_active.svg";
import ic_test_radio_two_active from "assets/ic_test_radio_two_active.svg";
import ic_test_radio_three_active from "assets/ic_test_radio_three_active.svg";

export const ANALYSIS_SUMARRY = "/analysis/summary";
export const ANALYSIS_CUSTOMER = "/analysis/customer";
export const ANALYSIS_SCENARIO = "/analysis/scenario";
export const ANALYSIS_MODEL = "/analysis/model";
export const CONCEPT_BENCHMARKING = "/concept/benchmarking";
export const CONCEPT_FEATURE = "/concept/feature";
export const CONCEPT_DESIGN = "/concept/design";
export const PRODUCTION = "/production";
export const TEST_FORM = "/test/form";
export const TEST_RESULT = "/test/feedback";
export const REVIEW = "/review";

export const DEFAULT = "DEFAULT";
export const BEFORE_PROCEEDING = "BEFORE_PROCEEDING";
export const PROCEEDING = "PROCEEDING";
export const END_OF_PROGRESS = "END_OF_PROGRESS";
export const STOP = "STOP";
export const COMPLETION = "COMPLETION";
export const MAN = "MAN";
export const WOMAN = "WOMAN";
export const UNDEFINED = "UNDEFINED";
export const LASTEST = "LATEST";
export const OLDEST = "OLDEST";

export const progressImg = {
  analysis: progress_one,
  concept: progress_two,
  production: progress_three,
  test: progress_four,
  review: progress_five,
};

export const routes = {
  analysis: [
    {
      pathname: "/analysis/summary",
      name: "기본 정보",
    },
    {
      pathname: "/analysis/customer",
      name: "고객 프로필",
    },
    {
      pathname: "/analysis/scenario",
      name: "고객 경험 시나리오",
    },
    {
      pathname: "/analysis/model",
      name: "비즈니스 모델",
    },
  ],
  concept: [
    {
      pathname: "/concept/benchmarking",
      name: "벤치마킹",
    },
    {
      pathname: "/concept/feature",
      name: "핵심기능 키워드",
    },
    {
      pathname: "/concept/design",
      name: "디자인컨셉 키워드",
    },
  ],
  production: [
    {
      pathname: "/production",
      name: "화면구성",
    },
  ],
  test: [
    {
      pathname: "/test/form",
      name: "설문 작성",
    },
    {
      pathname: "/test/feedback",
      name: "테스트",
    },
  ],
  review: [
    {
      pathname: "/review",
      name: "대시 보드",
    },
  ],
};

// 회원 가입
export const companySize = [
  {
    code: "SU",
    value: "스타트업",
  },
  {
    code: "SB",
    value: "중소기업",
  },
  {
    code: "BB",
    value: "대기업",
  },
];
export const series = [
  {
    code: "SEED",
    value: "Seed",
  },
  {
    code: "PRE_A",
    value: "Pre-A",
  },
  {
    code: "SERIES_A",
    value: "Series A",
  },
  {
    code: "SERIES_B",
    value: "Series B",
  },
  {
    code: "SERIES_C",
    value: "Series C 이상",
  },
  {
    code: "IPO_MNA",
    value: "IPO/M&A",
  },
];
export const businessType = [
  {
    keywordId: "EDUCATION",
    keywordName: "교육",
  },
  {
    keywordId: "MEDICAL",
    keywordName: "의료",
  },
  {
    keywordId: "FASHION_BEAUTY",
    keywordName: "패션/뷰티",
  },
  {
    keywordId: "FOOD",
    keywordName: "식품",
  },
  {
    keywordId: "FINANCE",
    keywordName: "금융",
  },
  {
    keywordId: "HEALTH_CARE",
    keywordName: "헬스케어",
  },
  {
    keywordId: "LEISURE",
    keywordName: "레저/여행",
  },
  {
    keywordId: "GAME",
    keywordName: "게임",
  },
  {
    keywordId: "TRAVEL",
    keywordName: "관광",
  },
  {
    keywordId: "INTERIOR",
    keywordName: "인테리어",
  },
  {
    keywordId: "ELECTRIC_IT",
    keywordName: "전자/IT",
  },
  {
    keywordId: "CONTENTS",
    keywordName: "콘텐츠",
  },
];
export const teamSize = [
  {
    code: "ONE",
    value: "1인",
  },
  {
    code: "LESS_FIVE",
    value: "5인 이하",
  },
  {
    code: "BETWEEN_SIX_TO_TEN",
    value: "6~10인",
  },
  {
    code: "MORE_ELEVEN",
    value: "11명 이상",
  },
  {
    code: "MORE_THIRTY",
    value: "30명 이상",
  },
];

// 홈 --------------------------------------------------------------------------

export const projectFilter = [
  {
    filter: "all",
    name: "전체 프로젝트",
  },
  {
    filter: "PROCEEDING",
    name: "진행 중 프로젝트",
  },
  {
    filter: "COMPLETION",
    name: "완료된 프로젝트",
  },
  {
    filter: "STOP",
    name: "중단된 프로젝트",
  },
];

export const randomBg = ["#4575EE", "#E2814A", " #F6DD66", " #70BECD", "#619F54"];

// 분석 > 고객 프로필
export const personalityArr = [
  { id: "GOAL_ORIENTED", name: "목표 지향적" },
  { id: "RATIONAL", name: "합리적" },
  { id: "CHEERFUL", name: "쾌활한" },
  { id: "FAINTHEARTED", name: "소심한" },
  { id: "NEGATIVE", name: "부정적인" },
  { id: "TIMID", name: "겁많은" },
  { id: "EFFORT_TYPE", name: "노력형" },
  { id: "SCRUPULOUS", name: "꼼꼼한" },
  { id: "PESSIMISTIC", name: "염세적인" },
  { id: "POSITIVE", name: "긍정적인" },
  { id: "PLAYFUL", name: "장난기 많은" },
  { id: "CONFIDENT", name: "자신감있는" },
];

// 테스트 화면에서 사용할 이메일 주소 select option
export const testerGender = [
  { id: 0, text: "남", value: "MAN" },
  { id: 1, text: "여", value: "WOMAN" },
  { id: 2, text: "구분없음", value: "UNDEFINED" },
];

// 도움말 팝업 가이드 텍스트
export const summaryGuide = [
  "제품/서비스에 대한 기본 정보를 설정하는 과정입니다.",
  "어떤 유형과 분야로 프로젝트를 진행할 것인지 정하고 제품/서비스의 정보를 입력해보세요.",
  "기본 정보 입력이 어렵다면, I2B 매니저에게 도움을 요청해보세요!",
];

export const customerGuide = [
  "우리의 서비스를 사용할 가상의 사용자를 만든다고 생각하며 작성하면 좋아요.",
  "고객의 입장에서 왜 우리 프로덕트가 필요한지, 실제 고객들은 어떻게 살고 있을지 떠올리며 작성해보세요.",
  "고객이 행동을 하는 동기와 행동을 통해 달성하고자 하는 목표에 대해 생각해보세요.",
  "고객을 실제 인물처럼 정의함으로써 더욱 고객 친화적인 프로덕트를 만들 수 있어요.",
  "어떻게 작성해야 할 지 모르겠다면 I2B 매니저에게 도움을 요청해보세요!",
];

export const scenarioGuide = [
  "우리의 제품/서비스가 없을 때 가상의 사용자가 목표를 달성하기 위한 과정을 탐색합니다.",
  "주위 사람들에게 어떤 불편함을 겪고 있고 어떤 것에 만족하는지 물어보세요.",
  "상황에서 고객이 좋다고 느끼는 점과 불편하다고 느끼는 점을 적고 필요로하는 것은 무엇인지 탐색해보세요.",
  "우리 제품/서비스가 당장 해결할 수 있는 부분이 아니더라도 문제와 해결방안을 작성해보세요.",
  "고객 경험 시나리오 작성이 어렵다면 I2B 매니저에게 도움을 요청해보세요!",
];

export const modelGuide = [
  "본 템플릿을 통해 프로덕트를 기준으로 수익구조에 대해 이해할 수 있어요.",
  "프로덕트와 가장 유사한 비즈니스 모델을 찾아 운영 구조에 대해 알 수 있어요.",
  "프로덕트를 사용하는 사용자뿐만 아니라 운영에 영향을 미치는 이해관계를  고려하여 상관 관계를 작성합니다.",
  "어떤 이해관계가 있을지 잘 모르겠다면 I2B 매니저에게 도움을 요청해보세요!",
];

export const benchGuide = [
  "유사한 마케팅 전략을 가지고 있거나, 유사한 기능을 제공하는 다른 제품/서비스를 살펴보는 과정입니다.",
  "I2B가 추천하는 벤치마킹 제품/서비스를 이용할 수도 있고 직접 찾은 제품/서비스를 업로드할 수 있습니다.",
  "벤치마킹 제품/서비스를 통해 얻은 인사이트와 의견이 있다면  화면별로 메모를 남길 수 있습니다.",
  "직접 입력하는 벤치마킹 제품/서비스에 해당하는 키워드를 선택하여 입력하세요.",
  "벤치마킹에 대해 잘 모르겠다면 I2B 매니저에게 도움을 요청해보세요!",
];

export const featureGuide = [
  "제품/서비스에서 제공하고자 하는 기능 중 가장 중요한 핵심기능 키워드를 선택하세요.",
  "키워드를 해제하여 다시 입력할 수 있어요.",
  "많은 기능을 담고 싶겠지만 우선 가장 중요한 6개 기능에 대해 제품/ 서비스를 구성해보아요.",
  "핵심기능에 대해 잘 모르겠다면 I2B 매니저에게 도움을 요청해보세요!",
];

export const designGuide = [
  "제품/서비스가 어떤 모습일 지 생각하며 원하는 디자인 컨셉 키워드를 선택하세요.",
  "키워드를 해제하여 다시 입력할 수 있어요.",
  "많은 분위기를 담고 싶겠지만 제품/서비스와 잘 맞는 3개 키워드를 우선 선택해보아요.",
  "디자인 컨셉에 대해 잘 모르겠다면 I2B 매니저에게 도움을 요청해보세요!",
];

export const prodGuide = [
  "앞서 선택했던 핵심기능과 디자인 컨셉 키워드를 떠올리며 화면을 구성해보세요.",
  "제품/ 서비스를 잘 나타낼 수 있는 핵심 화면 5장이면 충분해요.",
  "우리 제품/서비스에 맞게 화면명을 수정할 수 있어요.",
  "화면 구성이 어렵다면 I2B 디자이너에게 도움을 요청해보세요!",
];

export const formGuide = [
  "제품/서비스를 테스트하기 위한 설문을 작성하는 과정으로 인사말과 끝맺음말을 작성할 수 있습니다.",
  "원하는 테스터 조건이 있다면 작성해보세요.",
  "원하는 설문 문항만 선택하여 테스트할 수 있습니다.",
  "문항은 '가치합산'에서 가치를 계산하기 위한 항목과 사업진행 판단여부에 도움이 되는 근거 도출을 위한 항목으로 구성하였습니다.",
  "설문작성에 궁금한 점이 있다면 I2B매니저에게 도움을 요청해보세요!",
];

export const feedbackGuide = [
  "우리의 서비스를 사용할 가상의 사용자를 만든다고 생각하며 작성하면 좋아요.",
  "고객의 입장에서 왜 우리 프로덕트가 필요한지, 실제 고객들은 어떻게 살고 있을지 떠올리며 작성해보세요.",
  "고객이 행동을 하는 동기와 행동을 통해 달성하고자 하는 목표에 대해 생각해보세요.",
  "고객을 실제 인물처럼 정의함으로써 더욱 고객 친화적인 프로덕트를 만들 수 있어요.",
  "어떻게 작성해야 할 지 모르겠다면 I2B 매니저에게 도움을 요청해보세요!",
];

// test page status
export const PREVIEW = "preview";
export const USER = "user";
export const TESTER = "tester";
export const USER_REVIEW = "user_review";

// test page answer step

export const testStepIdDic = {
  GREETINGS: 0,
  BEFORE_TEST_ANSWER: 0,
  INPUT_TESTER_INFO: 1,
  PRODUCT_USE_INTENTION: 2,
  PAY_INTENTION: 3,
  EXPECTED_FREQUENCY_USE: 4,
  DESIGN_SATISFACTION: 5,
  CONTENT_SATISFACTION: 6,
  FUNCTIONAL_SATISFACTION: 7,
  SIMILAR_SERVICE: 8,
  GENERAL_OPINION: 9,
  CLOSING_WORDS: 10,
  TEST_CASE_FINISHED: 11,
  TEST_CASE_EXPIRE: 12,
};

export const questionTextArr = [
  {
    id: 2,
    step: "PRODUCT_USE_INTENTION",
    category: "제품/서비스 사용 의향",
    question: "본 제품/서비스가 출시된다면 사용하실 의향이 있으신가요?",
  },
  {
    id: 3,
    step: "PAY_INTENTION",
    category: "비용 지불의향",
    question: "본 제품/서비스를 사용하기 위해 비용을 지불할 의향이 있으신가요?",
  },
  {
    id: 4,
    step: "EXPECTED_FREQUENCY_USE",
    category: "예상 사용 빈도",
    question: "얼마나 자주 사용할 것 같나요?",
  },
  {
    id: 5,
    step: "DESIGN_SATISFACTION",
    category: "디자인 만족도",
    question: "본 제품/서비스의 디자인은 얼마나 만족스러우신가요?",
  },
  {
    id: 6,
    step: "CONTENT_SATISFACTION",
    category: "콘텐츠 만족도",
    question: "본 제품/서비스를 통해 제공되는 콘텐츠는 얼마나 만족스러우신가요?",
  },
  {
    id: 7,
    step: "FUNCTIONAL_SATISFACTION",
    category: "기능 만족도",
    question: "본 제품/서비스를 통해 제공되는 기능은 얼마나 만족스러우신가요?",
  },
  {
    id: 8,
    step: "SIMILAR_SERVICE",
    category: "유사 서비스",
    question: "유사한 기능, 콘텐츠를 제공하는 다른 제품/ 서비스를 사용해본 경험이 있으신가요? 경험에 대해 작성해주세요.",
  },
  {
    id: 9,
    step: "GENERAL_OPINION",
    category: "종합 의견",
    question: "제품/서비스의 개선해야 하는 점과 아쉬웠던 점, 좋았던 점에 대해 자유롭게 의견 기재해주세요",
  },
];

export const questionAnswerDic = {
  PRODUCT_USE_INTENTION: ["productUseIntentionYn", "productUseIntentionAnswer"],
  PAY_INTENTION: ["payIntentionYn", "payIntentionAnswer"],
  EXPECTED_FREQUENCY_USE: ["expectedFrequencyUseType", "expectedFrequencyUseAnswer"],
  DESIGN_SATISFACTION: ["designSatisfactionType", "designSatisfactionAnswer"],
  CONTENT_SATISFACTION: ["contentSatisfactionType", "contentSatisfactionAnswer"],
  FUNCTIONAL_SATISFACTION: ["functionalSatisfactionType", "functionalSatisfactionAnswer"],
  SIMILAR_SERVICE: ["", "similarServiceAnswer"],
  GENERAL_OPINION: ["", "generalOpinionAnswer"],
};

export const answerTextArr = [
  { id: "Y", text: "있다", icon: ic_test_yes_active },
  {
    id: "N",
    text: "없다",
    icon: ic_test_no_active,
  },
  {
    id: "VERY_SATISFIED",
    chartId: "5",
    text: "매우만족",
    icon: ic_test_yes_active,
    color: "hsl(227, 92%, 66%)",
  },
  {
    id: "SOMEWHAT_SATISFIED",
    chartId: "4",
    text: "만족",
    icon: ic_test_radio_one_active,
    color: "hsl(190, 48%, 62%)",
  },
  {
    id: "NEITHER_SATISFIED_NOR_DISSATISFIED",
    chartId: "3",
    text: "보통",
    icon: ic_test_radio_two_active,
    color: "hsl(50, 89%, 68%, 1)",
  },
  {
    id: "SOMEWHAT_DISSATISFIED",
    chartId: "2",
    text: "불만족",
    icon: ic_test_radio_three_active,
    color: "hsl(22, 72%, 59%, 1)",
  },
  {
    id: "VERY_DISSATISFIED",
    chartId: "1",
    text: "매우 불만족",
    icon: ic_test_no_active,
    color: "hsl(12, 59%, 53%, 1)",
  },
  {
    id: "ONE_DAY_SEVERAL_TIMES",
    chartId: "5",
    text: "하루에 몇 번",
    icon: ic_test_yes_active,
    color: "hsl(227, 92%, 66%)",
  },
  {
    id: "ONE_DAY_ONE_TIME",
    chartId: "4",
    text: "하루에 한 번",
    icon: ic_test_radio_one_active,
    color: "hsl(190, 48%, 62%)",
  },
  {
    id: "WEEK_ONE_MORE_TIME",
    chartId: "3",
    text: "주 1회 이상",
    icon: ic_test_radio_two_active,
    color: "hsl(50, 89%, 68%, 1)",
  },
  {
    id: "MONTH_ONE_MORE_TIME",
    chartId: "2",
    text: "월 1회 이상",
    icon: ic_test_radio_three_active,
    color: "hsl(22, 72%, 59%, 1)",
  },
  { id: "NONE_USE", chartId: "1", text: "사용하지 않음", icon: ic_test_no_active, color: "hsl(12, 59%, 53%, 1)" },
];

export const barDefaultData = [
  {
    answer: "Y",
    response: "yes",
    yes: 0,
    ratio: 0,
    answerCount: 0,
    answerRatio: 0,
    yesColor: "hsl(217, 90%, 61%)",
  },
  {
    answer: "N",
    response: "no",
    no: 0,
    ratio: 0,
    answerCount: 0,
    answerRatio: 0,
    noColor: "hsl(12, 59%, 53%)",
  },
];

export const pieSatisfactionDefaultData = [
  { answer: "VERY_SATISFIED", answerCount: 0, answerRatio: 0 },
  { answer: "SOMEWHAT_SATISFIED", answerCount: 0, answerRatio: 0 },
  { answer: "NEITHER_SATISFIED_NOR_DISSATISFIED", answerCount: 0, answerRatio: 0 },
  { answer: "SOMEWHAT_DISSATISFIED", answerCount: 0, answerRatio: 0 },
  { answer: "VERY_DISSATISFIED", answerCount: 0, answerRatio: 0 },
];

export const pieFrequencyDefaultData = [
  { answer: "ONE_DAY_SEVERAL_TIMES", answerCount: 0, answerRatio: 0 },
  { answer: "ONE_DAY_ONE_TIME", answerCount: 0, answerRatio: 0 },
  { answer: "WEEK_ONE_MORE_TIME", answerCount: 0, answerRatio: 0 },
  { answer: "MONTH_ONE_MORE_TIME", answerCount: 0, answerRatio: 0 },
  { answer: "NONE_USE", answerCount: 0, answerRatio: 0 },
];

export const textDefaultData = [
  {
    answer: "",
  },
];

// 제작 > 화면 구성 (2차 개발에서는 관리자 페이지에서 등록하여 사용)
export const templateArr = [
  {
    id: 1,
    templateId: 1,
    name: "템플릿 1",
    image: pickdoc_btn_img,
    registerType: "ADMIN",
    produceLayouts: [
      {
        layoutId: 1,
        priority: 1,
        layoutImage: prod_pick_home,
        fileName: "prod_pick_home.png",
        layoutName: "홈",
        fileDataUrl: "",
        type: "image/png",
      },
      {
        layoutId: 2,
        priority: 2,
        layoutImage: prod_pick_search,
        fileName: "prod_pick_search.png",
        layoutName: "탐색",
        fileDataUrl: "",
        type: "image/png",
      },
      {
        layoutId: 3,
        priority: 3,
        layoutImage: prod_pick_detail,
        fileName: "prod_pick_detail.png",
        layoutName: "상세",
        fileDataUrl: "",
        type: "image/png",
      },
      {
        layoutId: 4,
        priority: 4,
        layoutImage: prod_pick_purchase,
        fileName: "prod_pick_purchase.png",
        layoutName: "구매",
        fileDataUrl: "",
        type: "image/png",
      },
      {
        layoutId: 5,
        priority: 5,
        layoutImage: prod_pick_review,
        fileName: "prod_pick_review.png",
        layoutName: "리뷰",
        fileDataUrl: "",
        type: "image/png",
      },
    ],
  },
  {
    id: 2,
    templateId: 2,
    name: "템플릿 2",
    image: unitas_btn_img,
    registerType: "ADMIN",
    produceLayouts: [
      {
        layoutId: 1,
        priority: 1,
        layoutImage: prod_uni_home,
        fileName: "prod_uni_home.png",
        layoutName: "홈",
        fileDataUrl: "",
        type: "image/png",
      },
      {
        layoutId: 2,
        priority: 2,
        layoutImage: prod_uni_magazine,
        fileName: "prod_uni_magazine.png",
        layoutName: "매거진",
        fileDataUrl: "",
        type: "image/png",
      },
      {
        layoutId: 3,
        priority: 3,
        layoutImage: prod_uni_contents,
        fileName: "prod_uni_contents.png",
        layoutName: "콘텐츠",
        fileDataUrl: "",
        type: "image/png",
      },
      {
        layoutId: 4,
        priority: 4,
        layoutImage: prod_uni_detail,
        fileName: "prod_uni_detail.png",
        layoutName: "상세",
        fileDataUrl: "",
        type: "image/png",
      },
      {
        layoutId: 5,
        priority: 5,
        layoutImage: prod_uni_search,
        fileNmae: "prod_uni_search.png",
        layoutName: "탐색",
        fileDataUrl: "",
        type: "image/png",
      },
    ],
  },
  {
    id: 3,
    templateId: 3,
    name: "템플릿 3",
    image: listbook_btn_img,
    registerType: "ADMIN",
    produceLayouts: [
      {
        layoutId: 1,
        priority: 1,
        layoutImage: prod_listbook_mylist,
        fileName: "prod_listbook_mylist.png",
        layoutName: "마이",
        fileDataUrl: "",
        type: "image/png",
      },
      {
        layoutId: 2,
        priority: 2,
        layoutImage: prod_listbook_feed,
        fileName: "prod_listbook_feed.jpeg",
        layoutName: "피드",
        fileDataUrl: "",
        type: "image/jpeg",
      },
      {
        layoutId: 3,
        priority: 3,
        layoutImage: prod_listbook_contents,
        fileName: "prod_listbook_contents.png",
        layoutName: "콘텐츠",
        fileDataUrl: "",
        type: "image/png",
      },
      {
        layoutId: 4,
        priority: 4,
        layoutImage: prod_listbook_detail,
        fileName: "prod_listbook_detail.png",
        layoutName: "상세",
        fileDataUrl: "",
        type: "image/png",
      },
      {
        layoutId: 5,
        priority: 5,
        layoutImage: prod_listbook_market,
        fileNmae: "prod_listbook_market.png",
        layoutName: "마켓",
        fileDataUrl: "",
        type: "image/png",
      },
    ],
  },
];
