import styled from "styled-components";
import ic_pencil_grey from "assets/ic_pencil_grey.svg";
import ic_pencil_black from "assets/ic_pencil_black.svg";
import ic_insight from "assets/ic_insight.svg";
import ic_insight_disabled from "assets/ic_insight_disabled.svg";
import ic_insight_hover from "assets/ic_insight_hover.svg";
import ic_insight_active from "assets/ic_insight_active.svg";
import ic_add_plus_blue from "assets/ic_add_plus_blue.svg";
import ic_round_circle_x from "assets/ic_round_circle_x.svg";
import example_view_img from "assets/example_view_img.jpg";
import { flexbox, truncate } from "styles/mixins";

export const Container = styled.div`
  width: 206px;
`;

export const TextInputWrapper = styled.div`
  ${flexbox("flex-start", "center")};
  position: relative;
  height: 34px;
  padding: 0 0 0 10px;
  margin: 0 0 13px 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #000000;
  border: 1px solid transparent;
  > p:first-of-type {
    ${truncate};
    width: 134px;
  }
`;

export const TextInput = styled.input`
  ${flexbox("flex-start", "center")};
  padding: 0 10px;
  margin: 0 0 13px 0;
  caret-color: #4556ee;
  width: 200px;
  height: 34px;
  background-color: #ffffff;

  border: 1px solid #181818;
  border-radius: 4px;

  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #000000;
  transition: border-color 300ms ease-in-out;

  ::placeholder {
    color: #b1b1b1;
  }

  &:not(:disabled):focus,
  &:not(:disabled):active {
    border: 1px solid #181818;
  }
`;

// 인사이트 말풍선
export const InsightBtnWrapper = styled.div`
  ${flexbox("flex-start", "center")};
  position: absolute;
  top: 3px;
  right: 10px;
`;

export const EditBtn = styled.button`
  display: block;
  width: 14px;
  height: 14px;
  margin: 0 3px 0 0;
  background-image: url(${ic_pencil_grey});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;

  &:not(:disabled):hover,
  &:not(:disabled):active {
    background-image: url(${ic_pencil_black});
  }

  &:disabled {
    cursor: default;
  }
`;

export const InsightBtn = styled.button`
  display: block;
  width: 24px;
  height: 24px;
  background-color: ${(props) => (props.isActive ? "#4556ee" : "transparent")};
  border-radius: 50%;
  background-image: url(${(props) => (props.isActive ? ic_insight_active : ic_insight)});
  background-position: center center;
  background-size: 24px 24px;
  background-repeat: no-repeat;
  transition: background-color 300ms ease-in-out;

  :hover {
    background-size: 24px 24px;
    background-image: url(${(props) => (props.isActive ? ic_insight_active : ic_insight_hover)});
  }

  :disabled {
    pointer-events: none;
    cursor: not-allowed;
    background-size: 24px 24px;
    background-image: url(${ic_insight_disabled});
  }
`;

// 하단 이미지 버튼
export const UploadWrapper = styled.div`
  ${flexbox("center", "center")};
  width: 206px;
  height: 448px;
  border-radius: 4px;
  background-color: ${(props) => (props.isBench ? "#f3f3f3" : "#fff")};
  background-image: url(${(props) => (props.isFirst ? example_view_img : "")});
  background-size: 206px 448px;
  background-repeat: no-repeat;

  label {
    display: block;
    width: 75px;
    height: 75px;
    border: 1px solid #e0e0e0;
    border-radius: 50%;
    border: 1px solid #d6d6d6;
    background-color: #fff;
    background-image: url(${ic_add_plus_blue});
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 15px 15px;
    cursor: pointer;
  }

  input[type="file"] {
    position: absolute;
    width: 0;
    height: 0;
    padding: 0;
    overflow: hidden;
    border: 0;
  }

  input[type="file"]:disabled + label {
    cursor: default;
  }
`;

export const UploadPreview = styled.div`
  width: 206px;
  height: 448px;
  border-radius: 4px;
  filter: drop-shadow(0px 6px 18px rgba(0, 0, 0, 0.12));
  cursor: pointer;
  position: relative;

  img {
    width: 206px;
    height: 448px;
    border-radius: 4px;
    object-fit: cover;
  }

  .ant-image-mask {
    border-radius: 4px;
    background: unset;
    opacity: unset;
  }

  .ant-image-mask:hover {
    background: unset;
    opacity: unset;
  }
  .ant-image-mask::after {
    content: "";
    display: block;
    width: 206px;
    height: 448px;
    opacity: 0;
    border-radius: 4px;
    background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 27.08%);
    -webkit-transition: opacity 1s;
    -moz-transition: opacity 1s;
    -ms-transition: opacity 1s;
    -o-transition: opacity 1s;
    transition: opacity 1s;
  }

  .ant-image-mask:hover::after {
    opacity: 0.5;
    background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 27.08%);
  }

  &:hover .ant-image-mask::after {
    opacity: 0.5;
    background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 27.08%);
  }

  .ant-image-mask-info {
    display: none;
  }

  button {
    display: none;
    position: absolute;
    top: 8px;
    right: 8px;
    width: 20px;
    height: 20px;
    background-image: url(${ic_round_circle_x});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;

    :disabled {
      cursor: default;
    }
  }

  &:hover {
    button {
      display: block;
    }
  }
`;
