import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const useViewMode = () => {
  const { id: projectId } = useParams();

  const [isViewMode, setIsViewMode] = useState(false);
  useEffect(() => {
    const projectInfo = window.localStorage.getItem("project-list");
    const urlViewMode = window.localStorage.getItem("mode");
    if (urlViewMode === "true") {
      setIsViewMode(true);
    } else {
      if (projectInfo) {
        const listInfo = JSON.parse(projectInfo);
        const targetProject = listInfo?.find((item) => item.id === +projectId);
        if (targetProject) {
          const isViewMode = targetProject.viewMode;
          if (isViewMode) {
            setIsViewMode(true);
          } else {
            setIsViewMode(false);
          }
        } else {
          setIsViewMode(false);
        }
      } else {
        setIsViewMode(false);
      }
    }
  }, [projectId]);

  return isViewMode;
};

export default useViewMode;
