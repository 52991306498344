import styled from "styled-components";
import ic_password_line from "assets/ic_password_line.svg";
import { flexboxColumn } from "styles/mixins";

export const Heading = styled.h1`
  padding: 10px 0 10px 0 !important;
  width: 100%;
  background-image: url(${ic_password_line});
  background-size: 136px 8px;
  background-repeat: no-repeat;
  background-position: 229px 50px;
`;

export const Form = styled.form`
  ${flexboxColumn("space-between", "center")};
  height: 264px;
  margin: 44px 0 0 0;
`;

export const HelperText = styled.p`
  margin: 4px 0 0 0;
  font-weight: 500;
  font-size: 9px;
  line-height: 22px;
  color: ${(props) => (props.isValid ? "#4556ee" : "#CE5E41")};
  transition: color 300ms ease-in-out;

  &:first-of-type {
    margin: 4px 0 -10px 0;
  }
`;
