import styled from "styled-components";
import { flexbox } from "styles/mixins";

import ic_test_yes from "assets/ic_test_yes.svg";
import ic_test_yes_active from "assets/ic_test_yes_active.svg";
import ic_test_radio_one from "assets/ic_test_radio_one.svg";
import ic_test_radio_one_active from "assets/ic_test_radio_one_active.svg";
import ic_test_radio_two from "assets/ic_test_radio_two.svg";
import ic_test_radio_two_active from "assets/ic_test_radio_two_active.svg";
import ic_test_radio_three from "assets/ic_test_radio_three.svg";
import ic_test_radio_three_active from "assets/ic_test_radio_three_active.svg";
import ic_test_no from "assets/ic_test_no.svg";
import ic_test_no_active from "assets/ic_test_no_active.svg";

export const Wrapper = styled.div`
  ${flexbox("flex-start", "center")};
  flex-wrap: wrap;
  width: 100%;
  height: 72px;
  margin: 10px 0 28px 0;
`;

export const Radio = styled.div`
  margin: 0 8px 0 0;
  flex-shrink: 0;
  width: 85px;
  height: 72px;

  &:last-of-type {
    margin: 0;
  }

  input[type="radio"] {
    width: 0;
    height: 0;
    position: absolute;
    left: -9999px;
  }

  input[type="radio"] + label {
    display: block;
    width: 85px;
    height: 72px;
    padding: 40px 0 0 0;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    color: #808080;
    text-align: center;
    transition: border-color 300ms ease-in-out, color 300ms ease-in-out;
    background-repeat: no-repeat;
    background-size: 28px 28px;
    background-position: 29px 12px;
    cursor: pointer;
  }

  input[type="radio"]:disabled + label {
    cursor: unset;
  }

  input[type="radio"]:checked + label {
    font-weight: 700;
    font-size: 12px;
    line-height: 22px;
    text-align: center;
    color: #000000;
  }

  &:first-of-type input[type="radio"] + label {
    background-image: url(${ic_test_yes});
  }

  &:first-of-type input[type="radio"]:checked + label {
    border: 1px solid #5a7cf8;
    background-image: url(${ic_test_yes_active});
  }

  &:nth-of-type(2) input[type="radio"] + label {
    background-image: url(${ic_test_radio_one});
  }

  &:nth-of-type(2) input[type="radio"]:checked + label {
    border: 1px solid #70becd;
    background-image: url(${ic_test_radio_one_active});
  }

  &:nth-of-type(3) input[type="radio"] + label {
    background-image: url(${ic_test_radio_two});
  }

  &:nth-of-type(3) input[type="radio"]:checked + label {
    border: 1px solid #f6dd66;
    background-image: url(${ic_test_radio_two_active});
  }

  &:nth-of-type(4) input[type="radio"] + label {
    background-image: url(${ic_test_radio_three});
  }

  &:nth-of-type(4) input[type="radio"]:checked + label {
    border: 1px solid #e2814a;
    background-image: url(${ic_test_radio_three_active});
  }

  &:last-of-type input[type="radio"] + label {
    background-image: url(${ic_test_no});
  }

  &:last-of-type input[type="radio"]:checked + label {
    border: 1px solid #ce5e41;
    background-image: url(${ic_test_no_active});
  }
`;
