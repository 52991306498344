import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axiosPrivate from "utils/axiosPrivate";
import { TabContainer } from "pages/test/form/styles";
import TabTag from "components/test/TabTag";
import ProjectInfo from "components/review/ProjectInfo";
import QuestionMain from "components/test/QuestionMain";
import ResponseBtn from "components/review/ResponseBtn";
import Pie from "components/review/Graph/Pie";
import { pieSatisfactionDefaultData } from "utils/data";

const ReviewContents = ({ isShowing, testInfo, onClickMoreResponse }) => {
  const { id: projectId } = useParams();

  const [data, setData] = useState();

  useEffect(() => {
    axiosPrivate
      .get(`api/v1/projects/${projectId}/review/dashboard`, {
        params: {
          testSurveyType: "CONTENT_SATISFACTION",
        },
      })
      .then((res) => {
        if (res.data?.result?.answers?.length > 0) {
          setData(res.data.result.answers);
        } else {
          setData(pieSatisfactionDefaultData);
        }
      })
      .catch((err) => {
        console.log(err);
        // window.alert("콘텐츠 만족도 설문 결과를 불러오는데 실패했습니다. 관리자에게 문의해주세요!");
        setData(pieSatisfactionDefaultData);
      });
  }, []);

  // 답변 전체보기 모달
  const onClickResponse = () => onClickMoreResponse("CONTENT_SATISFACTION");

  return (
    <TabContainer isShowing={isShowing}>
      <ProjectInfo testInfo={testInfo} />
      <TabTag text="콘텐츠 만족도" />
      <QuestionMain text="본 제품/서비스를 통해 제공되는 콘텐츠는 얼마나 만족스러우신가요?" />
      <ResponseBtn onClick={onClickResponse} />
      {data && <Pie data={data} />}
    </TabContainer>
  );
};

export default ReviewContents;
