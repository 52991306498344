import styled from "styled-components";
import { flexbox } from "styles/mixins";

export const ToastWrapper = styled.div`
  position: fixed;
  top: 60px;
  left: 50%;
  ${flexbox("center", "center")};
  height: 32px;
  padding: 0 14px;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  color: #ffffff;
  background-color: #181818;
  border-radius: 5px;
  z-index: 9999999999;
  transform: translate(-50%, -92px);
  animation: ${(props) => (props.isToastVisible ? "slideIn 3000ms ease-in-out" : "none")};

  @keyframes slideIn {
    0% {
      transform: translate(-50%, -92px);
      opacity: 0;
    }
    20% {
      transform: translate(-50%, 0);
      opacity: 1;
    }

    80% {
      transform: translate(-50%, 0);
      opacity: 1;
    }
    100% {
      transform: translate(-50%, -92px);
      opacity: 0;
    }
  }
`;
