import styled from "styled-components";
import { flexbox, flexboxColumn } from "styles/mixins";

export const DefaultCard = styled.li`
  ${flexboxColumn("center", "center")};
  margin: 0 10px 0 0;
  > div:first-of-type {
    margin: 0 0 10px 0;
    width: 100px;
    height: 32px;
    border: 1px solid #d6d6d6;
    border-radius: 20px;
  }
  > div:last-of-type {
    width: 165px;
    height: 360px;
    background-color: #f3f3f3;
    border-radius: 4px;
  }
`;

export const ImageCard = styled.li`
  ${flexboxColumn("center", "center")};
  margin: 0 10px 0 0;
  filter: drop-shadow(0px 6px 18px rgba(0, 0, 0, 0.12));

  > div:first-of-type {
    ${flexbox("center", "center")};
    margin: 0 0 10px 0;
    min-width: 58px;
    height: 32px;
    padding: 0 12px;
    border-radius: 20px;
    border: 1px solid ${(props) => props.bg};
    background-color: ${(props) => props.bg};

    p {
      font-weight: 700;
      font-size: 12px;
      line-height: 20px;
      text-align: center;
      color: #000000;
    }
  }

  > div:last-of-type {
    width: 165px;
    height: 360px;
    position: relative;

    &:hover {
      background: unset;
      opacity: unset;
    }

    &::after {
      content: "";
      display: block;
      width: 165px;
      height: 360px;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      border-radius: 4px;
      background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 27.08%);
      -webkit-transition: opacity 1s;
      -moz-transition: opacity 1s;
      -ms-transition: opacity 1s;
      -o-transition: opacity 1s;
      transition: opacity 1s;
    }

    &:hover::after {
      opacity: 0.5;
      background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 27.08%);
    }

    &:hover h3 {
      display: block;
    }

    h3 {
      display: none;
      position: absolute;
      top: 12px;
      left: 12px;
      font-weight: 700;
      font-size: 14px;
      line-height: 14px;
      color: #ffffff;
      z-index: 1;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 4px;
    }
  }
`;
