import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosPublic from "utils/axiosPublic";
import { Link } from "react-router-dom";
import {
  Main,
  Form,
  InputWrapper,
  HelperText,
  Register,
  Question,
  ContentWrapper,
  BtnWrapper,
  ConfirmBtn,
} from "./styles";
import CustomModal from "components/common/CustomModal";
import { ModalContent, ModalDesc } from "pages/home/styles";

const Login = () => {
  let navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  // 입력값
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const onChangeEmail = (e) => setEmail(e.target.value);
  const onChangePassword = (e) => setPassword(e.target.value);

  // 에러
  const [hasError, setHasError] = useState(false);

  const onClickConfirm = () => setIsModalVisible(false);

  const onSubmit = (e) => {
    e.preventDefault();

    setHasError(false);

    const data = {
      email,
      password,
    };

    axiosPublic
      .post("/api/v1/open/sign-in", data)
      .then((resp) => {
        if (resp.data.code === "AUTH_S_001") {
          const uid = resp.data.result.uid;
          const token = resp.data.result.token;
          const role = resp.data.result.roleCodes[0];
          window.localStorage.setItem("uid", uid);
          window.localStorage.setItem("accessToken", token);
          window.localStorage.setItem("role", role);
          window.localStorage.setItem("mode", "");
          navigate("/home");
        }
      })
      .catch((err) => {
        setIsModalVisible(true);
        setHasError(true);
        console.log(err);
      });
  };

  return (
    <Main>
      <ContentWrapper>
        <Form onSubmit={onSubmit}>
          <h1>
            I2B Campus
            <br />
            로그인하기
          </h1>
          <InputWrapper>
            <input
              type="text"
              placeholder="ID"
              value={email}
              onChange={onChangeEmail}
            />
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={onChangePassword}
            />
          </InputWrapper>
          <Link to="/reset/email" className="password">
            비밀번호를 잊으셨나요?
          </Link>
          {hasError && (
            <HelperText>
              아이디 또는 비밀번호를 잘못 입력했습니다.
              <br />
              입력하신 내용을 다시 확인해주세요.
            </HelperText>
          )}

          <button type="submit">로그인</button>
          <Register>
            <p>회원이 아니신가요?</p>
            <Link to="/register">회원가입</Link>
          </Register>
        </Form>
      </ContentWrapper>

      <Question>
        <a
          href="https://forms.gle/BPgRfe8CyLavyajt8"
          target="_blank"
          rel="noreferrer">
          <span>I2B Toolkit에 대해 궁금점이 있으신가요?</span>
        </a>
      </Question>
      {isModalVisible && (
        <CustomModal isVisible={isModalVisible}>
          <ModalContent>
            <h2>초대 코드 발급이 필요합니다.</h2>
            <ModalDesc>
              <p>
                초대 코드를 발급받아 회원가입하시면
                <br />
                I2BToolkit을 이용하실 수 있습니다.
                <br />
                I2B Campus에 문의하세요.
              </p>
              <BtnWrapper>
                <ConfirmBtn type="button" onClick={onClickConfirm}>
                  확인
                </ConfirmBtn>
              </BtnWrapper>
            </ModalDesc>
          </ModalContent>
        </CustomModal>
      )}
    </Main>
  );
};

export default Login;
