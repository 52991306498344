import styled from "styled-components";
import { flexbox, flexboxColumn } from "styles/mixins";

export const Item = styled.li``;

export const InsightHeading = styled.div`
  ${flexbox("space-between", "center")};
  margin: 0 0 8px 0;
  > div {
    ${flexbox("flex-start", "center")};
  }
  img {
    width: 24px;
    height: 24px;
    margin: 0 6px 0 0;
    border-radius: 50%;
  }
  strong {
    margin: 0 10px 0 0;
    font-weight: 700;
    font-size: 13px;
    line-height: 24px;
    color: #000000;
  }
  small {
    font-weight: 500;
    font-size: 10px;
    line-height: 24px;
    color: #8b95a1;
  }

  button {
    margin: 0 8px 0 0;
    font-weight: 500;
    font-size: 10px;
    line-height: 24px;
    text-align: center;
    color: #4556ee;

    &:last-of-type {
      margin: 0;
    }

    &:disabled {
      cursor: default;
    }
  }
`;

export const InsightContent = styled.div`
  ${flexboxColumn};

  textarea {
    resize: none;
    padding: 8px 16px;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #000000;
    border: 1px solid #181818;
    border-radius: 4px;
    background-color: #fff;

    ::-webkit-scrollbar {
      width: 8px;
    }

    ::-webkit-scrollbar-thumb {
      border: 2px solid #f5f5f5;
      background-clip: padding-box;
      border-radius: 6px;
      background-color: rgba(144, 144, 144, 0.3);
    }
  }
`;

export const BtnWrapper = styled.div`
  align-self: flex-end;
  margin: 4px 0 12px 0;

  button {
    margin: 0 8px 0 0;
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
    color: #4556ee;
    &:last-of-type {
      margin: 0;
    }

    &:disabled {
      cursor: default;
    }
  }

  // TODO
  button.edit-save-btn {
    transition: opacity 300ms ease-in-out;

    &:disabled {
      opacity: 0.5;
      cursor: default;
      pointer-events: none;
    }
  }
`;

export const Comment = styled.p`
  font-size: 12px;
  line-height: 18px;
  color: #000000;
  margin: 0 0 15px 0;
  word-break: keep-all;
  word-wrap: break-word;
`;
