import { useState, useEffect } from "react";
import { Main, TabButtonWrapper, Button, HomeLink } from "./styles";
import UserTab from "./UserTab";
import ExpertTab from "./ExpertTab";
import CompanyTab from "./CompanyTab";
import ToastPopup from "components/common/ToastPopup";

const Profile = () => {
  const [tab, setTab] = useState(1);
  const [uid, setUid] = useState();
  const [role, setRole] = useState();

  useEffect(() => {
    const uid = window.localStorage.getItem("uid");
    const role = window.localStorage.getItem("role");
    setUid(uid);
    setRole(role);
  }, []);

  const onClickButton = (num) => setTab(num);

  // 저장 토스트 팝업
  const [isToastVisible, setIsToastVisible] = useState(false);
  // 저장 에러 여부 - 토스트 팝업을 위해
  const [hasSaveError, setHasSaveError] = useState(false);

  return (
    <>
      {isToastVisible && !hasSaveError && (
        <ToastPopup isToastVisible={isToastVisible} setIsToastVisible={setIsToastVisible}>
          저장에 성공했습니다!
        </ToastPopup>
      )}
      {isToastVisible && hasSaveError && (
        <ToastPopup isToastVisible={isToastVisible} setIsToastVisible={setIsToastVisible}>
          저장에 실패했습니다!
        </ToastPopup>
      )}
      <Main>
        <HomeLink to="/home" />
        {role === "ROLE_USER" ? (
          <div>
            <TabButtonWrapper>
              <Button type="button" onClick={() => onClickButton(1)} isCurrent={tab === 1}>
                회원정보
              </Button>
              <Button type="button" onClick={() => onClickButton(2)} isCurrent={tab === 2}>
                회사정보
              </Button>
            </TabButtonWrapper>
            {tab === 1 ? (
              <UserTab uid={uid} isToastVisible={isToastVisible} setIsToastVisible={setIsToastVisible} hasSaveError={hasSaveError} setHasSaveError={setHasSaveError} />
            ) : (
              <CompanyTab uid={uid} isToastVisible={isToastVisible} setIsToastVisible={setIsToastVisible} hasSaveError={hasSaveError} setHasSaveError={setHasSaveError} />
            )}
          </div>
        ) : (
          <div>
            <TabButtonWrapper>
              <Button type="button" onClick={() => onClickButton(1)} isCurrent={tab === 1}>
                전문가정보
              </Button>
            </TabButtonWrapper>
            {<ExpertTab role={role} uid={uid} isToastVisible={isToastVisible} setIsToastVisible={setIsToastVisible} hasSaveError={hasSaveError} setHasSaveError={setHasSaveError} />}
          </div>
        )}
      </Main>
    </>
  );
};

export default Profile;
