import styled from "styled-components";

export const Input = styled.input`
  caret-color: #4556ee;
  width: 100%;
  height: 32px;
  padding: 4px 11px;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  color: #000000;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  transition: border-color 300ms ease-in-out;

  ::placeholder {
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    color: #b1b1b1;
  }

  &:focus {
    border: 1px solid #181818;
  }
`;
