import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "components/register/Layout";
import Card from "components/register/Card";
import Radios from "components/register/Radios";
import NextBtn from "components/register/NextBtn";
import { Form, GroupOne, GroupTwo, Input } from "./styles";
import register_progress_four from "assets/register_progress_four.svg";
import { companySize, series } from "utils/data";

const CompanyInfoFirst = () => {
  let navigate = useNavigate();

  // 인풋값
  const [name, setName] = useState("");
  const [type, setType] = useState();
  const [level, setLevel] = useState();
  const [investor, setInvestor] = useState("");

  const onChangeName = (e) => setName(e.target.value);
  const onChangeType = (type) => setType(type);
  const onChangeLevel = (level) => setLevel(level);
  const onChangeInvestor = (e) => setInvestor(e.target.value);

  // 모든 값을 입력해야 다음으로 버튼 클릭 가능
  const [isDisabled, setIsDisabled] = useState(true);
  useEffect(() => {
    if (name?.length > 0 && type && level && investor?.length > 0) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [name, type, level, investor]);

  const onSubmit = (e) => {
    e.preventDefault();

    const data = JSON.parse(window.localStorage.getItem("register"));
    if (data) {
      const newData = {
        ...data,
        company: {
          companyName: name,
          companySize: type,
          series: level,
          mainVc: investor,
        },
      };

      window.localStorage.setItem("register", JSON.stringify(newData));

      navigate("/register/company-second");
    } else {
      // TODO
    }
  };

  return (
    <Layout>
      <Card>
        <img src={register_progress_four} alt="fourth register progress" />
        <h1>
          <span className="eng">I2B Campus</span>와 함께할 회사를 알려주세요!
        </h1>
        <Form onSubmit={onSubmit}>
          <div>
            <GroupOne>
              <Input>
                <label htmlFor="name">회사명을 입력하세요.</label>
                <input type="text" id="name" placeholder="사업체 또는 준비하고 계시는 제품/서비스명을 입력하세요." value={name} onChange={onChangeName} />
              </Input>
              {companySize?.length > 0 && <Radios name="companySize" array={companySize} selectedValue={type} onChange={onChangeType} isBlue />}
            </GroupOne>
            <GroupTwo>{series?.length > 0 && <Radios name="series" label="투자 단계를 선택하세요." array={series} selectedValue={level} onChange={onChangeLevel} />}</GroupTwo>
            <Input>
              <label htmlFor="investor">메인 투자사는 어디인가요?</label>
              <input type="text" id="investor" value={investor} onChange={onChangeInvestor} />
            </Input>
          </div>
          <NextBtn type="submit" disabled={isDisabled}>
            다음으로
          </NextBtn>
        </Form>
      </Card>
    </Layout>
  );
};

export default CompanyInfoFirst;
