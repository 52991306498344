import { Collapse } from "antd";
import KeywordCheckbox from "components/common/KeywordCheckbox";
import KeywordSelfInput from "../KeywordSelfInput";
import { CollapseWrapper, Icon, ExtraDesc, SelfContent, CheckboxWrapper, SelectContent } from "./styles";
import BenchmarkingCard from "components/concept/BenchmarkingCard";

const AsideAccordion = ({
  activeKey,
  setActiveKey,
  keywordArr,
  selectedKeywordArr,
  typedKeywordArr,
  isMax,
  onChangeTypedKeyword,
  onChangeSelectedKeyword,
  exampleBenchArr,
  typeRandomBgArr,
  onClickBenchExample,
  currentBench,
  combinedBenchList,
  isViewMode,
}) => {
  const { Panel } = Collapse;

  return (
    <CollapseWrapper>
      <Collapse
        accordion
        bordered={false}
        defaultActiveKey={["2"]}
        activeKey={activeKey}
        onChange={(key) => setActiveKey(key)}
        expandIcon={({ isActive }) => <Icon isActive={isActive} />}
        expandIconPosition={"end"}
      >
        {/* '선택 입력'하는 화면에서는 직접 입력 클릭 불가능 */}
        <Panel header="직접 추가하기" key="1" collapsible={currentBench.registerType === "ADMIN" || isViewMode ? "disabled" : "header"}>
          <ExtraDesc>벤치마킹 대상에 해당하는 키워드를 아래에서 선택하세요 (최대3개)</ExtraDesc>
          <SelfContent>
            <h4>벤치마킹 키워드</h4>
            <CheckboxWrapper>
              {keywordArr?.length > 0 && (
                <KeywordCheckbox
                  array={keywordArr}
                  randomBgArr={typeRandomBgArr}
                  name="self-keyword"
                  selectedArr={selectedKeywordArr}
                  onChange={onChangeSelectedKeyword}
                  isMax={isMax}
                  isViewMode={isViewMode}
                />
              )}
              <KeywordSelfInput typedKeywordArr={typedKeywordArr} onChangeTypedKeyword={onChangeTypedKeyword} isMax={isMax} isViewMode={isViewMode} />
            </CheckboxWrapper>
          </SelfContent>
        </Panel>
        <Panel header="벤치마킹 제품/서비스 선택하기" key="2" collapsible={isViewMode ? "disabled" : "header"}>
          <ExtraDesc>I2B가 추천하는 벤치마킹 제품/서비스 입니다. 선택해서 크게 확인해보세요.</ExtraDesc>
          <SelectContent>
            {exampleBenchArr?.length > 0
              ? exampleBenchArr.map((item, i) => (
                  <BenchmarkingCard key={i} item={item} onClickBenchExample={onClickBenchExample} currentBench={currentBench} combinedBenchList={combinedBenchList} isViewMode={isViewMode} />
                ))
              : ""}
          </SelectContent>
        </Panel>
      </Collapse>
    </CollapseWrapper>
  );
};

export default AsideAccordion;
