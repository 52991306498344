import styled from "styled-components";
import { flexbox, flexboxColumn, whiteCardBase } from "styles/mixins";
import ic_analysis_mobile from "assets/ic_analysis_mobile.svg";

export const WhiteCard = styled.div`
  ${whiteCardBase};
`;

export const Row = styled.div`
  ${flexbox};
  &:first-of-type,
  &:nth-of-type(2) {
    margin: 0 0 40px 0;
  }
  &:nth-of-type(3) {
    margin: 0 0 50px 0;
  }
  &:nth-of-type(4) {
    margin: 0 0 30px 0;
  }
  &:last-of-type {
    margin: 0 0 53px 0;
  }
`;

export const LabelWrapper = styled.div`
  ${flexboxColumn};
  min-width: 319px;
  margin: 0 49px 0 0;
  flex-shrink: 0;
  flex-grow: 0;

  label {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    color: #000;
  }

  p {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #808080;
  }
`;

export const CheckboxWrapper = styled.div`
  ${flexbox("flex-start", "center")};
  flex-wrap: wrap;
  gap: 6px 8px;
`;

export const Mobile = styled.div`
  width: 120px;
  height: 100px;
  background-image: url(${ic_analysis_mobile});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 4px;
`;

export const Ready = styled.div`
  ${flexbox("center", "center")};
  width: 120px;
  height: 100px;
  background: #f2f2f2;
  border-radius: 4px;
  font-weight: 700;
  font-size: 12px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.2);
`;

export const FlexWrapper = styled.div`
  ${flexbox("flex-start", "flex-start")};
  flex-wrap: wrap;
`;

export const Name = styled.div`
  ${flexbox("flex-start", "center")};
  margin: 0 30px 10px 0;
  p {
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    color: #727682;
    margin: 0 12px 0 0;
    flex-shrink: 0;
  }

  input {
    width: 300px;
    height: 44px;
    padding: 0 16px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    color: #353535;
    transition: border-color 300ms ease-in-out;

    ::placeholder {
      font-weight: 500;
      font-size: 12px;
      line-height: 22px;
      color: #b1b1b1;
    }

    &:focus {
      border: 1px solid #181818;
    }
  }
`;

export const Icon = styled.div`
  ${flexbox};
  flex-wrap: wrap;

  p {
    margin: 0 12px 0 0;
    padding: 12px 0 0 0;
    flex-shrink: 0;
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    color: #727682;
  }
`;

export const DefWrapper = styled.div`
  ${flexboxColumn};

  > div {
    ${flexbox("flex-start", "center")};
    &:first-of-type {
      margin: 0 0 10px 0;
    }
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: #000000;
  }
  input {
    width: 300px;
    height: 44px;
    padding: 0 18px;
    border-radius: 4px;
    border: 1px solid #e0e0e0;
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    color: #353535;
    margin: 0 12px 0 0;
    transition: border-color 300ms ease-in-out;

    ::placeholder {
      font-weight: 500;
      font-size: 12px;
      line-height: 22px;
      color: #b1b1b1;
    }

    &:focus {
      border: 1px solid #181818;
    }
  }
`;
