import styled from "styled-components";
import { flexbox, flexboxColumn } from "styles/mixins";

export const Container = styled.div`
  ${flexboxColumn("center", "center")};
  position: relative;
  width: 100vw;
  min-height: 100vh;
  padding: 30px 0 0 0;
  background-color: #f2f2f2;
  overflow-x: hidden;
  overflow-y: auto;

  > div {
    max-width: 1106px;
  }
`;

export const Header = styled.div`
  ${flexbox("center", "center")};
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100vw;
  width: 100vw;
  height: 36px;
  background-color: #222222;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 13px;
  line-height: 22px;
  color: #ffffff;
  z-index: 1;
`;
