import styled from "styled-components";
import ic_response from "assets/ic_response.svg";

import ic_arrow_down_grey from "assets/ic_arrow_down_grey.svg";
import ic_arrow_down_white from "assets/ic_arrow_down_white.svg";

export const Table = styled.table`
  min-width: 1106px;
  width: 100%;

  thead {
    display: block;
    width: 100%;
  }

  tbody {
    display: block;
    max-height: 600px;
    overflow-y: auto;
  }

  tr {
    display: table;
    width: 100%;
  }

  th,
  td {
    vertical-align: middle;
    text-align: center;
  }

  tr th:first-of-type,
  tr td:first-of-type {
    width: 18%;
  }

  tr th:nth-of-type(2),
  tr td:nth-of-type(2) {
    width: 8%;
  }

  tr th:nth-of-type(3),
  tr td:nth-of-type(3) {
    width: 8%;
  }

  tr th:nth-of-type(4),
  tr td:nth-of-type(4) {
    width: 25%;
  }

  tr th:nth-of-type(5),
  tr td:nth-of-type(5) {
    width: 20%;
  }

  tr th:nth-of-type(6),
  tr td:nth-of-type(6) {
    width: 11%;
  }

  tr th:nth-of-type(7),
  tr td:nth-of-type(7) {
    width: 10%;
  }
`;

export const Thead = styled.thead`
  display: table; //tr이랑 같은 너비 차지하도록
  width: 100%;
  height: 44px;
  padding: 0 12px;
  background-color: #181818;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  color: rgba(255, 255, 255, 0.5);
  font-weight: 700;
  font-size: 13px;
  line-height: 24px;

  th {
    height: 44px;
    position: relative;

    :not(:last-of-type)::after {
      content: "";
      display: inline-block;
      width: 1px;
      height: 20px;
      background-color: #808080;
      position: absolute;
      right: 0;
      top: 12px;
    }

    button {
      display: block;
      width: 100%;
      background-image: url(${ic_arrow_down_grey});
      background-repeat: no-repeat;
      background-size: 10px 5px;
      background-position: right 11px bottom 9px;
      font-weight: 700;
      font-size: 13px;
      line-height: 24px;
      color: rgba(255, 255, 255, 0.5);
      transition: all 300ms ease-in-out;
      &:hover {
        color: #fff;
        background-image: url(${ic_arrow_down_white});
      }
    }
  }
`;

export const Tbody = styled.tbody`
  display: block; // 세로 스크롤 가능하도록
  max-height: 600px; // 40px * 15개
  overflow-y: auto;
  padding: 0 0 0 12px;
  background-color: #fff;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: #353535;

  tr:not(:last-of-type) {
    border-bottom: 1px solid #e0e0e0;
  }

  td {
    height: 40px;
  }

  ::-webkit-scrollbar {
    width: 12px;
  }

  ::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
    border-left: 4px solid #fff;
    border-radius: 6px;
    background-color: rgba(144, 144, 144, 0.3);
  }
`;

export const StatusTag = styled.div`
  width: 64px;
  height: 24px;
  padding: 2px 0 0 0;
  margin: 0 auto;
  border-radius: 20px;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  background-color: #4556ee;
  color: #ffffff;

  &.tag {
    margin: 0 13px 0 0;
  }

  &.before {
    background-color: #b1b1b1;
  }

  &.end {
    background-color: #181818;
  }
`;

export const ReplyBtn = styled.button`
  display: block;
  width: 14px;
  height: 18px;
  margin: 0 auto;
  background-image: url(${ic_response});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;

  &:disabled {
    cursor: default;
    opacity: 0.1;
  }
`;
