import styled from "styled-components";
import ic_test_complete_start from "assets/ic_test_complete_start.svg";
import ic_test_complete_line from "assets/ic_test_complete_line.svg";
import { flexboxColumn } from "styles/mixins";

export const Wrapper = styled.div`
	${flexboxColumn("center", "center")};
	width: 700px;
	padding: 132px 0 88px;
	background-color: #ffffff;
	background-image: url(${ic_test_complete_start});
	background-repeat: no-repeat;
	background-size: 65px 42px;
	background-position: 319px 86px;
	border-radius: 8px;

	h1 {
		margin: 0 0 10px 0;
		font-weight: 700;
		font-size: 28px;
		line-height: 38px;
		color: #000000;
	}

	p {
		padding: 0 0 8px 0;
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		color: #353535;
		background-image: url(${ic_test_complete_line});
		background-repeat: no-repeat;
		background-position: bottom center;
		background-size: contain;
	}
`;
