import styled from "styled-components";
import { flexboxColumn } from "styles/mixins";
import password_hidden from "assets/password_hidden.svg";
import password_visible from "assets/password_visible.svg";

export const Wrapper = styled.div`
  ${flexboxColumn("flex-start", "flex-start")};

  &:nth-of-type(2) {
    margin: 20px 0 0 0;
  }
`;

export const Label = styled.label`
  margin: 0 0 4px 0;
  font-size: 13px;
  font-weight: 500;
  line-height: 22px;
  color: #000;
  text-align: left;
`;

export const InputWrapper = styled.div`
  position: relative;

  input {
    height: 44px;
    width: 320px;
    padding: 0 18px;
    border-radius: 4px;
    border: ${(props) => (props.isValid ? "1px solid #e0e0e0" : "1px solid #ce5e41")};
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    color: #000;
    transition: border-color 300ms ease-in-out;

    &:focus,
    &:active {
      border: ${(props) => (props.isValid ? "1px solid #181818" : "1px solid #ce5e41")};
    }
  }
`;

export const ToggleBtn = styled.button`
  position: absolute;
  top: 16px;
  right: 14px;
  width: 16px;
  height: 12px;
  background-image: url(${(props) => (props.isPwdVisible ? password_visible : password_hidden)});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
`;
