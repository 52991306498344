import styled from "styled-components";
import { flexbox, flexboxColumn } from "styles/mixins";
import ic_carousel_next from "assets/ic_carousel_next.svg";

export const Wrapper = styled.div`
  width: 340px;
  padding: 48px 46px 55px;
  flex-shrink: 0;
  flex-grow: 0;
  background-color: #d9d9d9;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  overflow: hidden;

  .ant-carousel .slick-dots {
    display: none !important;
  }
`;

export const ButtonWrapper = styled.div`
  ${flexbox("space-between", "center")};
  height: 42px;
  width: 100%;
  padding: 0 20px;
  margin: 0 0 24px 0;
  background-color: #ffffff;
  border: 1px solid #ffffff;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  font-weight: 700;
  font-size: 14px;
  line-height: 36px;
  text-align: center;
  color: #000000;

  button {
    width: 5px;
    height: 11px;
    background-image: url(${ic_carousel_next});
    background-repeat: no-repeat;
    background-size: 5px 11px;
    background-position: center center;

    &:disabled {
      opacity: 0.2;
      pointer-events: none;
    }
  }
  button:first-of-type {
    transform: rotate(180deg);
  }
`;

export const ImageWrapper = styled.div`
  width: 247px;
  height: 535px;
  border-radius: 4px;

  img {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    object-fit: cover;
  }
`;

export const NoLayout = styled.div`
  ${flexboxColumn("center", "center")};
  width: 247px;
  height: 535px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 4px;
  filter: drop-shadow(0px 6px 18px rgba(0, 0, 0, 0.12));

  img {
    width: 32px;
    height: 32px;
    margin: 0 0 4px 0;
  }
  p {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #808080;

    strong {
      font-weight: 700;
    }
  }
`;
