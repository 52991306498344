import styled from "styled-components";
import { flexbox, flexboxColumn } from "styles/mixins";

export const Heading = styled.h1`
  margin: 0 0 60px 0;
`;

export const Form = styled.form`
  ${flexboxColumn("space-between", "center")};
  width: 320px;
  height: 100%;
`;

export const AreaLabel = styled.p`
  margin: 0 0 4px 0;
  font-weight: 500;
  font-size: 13px;
  line-height: 22px;
  color: #000000;
  span {
    color: #808080;
  }
`;

export const CheckWrapper = styled.div`
  ${flexbox};
  flex-wrap: wrap;
  gap: 8px 6px;
  margin: 0 0 20px 0;

  label {
    min-width: 101px;
  }
`;
