import { useState, useEffect } from "react";
import LinkInfo from "components/test/LinkInfo";
import LinkInput from "components/test/LinkInput";
import TabTag from "components/test/TabTag";
import QuestionMain from "components/test/QuestionMain";
import FiveRadio from "components/test/FiveRadio";
import QuestionSub from "components/test/QuestionSub";
import TestTextarea from "components/test/TestTextarea";
import PrevNextBtn from "components/test/PrevNextBtn";
import TesterPrevNextBtn from "components/test/TesterPrevNextBtn";
import { TabContainer } from "../../../../pages/test/form/styles";
import { USER, PREVIEW, TESTER } from "utils/data";
import patchTesterAnswer from "utils/patchTesterAnswer";

const answerArr = [
  { text: "하루에 몇 번", value: "ONE_DAY_SEVERAL_TIMES" },
  { text: "하루에 한 번", value: "ONE_DAY_ONE_TIME" },
  { text: "주 1회 이상", value: "WEEK_ONE_MORE_TIME" },
  { text: "월 1회 이상", value: "MONTH_ONE_MORE_TIME" },
  { text: "사용하지 않음", value: "NONE_USE" },
];

const Frequency = ({ isShowing, status, link, projectId, testerUid, savedAnswer }) => {
  const [frequency, setFrequency] = useState();
  const [text, setText] = useState();

  useEffect(() => {
    if (status === TESTER && savedAnswer) {
      const { expectedFrequencyUseType, expectedFrequencyUseAnswer } = savedAnswer;
      if (expectedFrequencyUseType?.length > 0) setFrequency(expectedFrequencyUseType);
      if (expectedFrequencyUseAnswer?.length > 0) setText(expectedFrequencyUseAnswer);
    }
  }, [savedAnswer]);

  const onChangeFrequency = (e) => {
    setFrequency(e.target.value);

    if (status === TESTER) {
      patchTesterAnswer(projectId, testerUid, "expectedFrequencyUseType", e.target.value, "EXPECTED_FREQUENCY_USE")
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
          window.alert("제품/서비스 사용 빈도 저장에 실패했습니다. 관리자에게 문의 부탁드립니다.");
        });
    }
  };

  const onChangeText = (e) => {
    setText(e.target.value);
    if (status === TESTER) {
      patchTesterAnswer(projectId, testerUid, "expectedFrequencyUseAnswer", e.target.value, "EXPECTED_FREQUENCY_USE")
        .then((res) => console.log(res))
        .catch((err) => {
          console.log(err);
          window.alert("제품/서비스 사용 빈도 이유 저장에 실패했습니다. 관리자에게 문의 부탁드립니다.");
        });
    }
  };

  return (
    <TabContainer isShowing={isShowing}>
      {status !== USER ? <LinkInfo link={link} /> : <LinkInput />}
      <TabTag text="예상 사용 빈도" />
      <QuestionMain text="얼마나 자주 사용할 것 같나요?" />
      <FiveRadio name="frequency" arr={answerArr} status={status} selectedValue={frequency} onChange={onChangeFrequency} />
      <QuestionSub text="위와 같이 선택하신 이유가 무엇인가요?" />
      <TestTextarea name="frequency" placeholder="테스터의 작성이 이곳에 표시됩니다." status={status} value={text} onChange={onChangeText} />
      {status === PREVIEW && <PrevNextBtn />}
      {status === TESTER && <TesterPrevNextBtn />}
    </TabContainer>
  );
};

export default Frequency;
