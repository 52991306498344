import { Container, HeadingWrapper, BtnWrapper, FeedbackTable, Thead, Tbody, RadioResponse } from "components/review/ResponseModalContent/styles";
import { StatusTag } from "../FeedbackTable/styles";
import { questionTextArr, questionAnswerDic, answerTextArr } from "utils/data";

const changeToTextAnswer = (answer) => {
  const targetObj = answerTextArr.find((item) => item.id === answer);
  if (targetObj) return targetObj;
  else return { text: answer, icon: "" };
};

// 피드백 페이지
const AnswerModalContent = ({ questionList, testerResponse, onClickClose }) => {
  const {
    contentSatisfactionAnswer,
    contentSatisfactionType,
    designSatisfactionAnswer,
    designSatisfactionType,
    expectedFrequencyUseAnswer,
    expectedFrequencyUseType,
    functionalSatisfactionAnswer,
    functionalSatisfactionType,
    genderType,
    generalOpinionAnswer,
    payIntentionAnswer,
    payIntentionYn,
    productUseIntentionAnswer,
    productUseIntentionYn,
    projectId,
    projectStatusType,
    similarServiceAnswer,
    testCaseAnswerStepType,
    testCaseExpireDate,
    testCaseStepType,
    testerAge,
    testerEmail,
    testerName,
    testerUid,
  } = testerResponse;

  const usedQuestionList = questionList.filter((question) => question.use === true);
  const questionArr = usedQuestionList.map((question, i) => {
    const targetStep = question.testSurveyType;
    const neededAnswerList = questionAnswerDic[targetStep];
    const testerAnswerList = neededAnswerList.map((item, i) => (testerResponse[item] ? changeToTextAnswer(testerResponse[item]) : { text: "-", icon: "" }));
    const targetObj = questionTextArr.find((item) => item.step === targetStep);

    return { ...targetObj, testerAnswerList };
  });

  return (
    <Container>
      <HeadingWrapper>
        <StatusTag className={testCaseStepType === "BEFORE_PROCEEDING" ? "tag before" : testCaseStepType === "END_OF_PROGRESS" ? "tag end" : "tag proceeding"}>
          {testCaseStepType === "BEFORE_PROCEEDING" ? "진행 전" : testCaseStepType === "END_OF_PROGRESS" ? "진행 완료" : "진행 중"}
        </StatusTag>
        {testerName && <h1>{testerName}</h1>}
        {genderType && <p>{genderType === "MAN" ? "남성" : genderType === "WOMAN" ? "여성" : "구분없음"}</p>}
        {testerAge && <p>{testerAge}</p>}
        <p>{testerEmail}</p>
        <BtnWrapper>
          {/* <button type="button"></button> */}
          <button type="button" onClick={onClickClose}></button>
        </BtnWrapper>
      </HeadingWrapper>
      <div>
        <FeedbackTable>
          <Thead>
            <tr>
              <th>설문 목록</th>
              <th>질문</th>
              <th>선택 답변</th>
              <th>답변</th>
            </tr>
          </Thead>
          <Tbody>
            {questionArr?.map((item, i) => (
              <tr key={i}>
                <td>
                  Q.{i + 1} {item?.category}
                </td>
                <td>{item?.question}</td>
                {item?.testerAnswerList?.map((answer, i) => (
                  <td key={`${i}${item.category}`} className={answer.icon ? "with-icon" : ""}>
                    {answer.icon && <img src={answer.icon} referrerPolicy="no-referrer" />} {answer.text}
                  </td>
                ))}
              </tr>
            ))}
          </Tbody>
        </FeedbackTable>
      </div>
    </Container>
  );
};

export default AnswerModalContent;
