import styled from "styled-components";
import { flexbox, flexboxColumn, scrollBase } from "styles/mixins";
import { Main } from "pages/home/styles";

import ic_expert_designer from "assets/new_ic_expert_designer.svg";
import ic_expert_mentor from "assets/new_ic_expert_mentor.svg";
import ic_expert_manager from "assets/new_ic_expert_manager.svg";

export const ExpertMain = styled(Main)`
  padding: 57px 0 58px 46px;
  position: relative;
`;

export const PageHeading = styled.h1`
  margin: 38px 0 46px 0;
  font-weight: 700;
  font-size: 26px;
  line-height: 36px;
  color: #000000;
`;

export const ExpertList = styled.ul`
  ${flexbox};
  flex-wrap: wrap;
  gap: 65px 46px;
  max-width: 898px;

  li {
    ${flexboxColumn("flex-start", "center")};
    width: 190px;

    h2 {
      margin: 0 0 6px 0;
      font-weight: 700;
      font-size: 18px;
      line-height: 20px;
      text-align: center;
      color: #353535;
    }

    small {
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      text-align: center;
      color: #8b95a1;
    }
  }
`;

export const ImgWrapper = styled.div`
  width: 190px;
  height: 190px;
  border-radius: 25px;
  margin: 0 0 14px 0;

  img {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    object-fit: cover;
  }
`;

export const RoleTag = styled.p`
  ${flexbox("flex-start", "center")};
  height: 26px;
  padding: 0 15px 2px 38px;
  margin: 0 0 6px 0;
  border-radius: 20px;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
  background-color: ${(props) => (props.role === "MANAGER" ? "#F6DD66" : props.role === "MENTOR" ? "#70BECD" : "#E2814A")};
  background-image: url(${(props) => (props.role === "MANAGER" ? ic_expert_manager : props.role === "MENTOR" ? ic_expert_mentor : ic_expert_designer)});
  background-repeat: no-repeat;
  background-size: 24px 24px;
  background-position: 10px 1px;
`;
