import styled from "styled-components";
import { flexbox, flexboxColumn, truncate } from "styles/mixins";

export const ProjectInfo = styled.div`
  ${flexbox("space-between", "center")};
  padding: 0 16px 0 10px;
  margin: 0 0 10px 0;

  > div:first-of-type {
    ${flexbox("flex-start", "center")};
  }
`;

export const Progress = styled.p`
  ${flexbox("center", "center")};
  min-width: 67px;
  padding: 0 8px;
  margin: 0 8px 0 0;
  border-radius: 4px;
  border: 1px solid ${(props) => (props.status === "PROCEEDING" ? "#4556EE" : props.status === "BEFORE_PROCEEDING" ? "#B1B1B1" : " #000000")};
  height: 28px;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: ${(props) => (props.status === "PROCEEDING" ? "#4556EE" : props.status === "BEFORE_PROCEEDING" ? "#B1B1B1" : " #000000")};
  background-color: #fff;
`;

export const Name = styled.h3`
  // margin: 0 16px 0 0;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #000000;
`;

export const Duration = styled.p`
  ont-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #808080;
  :before {
    content: "";
    display: inline-block;
    width: 1px;
    height: 13px;
    margin: auto 16px;
    background-color: #808080;
  }
`;

export const Count = styled.p`
  font-weight: 700;
  font-size: 20px;
  line-height: 36px;
  color: #000000;
`;

// aside

export const AsideHeading = styled.h4`
  ${flexbox("flex-start", "center")};
  height: 33px;
  padding: 0 0 0 24px;
  background-color: #d9d9d9;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  color: #353535;
`;

export const LinkInfo = styled.p`
  padding: 10px 22px 12px;
  border-bottom: 1px solid #e3e3e3;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #353535;
`;

export const AsideContent = styled.div`
  padding: 20px 22px 25px 24px;
  border-bottom: 1px solid #e3e3e3;

  > div:first-of-type {
    margin: 0 0 30px 0;
  }

  h5 {
    margin: 0 0 2px 0 0;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #000000;
  }

  p {
    margin: 0 0 10px 0;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #353535;
  }
`;

export const Form = styled.form`
  ${flexbox("flex-start", "center")};
`;

export const SendBtn = styled.button`
  ${flexbox("center", "center")};
  flex-shrink: 0;
  height: 36px;
  width: 65px;
  border-radius: 4px;
  background-color: #181818;
  font-weight: 700;
  font-size: 12px;
  line-height: 22px;
  color: #ffffff;
  transition: background-color 300ms ease-in-out, color 300ms ease-in-out;
  &:disabled {
    background-color: #f3f3f3;
    color: #d6d6d6;
    cursor: default;
  }
`;

export const FileBtn = styled.div`
  width: 159px;
  margin: 0 6px 0 0;
  input[type="file"] {
    position: absolute;
    width: 0;
    height: 0;
    padding: 0;
    overflow: hidden;
    border: 0;
  }

  input[type="file"] + label {
    ${truncate}
    display: block;
    height: 36px;
    max-width: 160px;
    padding: 7px 11px;
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    color: #b1b1b1;
    cursor: pointer;

    span {
      font-weight: 500;
      font-size: 12px;
      line-height: 22px;
      color: #000000;
    }
  }

  input[type="file"]:disabled + label {
    cursor: default;
  }
`;

export const EmailInput = styled.input`
  ${flexbox("center", "center")};
  margin: 0 6px 0 0;
  padding: 0 11px;
  height: 36px;
  max-width: 160px;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  transition: border-color 300ms ease-in-out;

  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  color: #000000;

  ::placeholder {
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    color: #b1b1b1;
  }

  &:focus {
    border: 1px solid #181818;
  }
`;

export const FormDownload = styled.a`
  display: block;
  margin: 5px 0 0 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #353535;
  a:hover {
    color: #353535;
  }
`;

export const TerminateBtn = styled.button`
  ${flexbox("center", "center")};
  width: 230px;
  height: 44px;
  margin: 20px auto 0;
  border: 1px solid #181818;
  border-radius: 100px;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #000000;
  transition: border-color 300ms ease-in-out, color 300ms ease-in-out;

  &:disabled {
    border: 1px solid #e0e0e0;
    color: #d6d6d6;
    cursor: default;
  }
`;

// 팝업
export const EmailPopupBody = styled.div`
  ${flexboxColumn("center", "center")};
  padding: 0 20px;
  height: 116px;
  p {
    font-size: 15px;
    line-height: 22px;
    font-weight: 500;
    color: #000;
  }
  p:first-of-type {
    font-weight: 700;
    color: #808080;
  }
`;
