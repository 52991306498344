import styled from "styled-components";
import { flexbox } from "styles/mixins";

const Div = styled.div`
  ${flexbox};
  width: 100%;
  min-height: ${(props) => (props.status === "user" ? "calc(100vh - 36px - 40px - 40px - 93px - 70px)" : "704px")};
  border-radius: 8px;
`;

const TabWrapper = ({ status, children }) => {
  return <Div status={status}>{children}</Div>;
};

export default TabWrapper;
