import styled from "styled-components";

export const Email = styled.p`
  margin: 8px 0 30px 0;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #808080;
`;
