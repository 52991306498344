import { Wrapper, HeadingWrapper, Heading, CloseBtn, GuideWrapper, GuideList, ImageWrapper } from "./styles";

const HelpModalContent = ({ headingText, guideArr, img, onClick }) => {
  return (
    <Wrapper>
      <HeadingWrapper>
        <Heading>
          <h1>도움말</h1>
          <p>{headingText}</p>
        </Heading>
        <CloseBtn type="button" onClick={onClick} />
      </HeadingWrapper>
      <GuideWrapper>
        {/* 왼쪽 */}
        <GuideList>
          {guideArr.map((item, i) => (
            <li key={i}>{item}</li>
          ))}
        </GuideList>
        {/* 오른쪽 - 이미지 */}
        <ImageWrapper>
          <img src={img} alt="guide image" referrerPolicy="no-referrer" />
        </ImageWrapper>
      </GuideWrapper>
    </Wrapper>
  );
};

export default HelpModalContent;
