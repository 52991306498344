import styled from "styled-components";
import { flexbox } from "styles/mixins";
import ic_help from "assets/ic_help.svg";
import ic_popup_close from "assets/ic_popup_close.svg";

export const Wrapper = styled.div`
  width: 1106px;
`;

export const HeadingWrapper = styled.div`
  ${flexbox("space-between", "center")};
  padding: 28px 28px 24px 34px;
  background-color: #fff;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;

export const Heading = styled.div`
  ${flexbox("flex-start", "center")};

  h1 {
    ${flexbox("flex-start", "center")};
    margin: 0 0 0 8px;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
    color: #000000;

    ::after {
      content: "";
      display: block;
      width: 1px;
      height: 14px;
      margin: 0 14px 0 12px;
      background-color: #7b8289;
    }
  }

  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #7b8289;
  }

  ::before {
    content: "";
    width: 22px;
    height: 22px;
    background-image: url(${ic_help});
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
  }
`;

export const CloseBtn = styled.button`
  width: 18px;
  height: 18px;
  background-image: url(${ic_popup_close});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
`;

export const GuideWrapper = styled.div`
  ${flexbox};
  padding: 45px 42px 67px 34px;
  background-color: #f3f3f3;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;

export const GuideList = styled.ul`
  li {
    ${flexbox};
    margin: 0 0 20px 0;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #000000;

    &:last-of-type {
      margin: 0;
    }

    ::before {
      content: "•";
      display: block;
      width: 12px;
      height: 18px;
      margin: 0 4px;
    }
  }
`;

// image
export const ImageWrapper = styled.div`
  width: 724px;
  height: 515px;
  margin: 0 0 0 21px;
`;
