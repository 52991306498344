import { Link } from "react-router-dom";
import styled from "styled-components";
import { flexbox } from "styles/mixins";

// Tab
export const TabContainer = styled.div`
  display: ${(props) => (props.isShowing ? "block" : "none")};
`;

// aside
export const PreviewLink = styled(Link)`
  ${flexbox("center", "center")};
  width: 230px;
  height: 44px;
  margin: 14px auto 15px;
  border: 1px solid #000;
  border-radius: 100px;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: #000000;

  :hover,
  :active {
    color: #000;
  }
`;

export const TestListHeader = styled.h5`
  padding: 7px 24px 6px;
  background-color: #d9d9d9;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  color: #353535;
`;

export const ScrollArea = styled.div`
  height: calc(100vh - 36px - 40px - 40px - 126px - 14px - 44px - 15px - 33px);
  margin: 6px 0;
  overflow-y: auto;

  > div:first-of-type,
  > div:last-of-type {
    padding: 12px 24px 18px !important;
  }

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background-color: rgba(144, 144, 144, 0.3);
    transition: background-color 300ms ease-in-out;
  }

  ::-webkit-scrollbar-track {
    border-radius: 6px;
    background-color: transparent;
  }

  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }
`;

export const DefaultItem = styled.div`
  ${flexbox("flex-start", "center")};
  padding: 18px 24px;
  cursor: pointer;

  p {
    pointer-events: none;
  }

  p:first-of-type {
    ${flexbox("center", "center")};
    margin: 0 10px 0 0;
    height: 26px;
    min-width: 56px;
    border-radius: 20px;
    font-weight: ${(props) => (props.isActive ? "700" : "500")};
    font-size: 12px;
    line-height: 20px;
    background-color: ${(props) => (props.isActive ? "#4556EE" : "#181818")};
    color: #fff;
    transition: background-color 300ms ease-in-out, font-weight 300ms ease-in-out;
  }

  p:nth-of-type(2) {
    font-weight: ${(props) => (props.isActive ? "700" : "500")};
    font-size: 14px;
    line-height: 22px;
    color: ${(props) => (props.isActive ? "#4556EE" : "#000")};
    transition: color 300ms ease-in-out, font-weight 300ms ease-in-out;
  }
`;

export const Form = styled.form`
  min-width: 1106px;
`;
