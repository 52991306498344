import { useState, useEffect } from "react";
import { Item, InsightHeading, InsightContent, BtnWrapper, Comment } from "./styles";
import ic_startup_small from "assets/ic_startup_small.svg";
import { useLocation } from "react-router-dom";

const CommentItem = ({ item, onClickInsightEditSave, onClickInsightDelete, isViewMode, userName }) => {
  const { pathname } = useLocation();
  const isBenchmarking = pathname.includes("benchmarking");

  const commentId = isBenchmarking ? item.benchmarkCommentId : item.layoutCommentId;
  const [isEditing, setIsEditing] = useState(false);
  const [isEditDisabled, setIsEditDisabled] = useState(false);
  const [isEditSabveDisabled, setIsEditSaveDisabled] = useState(false);
  const [textarea, setTextarea] = useState(isBenchmarking ? item.benchmarkComment : item.layoutComment);

  useEffect(() => {
    if (item.modifiedUserName === userName) {
      setIsEditDisabled(true);
    } else {
      setIsEditDisabled(false);
    }
  }, []);

  const onChangeTextarea = (e) => {
    if (e.target.value?.length === 0) {
      setIsEditSaveDisabled(true);
    } else {
      setIsEditSaveDisabled(false);
    }
    setTextarea(e.target.value);
  };

  const onClickEditBtn = () => {
    setIsEditing(true);
  };

  const onClickEditSaveBtn = () => {
    onClickInsightEditSave(commentId, textarea);
    setIsEditing(false);
  };

  const onClickEditCancelBtn = () => {
    // 초기화
    setIsEditing(false);
    setIsEditSaveDisabled(false);
    setTextarea(isBenchmarking ? item.benchmarkComment : item.layoutComment);
  };

  const onClickDeleteBtn = () => onClickInsightDelete(commentId);

  return (
    <Item>
      <InsightHeading>
        <div>
          <img src={item?.headshot ?? ic_startup_small} alt="avatar image" referrerPolicy="no-referrer" />
          <strong>{item.modifiedUserName}</strong>
          <small>{item.modifiedTime}</small>
        </div>
        {isEditDisabled && !isEditing ? (
          <div>
            <button type="button" onClick={onClickEditBtn} disabled={isViewMode}>
              편집
            </button>
            <button type="button" onClick={onClickDeleteBtn} disabled={isViewMode}>
              삭제
            </button>
          </div>
        ) : (
          <></>
        )}
      </InsightHeading>

      <InsightContent>
        {isEditing ? (
          <textarea value={textarea} onChange={onChangeTextarea} disabled={isViewMode}></textarea>
        ) : (
          <Comment>
            {textarea?.length > 0 &&
              textarea.split("\n").map((line, i) => {
                return (
                  <span key={i}>
                    {line}
                    <br />
                  </span>
                );
              })}
          </Comment>
        )}
        <>
          {isEditing && (
            <BtnWrapper>
              <button type="button" onClick={onClickEditCancelBtn}>
                취소
              </button>
              <button type="button" onClick={onClickEditSaveBtn} className="edit-save-btn" disabled={isEditSabveDisabled || isViewMode}>
                저장
              </button>
            </BtnWrapper>
          )}
        </>
      </InsightContent>
    </Item>
  );
};

export default CommentItem;
