import { atom, selector } from "recoil";

const initialState = {
  projectId: "",
};

export const recoilProjectState = atom({
  key: "recoilProjectState",
  default: initialState,
});

export const recoilProjectSelector = selector({
  key: "recoilProjectSelector",
  get: ({ get }) => {
    const { data } = get(recoilProjectState);
    return data;
  },
});
