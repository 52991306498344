import { css } from "styled-components";

// ------------------------------------------------------------------------------ flexbox

export const flexbox = (jc, ai) => css`
  display: flex;
  justify-content: ${jc};
  align-items: ${ai};
`;

export const flexboxColumn = (jc, ai) => css`
  ${flexbox(jc, ai)};
  flex-direction: column;
`;

// ------------------------------------------------------------------------------ position

export const positionCenterX = (type = "absolute") => css`
  position: ${type};
  left: 50%;
  transform: translateX(-50%);
`;

export const positionCenterY = (type = "absolute") => css`
  position: ${type};
  top: 50%;
  transform: translateY(-50%);
`;

export const positionCenter = (type = "absolute") => css`
  position: ${type};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

// ------------------------------------------------------------------------------

export const buttonBase = css`
  ${flexbox("center", "center")};
  border-radius: 100px;
  height: 44px;
  text-align: center;
  &:hover {
  }

  &:active {
  }

  &:focus {
  }

  /* &:disabled {
		cursor: not-allowed;
	} */
`;

export const buttonBlackFilledBase = css`
  ${buttonBase};
  background-color: ${(props) => props.theme.buttonBlack_bg};
  color: ${(props) => props.theme.white};
  border: 1px solid ${(props) => props.theme.buttonBlack_bg};
  transition: background-color 300ms ease-in-out, color 300ms ease-in-out, border 300ms ease-in-out;

  &:disabled {
    background-color: ${(props) => props.theme.buttonGrey_bg};
    color: ${(props) => props.theme.buttonGrey_color};
    border: 1px solid ${(props) => props.theme.buttonGrey_bg};
    pointer-events: none;
  }
`;

export const buttonBlackOutlinedBase = css`
  ${buttonBase};
  background-color: #000;
  border: 1px solid #000;
  color: #fff;
`;

export const buttonBlueFilledBase = css`
  ${buttonBase};
  background-color: #4556ee;
  border: 1px solid #4556ee;
  color: #fff;
`;
// ------------------------------------------------------------------------------ etc.

export const registerInputWrapperBase = css`
  ${flexboxColumn("flex-start", "flex-start")};

  label {
    margin: 0 0 4px 0;
    font-size: 13px;
    font-weight: 500;
    line-height: 22px;
    color: ${(props) => props.theme.black};
    text-align: left;
  }

  input {
    caret-color: #4556ee;
    height: 44px;
    width: 320px;
    padding: 0 18px;
    border-radius: 4px;
    border: 1px solid #e0e0e0;
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    color: #000;
    transition: border-color 300ms ease-in-out;

    &:focus,
    &:active {
      border: 1px solid #181818;
    }

    ::placeholder {
      font-family: "Noto Sans KR", sans-serif;
      font-weight: 500;
      font-size: 12px;
      line-height: 22px;
      color: #b1b1b1;
    }
  }
`;

export const registerCardBase = css`
  ${flexboxColumn("center", "center")};
  width: 738px;
  height: 744px;
  background: ${(props) => props.theme.white};
  border: 1px solid #dddddd;
  border-radius: 8px;
`;

export const scrollBase = css`
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background-color: transparent;
    transition: background-color 300ms ease-in-out;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }
`;

export const truncate = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const visuallyHidden = css`
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
`;

// ------------------------------------------------------------------------------ layout base

export const containerBase = css`
  width: 100vw;
  height: 100vh;
  max-width: 100vw;
  max-height: 100vh;
  overflow: hidden;
`;

export const sectionBase = css`
  width: calc(100% - 276px);
  background-color: #f2f2f2;
`;

export const canvasBase = css`
  ${scrollBase};
  overflow: auto;
  height: calc(100vh - 36px - 40px - 40px - 70px);
`;

export const contentBase = css`
  width: 100%;
  height: 100%;
  max-width: 1106px;
  max-height: 704px;
  overflow: auto;
`;

export const whiteCardBase = css`
  min-height: calc(100vh - 36px - 40px - 40px - 31px - 38px - 24px - 70px);
  padding: 24px 30px;
  background-color: #fff;
  border-radius: 8px;
`;

export const asideBase = css`
  flex-shrink: 0;
  flex-grow: 0;
  width: 276px;
  min-width: 276px;
  max-width: 276px;
  height: calc(100vh - 36px - 40px - 40px);
`;

// 테스트 페이지
export const testTextareaBase = css`
  resize: none;
  width: 100%;
  padding: 16px;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  color: #000000;
  transition: border-color 300ms ease-in-out;

  ::placeholder {
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    color: #b1b1b1;
  }

  &:not(:disabled):focus,
  &:not(:disabled):active {
    border: 1px solid #181818;
  }
`;
