import styled from "styled-components";
import { flexbox, flexboxColumn, truncate } from "styles/mixins";

export const Heading = styled.h4`
  ${flexbox("flex-start", "center")};
  height: 64px;
  padding: 0 24px;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #353535;
  border-bottom: 1px solid #f3f3f3;
`;

export const Content = styled.div`
  ${flexboxColumn("flex-start", "center")};
  padding: 22px;
  width: calc(100% - 2px);

  div,
  ul {
    width: 100%;
  }

  height: calc(100vh - 323px);
  overflow: auto;
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background-color: rgba(144, 144, 144, 0.3);
    transition: background-color 300ms ease-in-out;
  }

  ::-webkit-scrollbar-track {
    border-radius: 6px;
    background-color: transparent;
  }

  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }
`;

export const Image = styled.div`
  width: 230px;
  height: 230px;
  border-radius: 25px;
  margin: 0 0 22px 0;
  img {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 25px;
    object-fit: cover;
  }
`;

export const Name = styled.h4`
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  color: #353535;
  margin: 0 0 18px 0;
`;

export const InfoList = styled.dl`
  margin: 0 0 16px 0;
  > div {
    ${flexbox("flex-start", "center")};
    height: 30px;
    padding: 0 5px;
    border-bottom: 1px solid #d6d6d6;

    &:last-of-type {
      border-bottom: 1px solid transparent;
    }
  }

  dt {
    min-width: 44px;
    margin: 0 10px 0 0;
    font-weight: 400;
    font-size: 11px;
    line-height: 20px;
    color: #8b95a1;
  }

  dd {
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    color: #181818;
  }
`;

export const PersonalityList = styled.ul`
  ${flexbox("center", "center")};
  li {
    ${flexboxColumn("center", "center")};
    padding: 0 16px;
    border-right: 1px solid #f3f3f3;

    &:last-of-type {
      border-right: unset;
    }

    img {
      width: 40px;
      height: 40px;
      object-fit: contain;
    }

    span {
      ${truncate};
      font-weight: 500;
      font-size: 10px;
      line-height: 20px;
      color: #181818;
    }
  }
`;

export const InputWrapper = styled.div`
  ${flexbox("flex-start", "center")};
  margin: 0 0 8px 0;

  &:first-of-type {
    margin: 30px 0 8px 0;
  }

  label {
    margin: 0 23px 0 0;
    font-weight: 700;
    font-size: 13px;
    line-height: 50px;
    color: #000000;
  }
  input {
    height: 44px;
    width: 150px;
    padding: 0 18px;
    border-radius: 4px;
    border: 1px solid #e0e0e0;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #000000;
    transition: border-color 300ms ease-in-out;

    ::placeholder {
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: #b1b1b1;
    }

    &:focus {
      border: 1px solid #181818;
    }
  }
`;
