import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "components/register/Layout";
import Card from "components/register/Card";
import PasswordInput from "components/register/PasswordInput";
import NextBtn from "components/register/NextBtn";
import {
  Paragraph,
  Form,
  HelperText,
  CheckboxWrapper,
  Checkbox,
} from "./styles";
import register_progress_three from "assets/register_progress_three.svg";
import { passwordReg } from "utils/regex";

const Password = () => {
  let navigate = useNavigate();

  const [authData, setAuthData] = useState();

  useEffect(() => {
    const data = JSON.parse(window.localStorage.getItem("register"));
    if (data) {
      setAuthData(data);
    }
  }, []);

  const [isDisabled, setIsDisabled] = useState(false);

  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [isPasswordSame, setIsPasswordSame] = useState(true);
  const [checkOne, setCheckOne] = useState(true);
  const [checkTwo, setCheckTwo] = useState(true);

  // 비밀번호 validation
  useEffect(() => {
    if (password?.length > 0) {
      if (passwordReg.test(password)) {
        setIsPasswordValid(true);
      } else {
        setIsPasswordValid(false);
      }
    } else {
      // 수정 필요
      setIsPasswordValid(true);
    }
  }, [password]);

  // 비밀번호 일치 여부
  useEffect(() => {
    if (passwordConfirm?.length > 0) {
      if (password === passwordConfirm) {
        setIsPasswordSame(true);
      } else {
        setIsPasswordSame(false);
      }
    } else {
      setIsPasswordSame(true);
    }
  }, [password, passwordConfirm]);

  const containsInvalidCharacters = (inputValue) => {
    const invalidCharacters = ["?"];
    return invalidCharacters.some((char) => inputValue.includes(char));
  };

  const onChangePassword = (e) => {
    const inputValue = e.target.value;
    if (containsInvalidCharacters(inputValue)) {
      alert("비밀번호에 ? 특수문자를 사용할 수 없습니다.");
    } else {
      setPassword(e.target.value);
    }
  };

  const onChangePasswordConfirm = (e) => {
    setPasswordConfirm(e.target.value);
  };

  const onCheckOne = (e) => setCheckOne(e.target.checked);

  const onCheckTwo = (e) => setCheckTwo(e.target.checked);

  useEffect(() => {
    if (
      password.length > 0 &&
      passwordConfirm.length > 0 &&
      isPasswordValid &&
      isPasswordSame &&
      checkOne &&
      checkTwo
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [
    password,
    passwordConfirm,
    isPasswordValid,
    isPasswordSame,
    checkOne,
    checkTwo,
  ]);

  const onSubmit = (e) => {
    e.preventDefault();

    const data = JSON.parse(window.localStorage.getItem("register"));
    if (data) {
      const newData = { ...data, password, useTos: true, personInfoRule: true };
      window.localStorage.setItem("register", JSON.stringify(newData));
      navigate("/register/company-first");
    } else {
      // TODO
      // 만약 혹시나 로컬 스토리지에 값이 없다면 어떻게 처리할지
    }
  };

  return (
    <Layout>
      <Card>
        <img src={register_progress_three} alt="third progress" />
        <h1>이메일 인증에 성공하였습니다.</h1>
        <Paragraph>
          {authData && authData?.email ? authData.email : "-"}
        </Paragraph>
        <Form onSubmit={onSubmit}>
          <div>
            <div>
              <PasswordInput
                label="비밀번호를 입력하세요."
                value={password}
                onChange={onChangePassword}
                isValid={isPasswordValid}
              />
              {password?.length > 0 && isPasswordValid && (
                <HelperText isValid={isPasswordValid}>
                  사용 가능한 비밀번호 입니다!
                </HelperText>
              )}
              {password?.length > 0 ? (
                !isPasswordValid && (
                  <HelperText isValid={isPasswordValid}>
                    영문, 숫자와 특수문자를 포함한 8~16자리를 입력하세요.
                  </HelperText>
                )
              ) : (
                <HelperText isValid={true}>
                  영문, 숫자와 특수문자를 포함한 8~16자리를 입력하세요.
                </HelperText>
              )}
              <PasswordInput
                label="비밀번호 확인"
                value={passwordConfirm}
                onChange={onChangePasswordConfirm}
                isValid={isPasswordSame}
              />
              {passwordConfirm?.length > 0 && (
                <HelperText isValid={isPasswordSame}>
                  {isPasswordSame
                    ? "비밀번호가 일치합니다!"
                    : "비밀번호가 일치하지 않습니다."}
                </HelperText>
              )}
            </div>
            <CheckboxWrapper>
              <Checkbox>
                <div>
                  <input
                    type="checkbox"
                    id="service-terms"
                    checked={checkOne}
                    onChange={onCheckOne}
                  />
                  <label htmlFor="service-terms"></label>
                </div>
                <p>
                  I2B Campus의&nbsp;
                  <a
                    href="https://www.i2bcampus.com/8b032cf9-460b-4202-8135-1b5c3a3fc3fe"
                    target="_blank"
                    rel="noreferrer">
                    이용약관
                  </a>
                  에 동의합니다.
                </p>
              </Checkbox>
              <Checkbox>
                <div>
                  <input
                    type="checkbox"
                    id="privacy-policy"
                    checked={checkTwo}
                    onChange={onCheckTwo}
                  />
                  <label htmlFor="privacy-policy"></label>
                </div>
                <p>
                  I2B Campus의&nbsp;
                  <a
                    href="https://www.i2bcampus.com/1d222add-dfbf-4271-830b-84d79a229015"
                    target="_blank"
                    rel="noreferrer">
                    개인정보처리방침
                  </a>
                  에 동의합니다.
                </p>
              </Checkbox>
            </CheckboxWrapper>
          </div>
          <NextBtn type="submit" disabled={isDisabled}>
            다음으로
          </NextBtn>
        </Form>
      </Card>
    </Layout>
  );
};

export default Password;
