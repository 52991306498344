import { Wrapper } from "./styles";
const LinkInfo = ({ link }) => {
  return (
    <Wrapper>
      <p>프로토타입 직접 사용해보려면?</p>
      <a href={link} target="_blank">
        {link}
      </a>
    </Wrapper>
  );
};

export default LinkInfo;
