import styled from "styled-components";

export const Input = styled.input`
  caret-color: #4556ee;
  width: 100%;
  height: 34px;
  margin: 0 5px;
  padding: 0 15px;
  border: 1px solid #181818;
  border-radius: 4px;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
`;
