import styled from "styled-components";
import { flexbox, truncate } from "styles/mixins";
import ic_export from "assets/ic_export.svg";
import ic_x_modal_close from "assets/ic_x_modal_close.svg";
import ic_test_yes_active from "assets/ic_test_yes_active.svg";
import ic_test_no_active from "assets/ic_test_no_active.svg";

export const Container = styled.div`
  width: 1106px;
  height: 704px;
  background-color: #ffffff;
  border-radius: 8px;
`;

export const HeadingWrapper = styled.div`
  ${flexbox("flex-start", "center")};
  height: 77px;
  padding: 0 28px 0 34px;
  position: relative;

  h1 {
    font-weight: 700;
    font-size: 20px;
    line-height: 22px;
    color: #000000;
  }

  p {
    ${flexbox("flex-start", "center")};
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #7b8289;

    &::before {
      content: "";
      display: block;
      width: 1px;
      height: 14px;
      margin: 0 14px 0 16px;
      background-color: #7b8289;
    }
  }
`;

export const BtnWrapper = styled.div`
  position: absolute;
  top: 28px;
  right: 28px;
  ${flexbox("flex-start", "center")};

  button {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
  }

  button:first-of-type {
    width: 16px;
    height: 18px;
    margin: 0 30px 0 0;
    background-image: url(${ic_export});

    &:disabled {
      pointer-events: none;
      cursor: default;
      opacity: 0.6;
    }
  }
  button:last-of-type {
    width: 17px;
    height: 17px;
    margin: 0;
    background-image: url(${ic_x_modal_close});
  }
`;

export const Table = styled.table`
  width: 1106px;

  thead {
    display: block;
    width: 100%;
  }

  tbody {
    display: block;
    max-height: 577px;
    overflow-y: auto;
  }

  tr {
    display: table;
    width: 100%;
  }

  th,
  td {
    vertical-align: middle;
    text-align: center;
  }

  td.age {
    text-align: center;
  }

  tr th:first-of-type,
  tr td:first-of-type {
    width: 15%;
    /* max-width: 167px; */
  }

  tr th:nth-of-type(2),
  tr td:nth-of-type(2) {
    width: 10%;
    /* max-width: 66px; */
  }

  tr th:nth-of-type(3),
  tr td:nth-of-type(3) {
    width: 6%;
    /* max-width: 66px; */
  }

  tr th:nth-of-type(4),
  tr td:nth-of-type(4) {
    width: 202px;
    max-width: 202px;
  }
  tr td:nth-of-type(4) {
    ${truncate};
  }

  tr th:nth-of-type(5),
  tr td:nth-of-type(5) {
    width: 11%;
    /* max-width: 120px; */
  }

  tr th:nth-of-type(6),
  tr td:nth-of-type(6) {
    // widtth: 44%;
    /* max-width: 485px; */
  }

  tr td:nth-of-type(6) {
    text-align: left;
  }

  td.with-icon {
    img {
      width: 15px;
      height: 15px;
      margin: 0 4px 0 0;
      position: relative;
      top: 3px;
    }
  }
`;

export const FeedbackTable = styled.table`
  width: 1106px;

  thead {
    display: block;
    width: 100%;
  }

  tbody {
    display: block;
    max-height: 577px;
    overflow-y: auto;
  }

  tr {
    display: table;
    width: 100%;
  }

  th,
  td {
    vertical-align: middle;
    text-align: center;
  }

  td.age {
    text-align: center;
  }

  tr th:first-of-type,
  tr td:first-of-type {
    width: 18%;
  }

  tr td:first-of-type {
    text-align: center;
  }

  tr th:nth-of-type(2),
  tr td:nth-of-type(2) {
    width: 300px;
  }

  tr td:nth-of-type(2) {
    text-align: left;
  }

  tr th:nth-of-type(3),
  tr td:nth-of-type(3) {
    width: 16%;
  }

  tr th:nth-of-type(4),
  tr td:nth-of-type(4) {
    /* width: 44%; */
  }

  tr td:nth-of-type(4) {
    text-align: left;
  }

  td.with-icon {
    img {
      width: 15px;
      height: 15px;
      margin: 0 4px 0 0;
      position: relative;
      top: 3px;
    }
  }
`;

export const Thead = styled.thead`
  display: table; //tr이랑 같은 너비 차지하도록
  width: 100%;
  height: 50px;
  padding: 0 12px;
  background-color: #181818;
  color: #fff;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;

  th {
    height: 50px;
    position: relative;
    text-align: center;

    :not(:last-of-type)::after {
      content: "";
      display: inline-block;
      width: 1px;
      height: 18px;
      background-color: rgba(255, 255, 255, 0.2);
      position: absolute;
      right: 0;
      top: 16px;
    }
  }
`;

export const Tbody = styled.tbody`
  display: block; // 세로 스크롤 가능하도록
  max-height: 577px;
  overflow-y: auto;
  padding: 0 0 0 12px;
  background-color: #fff;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  color: #353535;

  tr:not(:last-of-type) {
    border-bottom: 1px solid #e0e0e0;
  }

  tr,
  td {
    min-height: 82px;
  }

  ::-webkit-scrollbar {
    width: 12px;
  }

  ::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
    border-left: 4px solid #fff;
    border-radius: 6px;
    background-color: rgba(144, 144, 144, 0.3);
  }
`;

export const RadioResponse = styled.div`
  padding: 0 0 0 5px;
  background-image: url(${(props) => (props.bg ? ic_test_yes_active : ic_test_no_active)});
  background-repeat: no-repeat;
  background-size: 15px 15px;
  background-position: 37px 4px;
`;
