import { Container, CloseBtn, Heading, InfoText, InputWrapper, ButtonWrapper, PDFBtn, ConfirmBtn } from "./styles";

const FinishModalContent = ({ selectedStatus, onChange, onClickClose, onClickConfirm }) => {
  return (
    <Container>
      <CloseBtn type="button" onClick={onClickClose}></CloseBtn>
      <Heading>Project를 끝내시겠습니까?</Heading>
      <InfoText>추가 단계를 진행하여 더 나은 제품/서비스를 만들 수 있습니다</InfoText>
      <InputWrapper>
        <input type="radio" name="project-status" id="version-up" value="version-up" onChange={onChange} checked={"version-up" === selectedStatus} />
        <label htmlFor="version-up">
          <span>
            <strong>심기일전! 한번 더 진행해봅시다!💪</strong>
          </span>
          <span>원하는 시장 반응을 얻지 못하셨나요? 한번 더 진행할 수 있어요!</span>
        </label>
      </InputWrapper>

      <InputWrapper>
        <input type="radio" name="project-status" id="complete" value="complete" onChange={onChange} checked={"complete" === selectedStatus} />
        <label htmlFor="complete">
          <span>
            <strong>연습 끝! 실전단계, 제대로 개발해볼까요?👩‍💻</strong>
          </span>
          <span>MVP과정을 토대로 실제 프로젝트를 개발할 업체를 연결해드립니다!</span>
        </label>
      </InputWrapper>

      <InputWrapper>
        <input type="radio" name="project-status" id="stop" value="stop" onChange={onChange} checked={"stop" === selectedStatus} />
        <label htmlFor="stop">
          <span>
            <strong>아쉽지만 여기서 마무리할까요?😢</strong>
          </span>
          <span>도전은 언제나 가치있습니다!</span>
        </label>
      </InputWrapper>

      <ButtonWrapper>
        {/* <PDFBtn type="button">
          <span>PDF 저장하기</span>
        </PDFBtn> */}
        <ConfirmBtn type="button" onClick={onClickConfirm} disabled={!selectedStatus}>
          확인
        </ConfirmBtn>
      </ButtonWrapper>
    </Container>
  );
};

export default FinishModalContent;
