import styled from "styled-components";
import { flexboxColumn } from "styles/mixins";

const Div = styled.div`
  position: relative;
  ${flexboxColumn};
  flex-grow: 1;
  padding: 32px 57px 25px;
  background-color: #fff;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
`;

const TabContent = ({ children }) => {
  return <Div>{children}</Div>;
};

export default TabContent;
