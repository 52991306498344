import styled from "styled-components";
import { scrollBase } from "styles/mixins";

export const ImageWrapper = styled.div`
  position: relative;
  width:1106px;
  height704px;
  margin:0 auto;
  overflow: hidden;
  border-radius:8px;

  img {
    width:1106px;
    height:704px;
    object-fit: cover;
    border-radius:8px;

  }

  h3 {
    position: absolute;
    top: 30px;
    left: 34px;
    font-weight: 700;
    font-size: 22px;
    line-height: 24px;
    color: #4556ee;
  }
  h4 {
    position: absolute;
    top: 60px;
    left: 34px;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    color: #808080;
  }
`;

export const ModelCardWrapper = styled.div`
  ${scrollBase};
  height: calc(100vh - 36px - 40px - 40px - 150px);
  padding: 5px 7px 5px 12px;
  margin: 10px 4px 0 0;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background-color: rgba(144, 144, 144, 0.3);
    transition: background-color 300ms ease-in-out;
  }

  ::-webkit-scrollbar-track {
    border-radius: 6px;
    background-color: transparent;
  }

  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }
`;
