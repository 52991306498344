import { useState, useEffect } from "react";
import { Wrapper, ImageWrapper, ImageWrapperProfile, WrapperProfile } from "./styles";

const imageMimeType = /image\/(svg|png|jpg|jpeg)/i;

const UploadPhoto = ({ file, setFile, imgSrc, isViewMode, deleteCustomerPhoto, profile }) => {
  const [fileDataURL, setFileDataURL] = useState(null);
  const [isImg, setIsImg] = useState(imgSrc);

  useEffect(() => {
    if (imgSrc) {
      setIsImg(imgSrc);
    }
  }, [imgSrc]);

  const onChange = (e) => {
    const file = e.target.files[0];
    if (!file.type.match(imageMimeType)) {
      // 파일 체크
      return;
    }
    setFile(file);
  };

  useEffect(() => {
    let fileReader,
      isCancel = false;
    if (file) {
      fileReader = new FileReader();
      fileReader.onload = (e) => {
        const { result } = e.target;
        if (result && !isCancel) {
          setFileDataURL(result);
        }
      };
      fileReader.onabort = () => {
        console.log("abort!");
      };
      fileReader.onerror = () => {
        console.log("error!");
      };

      fileReader.readAsDataURL(file);
    }
    return () => {
      isCancel = true;
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort();
      }
    };
  }, [file]);

  //const onClickDelete = () => setFile(null);
  //const setFileInput = () => setIsImg(false);

  const onClickDelete = () => {
    //이미지를 선택해서 받아왔을때 x버튼 호출
    setFile(null);
    //deleteCustomerPhoto();
  };

  const setFileInput = () => {
    //서버에서 이미지를 받아왔을때 x버튼 호출
    setIsImg(false);
    deleteCustomerPhoto(); //서버에 저장된 이미지 삭제호출
  };

  return (
    <>
      {profile ? (
        !file && isImg ? (
          <ImageWrapperProfile>
            <img src={isImg} alt="preview" referrerPolicy="no-referrer" />
            <button type="button" onClick={setFileInput}></button>
          </ImageWrapperProfile>
        ) : file ? (
          <ImageWrapperProfile>
            <img src={fileDataURL} alt="preview" referrerPolicy="no-referrer" />
            <button type="button" onClick={onClickDelete}></button>
          </ImageWrapperProfile>
        ) : (
          <WrapperProfile>
            <input type="file" id="customer-pic" onChange={onChange} accept="image/*" disabled={isViewMode} />
            <label htmlFor="customer-pic"></label>
          </WrapperProfile>
        )
      ) : !file && isImg ? (
        <ImageWrapper>
          <img src={isImg} alt="preview" referrerPolicy="no-referrer" />
          <button type="button" onClick={setFileInput}></button>
        </ImageWrapper>
      ) : file ? (
        <ImageWrapper>
          <img src={fileDataURL} alt="preview" referrerPolicy="no-referrer" />
          <button type="button" onClick={onClickDelete}></button>
        </ImageWrapper>
      ) : (
        <Wrapper>
          <input type="file" id="customer-pic" onChange={onChange} accept="image/*" disabled={isViewMode} />
          <label htmlFor="customer-pic"></label>
        </Wrapper>
      )}
    </>
  );
};

export default UploadPhoto;
