import styled from "styled-components";
import ic_reset_round_big from "assets/ic_reset_round_big.svg";

export const Heading = styled.h1`
  width: 100%;
  padding: 110px 0 0 0 !important;
  background-image: url(${ic_reset_round_big});
  background-size: 231px 57px;
  background-repeat: no-repeat;
  background-position: 124px 102px;
`;
