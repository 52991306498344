import styled from "styled-components";
import { flexbox, flexboxColumn } from "styles/mixins";
import ic_checkbox_blue from "assets/ic_checkbox_blue.svg";
import ic_checkbox_gray from "assets/ic_checkbox_gray.svg";

export const TemplateHeading = styled.div`
  width: 100%;
  height: 33px;
  padding: 7px 0 6px 22px;
  background-color: #d9d9d9;
  border-left: 1px solid #e0e0e0;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  color: #353535;
`;

export const TemplateList = styled.ul`
  /* ${flexbox("flex-start", "center")}; */
  /* flex-wrap: wrap;
  gap: 22px 11px; */
  margin: 6px 0;
  padding: 20px 12px 30px 22px;

  height: calc(100vh - 36px - 40px - 40px - 126px - 45px);
  overflow-x: hidden;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-thumb {
    border: 2px solid #fff;
    background-clip: padding-box;
    border-radius: 6px;
    background-color: rgba(144, 144, 144, 0.3);
  }
`;

export const TemplateItem = styled.li`
  ${flexboxColumn};
  margin: 0 0 22px 0;
`;

export const BtnWrapper = styled.div`
  ${flexbox("flex-start", "center")};
  margin: 0 0 6px 0;

  button {
    display: block;
    width: 18px;
    height: 18px;
    margin: 0 6px 0 0;
    background-image: url(${(props) => (props.isChecked ? ic_checkbox_blue : ic_checkbox_gray)});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    pointer-events: ${(props) => (props.isDisabled ? "none" : "auto")};

    &:disabled {
      cursor: default;
    }
  }

  h3 {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #000000;
    opacity: ${(props) => (props.isDisabled ? "0.2" : "1")};
    transition: opacity 300ms ease-in-out;
  }
`;

export const ImgBtn = styled.div`
  display: block;
  width: 206px;
  height: 448px;
  position: relative;
  opacity: ${(props) => (props.isDisabled ? "0.2" : "1")};
  transition: opacity 300ms ease-in-out;

  button {
    display: block;
    width: 206px;
    height: 448px;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: ${(props) => (props.isDisabled ? "none" : "auto")};

    &:disabled {
      cursor: default;
    }
  }

  img {
    width: 206px;
    height: 448px;
  }
`;
