import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const useExpertMode = () => {
  const [isExpertMode, setIsExpertMode] = useState(false);
  useEffect(() => {
    const role = window.localStorage.getItem("role");
    if (role && role === "ROLE_EXPERT") {
      setIsExpertMode(true);
    } else {
      setIsExpertMode(false);
    }
  }, []);
  return isExpertMode;
};

export default useExpertMode;
