import styled from "styled-components";
import ic_personal_0 from "assets/ic_personal_0.svg";
import ic_personal_1 from "assets/ic_personal_1.svg";
import ic_personal_2 from "assets/ic_personal_2.svg";
import ic_personal_3 from "assets/ic_personal_3.svg";
import ic_personal_4 from "assets/ic_personal_4.svg";
import ic_personal_5 from "assets/ic_personal_5.svg";
import ic_personal_6 from "assets/ic_personal_6.svg";
import ic_personal_7 from "assets/ic_personal_7.svg";
import ic_personal_8 from "assets/ic_personal_8.svg";
import ic_personal_9 from "assets/ic_personal_9.svg";
import ic_personal_10 from "assets/ic_personal_10.svg";
import ic_personal_11 from "assets/ic_personal_11.svg";
import ic_personal_0_active from "assets/ic_personal_0_active.svg";
import ic_personal_1_active from "assets/ic_personal_1_active.svg";
import ic_personal_2_active from "assets/ic_personal_2_active.svg";
import ic_personal_3_active from "assets/ic_personal_3_active.svg";
import ic_personal_4_active from "assets/ic_personal_4_active.svg";
import ic_personal_5_active from "assets/ic_personal_5_active.svg";
import ic_personal_6_active from "assets/ic_personal_6_active.svg";
import ic_personal_7_active from "assets/ic_personal_7_active.svg";
import ic_personal_8_active from "assets/ic_personal_8_active.svg";
import ic_personal_9_active from "assets/ic_personal_9_active.svg";
import ic_personal_10_active from "assets/ic_personal_10_active.svg";
import ic_personal_11_active from "assets/ic_personal_11_active.svg";

export const Wrapper = styled.div`
  input[type="checkbox"] {
    width: 0;
    height: 0;
    position: absolute;
    left: -9999px;
  }

  input[type="checkbox"] + label {
    position: relative;
    display: block;
    width: 50px;
    height: 70px;
    padding: 50px 0 0 0;
    font-weight: 500;
    font-size: 10px;
    line-height: 20px;
    text-align: center;
    color: #b1b1b1;
    cursor: pointer;
    transition: color 300ms ease-in-out, font-weight 300ms ease-in-out, opacity 300ms ease-in-out;
    background-repeat: no-repeat;
    background-size: 50px 50px;
    background-position: top center;
  }

  input[type="checkbox"]:checked + label {
    font-weight: 700;
    color: #000000;
  }

  input[type="checkbox"]:not(:checked) + label {
    pointer-events: ${(props) => (props.isMax ? "none" : "initial")};
    opacity: ${(props) => (props.isMax ? "0.3" : "1")};
  }

  &:first-of-type {
    input[type="checkbox"] + label {
      background-image: url(${ic_personal_0});
    }
    input[type="checkbox"]:checked + label {
      background-image: url(${ic_personal_0_active});
    }
  }

  &:nth-of-type(2) {
    input[type="checkbox"] + label {
      background-image: url(${ic_personal_1});
    }
    input[type="checkbox"]:checked + label {
      background-image: url(${ic_personal_1_active});
    }
  }

  &:nth-of-type(3) {
    input[type="checkbox"] + label {
      background-image: url(${ic_personal_2});
    }
    input[type="checkbox"]:checked + label {
      background-image: url(${ic_personal_2_active});
    }
  }

  &:nth-of-type(4) {
    input[type="checkbox"] + label {
      background-image: url(${ic_personal_3});
    }
    input[type="checkbox"]:checked + label {
      background-image: url(${ic_personal_3_active});
    }
  }

  &:nth-of-type(5) {
    input[type="checkbox"] + label {
      background-image: url(${ic_personal_4});
    }
    input[type="checkbox"]:checked + label {
      background-image: url(${ic_personal_4_active});
    }
  }

  &:nth-of-type(6) {
    input[type="checkbox"] + label {
      background-image: url(${ic_personal_5});
    }
    input[type="checkbox"]:checked + label {
      background-image: url(${ic_personal_5_active});
    }
  }

  &:nth-of-type(7) {
    input[type="checkbox"] + label {
      background-image: url(${ic_personal_6});
    }
    input[type="checkbox"]:checked + label {
      background-image: url(${ic_personal_6_active});
    }
  }

  &:nth-of-type(8) {
    input[type="checkbox"] + label {
      background-image: url(${ic_personal_7});
    }
    input[type="checkbox"]:checked + label {
      background-image: url(${ic_personal_7_active});
    }
  }

  &:nth-of-type(9) {
    input[type="checkbox"] + label {
      background-image: url(${ic_personal_8});
    }
    input[type="checkbox"]:checked + label {
      background-image: url(${ic_personal_8_active});
    }
  }

  &:nth-of-type(10) {
    input[type="checkbox"] + label {
      background-image: url(${ic_personal_9});
    }
    input[type="checkbox"]:checked + label {
      background-image: url(${ic_personal_9_active});
    }
  }

  &:nth-of-type(11) {
    input[type="checkbox"] + label {
      background-image: url(${ic_personal_10});
    }
    input[type="checkbox"]:checked + label {
      background-image: url(${ic_personal_10_active});
    }
  }

  &:last-of-type {
    input[type="checkbox"] + label {
      background-image: url(${ic_personal_11});
    }
    input[type="checkbox"]:checked + label {
      background-image: url(${ic_personal_11_active});
    }
  }

  input[type="checkbox"]:disabled + label {
    cursor: default;
  }
`;
