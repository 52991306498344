import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axiosPrivate from "utils/axiosPrivate";
import { TabContainer } from "pages/test/form/styles";
import TabTag from "components/test/TabTag";
import ProjectInfo from "components/review/ProjectInfo";
import QuestionMain from "components/test/QuestionMain";
import ResponseBtn from "components/review/ResponseBtn";
import Pie from "components/review/Graph/Pie";
import { pieFrequencyDefaultData } from "utils/data";
const ReviewFrequency = ({ isShowing, testInfo, onClickMoreResponse }) => {
  const { id: projectId } = useParams();

  const [data, setData] = useState();

  useEffect(() => {
    axiosPrivate
      .get(`api/v1/projects/${projectId}/review/dashboard`, {
        params: {
          testSurveyType: "EXPECTED_FREQUENCY_USE",
        },
      })
      .then((res) => {
        if (res.data?.result?.answers?.length > 0) {
          setData(res.data.result.answers);
        } else {
          setData(pieFrequencyDefaultData);
        }
      })
      .catch((err) => {
        console.log(err);
        setData(pieFrequencyDefaultData);
      });
  }, []);

  // 답변 전체보기 모달
  const onClickResponse = () => {
    onClickMoreResponse("EXPECTED_FREQUENCY_USE");
  };

  return (
    <TabContainer isShowing={isShowing}>
      <ProjectInfo testInfo={testInfo} />
      <TabTag text="예상 사용 빈도" />
      <QuestionMain text="얼마나 자주 사용할 것 같나요?" />
      <ResponseBtn onClick={onClickResponse} />
      {data && <Pie data={data} />}
    </TabContainer>
  );
};

export default ReviewFrequency;
