import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "components/register/Layout";
import ResetCard from "components/reset-password/ResetCard";
import PasswordInput from "components/register/PasswordInput";
import NextBtn from "components/register/NextBtn";
import { Heading, Form, HelperText } from "./styles";
import { passwordReg } from "utils/regex";
import axiosPublic from "utils/axiosPublic";

const ResetPassword = () => {
  let navigate = useNavigate();

  const [isDisabled, setIsDisabled] = useState(false);

  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [isPasswordSame, setIsPasswordSame] = useState(true);

  // 비밀번호 validation
  useEffect(() => {
    if (password?.length > 0) {
      if (passwordReg.test(password)) {
        setIsPasswordValid(true);
      } else {
        setIsPasswordValid(false);
      }
    } else {
      // 수정 필요
      setIsPasswordValid(true);
    }
  }, [password]);

  // 비밀번호 일치 여부
  useEffect(() => {
    if (passwordConfirm?.length > 0) {
      if (password === passwordConfirm) {
        setIsPasswordSame(true);
      } else {
        setIsPasswordSame(false);
      }
    } else {
      setIsPasswordSame(true);
    }
  }, [password, passwordConfirm]);

  const onChangePassword = (e) => {
    setPassword(e.target.value);
  };
  const onChangePasswordConfirm = (e) => {
    setPasswordConfirm(e.target.value);
  };

  useEffect(() => {
    if (password.length > 0 && passwordConfirm.length > 0 && isPasswordValid && isPasswordSame) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [password, passwordConfirm, isPasswordValid, isPasswordSame]);

  const onSubmit = (e) => {
    e.preventDefault();
    const data = { password };

    axiosPublic
      .patch(`/api/v1/open/auth/${window.localStorage.getItem("uid")}/password`, data)
      .then((res) => {
        navigate("/reset/complete");
      })
      .catch((err) => {
        console.log(err);
        window.alert("비밀번호 변경에 실패했습니다. 다시 시도해주세요!");
      });
  };

  return (
    <Layout>
      <ResetCard>
        <Heading>새로운 비밀번호를 입력해주세요!</Heading>
        <Form onSubmit={onSubmit}>
          <div>
            <PasswordInput label="비밀번호를 입력하세요." value={password} onChange={onChangePassword} isValid={isPasswordValid} />
            {password?.length > 0 && isPasswordValid && <HelperText isValid={isPasswordValid}>사용 가능한 비밀번호 입니다!</HelperText>}
            {password?.length > 0 && !isPasswordValid && <HelperText isValid={isPasswordValid}>영문, 숫자와 특수문자를 포함한 8~16자리를 입력하세요.</HelperText>}
            <PasswordInput label="비밀번호 확인" value={passwordConfirm} onChange={onChangePasswordConfirm} isValid={isPasswordSame} />
            {passwordConfirm?.length > 0 && <HelperText isValid={isPasswordSame}>{isPasswordSame ? "비밀번호가 일치합니다!" : "비밀번호가 일치하지 않습니다."}</HelperText>}
          </div>
          <NextBtn type="submit" disabled={isDisabled}>
            다음으로
          </NextBtn>
        </Form>
      </ResetCard>
    </Layout>
  );
};

export default ResetPassword;
