import styled from "styled-components";
import profile_plan_start from "assets/profile_plan_start.svg";
import { flexbox, flexboxColumn } from "styles/mixins";
import { TextInput } from "../styles";
import { EmailText } from "pages/register/BaseInfo/styles";

export const FlexWrapper = styled.div`
  ${flexbox("flex-start", "center")};
`;

export const HeadingWrapper = styled.div`
  ${flexbox("flex-start", "center")};
  margin: 58px 0 32px;
`;

export const Avatar = styled.div`
  width: 106px;
  height: 106px;
  border: 2px solid #fff;
  margin: 0 19px 0 0;
  border-radius: 50%;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.12));

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
`;

export const UserName = styled.h2`
  margin: 0 0 6px 0;
  font-weight: 700;
  font-size: 26px;
  line-height: 36px;
  color: #000000;
`;

export const UploadBtn = styled.div`
  margin: 0 6px 0 0;
  input[type="file"] {
    position: absolute;
    width: 0;
    height: 0;
    padding: 0;
    overflow: hidden;
    border: 0;
  }

  label {
    display: block;
    height: 30px;
    padding: 4px 12px;
    font-weight: 700;
    font-size: 12px;
    line-height: 22px;
    text-align: center;
    color: #ffffff;
    background-color: #4556ee;
    border-radius: 100px;
    cursor: pointer;
  }
`;

export const AvatarDelBtn = styled.button`
  ${flexbox("center", "center")};
  height: 30px;
  padding: 0 12px;
  border-radius: 100px;
  border: 1px solid #f3f3f3;
  background-color: #f3f3f3;
  font-weight: 700;
  font-size: 12px;
  line-height: 22px;
  color: #000000;
  transition: background-color 300ms ease-in-out, color 300ms ease-in-out, border-color 300ms ease-in-out;

  &:disabled {
    color: #d6d6d6;
    border: 1px solid #e0e0e0;
    background-color: #fff;
    cursor: default;
  }
`;

// 이메일 변경
export const EmailBtnWrapper = styled.div`
  ${flexbox("flex-start", "center")}
  margin: 6px 0 0 92px;

  > button:first-of-type {
    margin: 0 6px 0 0;
  }
`;

export const EmailChangeBtn = styled.button`
  ${flexbox("center", "center")};
  height: 30px;
  padding: 0 12px;
  font-weight: 700;
  font-size: 12px;
  line-height: 22px;
  color: #000000;
  background-color: #f3f3f3;
  border-radius: 100px;
  transition: background-color 300ms ease-in-out, color 300ms ease-in-out;
`;

export const AuthRequestBtn = styled(EmailChangeBtn)`
  background-color: #4556ee;
  color: #fff;

  &:disabled {
    background-color: #f3f3f3;
    color: #d6d6d6;
  }
`;

export const AuthNumBtnWrapper = styled.div`
  ${flexbox("flex-start", "center")};
  margin: 0 0 32px 90px !important;
  button {
    ${flexbox("center", "center")};
    height: 30px;
    padding: 0 12px;
    border-radius: 100px;
    font-weight: 700;
    font-size: 12px;
    line-height: 22px;
  }

  button:first-of-type {
    margin: 0 6px 0 0;
    background-color: #4556ee;
    color: #ffffff;
  }

  button:nth-of-type(2) {
    background-color: #f3f3f3;
    color: #000000;
  }
`;

export const EmailHelperText = styled(EmailText)`
  margin: 4px 0 6px 92px;
  color: ${(props) => (props.isValid ? "#4556EE" : "#CE5E41")};
`;

// 비밀번호
export const PasswordInputBox = styled(TextInput)`
  ${flexbox("flex-start", "flex-start")};
  position: relative;
  margin: 22px 0 0 0;
`;

export const PasswordInputWrapper = styled.div`
  ${flexboxColumn};
`;

export const PwdChangeBtn = styled.button`
  ${flexbox("center", "center")};
  height: 30px;
  padding: 0 12px;
  background-color: #f3f3f3;
  border-radius: 100px;
  font-weight: 700;
  font-size: 12px;
  line-height: 22px;
  color: #000;

  &.cancel {
    margin: 6px 0 0 0;
  }
`;

export const PwdHelperText = styled.p`
  margin: 4px 0 0 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  color: ${(props) => (props.isValid ? "#4556EE" : "#CE5E41")};
`;

export const PhoneHelperText = styled(PwdHelperText)`
  margin: 0 0 0 92px;
`;
//
export const Plan = styled.div`
  ${flexbox};
  width: 100%;
  margin: 30px 0 41px 0;

  p {
    width: 80px;
    max-width: 80px;
    margin: 0 12px 0 0;
    font-weight: 700;
    font-size: 13px;
    line-height: 20px;
    color: #727682;
  }

  > div {
    ${flexboxColumn};
    width: 300px;
    height: 100px;
    padding: 31px 24px 28px 113px;

    border-radius: 4px;
    background-color: #e2814a;
    background-image: url(${profile_plan_start});
    background-repeat: no-repeat;
    background-position: 24px center;
    color: #000;

    strong {
      font-family: "Poppins", sans-serif;
      font-weight: 700;
      font-size: 20px;
      line-height: 20px;
      margin: 0 0 3px 0;
    }
    span {
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
    }
  }
`;

export const SaveBtn = styled.button`
  display: block;
  width: 392px;
  height: 44px;
  background-color: #181818;
  border-radius: 100px;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
`;

export const DeleteBtn = styled.button`
  display: block;
  margin: 11px auto 70px;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  text-decoration: underline;
  color: #b1b1b1;
`;
