import styled from "styled-components";
import ic_review_arrow_left from "assets/ic_review_arrow_left.svg";
import ic_review_arrow_right from "assets/ic_review_arrow_right.svg";
import { flexbox } from "styles/mixins";

export const Container = styled.div`
  margin: 40px 0 0 0;
`;

export const BtnWrapper = styled.div`
  ${flexbox("flex-start", "center")};
  margin: 0 0 20px 0;

  button {
    position: relative;
    top: 1px;
    display: block;
    width: 6px;
    height: 12px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 6px 12px;
    &:first-of-type {
      background-image: url(${ic_review_arrow_left});
      margin: 0 14px 0 0;
    }

    &:last-of-type {
      background-image: url(${ic_review_arrow_right});
      margin: 0 0 0 14px;
    }
  }
`;

export const IndexWrapper = styled.div`
  ${flexbox("flex-start", "center")};

  font-weight: 500;
  font-size: 15px;
  line-height: 24px;

  p:first-of-type {
    color: #000000;
  }

  p:nth-of-type(2),
  p:last-of-type {
    color: #808080;
  }

  p:nth-of-type(2) {
    margin: 0 5px;
  }
`;

export const Text = styled.p`
  width: 652px;
  height: 250px;
  padding: 16px;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  color: #000000;
`;
