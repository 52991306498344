import styled from "styled-components";
import { flexbox } from "styles/mixins";

export const Heading = styled.h3`
  margin: 55px 0 34px;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
`;
export const FormWrapper = styled.div`
  margin: 0 0 191px 0;
`;
export const FlexWrapper = styled.div`
  ${flexbox("flex-start", "center")};
  margin: 0 0 30px 0;
  &:last-of-type {
    margin: 0;
  }
`;

export const Label = styled.label`
  width: 198px;
  flex-grow: 0;
  flex-shrink: 0;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #353535;
`;

export const TextInput = styled.input`
  display: block;
  height: 44px;
  width: 320px;
  caret-color: #4556ee;
  padding: 0 18px;
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  color: #000000;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  transition: border-color 300ms ease-in-out;

  &:focus,
  &:active {
    border: 1px solid #181818;
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;

  &:disabled {
    pointer-events: none;
  }
`;

// 성별
export const Radio = styled.div`
  margin: 0 10px 0 0;

  &:last-of-type {
    margin: 0;
  }

  input[type="radio"] {
    width: 0;
    height: 0;
    position: absolute;
    left: -9999px;
  }

  input[type="radio"] + label {
    ${flexbox("center", "center")};
    width: 100px;
    height: 44px;
    border-radius: 4px;
    border: 1px solid #e0e0e0;
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    text-align: center;
    color: #b1b1b1;
    transition: border-color 300ms ease-in-out, font-weight 300ms ease-in-out, color 300ms ease-in-out;
    cursor: pointer;
  }

  input[type="radio"]:checked + label {
    border: 1px solid #181818;
    font-weight: 700;
    color: #000000;
  }
`;
