import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  const token = localStorage.getItem("accessToken");
  const url = new URL(window.location.href);
  const urlParams = url.searchParams;
  const urlViewMode = urlParams.get("viewMode");
  if (!token) {
    if (urlViewMode === "true") {
      return children;
    }
    return <Navigate to="/" replace />;
  }

  return children;
};

export default ProtectedRoute;
