import { useRef } from "react";
import { HeadingWrapper, BtnWrapper } from "../ResponseModalContent/styles";
import { Container, ContentWrapper, ImageWrapper, MentorTag, Profile, MentorName, MentorInfo, Textarea, SaveBtn } from "./styles";
import expert_image_three from "assets/expert_image_three.svg";
import { useReactToPrint } from "react-to-print";

const FeedbackModalContent = ({ feedbackData, feedbackText, onChangeFeedbackText, isExpertMode, onClickClose, onSubmitFeedback, isViewMode }) => {
  // pdf 추출
  const printRef = useRef();

  const onClickPdf = useReactToPrint({ content: () => printRef.current });
  return (
    <Container ref={printRef}>
      <HeadingWrapper>
        <h1>멘토 피드백</h1>
        <BtnWrapper>
          <button type="button" onClick={onClickPdf}></button>
          <button type="button" onClick={onClickClose}></button>
        </BtnWrapper>
      </HeadingWrapper>
      <ContentWrapper>
        <Profile>
          <ImageWrapper>
            <img src={feedbackData?.headshot ?? expert_image_three} alt="mentor default" referrerPolicy="no-referrer" />
            <MentorTag>
              {feedbackData?.expertType === "MENTOR" ? "멘토" : feedbackData?.expertType === "DESIGNER" ? "디자이너" : feedbackData?.expertType === "MANAGER" ? "매니저" : "전문가"}
            </MentorTag>
          </ImageWrapper>
          <ul>
            <MentorName>{feedbackData?.expertName ?? "전문가 이름"}</MentorName>
            <MentorInfo>{feedbackData?.expertPhone ?? "전문가 연락처"}</MentorInfo>
            <MentorInfo>{feedbackData?.expertEmail ?? "전문가 이메일"}</MentorInfo>
          </ul>
        </Profile>
        <form onSubmit={onSubmitFeedback}>
          {isExpertMode ? (
            <Textarea isExpertMode={isExpertMode} value={feedbackText} onChange={onChangeFeedbackText} disabled={isViewMode}></Textarea>
          ) : (
            <Textarea disabled="true" value={feedbackData?.feedback} isExpertMode={isExpertMode}></Textarea>
          )}
          {isExpertMode && (
            <SaveBtn type="submit" disabled={isViewMode}>
              저장
            </SaveBtn>
          )}
        </form>
      </ContentWrapper>
    </Container>
  );
};

export default FeedbackModalContent;
