import styled from "styled-components";
import { flexbox } from "styles/mixins";

export const Button = styled.button`
  ${flexbox("center", "center")};
  position: absolute;
  right: 18px;
  width: 22px;
  height: 10px;
  border-radius: 6px;
  cursor: pointer;
  background-color: ${(props) => (props.blocked ? "#D9D9D9" : "#4556EE")};
  transition: all 300ms ease-in-out;

  &:disabled {
    cursor: default;
  }
`;

export const Circle = styled.div`
  width: 12px;
  height: 12px;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  position: absolute;
  left: 0;
  transform: ${(props) => (props.blocked ? "translate3d(0,0,0)" : "translate3d(10px,0,0)")};
  transition: all 300ms ease-in-out;
`;
