import styled from "styled-components";
import { flexbox, flexboxColumn } from "styles/mixins";
import ic_expert_mentor from "assets/ic_expert_mentor.svg";

export const Container = styled.div`
  width: 1106px;
  height: 704px;
  background-color: #ffffff;
  border-radius: 8px;
  overflow: hidden;
`;

export const ContentWrapper = styled.div`
  ${flexbox};
  height: 626px;
  border-top: 1px solid #e3e3e3;
`;

export const Profile = styled.div`
  ${flexboxColumn("flex-start", "center")};
  width: 298px;
  height: 100%;
  flex-grow: 0;
  flex-shrink: 0;
  padding: 34px;
  background-color: #f2f2f2;
`;

export const ImageWrapper = styled.div`
  position: relative;
  width: 230px;
  height: 230px;
  margin: 0 0 14px 0;
  border-radius: 25px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 25px;
  }
`;

export const MentorTag = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
  ${flexbox("flex-start", "center")};
  min-width: 66px;
  height: 26px;
  padding: 0 10px 0 30px;
  background-color: #70becd;
  border-radius: 20px;
  background-image: url(${ic_expert_mentor});
  background-repeat: no-repeat;
  background-position: 12px center;
  background-size: 13px 13px;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
`;

export const MentorName = styled.li`
  margin: 0 0 13px 0;
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  color: #000000;
  text-align: center;
`;

export const MentorInfo = styled.li`
  ${flexbox("center", "center")};
  height: 32px;
  width: 230px;
  background-color: #ffffff;
  border-radius: 35px;
  margin: 0 0 8px 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #000000;
`;

export const Textarea = styled.textarea`
  width: 740px;
  height: ${(props) => (props.isExpertMode ? "503px" : "555px")};
  padding: 16px;
  margin: 34px 34px 0;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  color: #000000;
  overflow-x: hidden;
  overflow-y: auto;
  resize: none;

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border: 2px solid #fff;
    background-clip: padding-box;
    border-radius: 6px;
    background-color: rgba(144, 144, 144, 0.3);
  }
`;

export const SaveBtn = styled.button`
  ${flexbox("center", "center")};
  width: 104px;
  height: 36px;
  margin: 14px 34px 0 auto;
  font-size: 14px;
  line-height: 22px;
  color: #ffffff;
  background-color: #181818;
  border-radius: 100px;

  &:disabled {
    cursor: default;
    background-color: #f3f3f3;
    color: #d6d6d6;
  }
`;
