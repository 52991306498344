import styled from "styled-components";
import { testTextareaBase } from "styles/mixins";

export const Label = styled.label`
  display: block;
  margin: 58px 0 8px 0;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #353535;
`;

export const Textarea = styled.textarea`
  ${testTextareaBase};
  height: 300px;
  margin: 0 0 28px 0;
`;

export const IntroText = styled.div`
  height: 300px;
  margin: 0 0 28px 0;
  padding: 16px;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  color: #000000;
`;

export const Guide = styled.div`
  h4 {
    margin: 0 0 5px 0;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    color: #353535;
  }

  li {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #727272;

    span {
      color: #ce5e41;
    }
  }
`;
