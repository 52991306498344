import styled from "styled-components";
import { flexbox } from "styles/mixins";
import ic_help from "assets/ic_help.svg";

export const Container = styled.div`
  padding: 17px 18px 20px;
  border-bottom: ${(props) => (props.hasNoBorder ? "1px solid transparent" : "1px solid #f3f3f3")};
`;

export const HeadingWrapper = styled.div`
  ${flexbox("space-between", "center")};
  margin: 0 0 16px 0;
`;

export const Heading = styled.h2`
  font-weight: 700;
  font-size: 18px;
  line-height: 36px;
  color: ${(props) => props.theme.black};
`;

export const HelpBtn = styled.button`
  position: relative;
  top: 1px;
  background-image: url(${ic_help});
  background-size: 15px 19px;
  background-repeat: no-repeat;
  background-position: left 1px;
  padding: 0 0 0 20px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
`;

export const Paragraph = styled.p`
  padding: 0 4px 0 2px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #353535;
`;
