import styled from "styled-components";
import ic_checkbox_blue from "assets/ic_checkbox_blue.svg";
import ic_checkbox_gray from "assets/ic_checkbox_gray.svg";
import { flexbox, truncate } from "styles/mixins";

export const Wrapper = styled.button`
  display: block;
  width: 240px;
  padding: 6px 0;
  border-bottom: 1px solid #f3f3f3;
  transition: opacity 300ms ease-in-out;

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
  }
`;

export const Content = styled.div`
  position: relative;
  padding: 14px 8px 7px 10px;
  border-radius: 8px;
  background-color: ${(props) => (props.isChecked ? " #FBFCFF" : "#fff")};
  border: ${(props) => (props.isChecked ? "1px solid #4556ee" : "1px solid transparent")};
  transition: background-color 300ms ease-in-out, border-color 300ms ease-in-out;

  > div.checkbox {
    display: block;
    position: absolute;
    top: 6px;
    right: 6px;
    width: 18px;
    height: 18px;
    background-image: url(${(props) => (props.isChecked ? ic_checkbox_blue : ic_checkbox_gray)});
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
  }
`;

export const Heading = styled.div`
  ${flexbox("flex-start", "center")};
  margin: 0 0 11px 0;
  img {
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 4px;
    margin: 0 6px 0 0;
  }

  h5 {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #353535;
  }
`;

export const KeyList = styled.ul`
  ${flexbox("flex-start", "center")};
  gap: 6px 8px;
  flex-wrap: wrap;
  margin: 0 0 10px 0;

  li {
    height: 26px;
    width: 68px;
    padding: 2px 9px 0;
    border: 1px solid #d6d6d6;
    border-radius: 20px;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 20px;
    text-align: center;
    color: #000000;
    ${truncate};
  }
`;

export const InfoList = styled.dl`
  ${flexbox("flex-start", "center")};
  margin: 0 0 9px 0;

  > div {
    ${flexbox};
    flex: 1;

    :first-of-type,
    :nth-of-type(2) {
      ::after {
        content: "";
        width: 1px;
        height: 23px;
        background-color: #d6d6d6;
        margin: auto;
      }
    }
  }

  dt {
    min-width: 68px;
    font-weight: 400;
    font-size: 11px;
    line-height: 20px;
    text-align: center;
    color: #8b95a1;
  }

  dd {
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    color: #000000;
  }
`;

export const Update = styled.p`
  font-weight: 500;
  font-size: 9px;
  line-height: 20px;
  text-align: right;
  color: #8b95a1;
`;

export const StarIcon = styled.img`
  width: 10px;
  height: 10px;
  position: relative;
  top: 1px;
  left: 1px;
`;
