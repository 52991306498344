import styled from "styled-components";
import ic_customer_photo_default from "assets/ic_customer_photo_default.svg";
import ic_x_grey from "assets/ic_x_grey.svg";

export const Wrapper = styled.div`
  input[type="file"] {
    position: absolute;
    width: 0;
    height: 0;
    padding: 0;
    overflow: hidden;
    border: 0;
  }

  input[type="file"] + label {
    display: block;
    width: 300px;
    height: 300px;
    background: #f6f6f6;
    border: 1px solid #e0e0e0;
    border-radius: 25px;
    background-image: url(${ic_customer_photo_default});
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: 240px 264px;
    cursor: pointer;
  }

  input[type="file"]:disabled + label {
    cursor: default;
  }
`;

export const WrapperProfile = styled.div`
  input[type="file"] {
    position: absolute;
    width: 0;
    height: 0;
    padding: 0;
    overflow: hidden;
    border: 0;
  }

  input[type="file"] + label {
    display: block;
    width: 220px;
    height: 220px;
    background: #f6f6f6;
    border: 1px solid #e0e0e0;
    border-radius: 25px;
    background-image: url(${ic_customer_photo_default});
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: 220px 210px;
    cursor: pointer;
  }

  input[type="file"]:disabled + label {
    cursor: default;
  }
`;
export const ImageWrapper = styled.div`
  position: relative;
  width: 300px;
  height: 300px;
  border-radius: 25px;
  border: 1px solid #e0e0e0;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 25px;
  }

  button {
    display: none;
    position: absolute;
    top: 14px;
    right: 14px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #f3f3f3;
    background-image: url(${ic_x_grey});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 8px 8px;
    z-index: 1;
  }

  &:hover button {
    display: block;
  }

  &:hover {
    background: unset;
    opacity: unset;
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 300px;
    height: 300px;
    border-radius: 25px;
    opacity: 0;
    background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 27.08%);
    -webkit-transition: opacity 1s;
    -moz-transition: opacity 1s;
    -ms-transition: opacity 1s;
    -o-transition: opacity 1s;
    transition: opacity 1s;
  }

  &:hover::after {
    opacity: 0.5;
    background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 27.08%);
  }
`;

export const ImageWrapperProfile = styled.div`
  position: relative;
  width: 220px;
  height: 220px;
  border-radius: 25px;
  border: 1px solid #e0e0e0;

  img {
    width: 222px;
    height: 222px;
    object-fit: cover;
    border-radius: 24px;
  }

  button {
    display: none;
    position: absolute;
    top: 14px;
    right: 14px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #f3f3f3;
    background-image: url(${ic_x_grey});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 8px 8px;
    z-index: 1;
  }

  &:hover button {
    display: block;
  }

  &:hover {
    background: unset;
    opacity: unset;
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 25px;
    opacity: 0;
    background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 27.08%);
    -webkit-transition: opacity 1s;
    -moz-transition: opacity 1s;
    -ms-transition: opacity 1s;
    -o-transition: opacity 1s;
    transition: opacity 1s;
  }

  &:hover::after {
    opacity: 0.5;
    background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 27.08%);
  }
`;
