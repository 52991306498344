import { useState } from "react";
import LinkInfo from "components/test/LinkInfo";
import { testerGender } from "utils/data";
import { TabContainer } from "../../../../pages/test/form/styles";
import { Heading, FormWrapper, FlexWrapper, Label, TextInput, Radio } from "./styles";
import { useEffect } from "react";
import { PREVIEW, TESTER } from "utils/data";
import PrevNextBtn from "components/test/PrevNextBtn";
import TesterPrevNextBtn from "components/test/TesterPrevNextBtn";
import patchTesterAnswer from "utils/patchTesterAnswer";

const Tester = ({ isShowing, status, link, projectId, testerUid, savedAnswer }) => {
  const [inputs, setInputs] = useState({
    name: "",
    gender: "",
    age: "",
  });
  const { name, gender, age } = inputs;

  useEffect(() => {
    if (status === TESTER && savedAnswer) {
      const { genderType, testerAge, testerName } = savedAnswer;
      if (genderType?.length > 0) setInputs((prev) => ({ ...prev, gender: genderType }));
      if (testerAge) setInputs((prev) => ({ ...prev, age: testerAge }));
      if (testerName?.length > 0) setInputs((prev) => ({ ...prev, name: testerName }));
    }
  }, [savedAnswer]);

  const onChangeInputs = async (e) => {
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    const dataKey = e.target.name === "name" ? "testerName" : e.target.name === "gender" ? "genderType" : "testerAge";
    try {
      const res = await patchTesterAnswer(projectId, testerUid, dataKey, e.target.value, "INPUT_TESTER_INFO");
      console.log("input res", res);
    } catch (err) {
      // console.log("input err", err);
      window.alert("테스터 정보 저장 실패 ");
      // 서버에 테스터 정보 저장 실패하는 경우, next 버튼 disable
      setIsNextDisabled(true);
    }
  };

  // 버튼 disable
  const [isNextDisabled, setIsNextDisabled] = useState(true);
  useEffect(() => {
    if (name && gender && age) {
      setIsNextDisabled(false);
    } else {
      setIsNextDisabled(true);
    }
  }, [name, gender, age]);

  return (
    <TabContainer isShowing={isShowing}>
      <LinkInfo link={link} />
      <Heading>테스터 정보 입력</Heading>
      <FormWrapper>
        <FlexWrapper>
          <Label htmlFor="name">귀하의 성함을 입력하세요</Label>
          <div>
            <TextInput type="text" id="name" name="name" value={name} onChange={onChangeInputs} disabled={status === PREVIEW} />
          </div>
        </FlexWrapper>

        <FlexWrapper>
          <Label>귀하의 성별을 선택하세요</Label>
          {testerGender.map((item, i) => (
            <Radio key={i}>
              <input type="radio" name="gender" id={item.text} value={item.value} checked={item.value === gender} onChange={onChangeInputs} disabled={status === PREVIEW} />
              <label htmlFor={item.text}>{item.text}</label>
            </Radio>
          ))}
        </FlexWrapper>

        <FlexWrapper>
          <Label htmlFor="age">귀하의 연령을 입력하세요</Label>
          <TextInput type="number" id="age" name="age" value={age} onChange={onChangeInputs} disabled={status === PREVIEW} />
        </FlexWrapper>
      </FormWrapper>
      {status === PREVIEW && <PrevNextBtn />}
      {status === TESTER && <TesterPrevNextBtn isNextDisabled={isNextDisabled} />}
    </TabContainer>
  );
};

export default Tester;
