import { useState, useMemo } from "react";
import { closestCenter, DndContext, KeyboardSensor, MouseSensor, TouchSensor, useSensor, useSensors } from "@dnd-kit/core";
import { arrayMove, SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import SortableItem from "../SortableItem";
import { Wrapper, OpinionItem } from "./styles";

const TabList = ({ tabs, setTabs, onClick, currentTabId, onToggleTab, isViewMode }) => {
  // dnd
  const [activeId, setActiveId] = useState();
  const items = useMemo(() => tabs?.map(({ id }) => id), [tabs]);

  const sensors = useSensors(useSensor(MouseSensor, {}), useSensor(TouchSensor, {}), useSensor(KeyboardSensor, {}));

  const handleDragStart = (e) => setActiveId(e.active.id);
  const handleDragEnd = (e) => {
    const { active, over } = e;
    if (active.id !== over.id) {
      setTabs((tabs) => {
        const oldIndex = items.indexOf(active.id);
        const newIndex = items.indexOf(over.id);
        return arrayMove(tabs, oldIndex, newIndex);
      });
    }
    setActiveId(null);
  };

  const handleDragCancel = () => setActiveId(null);

  return (
    <Wrapper>
      <DndContext sensors={sensors} onDragEnd={handleDragEnd} onDragStart={handleDragStart} onDragCancel={handleDragCancel} collisionDetection={closestCenter}>
        <SortableContext items={items} strategy={verticalListSortingStrategy}>
          {tabs.map((item, i) => (
            <SortableItem
              key={item.id}
              id={item.id}
              data={item}
              itemIndex={i}
              isCurrent={currentTabId === item.id}
              currentTab={currentTabId}
              onClick={onClick}
              onToggleTab={onToggleTab}
              className={item.blocked ? "disabled" : ""}
              isViewMode={isViewMode}
            />
          ))}
        </SortableContext>
      </DndContext>
      <OpinionItem id="9" onClick={onClick} isCurrent={currentTabId === 9}>
        <div>
          <p>Q.8</p>
          <p>종합 의견</p>
        </div>
      </OpinionItem>
    </Wrapper>
  );
};

export default TabList;
