const convertImgToFile = async (layoutItem) => {
  const { layoutImage, fileName, type } = layoutItem;
  return fetch(layoutImage)
    .then(function (res) {
      return res.arrayBuffer();
    })
    .then(function (buf) {
      return new File([buf], fileName, { type });
    });
};

export default convertImgToFile;
