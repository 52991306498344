import styled from "styled-components";

const Heading = styled.h2`
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #000000;

  span {
    font-family: "Poppins", sans-serif;
  }
`;

const QuestionMain = ({ text }) => {
  return (
    <Heading>
      <span>Q. </span>
      {text}
    </Heading>
  );
};

export default QuestionMain;
