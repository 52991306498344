import { useState, useEffect } from "react";
import ProjectCard from "../ProjectCard";
import { List } from "./styles";

const ProjectList = ({ projects, filter = "all", onClickDelete, onClickName, onClickStop }) => {
  const modifiedList = [...projects]
    .filter((item) => {
      if (item.projectStatusType === "PROCEEDING" && item.viewMode === true) {
        return false;
      } else {
        return true;
      }
    })
    .sort((a, b) => new Date(a.projectStartDate) - new Date(b.projectStartDate));

  const [list, setList] = useState([...modifiedList]);

  // useEffect(() => {
  //   let filteredList;
  //   if (filter === "all") {
  //     filteredList = [...projects];
  //   } else {
  //     filteredList = [...projects].filter((item) => item.projectStatusType === filter);
  //   }
  //   setList(filteredList);
  // }, [filter, projects]);

  useEffect(() => {
    let filteredList;
    if (filter === "all") {
      filteredList = [...modifiedList];
    } else {
      filteredList = [...modifiedList].filter((item) => item.projectStatusType === filter);
    }
    setList(filteredList);
  }, [filter, projects]);

  return (
    <List>
      {list.map((item, i) => (
        <ProjectCard key={`${i}${new Date()}`} item={item} onClickDelete={onClickDelete} onClickName={onClickName} onClickStop={onClickStop} />
      ))}
    </List>
  );
};

export default ProjectList;
