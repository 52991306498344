import { useState, useEffect } from "react";
import { Input, Button, Self } from "./styles";

const SelfInput = ({ selfArr, setSelfArr, isMax, isViewMode }) => {
  const [isSelf, setIsSelf] = useState(false);

  const onClickSelfBtn = () => {
    setIsSelf(true);
  };

  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      if (e.target.value === "") {
        setIsSelf(false);
      } else {
        setSelfArr((prev) => [...prev, e.target.value]);
        setIsSelf(false);
      }
    } else {
      return;
    }
  };
  const onBlur = (e) => {
    if (e.target.value === "") {
      setIsSelf(false);
    } else {
      setSelfArr((prev) => [...prev, e.target.value]);
      setIsSelf(false);
    }
  };

  const onClickDelete = (e) => {
    const target = e.target.parentElement.firstChild.innerText;
    const arr = [...selfArr];
    const newArr = arr.filter((item) => item !== target);
    setSelfArr(newArr);
  };

  return (
    <>
      {selfArr &&
        selfArr.map((item, i) => (
          <Self key={i} className="self-input-div">
            <span> {item}</span>
            <button type="button" onClick={onClickDelete}></button>
          </Self>
        ))}
      {!isSelf && (
        <Button
          type="button"
          onClick={onClickSelfBtn}
          disabled={isMax || isViewMode}>
          직접 입력
        </Button>
      )}
      {isSelf && <Input type="text" onKeyDown={onKeyDown} onBlur={onBlur} />}
    </>
  );
};

export default SelfInput;
