import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axiosPublic from "utils/axiosPublic";
import Layout from "components/register/Layout";
import Card from "components/register/Card";
import NextBtn from "components/register/NextBtn";
import register_progress_one from "assets/register_progress_one.svg";
import { emailReg, phoneReg } from "utils/regex";
import {
  Form,
  Heading,
  InputWrapper,
  Input,
  EmailText,
  HelperWrapper,
} from "./styles";

const Register = () => {
  let navigate = useNavigate();
  let regExp = /[-]/gi;

  // 인풋값 상태 관리
  const [state, setState] = useState({
    email: "",
    name: "",
    phone: "",
  });
  const { email, name, phone } = state;
  const onChangeInput = (e) => {
    const inputValue = e.target.value;
    const trimmedValue = inputValue.replace(phoneReg, "");
    if (trimmedValue?.length > 10) {
      setIsPhoneValid(true);
    } else {
      setIsPhoneValid(false);
    }
    setState((prev) => ({
      ...prev,
      [e.target.name]:
        e.target.name === "phone"
          ? e.target.value.replace(regExp, "")
          : e.target.value,
    }));
  };

  // 이메일 형태 유효성 검사
  const [isEmailValid, setIsEmailValid] = useState(false);
  useEffect(() => {
    if (email?.length > 0) {
      if (emailReg.test(email)) {
        setIsEmailValid(true);
      } else {
        setIsEmailValid(false);
      }
    } else {
      setIsEmailValid(false);
    }
  }, [email]);

  // 이메일 이미 존재하는지 체크
  const [hasEmailChecked, setHasEmailChecked] = useState(false);
  const [isEmailPresent, setIsEmailPresent] = useState(false);

  // 이메일 존재 여부 검사
  const onBlurEmail = (e) => {
    // 이메일 형식이 유효한 경우에만 중복 확인  체크
    if (isEmailValid) {
      setHasEmailChecked(false);
      setIsEmailPresent(false);

      axiosPublic
        .get("/api/v1/open/email/duplicate-check", {
          params: {
            email: e.target.value,
          },
        })
        .then((resp) => setIsEmailPresent(false))
        .catch((err) => setIsEmailPresent(true))
        .finally(() => {
          setHasEmailChecked(true);
        });
    }
  };

  // 이메일 존재 여부 검사 후 다시 이메일 수정하는 경우
  useEffect(() => {
    if (hasEmailChecked) {
      setHasEmailChecked(false);
    }
  }, [email]);

  // 이메일 인풋창 보더 색상
  const [hasError, setHasError] = useState(false);
  useEffect(() => {
    if (email.length > 0) {
      if (!isEmailValid || isEmailPresent) {
        setHasError(true);
      } else {
        setHasError(false);
      }
    } else {
      setHasError(false);
    }
  }, [email, isEmailValid, isEmailPresent]);

  // 연락처 하이픈 없애기
  const [isPhoneValid, setIsPhoneValid] = useState();
  const onBlurPhone = (e) => {
    const inputValue = e.target.value;
    const trimmedValue = inputValue.replace(phoneReg, "");
    if (trimmedValue?.length > 10) {
      setIsPhoneValid(true);
      setState((prev) => ({ ...prev, phone: trimmedValue }));
    } else {
      setIsPhoneValid(false);
    }
  };

  // 모든 인풋창에 값을 입력해야 '다음으로'버튼 클릭 가능
  const [isDisabled, setIsDisabled] = useState(true);
  useEffect(() => {
    if (
      isEmailValid &&
      !isEmailPresent &&
      email.length > 0 &&
      name.length > 0 &&
      phone.length > 0 &&
      isPhoneValid
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [email, isEmailValid, isEmailPresent, name, phone, isPhoneValid]);

  const onSubmit = (e) => {
    e.preventDefault();

    const data = {
      email,
      phone,
      name,
    };

    window.localStorage.setItem("register", JSON.stringify(data));

    navigate("/register/auth");
  };

  return (
    <>
      <Layout>
        <Card>
          <img src={register_progress_one} alt="first register progress" />
          <Heading>
            안녕하세요! <br />
            <span className="eng">I2B Campus</span>에 오신걸 환영합니다!
          </Heading>
          <Form onSubmit={onSubmit}>
            <div>
              <InputWrapper>
                <Input className="email" hasError={hasError}>
                  <label>사용하실 이메일을 입력하세요.</label>
                  <input
                    type="text"
                    name="email"
                    value={email}
                    onChange={onChangeInput}
                    onBlur={onBlurEmail}
                  />
                </Input>

                {email?.length > 0 && !isEmailValid && (
                  <EmailText>잘못된 형식입니다.</EmailText>
                )}

                {hasEmailChecked && isEmailPresent && (
                  <EmailText>
                    이미 가입된 이메일입니다.{" "}
                    <Link to="/reset/email">비밀번호 찾기</Link>
                  </EmailText>
                )}

                {hasEmailChecked && !isEmailPresent && (
                  <EmailText className="blue">
                    사용 가능한 이메일입니다.
                  </EmailText>
                )}

                <Input className="name">
                  <label>이름을 입력하세요.</label>
                  <input
                    type="text"
                    name="name"
                    value={name}
                    onChange={onChangeInput}
                    maxLength="50"
                  />
                </Input>

                <Input className="phone">
                  <label>연락처를 입력하세요 (숫자 11자리)</label>
                  <input
                    type="text"
                    maxLength={11}
                    name="phone"
                    value={phone}
                    onChange={onChangeInput}
                    onBlur={onBlurPhone}
                  />
                </Input>
                {phone?.length > 0 && !isPhoneValid && (
                  <EmailText>잘못된 형식입니다. (숫자 11자리)</EmailText>
                )}
              </InputWrapper>

              <HelperWrapper>
                <p>이미 계정이 있으신가요?</p>
                <Link to="/">로그인하기</Link>
              </HelperWrapper>
            </div>
            <NextBtn type="submit" disabled={isDisabled}>
              다음으로
            </NextBtn>
          </Form>
        </Card>
      </Layout>
    </>
  );
};

export default Register;
