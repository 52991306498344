import Nav from "layouts/Nav";
import PageNav from "layouts/PageNav";
import { Container, ContentWrapper } from "./styles";

const Layout = ({ onClickPdf, onSave, children, isViewMode }) => {
  return (
    <Container>
      <Nav onSave={onSave} isViewMode={isViewMode} />
      <main>
        <PageNav onClickPdf={onClickPdf} onSave={onSave} isViewMode={isViewMode} />
        <ContentWrapper>{children}</ContentWrapper>
      </main>
    </Container>
  );
};

export default Layout;
