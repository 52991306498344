import styled from "styled-components";
import ic_arrow_up_black from "assets/ic_arrow_up_black.svg";
import { flexbox } from "styles/mixins";

export const CollapseWrapper = styled.div`
  .ant-collapse {
  }

  .ant-collapse-borderless {
    background-color: #fff;
  }

  .ant-collapse > .ant-collapse-item,
  .ant-collapse-borderless > .ant-collapse-item {
    border-bottom: 1px solid #f3f3f3;
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    color: #353535;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
  }

  .ant-collapse-item {
    position: relative;
  }

  .ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header {
    ${flexbox("flex-start", "center")};
    padding: 0 24px;
    height: 64px;
  }

  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
  }
`;

export const Icon = styled.img.attrs({
  src: `${ic_arrow_up_black}`,
})`
  width: 12px;
  height: 6px;
  transform: ${(props) => (props.isActive ? "rotate(180deg) translateY(-50%)" : "rotate(0deg) translateY(-50%)")} !important;
  transition: transform 300ms ease-in-out;
`;

export const ExtraDesc = styled.div`
  position: absolute;
  top: 45px;
  left: 0;
  padding: 0 3px 22px 4px;
  margin: 0 20px;
  border-bottom: 1px solid #f3f3f3;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #8b95a1;
  background-color: #fff;
`;

// 직접 추가하기
export const SelfContent = styled.div`
  padding: 15px 13px 22px 24px;
  margin: 35px 0 0 0;

  h4 {
    margin: 0 0 10px 0;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    color: #353535;
  }
`;

export const CheckboxWrapper = styled.div`
  ${flexbox("flex-start", "center")};
  flex-wrap: wrap;
  gap: 6px 8px;

  // common checkbox 다른 곳에서는 높이가 32px이지만 여기서는 28px;
  input[type="checkbox"] + label {
    height: 28px;
  }

  // 직접 입력 버튼 - 분석 페이지에서는 높이가 32px이지만 여기서는 28px
  button {
    height: 28px;
  }

  input[type="text"] {
    height: 28px;
  }

  .self-input-div {
    height: 28px;
  }
`;

// 선택하기

export const SelectContent = styled.div`
  height: calc(100vh - 36px - 40px - 40px - 295px);
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 10px 0 18px;
  margin: 35px 0 20px 0;

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-thumb {
    border: 2px solid #fff;
    background-clip: padding-box;
    border-radius: 6px;
    background-color: rgba(144, 144, 144, 0.3);
  }
`;
