import { useState } from "react";
import { Container, BtnWrapper, IndexWrapper, Text } from "./styles";
const ResponseText = ({ data }) => {
  const [currentResponseIndex, setCurrentResponseIndex] = useState(0);

  const showPrevResponse = () => {
    if (currentResponseIndex !== 0) {
      setCurrentResponseIndex((prev) => prev - 1);
    } else {
      setCurrentResponseIndex(0);
    }
  };

  const showNextResponse = () => {
    if (currentResponseIndex !== data?.length - 1) {
      setCurrentResponseIndex((prev) => prev + 1);
    } else {
      setCurrentResponseIndex(data?.length - 1);
    }
  };

  return (
    <Container>
      <BtnWrapper>
        <button type="button" onClick={showPrevResponse} disabled={currentResponseIndex === 0}></button>
        <IndexWrapper>
          <p>{currentResponseIndex + 1}</p>
          <p>/</p>
          <p>{data?.length}</p>
        </IndexWrapper>
        <button type="button" onClick={showNextResponse} disabled={currentResponseIndex === data.length - 1}></button>
      </BtnWrapper>
      <Text>{data[currentResponseIndex].answer}</Text>
    </Container>
  );
};

export default ResponseText;
