import styled from "styled-components";
import { flexbox } from "styles/mixins";
import register_logo from "assets/register_logo.svg";

export const Div = styled.main`
  ${flexbox("center", "center")};
  width: 100vw;
  min-height: 100vh;
  background-color: #f6dd66;
  background-image: url(${register_logo});
  background-position: 30px 30px;
  background-size: 180px 60px;
  background-repeat: no-repeat;
`;
