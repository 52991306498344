import styled from "styled-components";

export const StyledAside = styled.aside`
	position: relative;
	flex-shrink: 0;
	flex-grow: 0;
	width: 276px;
	min-width: 276px;
	max-width: 276px;
	height: calc(100vh - 36px - 40px - 40px);
	border-left: 1px solid #e0e0e0;
`;
