import { List, Item } from "./styles";

const TagList = ({ arr, randomBgArr }) => {
  return (
    <List>
      {arr.map((item, i) => (
        <Item key={i} bg={randomBgArr[i]}>
          {item.keywordName}
        </Item>
      ))}
    </List>
  );
};

export default TagList;
