import styled from "styled-components";
import { flexbox, flexboxColumn, whiteCardBase } from "styles/mixins";

export const WhiteCard = styled.div`
  ${whiteCardBase};
  ${flexbox};
`;

export const Left = styled.div`
  width: 328px;
  padding: 27px 20px 27px 8px;
  flex-grow: 0;
  flex-shrink: 0;
`;

export const Right = styled.div`
  flex-grow: 1;
`;

export const PersonalityWrapper = styled.div`
  margin: 0 0 19px 0;
  > label {
    ${flexbox("flex-start", "center")};
    margin: 0 0 18px 0;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    color: #000000;

    span {
      margin: 0 0 0 2px;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      color: rgba(0, 0, 0, 0.4);
    }
  }
  > div {
    ${flexbox};
    flex-wrap: wrap;
    gap: 8px;
  }
`;

export const InputWrapper = styled.div`
  ${flexbox("flex-start", "center")};
  margin: 0 0 8px 0;

  &:first-of-type {
    margin: 30px 0 8px 0;
  }

  label {
    margin: 0 23px 0 0;
    font-weight: 700;
    font-size: 13px;
    line-height: 50px;
    color: #000000;
  }
  input {
    height: 44px;
    width: 253px;
    padding: 0 18px;
    border-radius: 4px;
    border: 1px solid #e0e0e0;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #000000;
    transition: border-color 300ms ease-in-out;

    ::placeholder {
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: #b1b1b1;
    }

    &:focus {
      border: 1px solid #181818;
    }
  }
`;

export const TextareaWrapper = styled.div`
  ${flexboxColumn};

  margin: 0 0 18px 0;
  &:last-of-type {
    margin: 0;
  }

  label {
    margin: 0 0 8px 0;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    color: #000000;
  }

  textarea {
    width: 100%;
    height: 118px;
    padding: 16px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    resize: none;
    overflow: hidden;
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    color: #000000;
    transition: border-color 300ms ease-in-out;

    &:focus {
      border: 1px solid #181818;
    }

    ::placeholder {
      font-weight: 500;
      font-size: 12px;
      line-height: 22px;
      color: #b1b1b1;
    }
  }
`;
