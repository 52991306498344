import { useNavigate } from "react-router-dom";
import { useState, useRef, useEffect } from "react";
import { Item, ItemLink, ItemHeader, MenuWrapper, MenuBtn, MenuList, MenuItem, MemberWrapper, Member, ItemContent, Version, Title, Duration, DefList, ItemButton } from "./styles";
import { PROCEEDING, STOP, COMPLETION } from "utils/data";

import { useRecoilState } from "recoil";
import { recoilProjectState } from "states/recoilProjectState";

import expert_image_three from "assets/expert_image_three.svg";

const ProjectCard = ({ item, onClickDelete, onClickName, onClickStop }) => {
  const navigate = useNavigate();
  const { projectStatusType, version, name, projectStartDate, projectEndDate, testCount, id: projectId, expertHeadshots } = item;

  const getProgress = (projectStatusType) => {
    if (projectStatusType === PROCEEDING) {
      return "진행 중";
    } else if (projectStatusType === COMPLETION) {
      return "완료";
    } else if (projectStatusType === STOP) {
      return "중단됨";
    }
  };

  // TODO : item이 없는 경우, 즉 아직 관리자 페이지에서 이미지를 등록하지 않은 전문가는 기획팀에서 지정해준 이미지 넣기
  const getAvatars = (members) => {
    const maxArr = [...members].slice(0, 4);
    return maxArr?.map((item, i) => {
      return (
        <Member key={i} index={members.length - i}>
          <img src={item ?? expert_image_three} alt="expert avatar" referrerPolicy="no-referrer" />
        </Member>
      );
    });
  };

  // 드롭다운 메뉴 열고 닫기
  const [isMenuVisible, setIsMenuVisible] = useState(false);

  const onClickMenuBtn = (e) => {
    e.preventDefault();
    setIsMenuVisible((prev) => !prev);
  };

  // 드롭다운 외부 영역 클릭 시 드롭다운 닫기
  const menuRef = useRef(null);
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isMenuVisible && menuRef.current && !menuRef.current.contains(e.target)) {
        setIsMenuVisible(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isMenuVisible]);

  const [_, setProjectId] = useRecoilState(recoilProjectState);

  const onClickItemLink = () => {
    setProjectId((prev) => {
      const prevState = { ...prev };
      prevState.projectId = projectId;
      return { ...prevState };
    });
    navigate(`/analysis/summary/${projectId}`);
  };

  const onClickStopBtn = () => {
    onClickStop(projectId);
  };

  const onClickDeleteBtn = () => {
    onClickDelete(projectId);
  };

  return (
    <Item style={{ marginBottom: "50px" }}>
      <ItemLink>
        <ItemButton onClick={onClickItemLink} />
        <ItemHeader projectStatusType={projectStatusType}>
          <span>{getProgress(projectStatusType)}</span>
          <MenuWrapper ref={menuRef}>
            <MenuBtn type="button" onClick={onClickMenuBtn}></MenuBtn>
            <MenuList isActive={isMenuVisible}>
              <MenuItem>
                <button type="button" onClick={onClickDeleteBtn}>
                  프로젝트 삭제
                </button>
              </MenuItem>
              <MenuItem>
                <button type="button" onClick={(e) => onClickName(e, projectId)}>
                  프로젝트 이름 변경
                </button>
              </MenuItem>
              {projectStatusType === "PROCEEDING" && (
                <MenuItem>
                  <button type="button" onClick={onClickStopBtn}>
                    프로젝트 중단
                  </button>
                </MenuItem>
              )}
            </MenuList>
          </MenuWrapper>
        </ItemHeader>
        <MemberWrapper>{expertHeadshots?.length > 0 ? getAvatars(expertHeadshots) : null}</MemberWrapper>
        <ItemContent>
          <div>
            <Version>ver. {version}</Version>
            <Title>{name ? name : "Untitled"}</Title>
            <Duration>
              {projectStartDate} - {projectEndDate}
            </Duration>
          </div>
          <DefList>
            <div>
              <dt>프로덕트</dt>
              <dd>모바일</dd>
            </div>
            <div>
              <dt>테스트</dt>
              <dd>{testCount === 0 ? "-" : `${testCount}명 진행 완료`}</dd>
            </div>
          </DefList>
        </ItemContent>
      </ItemLink>
    </Item>
  );
};

export default ProjectCard;
