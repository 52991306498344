import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  > div {
    width: 100%;
  }
`;

export const Label = styled.label`
  margin: 0 0 4px 0;
  font-weight: 500;
  font-size: 13px;
  line-height: 22px;
  color: #000;
`;

export const Radio = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 6px 8px;

  > div {
    flex: 1;
  }

  input[type="radio"] {
    width: 0;
    height: 0;
    position: absolute;
    left: -9999px;
  }

  input[type="radio"] + label {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    min-width: 101px;
    border: 1px solid #e0e0e0;
    border-radius: 20px;
    background-color: #fff;
    color: #000000;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    transition: border-color 300ms ease-in-out, background-color 300ms ease-in-out, font-weight 300ms ease-in-out;
    cursor: pointer;
  }

  input[type="radio"]:checked + label {
    font-weight: 700;
    background-color: ${(props) => (props.isBlue ? "#8FD3DF" : "#F6DD66")};
    border-color: ${(props) => (props.isBlue ? "#8FD3DF" : "#F6DD66")};
    z-index: 1;
  }
`;
