import Layout from "components/register/Layout";
import ResetCard from "components/reset-password/ResetCard";
import { Heading } from "./styles";
import { StyledLink } from "pages/register/Finish/styles";

const ResetComplete = () => {
  return (
    <Layout>
      <ResetCard>
        <Heading>
          비밀번호 재설정이 완료되었습니다!
          <br />
          다시 로그인해 주세요!
        </Heading>
        <StyledLink to="/">로그인 화면으로</StyledLink>
      </ResetCard>
    </Layout>
  );
};

export default ResetComplete;
