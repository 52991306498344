import { DefaultCard, ImageCard } from "./styles";
import mock_feature_1 from "assets/mock_feature_1.jpg";

const FeatureCard = ({ item, bg, src }) => {
  if (item) {
    return (
      <ImageCard bg={bg}>
        <div>
          <p>{item.keywordName}</p>
        </div>
        <div>
          <h3>{item?.keywordServiceName}</h3>
          <img src={src ? src : mock_feature_1} alt={item.keywordName} referrerPolicy="no-referrer" />
        </div>
      </ImageCard>
    );
  } else {
    return (
      <DefaultCard>
        <div></div>
        <div></div>
      </DefaultCard>
    );
  }
};

export default FeatureCard;
