import styled from "styled-components";
import { flexbox, flexboxColumn } from "styles/mixins";
import ic_export from "assets/ic_export.svg";

export const Container = styled.div`
  ${flexbox("space-between", "center")};
  border-bottom: 1px solid #e3e3e3;
  padding: 0 22px 0 6px;
  nav {
    ul {
      ${flexbox("flex-start", "center")};
    }
    li a {
      ${flexbox("center", "center")};
      width: 100%;
      height: 39px;
      padding: 0 12px;
      font-weight: 500;
      font-size: 13px;
      line-height: 22px;
      color: rgba(0, 0, 0, 0.4);

      &.is-active {
        font-weight: 700;
        color: ${(props) => props.theme.blue};
      }
    }
  }

  .imgButton {
    width: 15px;
    height: 17px;
    background-image: url(${ic_export});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
  }
`;

export const ModalContent = styled.div`
  width: 360px;
  border-radius: 8px;
  background-color: #ffffff;

  h2 {
    padding: 18px 0 14px;
    border-bottom: 1px solid #f3f3f3;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #000000;
  }
`;

export const ModalDesc = styled.div`
  p {
    ${flexboxColumn("center", "center")};
    height: 96px;
    margin: 10px 0;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    text-align: center;
    color: #000000;
    strong {
      font-weight: 500;
      color: #ce5e41;
    }
    span {
      color: #808080;
    }
  }
`;

export const ModalBtnWrapper = styled.div`
  ${flexbox("center", "center")};
  width: 100%;
  padding: 0 0 16px 0;
  button {
    ${flexbox("center", "center")};
    height: 44px;
    width: 155px;
    border-radius: 100px;
    font-weight: 700;
    font-size: 15px;
    line-height: 22px;
    transition: background-color 300ms ease-in-out, border-color 300ms ease-in-out, color 300ms ease-in-out;
  }

  button:first-of-type {
    background-color: #f3f3f3;
    color: #000000;
    margin: 0 10px 0 0;

    :hover,
    :focus {
      background-color: #c2c2c2;
    }
  }

  button:last-of-type {
    background-color: ${(props) => (props.hasInput ? "#4556EE" : "#ce5e41")};
    color: #ffffff;

    :hover,
    :focus {
      background-color: ${(props) => (props.hasInput ? "#3745BE" : "#a54b34")};
    }

    &:disabled {
      background-color: #f3f3f3;
      color: #d6d6d6;
      cursor: default;
    }
  }
`;

export const ModalForm = styled.div`
  ${flexboxColumn};
`;

export const ModalInputWrapper = styled.div`
  ${flexboxColumn("center", "flex-start")};
  padding: 25px 20px 21px;

  label {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    color: #000000;
    margin: 0 0 4px 0;
  }

  input {
    caret-color: #4556ee;
    width: 100%;
    height: 44px;
    padding: 0 18px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    color: #000000;
    transition: border-color 300ms ease-in-out;

    &:focus {
      border: 1px solid #181818;
    }
  }
`;
