import { useState, useEffect, useRef } from "react";
import useWarnUnsavedChange from "hooks/useWarnUnsaveChange";
import Layout from "layouts/Layout";
import Content from "layouts/Content";
import ContentHeading from "layouts/Content/ContentHeading";
import Aside from "layouts/Aside";
import AsideInfo from "layouts/Aside/AsideInfo";
import ModelCard from "components/analysis/ModelCard";
import { ModelCardWrapper, ImageWrapper } from "./styles";
import { ANALYSIS_SCENARIO, CONCEPT_BENCHMARKING } from "utils/data";
import { modelGuide } from "utils/data";
import popup_model from "assets/popup_model.svg";
import CustomModal from "components/common/CustomModal";
import HelpModalContent from "components/common/HelpModalContent";
import ToastPopup from "components/common/ToastPopup";

import useSWR from "swr";
import { useParams, useNavigate } from "react-router-dom";
import { fetcherPrivate } from "utils/fetcher";
import axiosPrivate from "utils/axiosPrivate";
import useViewMode from "hooks/useViewMode";
import { useReactToPrint } from "react-to-print";

const progressData = {
  prev: ANALYSIS_SCENARIO,
  next: CONCEPT_BENCHMARKING,
};

const Model = () => {
  const scrollRef = useRef();
  const { id } = useParams();
  let navigate = useNavigate();
  // 뷰모드
  const isViewMode = useViewMode();

  // pdf 추출
  const printRef = useRef();

  const onClickPdf = useReactToPrint({ content: () => printRef.current });

  const { onChanged, onSaved } = useWarnUnsavedChange();

  const { data: modelData, error: modelDataError } = useSWR(
    `/api/v1/projects/${id}/analysis/business-model`,
    (url) => fetcherPrivate(url),
    { revalidateOnFocus: false }
  );

  const { data: templateData, error: templateDataError } = useSWR(
    `/api/v1/projects/templates/attach`,
    (url) => fetcherPrivate(url),
    { revalidateOnFocus: false }
  );

  // 도움말 팝업
  const [isHelpVisible, setIsHelpVisible] = useState(false);
  const onClickCloseModal = () => setIsHelpVisible(false);
  const onClickHelp = () => setIsHelpVisible(true);

  //
  const [selectedModel, setSelectedModel] = useState(null);
  const [selectedModelName, setSelectedModelName] = useState("");
  const [businessModelType, setBusinessModelType] = useState("");
  const [imgUrl, setImgUrl] = useState();

  const onChangeModel = (id, name) => {
    setSelectedModel(id);
    setSelectedModelName(name);
  };

  useEffect(() => {
    onChanged();
  }, [selectedModel]);

  const onChangeSelectedModel = async () => {
    if (templateData) {
      const targetModel = await templateData?.result?.find(
        (item) => item.id === +selectedModel
      );
      setImgUrl(targetModel.templateImage);
      setBusinessModelType(targetModel.businessModelType);
    }
  };

  useEffect(() => {
    onChangeSelectedModel();
  }, [selectedModel, templateData]);

  useEffect(() => {
    if (modelData?.result) {
      const { name, templateImage, templateId, businessModelType } =
        modelData.result;
      setSelectedModelName(name);
      setImgUrl(templateImage);
      setSelectedModel(templateId);
      setBusinessModelType(businessModelType);
    } else if (!modelData?.result && templateData?.result?.length > 0) {
      const firstTemplate = templateData.result[0];
      const { id, name, templateImage } = firstTemplate;
      setSelectedModelName(name);
      setImgUrl(templateImage);
      setSelectedModel(id);
    }
  }, [modelData, templateData]);

  // 토스트 팝업
  const [isToastVisible, setIsToastVisible] = useState(false);

  const onSubmit = async (e, item) => {
    //e.preventDefault();
    const data = JSON.stringify({ templateId: selectedModel });

    try {
      const res = await axiosPrivate.put(
        `/api/v1/projects/${id}/analysis/business-model`,
        data
      );

      if (res) {
        onSaved();
        setIsToastVisible(true);
        navigate(item);
      }
    } catch (error) {
      console.warn(error);
      window.alert("비즈니스 모델 저장에 실패했습니다. 다시 시도해주세요!");
      navigate(item);
    }
  };

  return (
    <>
      {isToastVisible && (
        <ToastPopup
          isToastVisible={isToastVisible}
          setIsToastVisible={setIsToastVisible}>
          저장에 성공했습니다!
        </ToastPopup>
      )}
      <Layout onClickPdf={onClickPdf} onSave={onSubmit} isViewMode={isViewMode}>
        <Content
          progressData={progressData}
          ref={scrollRef}
          onSave={onSubmit}
          isViewMode={isViewMode}>
          <form onSubmit={onSubmit} ref={printRef}>
            <ContentHeading>
              <h1>
                제품/서비스의 모습을 파악하고 <span>비즈니스 관계</span>를
                이해해보세요.
              </h1>
              {/* 페이지 이동시 자동 저장으로 주석처리
              <button type="submit" disabled={isViewMode}>
                저장
              </button>
              */}
            </ContentHeading>

            {imgUrl ? (
              <ImageWrapper>
                {selectedModelName && <h3>{selectedModelName}</h3>}
                {businessModelType && <h4>{businessModelType}</h4>}
                <img
                  src={imgUrl}
                  alt={selectedModel}
                  referrerPolicy="no-referrer"
                />
              </ImageWrapper>
            ) : null}
          </form>
        </Content>
        <Aside>
          <AsideInfo heading="비즈니스 모델 선택" onClickHelp={onClickHelp}>
            제품/서비스와 유사한 비즈니스 모델을 확인하는 과정입니다.
          </AsideInfo>
          <ModelCardWrapper>
            {templateData?.result?.map((item, i) => (
              <ModelCard
                key={i}
                index={i}
                item={item}
                name="bizModel"
                selectedValue={selectedModel}
                onChange={onChangeModel}
                isViewMode={isViewMode}
              />
            ))}
          </ModelCardWrapper>
        </Aside>
      </Layout>
      {isHelpVisible && (
        <CustomModal isVisible={isHelpVisible}>
          <HelpModalContent
            headingText="비즈니스 모델 템플릿 사용 방법"
            guideArr={modelGuide}
            img={popup_model}
            onClick={onClickCloseModal}
          />
        </CustomModal>
      )}
    </>
  );
};
export default Model;
