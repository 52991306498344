import { DefaultCard, ImageCard } from "./styles";
import mock_design_1 from "assets/mock_design_1.jpg";

const DesignCard = ({ item, bg, src }) => {
  if (item) {
    return (
      <ImageCard bg={bg}>
        <div>
          <p>{item.keywordName}</p>
        </div>
        <div>
          <img src={src ? src : mock_design_1} alt={item.keywordName} referrerPolicy="no-referrer" />
        </div>
      </ImageCard>
    );
  } else {
    return (
      <DefaultCard>
        <div></div>
        <div></div>
      </DefaultCard>
    );
  }
};

export default DesignCard;
