import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import { fetcherPrivate } from "utils/fetcher";
import TabOutlet from "components/test/TabOutlet";
import { Container, Header } from "./styles";
import { TabContext } from "context/tab-context";
import { PREVIEW } from "utils/data";
import ic_test_header_prev from "assets/ic_test_header_prev.svg";

export const defaultTabs = [
  { id: 0, name: "인사말", blocked: false },
  { id: 1, name: "테스터 정보", blocked: false },
  { id: 9, name: "종합 의견", blocked: false },
  { id: 10, name: "끝내는 말", blocked: false },
  { id: 11, name: "작성 완료", blocked: false },
];

const TestPreview = () => {
  const { id: projectId } = useParams();
  const { data: layoutData, error: layoutError, mutate: layoutMutate } = useSWR(`/api/v1/projects/${projectId}/produce/layout`, (url) => fetcherPrivate(url), { revalidateOnFocus: false });
  const [layoutArr, setLayoutArr] = useState([]);
  useEffect(() => {
    if (layoutData?.result?.produceLayouts?.length > 0) {
      setLayoutArr(layoutData.result.produceLayouts);
    }
  }, [layoutData]);

  const tabCtx = useContext(TabContext);
  const currentTabId = tabCtx.currentTabId;

  //TODO : TabOutlet에 tabs 전달.tabs에 포함되어 있음 + currentTab과 id 일치하는 경우의 탭 보여주기
  const [tabs, setTabs] = useState([]);

  const onClickComplete = () => {
    tabCtx.goComplete();
  };

  const onClickClose = () => {
    window.close();
  };
  useEffect(() => {
    let savedItems = window.localStorage.getItem("tabs");
    if (savedItems) {
      const parsedItems = JSON.parse(savedItems);
      const selectedTabs = parsedItems.filter((item) => item.blocked === false);
      const orderedTabs = [...defaultTabs.slice(0, 2), ...selectedTabs, ...defaultTabs.slice(2)];
      setTabs(orderedTabs);
      tabCtx.setModifiedTabArr(orderedTabs);
    }
  }, []);

  return (
    <>
      <Container>
        <Header style={{ display: "flex", justifyContent: "space-between", paddingLeft: "20px", paddingRight: "20px" }}>
          <button onClick={onClickClose} style={{ marginTop: "5px" }}>
            <img src={ic_test_header_prev} alt="icon" referrerPolicy="no-referrer" />
          </button>
          <div>ProjectListBook 테스트</div>
          <button style={{ width: "60px", height: "26px", border: "1px solid #ffffff", borderRadius: "20px", fontSize: "11px" }} onClick={onClickComplete}>
            완료
          </button>
        </Header>
        <TabOutlet currentTabId={currentTabId} status={PREVIEW} layoutArr={layoutArr} />
      </Container>
    </>
  );
};

export default TestPreview;
