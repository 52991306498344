import { useState, useEffect } from "react";
import { NavLink, useLocation, useParams, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { recoilProjectState } from "states/recoilProjectState";
import { routes } from "utils/data";
import { Container, ModalContent, ModalDesc, ModalBtnWrapper, ModalForm, ModalInputWrapper } from "./styles";
import CustomModal from "components/common/CustomModal";

const PageNav = ({ onClickPdf, onSave, isViewMode }) => {
  let navigate = useNavigate();
  const { pathname } = useLocation();
  const { id } = useParams();
  const page = pathname.split("/")[1];
  const pageRoutes = routes[page];
  const location = useLocation();
  const [isSaveModalVisible, setIsSaveModalVisible] = useState(false);
  const [pathTest, setPathTest] = useState("");
  const { projectId } = useRecoilValue(recoilProjectState);
  // console.log("page nav", projectId);

  function onChangeTab(item) {
    const pathFlag = pathname.includes("feedback");
    if (isViewMode) {
      navigate(`${item.pathname}/${id}`);
    } else {
      pathFlag ? navigate(`${item.pathname}/${id}`) : onSave("", `${item.pathname}/${id}`);
      /*
      setTimeout(() => {
        navigate(`${item.pathname}/${id}`);
      }, 800);*/
    }
    /* 2차버젼시 수정 유무에 따라 저장 시 사용
    setPathTest(item);
    if (isViewMode) {
      navigate(`${item.pathname}/${id}`);
    } else {
      setIsSaveModalVisible(true);
    }*/
  }

  /* 2차버젼시 수정 유무에 따라 저장 시 사용 - 모달 이벤트 소스
  const onClickCancel = () => {
    console.log(pathTest);
    setIsSaveModalVisible(false);
    navigate(`${pathTest.pathname}/${id}`);
  };

  const onClickSave = () => {
    console.log(pathTest);
    setIsSaveModalVisible(false);
    const pathFlag = pathname.includes("feedback");
    pathFlag ? console.log("data") : onSave(pathTest);
    setTimeout(
      () => {
        navigate(`${pathTest.pathname}/${id}`);
      },
      isViewMode ? 100 : 800
    );
  };*/
  return (
    <Container className="not-print">
      {/* 2차버젼시 수정 유무에 따라 저장 시 사용 - 모달 컴포넌트 소스
      {isSaveModalVisible && (
        <CustomModal isVisible={isSaveModalVisible}>
          <ModalContent>
            <h2>변경된 내용 저장</h2>
            <ModalDesc>
              <p>
                저장하지 않고 페이지를 이동할 경우, <br />
                지금까지 변경된 내용이 반영되지 않습니다. <br />
                <strong style={{ color: "#4556EE" }}>내용을 저장할까요?</strong>
              </p>
              <ModalBtnWrapper>
                <button type="button" onClick={onClickCancel}>
                  취소
                </button>
                <button style={{ backgroundColor: "#4556EE" }} type="button" onClick={onClickSave}>
                  저장
                </button>
              </ModalBtnWrapper>
            </ModalDesc>
          </ModalContent>
        </CustomModal>
      )}*/}
      <nav>
        <ul>
          {pageRoutes.map((item, i) => (
            <li key={i}>
              <button
                style={{
                  width: "100%",
                  height: "39px",
                  padding: "0 12px",
                  fontWeight: `${item.pathname}/${id}` === location.pathname ? "700" : "500",
                  fontSize: "13px",
                  lineHeight: "22px",
                  color: `${item.pathname}/${id}` === location.pathname ? "#4556EE" : "rgba(0, 0, 0, 0.4)",
                }}
                onClick={() => onChangeTab(item)}
                className={({ isActive }) => (isActive ? "is-active" : undefined)}
              >
                {item.name}
              </button>
            </li>
          ))}
        </ul>
      </nav>
      <button className="imgButton" type="button" onClick={onClickPdf}></button>
    </Container>
  );
};

export default PageNav;
