import { useState, useEffect } from "react";
import TabWrapper from "components/test/TabWrapper";
import TabContent from "components/test/TabContent";
import Carousel from "components/test/Carousel";
import Intro from "components/test/TabItems/Intro";
import Use from "components/test/TabItems/Use";
import Pay from "components/test/TabItems/Pay";
import Frequency from "components/test/TabItems/Frequency";
import Design from "components/test/TabItems/Design";
import Contents from "components/test/TabItems/Contents";
import Feature from "components/test/TabItems/Feature";
import Experience from "components/test/TabItems/Experience";
import Opinion from "components/test/TabItems/Opinion";
import Outro from "components/test/TabItems/Outro";
import Tester from "components/test/TabItems/Tester";
import Complete from "components/test/TabItems/Complete";
import ReviewUse from "components/review/ReviewTabItems/ReviewUse";
import ReviewPay from "components/review/ReviewTabItems/ReviewPay";
import ReviewFrequency from "components/review/ReviewTabItems/ReviewFrequency";
import ReviewDesign from "components/review/ReviewTabItems/ReviewDesign";
import ReviewContents from "components/review/ReviewTabItems/ReviewContents";
import ReviewFeature from "components/review/ReviewTabItems/ReviewFeature";
import ReviewExperience from "components/review/ReviewTabItems/ReviewExperience";
import ReviewOpinion from "components/review/ReviewTabItems/ReviewOpinion";
import { TESTER, PREVIEW } from "utils/data";
import SurveyIntro from "components/survey/SurveyTabItems/SurveyIntro";
import SurveyOutro from "components/survey/SurveyTabItems/SurveyOutro";

const TabOutlet = ({ currentTabId, status, isReview, onClickMoreResponse, layoutArr, link, greetings, closingWords, projectId, testerUid, savedAnswer, testInfo, isViewMode }) => {
  const [protoLink, setProtoLink] = useState();
  useEffect(() => {
    if (status === PREVIEW) {
      // 사용자 미리보기 화면
      const localLink = window.localStorage.getItem("link");
      if (localLink) {
        setProtoLink(JSON.parse(localLink));
      }
    } else {
      // 사용자 화면 설문 작성 화면, 테스터에게 제공되는 화면
      setProtoLink(link);
    }
  }, [status, link]);

  if (currentTabId !== 11) {
    return (
      <>
        <TabWrapper status={status}>
          <Carousel layoutArr={layoutArr} />
          <TabContent>
            {status === TESTER ? (
              <SurveyIntro isShowing={currentTabId === 0} link={protoLink} greetings={greetings} />
            ) : (
              <Intro isShowing={currentTabId === 0} status={status} link={protoLink} greetings={greetings} isViewMode={isViewMode} />
            )}
            <Tester isShowing={currentTabId === 1} status={status} link={protoLink} projectId={projectId} testerUid={testerUid} savedAnswer={savedAnswer} />
            {isReview ? (
              <>
                <ReviewUse isShowing={currentTabId === 2} link={protoLink} onClickMoreResponse={onClickMoreResponse} testInfo={testInfo} />
                <ReviewPay isShowing={currentTabId === 3} link={protoLink} onClickMoreResponse={onClickMoreResponse} testInfo={testInfo} />
                <ReviewFrequency isShowing={currentTabId === 4} link={protoLink} onClickMoreResponse={onClickMoreResponse} testInfo={testInfo} />
                <ReviewDesign isShowing={currentTabId === 5} link={protoLink} onClickMoreResponse={onClickMoreResponse} testInfo={testInfo} />
                <ReviewContents isShowing={currentTabId === 6} link={protoLink} onClickMoreResponse={onClickMoreResponse} testInfo={testInfo} />
                <ReviewFeature isShowing={currentTabId === 7} link={protoLink} onClickMoreResponse={onClickMoreResponse} testInfo={testInfo} />
                <ReviewExperience isShowing={currentTabId === 8} link={protoLink} onClickMoreResponse={onClickMoreResponse} testInfo={testInfo} />
                <ReviewOpinion isShowing={currentTabId === 9} link={protoLink} onClickMoreResponse={onClickMoreResponse} testInfo={testInfo} />
              </>
            ) : (
              <>
                <Use isShowing={currentTabId === 2} status={status} link={protoLink} projectId={projectId} testerUid={testerUid} savedAnswer={savedAnswer} />
                <Pay isShowing={currentTabId === 3} status={status} link={protoLink} projectId={projectId} testerUid={testerUid} savedAnswer={savedAnswer} />
                <Frequency isShowing={currentTabId === 4} status={status} link={protoLink} projectId={projectId} testerUid={testerUid} savedAnswer={savedAnswer} />
                <Design isShowing={currentTabId === 5} status={status} link={protoLink} projectId={projectId} testerUid={testerUid} savedAnswer={savedAnswer} />
                <Contents isShowing={currentTabId === 6} status={status} link={protoLink} projectId={projectId} testerUid={testerUid} savedAnswer={savedAnswer} />
                <Feature isShowing={currentTabId === 7} status={status} link={protoLink} projectId={projectId} testerUid={testerUid} savedAnswer={savedAnswer} />
                <Experience isShowing={currentTabId === 8} status={status} link={protoLink} projectId={projectId} testerUid={testerUid} savedAnswer={savedAnswer} />
                <Opinion isShowing={currentTabId === 9} status={status} link={protoLink} projectId={projectId} testerUid={testerUid} savedAnswer={savedAnswer} />
              </>
            )}
            {status === TESTER ? (
              <SurveyOutro isShowing={currentTabId === 10} link={protoLink} closingWords={closingWords} />
            ) : (
              <Outro isShowing={currentTabId === 10} status={status} link={protoLink} closingWords={closingWords} isViewMode={isViewMode} />
            )}
          </TabContent>
        </TabWrapper>
      </>
    );
  } else {
    return <Complete isShowing={currentTabId === 11} status={status} projectId={projectId} testerUid={testerUid} />;
  }
};

export default TabOutlet;
