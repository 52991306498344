import styled from "styled-components";
import { flexbox, flexboxColumn } from "styles/mixins";
import logo from "assets/logo.svg";
import { Link } from "react-router-dom";

export const Main = styled.main`
  ${flexboxColumn("center", "center")};
  width: 100vw;
  min-height: 100vh;
  padding: 105px 0 99px;
  position: relative;
`;

export const HomeLink = styled(Link)`
  width: 120px;
  height: 40px;
  background-image: url(${logo});
  background-repeat: no-repeat;
  background-size: 120px 40px;
  background-position: center center;
  position: absolute;
  top: 24px;
  left: 24px;
`;

export const TabButtonWrapper = styled.div`
  ${flexbox("flex-start", "center")};
  margin: 0 0 10px 0;
`;

export const Button = styled.button`
  font-weight: ${(props) => (props.isCurrent ? "700" : "500")};
  font-size: 24px;
  line-height: 36px;
  color: ${(props) => (props.isCurrent ? "#000" : "#9D9D9D")};
  &:first-of-type {
    margin: 0 30px 0 0;
  }
`;

export const TabCard = styled.div`
  ${flexboxColumn("flex-start", "center")};
  width: 730px;
  min-height: 744px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
`;

export const Form = styled.form`
  h2 {
    font-weight: 700;
    font-size: 26px;
    line-height: 36px;
    color: ${(props) => props.theme.black};
    margin: 0 0 4px 0;
  }

  .profile-btn {
    font-weight: 600;
    font-size: 12px;
    line-height: 22px;
    color: ${(props) => props.theme.blue};
  }
`;

export const TextInput = styled.div`
  ${flexbox("flex-start", "center")};
  position: relative;
  margin: 22px 0 0 0;

  label {
    margin: 0 12px 0 0;
    width: 80px;
    min-width: 80px;
    font-weight: 700;
    font-size: 13px;
    line-height: 20px;
    color: #727682;
  }

  input {
    caret-color: #4556ee;
    width: 300px;
    height: 44px;
    padding: 0 18px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    color: #000;
    transition: border-color 300ms ease-in-out;

    &:active,
    &:focus {
      border: 1px solid #181818;
    }

    ::placeholder {
      color: #b1b1b1;
    }
  }
`;

export const EmailInput = styled(TextInput)`
  margin: 0;
`;

export const SaveBtn = styled.button`
  display: block;
  width: 392px;
  height: 44px;
  background-color: #181818;
  border-radius: 100px;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;

  transition: background-color 300ms ease-in-out, color 300ms ease-in-out;

  &:disabled {
    background-color: #f3f3f3;
    color: #d6d6d6;
  }
`;
