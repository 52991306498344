import { StyledModal } from "./styles";

const CustomModal = ({ isVisible, children }) => {
	return (
		<StyledModal visible={isVisible}>
			<div>{children}</div>
		</StyledModal>
	);
};

export default CustomModal;
