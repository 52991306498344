import { read, utils, writeFileXLSX } from "xlsx";
import { Container, HeadingWrapper, BtnWrapper, Table, Thead, Tbody, RadioResponse } from "./styles";
import { questionTextArr, answerTextArr } from "utils/data";

// 리뷰 페이지
const ResponseModalContent = ({ questionType, testerAnswerList, onClickClose }) => {
  const questionData = questionTextArr.find((item) => item.step === questionType);

  const choiceIconAndText = (answerType) => answerTextArr.find((item) => item.id === answerType);

  const getAnswerText = (item) => {
    if (item?.answerType) {
      return choiceIconAndText(item?.answerType)?.text;
    } else {
      return "-";
    }
  };

  const onClickExcelDownload = () => {
    const modifiedList = testerAnswerList.map((item) => {
      return { ...item, genderType: item?.genderType === "MAN" ? "남" : item?.genderType === "WOMAN" ? "여" : "구분없음", answerType: getAnswerText(item) };
    });
    const ws = utils.json_to_sheet(modifiedList);
    const wb = utils.book_new();
    utils.sheet_add_aoa(ws, [["이름", "성별", "나이", "이메일", "선택 답변", "답변"]], { origin: "A1" });
    utils.book_append_sheet(wb, ws, "TestResults");
    writeFileXLSX(wb, "TestResults.xlsx");
  };

  return (
    <Container>
      <HeadingWrapper>
        <h1>{questionData.category}</h1>
        <p>Q. {questionData.question}</p>
        <BtnWrapper>
          <button type="button" onClick={onClickExcelDownload} disabled={testerAnswerList?.length === 0}></button>
          <button type="button" onClick={onClickClose}></button>
        </BtnWrapper>
      </HeadingWrapper>
      <div>
        <Table>
          <Thead>
            <tr>
              <th>이름</th>
              <th>성별</th>
              <th>나이</th>
              <th>이메일</th>
              <th>선택 답변</th>
              <th>답변</th>
            </tr>
          </Thead>
          <Tbody>
            {testerAnswerList?.length > 0 &&
              testerAnswerList.map((item, i) => (
                <tr key={i}>
                  <td>{item.testerName}</td>
                  <td>{item.genderType === "MAN" ? "남" : item.genderType === "WOMAN" ? "여" : "구분없음"}</td>
                  <td className="age">{item.testerAge}</td>
                  <td>{item.testerEmail}</td>
                  {questionType === "GENERAL_OPINION" || questionType === "SIMILAR_SERVICE" ? (
                    <td>-</td>
                  ) : (
                    <td className="with-icon">
                      {item.answerType ? (
                        <>
                          <img src={choiceIconAndText(item.answerType).icon} alt="icon" referrerPolicy="no-referrer" />
                          <span>{choiceIconAndText(item.answerType).text}</span>
                        </>
                      ) : (
                        "-"
                      )}
                    </td>
                  )}
                  <td>{item.answer}</td>
                </tr>
              ))}
            {!testerAnswerList &&
              Array(15)
                .fill()
                .map((_, i) => (
                  <tr key={i}>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                ))}
            {testerAnswerList?.length < 15 &&
              Array(15 - testerAnswerList.length)
                .fill()
                .map((_, i) => (
                  <tr key={i}>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                ))}
          </Tbody>
        </Table>
      </div>
    </Container>
  );
};

export default ResponseModalContent;
