const BarCustomTick = (tick) => {
  if (tick.value === "yes") {
    return (
      <path
        transform={`translate(${tick.x - 26},${tick.y - 18})`}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.1398 12.4913L6.1398 16H9.86073V12.4918L12.3416 14.9727L14.9727 12.3416L12.4915 9.86044H16.0002V6.13951H12.4916L14.9726 3.65848L12.3415 1.02739L9.86073 3.50818V0H6.1398V3.5087L3.65901 1.0279L1.02791 3.659L3.50842 6.13951H0.000244141V9.86044H3.50847L1.02782 12.3411L3.65891 14.9722L6.1398 12.4913Z"
        fill="#5A7CF8"
      />
    );
  } else if (tick.value === "no") {
    return (
      <path
        transform={`translate(${tick.x - 25},${tick.y - 17})`}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4248 7.31359L14.5261 3.21235L11.4148 0.101078L7.31356 4.20232L3.21248 0.101237L0.101214 3.21251L4.20229 7.31358L0.10109 11.4148L3.21236 14.5261L7.31356 10.4249L11.4149 14.5262L14.5262 11.4149L10.4248 7.31359Z"
        fill="#CE5E41"
      />
    );
  }
};

export default BarCustomTick;
