import { useState, useEffect } from "react";
import LinkInfo from "components/test/LinkInfo";
import LinkInput from "components/test/LinkInput";
import TabTag from "components/test/TabTag";
import QuestionMain from "components/test/QuestionMain";
import FiveRadio from "components/test/FiveRadio";
import QuestionSub from "components/test/QuestionSub";
import TestTextarea from "components/test/TestTextarea";
import PrevNextBtn from "components/test/PrevNextBtn";
import TesterPrevNextBtn from "components/test/TesterPrevNextBtn";
import { TabContainer } from "../../../../pages/test/form/styles";
import { USER, PREVIEW, TESTER } from "utils/data";
import { answerArr } from "../Design";
import patchTesterAnswer from "utils/patchTesterAnswer";

const Contents = ({ isShowing, status, link, projectId, testerUid, savedAnswer }) => {
  const [contents, setContents] = useState();
  const [text, setText] = useState();

  useEffect(() => {
    if (status === TESTER && savedAnswer) {
      const { contentSatisfactionType, contentSatisfactionAnswer } = savedAnswer;
      if (contentSatisfactionType?.length > 0) setContents(contentSatisfactionType);
      if (contentSatisfactionAnswer?.length > 0) setText(contentSatisfactionAnswer);
    }
  }, [savedAnswer]);

  const onChangeContents = (e) => {
    setContents(e.target.value);
    if (status === TESTER) {
      patchTesterAnswer(projectId, testerUid, "contentSatisfactionType", e.target.value, "CONTENT_SATISFACTION")
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
          window.alert("제품/서비스 콘텐츠 만족도 저장에 실패했습니다. 관리자에게 문의 부탁드립니다.");
        });
    }
  };

  const onChangeText = (e) => {
    setText(e.target.value);
    if (status === TESTER) {
      patchTesterAnswer(projectId, testerUid, "contentSatisfactionAnswer", e.target.value, "CONTENT_SATISFACTION")
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
          window.alert("제품/서비스 콘텐츠 만족도 이유 저장에 실패했습니다. 관리자에게 문의 부탁드립니다.");
        });
    }
  };

  return (
    <TabContainer isShowing={isShowing}>
      {status !== USER ? <LinkInfo link={link} /> : <LinkInput />}
      <TabTag text="콘텐츠 만족도" />
      <QuestionMain text="본 제품/서비스를 통해 제공되는 콘텐츠는 얼마나 만족스러우신가요?" />
      <FiveRadio name="contents" arr={answerArr} status={status} selectedValue={contents} onChange={onChangeContents} />
      <QuestionSub text="위와 같이 선택하신 이유가 무엇인가요?" />
      <TestTextarea name="contents" placeholder="테스터의 작성이 이곳에 표시됩니다." status={status} value={text} onChange={onChangeText} />
      {status === PREVIEW && <PrevNextBtn />}
      {status === TESTER && <TesterPrevNextBtn />}
    </TabContainer>
  );
};

export default Contents;
