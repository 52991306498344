import { Wrapper } from "components/common/Checkbox/styles";

const KeywordCheckbox = ({ array, randomBgArr, name, selectedArr, onChange, isMax, isViewMode }) => {
  const onChangeHandler = (e, id) => {
    onChange(e.target.checked, id);
  };

  return array.map((item, i) => (
    <Wrapper key={i} bg={randomBgArr[i]} isMax={isMax}>
      <input
        type="checkbox"
        name={name}
        id={item.keywordId}
        value={item.keywordId}
        checked={selectedArr?.find((obj) => obj.keywordId === item.keywordId)}
        onChange={(e) => onChangeHandler(e, item.keywordId)}
        disabled={isViewMode}
      />
      <label htmlFor={item.keywordId}>{item.keywordName}</label>
    </Wrapper>
  ));
};

export default KeywordCheckbox;
