import { Link } from "react-router-dom";
import styled from "styled-components";
import { flexbox, flexboxColumn } from "styles/mixins";
import ic_arrow_left_grey from "assets/ic_arrow_left_grey.svg";
import ic_arrow_right_white from "assets/ic_arrow_right_white.svg";

export const Wrapper = styled.div`
  ${flexbox("space-between", "center")};
  // height: 110px;
  height: 70px;
  padding: 0 27px 0 32px;
  background-color: ${(props) => props.theme.bgGrey};
`;

export const ProgressContainer = styled.div`
  ${flexbox("flex-start", "center")};

  img {
    width: 450px;
    height: 48px;
  }
`;

// 버튼
export const LinkWrapper = styled.div`
  ${flexbox("flex-start", "center")};
`;

export const PrevLink = styled(Link)`
  display: block;
  padding: 0 0 0 17px;
  margin: 0 32px 0 0;
  background-image: url(${ic_arrow_left_grey});
  background-repeat: no-repeat;
  background-position: left 5px;
  background-size: 7px 15px;
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  color: #000;
  opacity: ${(props) => (props.disabled ? "0.2" : "0.7")};

  :hover {
    color: #000;
  }
`;

export const NextLink = styled(Link)`
  ${flexbox("flex-start", "center")};
  height: 44px;
  padding: 0 53px 0 38px;
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  color: #fff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.12);
  border-radius: 24px;
  background-color: #4556ee;
  background-image: url(${ic_arrow_right_white});
  background-repeat: no-repeat;
  background-size: 7px 15px;
  background-position: 78px 16px;

  &:hover {
    color: #fff;
  }
`;

export const CompleteBtn = styled.button`
  ${flexbox("center", "center")};
  height: 44px;
  padding: 0 35px 0 35px;
  background-color: #4556ee;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.12);
  border-radius: 24px;
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  color: #ffffff;

  &:disabled {
    cursor: not-allowed;
  }
`;
