import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "components/register/Layout";
import ResetCard from "components/reset-password/ResetCard";
import { Heading, Form, Input, ErrorText } from "./styles";
import NextBtn from "components/register/NextBtn";
import axiosPublic from "utils/axiosPublic";
import { emailReg } from "utils/regex";

const ResetEmail = () => {
  let navigate = useNavigate();

  const [email, setEmail] = useState("");
  const onChangeEmail = (e) => setEmail(e.target.value);

  // 이메일 형태 유효성 검사
  const [isEmailValid, setIsEmailValid] = useState(false);
  useEffect(() => {
    if (email?.length > 0) {
      if (emailReg.test(email)) {
        setIsEmailValid(true);
      } else {
        setIsEmailValid(false);
      }
    } else {
      setIsEmailValid(false);
    }
  }, [email]);

  // 이메일 존재 여부 검사
  const [hasError, setHasError] = useState(false);
  const onBlurEmail = (e) => {
    // 이메일 형식이 유효한 경우에만 중복 확인  체크
    if (isEmailValid) {
      setHasError(false);
      axiosPublic
        .get("/api/v1/open/email/duplicate-check", {
          params: {
            email: e.target.value,
          },
        })
        .then((resp) => {
          console.log("resp!", resp);
          // code : EMAIL_S_003 : 가입할 수 있는 이메일 -> 즉, 존재하지 않는 회원
          setHasError(true);
        })
        .catch((err) => {
          // code : EMAIL_E_003 : 이미 가입된 이메일 -> 즉, 존재하는 회원
          console.log("err!", err);
          setHasError(false);
        });
    }
  };

  const [isDisabled, setIsDisabled] = useState(true);
  useEffect(() => {
    if (email?.length > 0 && !hasError && isEmailValid) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [email, hasError, isEmailValid]);

  const onSubmit = (e) => {
    e.preventDefault();
    window.localStorage.setItem("reset-email", email);
    navigate("/reset/auth");
  };

  return (
    <Layout>
      <ResetCard>
        <Heading>비밀번호를 재설정합니다.</Heading>
        <Form onSubmit={onSubmit}>
          <div>
            <Input>
              <label htmlFor="email">I2B 캠퍼스에 가입한 이메일을 입력하세요.</label>
              <input type="text" id="email" value={email} onChange={onChangeEmail} onBlur={onBlurEmail} />
            </Input>
            {email?.length > 0 && !isEmailValid && <ErrorText>잘못된 형식입니다.</ErrorText>}
            {hasError && <ErrorText>가입되지 않은 이메일입니다.</ErrorText>}
          </div>
          <NextBtn type="submit" disabled={isDisabled}>
            다음으로
          </NextBtn>
        </Form>
      </ResetCard>
    </Layout>
  );
};

export default ResetEmail;
