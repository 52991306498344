import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axiosPrivate from "utils/axiosPrivate";
import { useRecoilState } from "recoil";
import { recoilProjectState } from "states/recoilProjectState";
import LNB from "components/home/LNB";
import ProjectFilter from "components/home/ProjectFilter";
import ProjectList from "components/home/ProjectList";
import AvatarBtn from "components/home/AvatarBtn";
import CustomModal from "components/common/CustomModal";
import { Layout, Main, LinkWrapper, FilterWrapper, StyledLink, ModalContent, ModalDesc, ModalBtnWrapper, ModalForm, ModalInputWrapper, Question } from "./styles";
import { DefaultView } from "./styles";
import ic_project_null from "assets/ic_project_null.svg";
import useSWR from "swr";
import { fetcherPrivate } from "utils/fetcher";
import useLogout from "hooks/useLogout";
import useExpertMode from "hooks/useExpertMode";
import TOOLKIT_GUIDE from "assets/TOOLKIT_GUIDE.pdf";

const Home = () => {
  const navigate = useNavigate();
  const { logout } = useLogout();

  const { data: projectList, error: projectError, mutate: projectListMutate } = useSWR(`/api/v1/projects`, (url) => fetcherPrivate(url));

  useEffect(() => {
    if (projectList) {
      window.localStorage.setItem("project-list", JSON.stringify(projectList.result));
    }
  }, [projectList]);

  const isExpertMode = useExpertMode();

  // 상단 아바타 클릭
  const [isLogoutVisible, setIsLogoutVisible] = useState(false);
  const onClickLogout = () => {
    setIsLogoutVisible(true);
  };

  // 로그아웃 취소
  const onCancelLogout = () => setIsLogoutVisible(false);

  //로그아웃
  const onConfirmLogout = () => {
    logout();
    setIsLogoutVisible(false);
  };

  const [filter, setFilter] = useState("all");
  const onChangeFilter = (e) => setFilter(e.target.value);

  const [targetProjectId, setTargetProjectId] = useState();

  // 삭제 모달
  const [isDelModalVisible, setIsDelModalVisible] = useState(false);
  const onClickDelete = (projectId) => {
    setTargetProjectId(projectId);
    setIsDelModalVisible(true);
  };
  const onCancelDel = () => {
    setIsDelModalVisible(false);
    setTargetProjectId();
  };

  const onConfirmDel = () => {
    axiosPrivate
      .delete(`/api/v1/projects/${targetProjectId}`)
      .then((res) => {
        setTargetProjectId();
        projectListMutate();
      })
      .catch((err) => {
        window.alert("프로젝트 삭제에 실패하였습니다. 다시 시도해주세요!");
      })
      .finally(() => {
        setIsDelModalVisible(false);
      });
  };

  // 이름 변경 모달
  const [isNameConfirmDisabled, setIsNameConfirmDisabled] = useState(true);
  const [newName, setNewName] = useState("");

  const onChangeNewName = (e) => setNewName(e.target.value);

  useEffect(() => {
    if (newName?.length > 0) {
      setIsNameConfirmDisabled(false);
    } else {
      setIsNameConfirmDisabled(true);
    }
  }, [newName]);

  const [isNameModalVisible, setIsNameModalVisible] = useState(false);
  const onClickName = (e, projectId) => {
    e.preventDefault();
    setTargetProjectId(projectId);
    setIsNameModalVisible(true);
  };
  const onCancelName = () => {
    setIsNameModalVisible(false);
    setNewName("");
    setTargetProjectId();
  };

  const onConfirmName = (e) => {
    e.preventDefault();
    const data = { name: newName };
    axiosPrivate
      .put(`/api/v1/projects/${targetProjectId}`, data)
      .then((res) => {
        projectListMutate();
      })
      .catch((err) => {
        window.alert("프로젝트 이름 변경에 실패했습니다. 관리자에게 문의해주세요!");
      })
      .finally(() => {
        setTargetProjectId();
        setIsNameModalVisible(false);
      });
  };

  // 중단 모달
  const [isStopModalVisible, setIsStopModalVisible] = useState(false);
  const onClickStop = (projectId) => {
    setTargetProjectId(projectId);
    setIsStopModalVisible(true);
  };

  const onCancelStop = () => {
    setIsStopModalVisible(false);
    setTargetProjectId();
  };

  const onConfirmStop = (e) => {
    const data = {
      projectStatusType: "STOP",
    };
    axiosPrivate
      .put(`/api/v1/projects/${targetProjectId}/status`, data)
      .then((res) => {
        projectListMutate();
      })
      .catch((err) => {
        window.alert("프로젝트 중단에 실패했습니다. 관리자에게 문의해주세요!");
      })
      .finally(() => {
        setIsStopModalVisible(false);
        setTargetProjectId();
      });
  };

  // 프로젝트 추가 버튼 클릭 시
  const [_, setProjectId] = useRecoilState(recoilProjectState);
  const onClickAddProject = async () => {
    try {
      const res = await axiosPrivate.post("/api/v1/projects");

      if (res.data.result?.id) {
        setProjectId((prev) => {
          const prevState = { ...prev };
          prevState.projectId = res.data.result?.id;
          return { ...prevState };
        });
        return navigate(`/analysis/summary/${res.data.result?.id}`);
      }
    } catch (error) {
      console.warn(error);
      window.alert("프로젝트 생성에 실패했습니다. 다시 시도해주세요");
    }
  };

  return (
    <>
      <Layout>
        <LNB />
        <Main>
          <AvatarBtn onClickLogout={onClickLogout} />
          <LinkWrapper>
            <a href={TOOLKIT_GUIDE} download="I2B Toolkit Guidebook_MVP.pdf">
              <p>처음이신가요?</p>
              <p>MVP Kit</p>
              <p>가이드북 다운로드</p>
            </a>
            <a href="https://forms.gle/28RR3f5Mxk1rNADo8" target="_blank" rel="noreferrer">
              <p>진행이 어려우세요?</p>
              <p>MVP 교육 프로그램</p>
              <p>신청하기</p>
            </a>
          </LinkWrapper>
          <FilterWrapper>
            <ProjectFilter checkedItem={filter} setChecked={onChangeFilter} />
            {!isExpertMode && (
              <StyledLink type="button" onClick={onClickAddProject}>
                프로젝트 추가
              </StyledLink>
            )}
          </FilterWrapper>
          {projectList?.result?.length > 0 ? (
            <ProjectList projects={projectList?.result ?? []} filter={filter} onClickDelete={onClickDelete} onClickName={onClickName} onClickStop={onClickStop} />
          ) : (
            <DefaultView>
              <div>
                <img src={ic_project_null} alt="empty project list" referrerPolicy="no-referrer" />
                <h2>프로젝트를 생성하세요</h2>
              </div>
            </DefaultView>
          )}
        </Main>
      </Layout>

      {isDelModalVisible && (
        <CustomModal isVisible={isDelModalVisible}>
          <ModalContent>
            <h2>프로젝트 삭제</h2>
            <ModalDesc>
              <p>
                프로젝트 삭제 시 입력한 데이터가 모두 삭제됩니다. <br />
                <strong>그래도 프로젝트를 삭제하시겠습니까?</strong>
              </p>
              <ModalBtnWrapper>
                <button type="button" onClick={onCancelDel}>
                  취소
                </button>
                <button type="button" onClick={onConfirmDel}>
                  삭제
                </button>
              </ModalBtnWrapper>
            </ModalDesc>
          </ModalContent>
        </CustomModal>
      )}

      {isNameModalVisible && (
        <CustomModal isVisible={isNameModalVisible}>
          <ModalContent>
            <h2>프로젝트 이름 변경</h2>
            <ModalDesc>
              <ModalForm>
                <form onSubmit={onConfirmName}>
                  <ModalInputWrapper>
                    <label htmlFor="new-name">프로젝트 이름을 입력하세요.</label>
                    <input type="text" id="new-name" value={newName} onChange={onChangeNewName} />
                  </ModalInputWrapper>
                  <ModalBtnWrapper hasInput>
                    <button type="button" onClick={onCancelName}>
                      취소
                    </button>
                    <button type="submit" disabled={isNameConfirmDisabled}>
                      확인
                    </button>
                  </ModalBtnWrapper>
                </form>
              </ModalForm>
            </ModalDesc>
          </ModalContent>
        </CustomModal>
      )}

      {isStopModalVisible && (
        <CustomModal isVisible={isStopModalVisible}>
          <ModalContent>
            <h2>프로젝트 중단</h2>
            <ModalDesc>
              <p>
                프로젝트 중단 시 더이상 Toolkit 데이터를 <br />
                입력할 수 없으며, 열람만 가능합니다.
                <br />
                <strong>그래도 프로젝트를 중단하시겠습니까?</strong>
              </p>
              <ModalBtnWrapper>
                <button type="button" onClick={onCancelStop}>
                  취소
                </button>
                <button type="button" onClick={onConfirmStop}>
                  중단
                </button>
              </ModalBtnWrapper>
            </ModalDesc>
          </ModalContent>
        </CustomModal>
      )}

      {isLogoutVisible && (
        <CustomModal isVisible={isLogoutVisible}>
          <ModalContent>
            <h2>로그아웃</h2>
            <ModalDesc>
              <p>
                로그아웃 시 현재 페이지에서 나가게 됩니다.
                <br />
                <strong>로그아웃 하시겠습니까?</strong>
              </p>
              <ModalBtnWrapper>
                <button type="button" onClick={onCancelLogout}>
                  취소
                </button>
                <button type="button" onClick={onConfirmLogout}>
                  로그아웃
                </button>
              </ModalBtnWrapper>
            </ModalDesc>
          </ModalContent>
        </CustomModal>
      )}

      <Question>
        <a href="https://forms.gle/BPgRfe8CyLavyajt8" target="_blank" rel="noreferrer">
          <span>I2B Toolkit에 대해 궁금점이 있으신가요?</span>
        </a>
      </Question>
    </>
  );
};
export default Home;
