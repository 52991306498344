import styled from "styled-components";

export const Card = styled.div`
  margin: 0 0 20px 0;

  &:last-of-type {
    margin: 0;
  }

  input[type="radio"] {
    width: 0;
    height: 0;
    position: absolute;
    left: -9999px;
  }

  input[type="radio"] + label {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 240px;
    padding: 6px;
    border: 1px solid transparent;
    border-radius: 4px;
    transition: border-color 300ms ease-in-out, color 300ms ease-in-out;
    cursor: pointer;

    h3 {
      margin: 0 9px 2px 9px;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      text-align: left;
      color: #353535;
    }

    p {
      margin: 0 9px 9px 9px;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: #8b95a1;
    }

    img {
      display: block;
      width: 228px;
      height: 143px;
      border-radius: 4px;
      margin: 0 0 12px 0;
    }
  }

  input[type="radio"]:checked + label {
    border: 1px solid rgba(69, 86, 238, 1);

    h3 {
      color: rgba(69, 86, 238, 1);
    }
  }

  input[type="radio"]:disabled + label {
    cursor: default;
  }
`;
