import styled, { keyframes } from "styled-components";
import { Link } from "react-router-dom";
import { flexbox, flexboxColumn } from "styles/mixins";
import home_banner_arrow from "assets/home_banner_arrow.svg";
import home_banner_workshop from "assets/home_banner_workshop.svg";
import ic_plus_white from "assets/ic_plus_white.svg";
import ic_question from "assets/ic_question_home.svg";

export const Layout = styled.div`
  min-height: 100vh;
  display: flex;
  // overflow-y: auto;
`;

export const Main = styled.main`
  position: relative;
  width: 1198px;
  padding: 57px 0 0 58px;
`;

export const LinkWrapper = styled.div`
  display: flex;
  gap: 20px;
  margin: 0 0 50px 0;

  a {
    ${flexboxColumn("center", "flex-start")};
    width: 460px;
    height: 124px;
    color: #000;
    // font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    border-radius: 8px;
    :hover,
    :focus {
      color: #000;
    }
  }

  a:first-of-type {
    padding: 0 45px 0 26px;
    background-color: #5a7cf8;
    background-image: url(${home_banner_arrow});
    background-repeat: no-repeat;
    background-position: right 48px top 19px;
    background-size: 110px 86px;
  }

  a:nth-of-type(2) {
    padding: 12px 21px 12px 26px;
    background-color: #f6dd66;
    background-image: url(${home_banner_workshop});
    background-repeat: no-repeat;
    background-position: right 21px top 12px;
    background-size: 156px 100px;
  }
`;

export const FilterWrapper = styled.div`
  ${flexbox("space-between", "center")};
  max-width: 940px;
  // margin: 0 0 30px 0;
`;

export const DefaultView = styled.div`
  ${flexbox("flex-start", "center")};
  height: calc(100vh - 267px);

  > div {
    position: relative;
    left: 357px;

    ${flexboxColumn("center", "center")};
    h2 {
      font-weight: 500;
      font-size: 24px;
      line-height: 36px;
      color: #c2c2c2;
      margin: 6px 0 0 0;
    }
  }
`;

export const StyledLink = styled.button`
  height: 36px;
  padding: 0 21px 0 34px;
  border-radius: 20px;
  background-color: #181818;
  font-weight: 500;
  font-size: 14px;
  line-height: 36px;
  color: #fff;
  background-image: url(${ic_plus_white});
  background-repeat: no-repeat;
  background-position: 20px center;
  background-size: 9px 9px;

  :hover,
  :focus {
    color: #fff;
  }
`;

// 모달

export const ModalContent = styled.div`
  width: 360px;
  border-radius: 8px;
  background-color: #ffffff;

  h2 {
    padding: 18px 0 14px;
    border-bottom: 1px solid #f3f3f3;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #000000;
  }
`;

export const ModalDesc = styled.div`
  p {
    ${flexboxColumn("center", "center")};
    height: 96px;
    margin: 10px 0;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    text-align: center;
    color: #000000;
    strong {
      font-weight: 500;
      color: #ce5e41;
    }
    span {
      color: #808080;
    }
  }
`;

export const ModalBtnWrapper = styled.div`
  ${flexbox("center", "center")};
  width: 100%;
  padding: 0 0 16px 0;
  button {
    ${flexbox("center", "center")};
    height: 44px;
    width: 155px;
    border-radius: 100px;
    font-weight: 700;
    font-size: 15px;
    line-height: 22px;
    transition: background-color 300ms ease-in-out, border-color 300ms ease-in-out, color 300ms ease-in-out;
  }

  button:first-of-type {
    background-color: #f3f3f3;
    color: #000000;
    margin: 0 10px 0 0;

    :hover,
    :focus {
      background-color: #c2c2c2;
    }
  }

  button:last-of-type {
    background-color: ${(props) => (props.hasInput ? "#4556EE" : "#ce5e41")};
    color: #ffffff;

    :hover,
    :focus {
      background-color: ${(props) => (props.hasInput ? "#3745BE" : "#a54b34")};
    }

    &:disabled {
      background-color: #f3f3f3;
      color: #d6d6d6;
      cursor: default;
    }
  }
`;

export const ModalForm = styled.div`
  ${flexboxColumn};
`;

export const ModalInputWrapper = styled.div`
  ${flexboxColumn("center", "flex-start")};
  padding: 25px 20px 21px;

  label {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    color: #000000;
    margin: 0 0 4px 0;
  }

  input {
    caret-color: #4556ee;
    width: 100%;
    height: 44px;
    padding: 0 18px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    color: #000000;
    transition: border-color 300ms ease-in-out;

    &:focus {
      border: 1px solid #181818;
    }
  }
`;

export const Question = styled.div`
  position: fixed;
  right: 40px;
  bottom: 5%;

  a {
    ${flexbox("flex-start", "center")};
    height: 54px;
    padding: 0 66px 0 0;
    background-image: url(${ic_question});
    background-size: 54px 54px;
    background-repeat: no-repeat;
    background-position: right center;

    span {
      font-weight: 500;
      font-size: 12px;
      line-height: 22px;
      color: #000000;
    }
  }

  @media only screen and (max-height: 770px) {
    right: 2%;
    bottom: 3%;
  }
`;
