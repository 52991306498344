import { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import axiosPublic from "utils/axiosPublic";
import { TestTabContext } from "context/test-tab-context";
import { TESTER } from "utils/data";
import { defaultTabs } from "../TestPreview";
import TabOutlet from "components/test/TabOutlet";
import { testStepIdDic } from "utils/data";
import { Container } from "../TestPreview/styles";
import Expired from "components/test/TabItems/Expired";

const Survey = () => {
  const { search } = useLocation();

  const [projectId, setProjectId] = useState();
  const [testerUid, setTesterUid] = useState();
  const [testCaseAnswerStep, setTestCaseAnswerStep] = useState();

  useEffect(() => {
    if (search) {
      const searchParams = new URLSearchParams(search);
      setProjectId(searchParams.get("projectId"));
      setTesterUid(searchParams.get("testerUid"));
      setTestCaseAnswerStep(searchParams.get("testCaseAnswerStep"));
    }
  }, [search]);

  // ---
  const [layoutArr, setLayoutArr] = useState([]);
  const [linkData, setLinkData] = useState();
  const [tabData, setTabData] = useState([]);
  const [greetings, setGreetings] = useState("");
  const [closingWords, setClosingWords] = useState("");
  const [savedAnswer, setSavedAnswer] = useState();

  const getLayoutData = (projectId, testerUid) => {
    return axiosPublic.get(`/api/v1/open/projects/${projectId}/produce/layout`, {
      params: {
        testerUid,
      },
    });
  };

  const getLinkData = (projectId, testerUid) => {
    return axiosPublic.get(`/api/v1/open/projects/${projectId}/test/prototype/url`, {
      params: {
        testerUid,
      },
    });
  };

  const getTabData = (projectId, testerUid) => {
    return axiosPublic.get(`/api/v1/open/projects/${projectId}/test/survey`, {
      params: {
        testerUid,
      },
    });
  };

  const getSavedAnswer = (projectId, testerUid) => {
    return axiosPublic.get(`/api/v1/open/projects/${projectId}/test/case/${testerUid}`);
  };

  const [isExpired, setIsExpired] = useState(false);
  useEffect(() => {
    if (testCaseAnswerStep === "TEST_CASE_EXPIRE") {
      setIsExpired(true);
    } else {
      if (projectId && testerUid?.length > 0) {
        Promise.allSettled([getLayoutData(projectId, testerUid), getLinkData(projectId, testerUid), getTabData(projectId, testerUid), getSavedAnswer(projectId, testerUid)]).then((result) => {
          if (result[0].status === "fulfilled") {
            if (result[0].value?.data?.result?.produceLayouts?.length > 0) {
              setLayoutArr(result[0].value?.data?.result?.produceLayouts);
            }
          } else {
            window.alert("화면 데이터 조회에 실패했습니다. 관리자에게 문의해주세요!");
          }

          if (result[1].status === "fulfilled") {
            setLinkData(result[1].value.data.result);
          } else {
            window.alert("프로토타입 링크 조회에 실패했습니다. 관리자에게 문의해주세요!");
          }

          if (result[2].status === "fulfilled") {
            const { greetings, closingWords, testSurveyIndices } = result[2].value.data.result;
            if (greetings?.length > 0) setGreetings(greetings);
            if (closingWords?.length > 0) setClosingWords(closingWords);
            if (testSurveyIndices?.length > 0) {
              const targetTabs = [...testSurveyIndices].slice(0, testSurveyIndices.length - 1);
              const newTabs = targetTabs.map((item, i) => {
                const { testSurveyType, use } = item;
                const id = testStepIdDic[testSurveyType];
                return { id, testSurveyType, blocked: !use };
              });
              setTabData(newTabs);
            }
          } else {
            window.alert("설문조사 문항 조회에 실패했습니다. 관리자에게 문의해주세요!");
          }

          if (result[3].status === "fulfilled") {
            setSavedAnswer(result[3].value.data.result);
          } else {
            window.alert("기존에 저장해둔 설문 응답 조회에 실패했습니다. 관리자에게 문의해주세요!");
          }
        });
      }
    }
  }, [projectId, testerUid, testCaseAnswerStep]);

  const tabCtx = useContext(TestTabContext);
  const { currentTabId, setCurrentTabId } = tabCtx;

  useEffect(() => {
    const selectedTabs = tabData.filter((item) => item.blocked === false);
    const orderedTabs = [...defaultTabs.slice(0, 2), ...selectedTabs, ...defaultTabs.slice(2)];
    tabCtx.setModifiedTabArr(orderedTabs);
  }, [tabData]);

  useEffect(() => {
    if (savedAnswer) {
      const { testCaseAnswerStepType } = savedAnswer;
      if (testCaseAnswerStepType) {
        const lastSavedStep = testStepIdDic[testCaseAnswerStepType];
        setCurrentTabId(lastSavedStep);
      }
    }
  }, [savedAnswer]);

  if (isExpired) {
    return (
      <>
        <Container>
          <Expired />
        </Container>
      </>
    );
  }

  return (
    <>
      <Container>
        <TabOutlet
          currentTabId={currentTabId}
          status={TESTER}
          layoutArr={layoutArr}
          link={linkData}
          greetings={greetings}
          closingWords={closingWords}
          projectId={projectId}
          testerUid={testerUid}
          testCaseAnswerStep={testCaseAnswerStep}
          savedAnswer={savedAnswer}
        />
      </Container>
    </>
  );
};

export default Survey;
