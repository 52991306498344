const theme = {
  white: "#fff",
  black: "#000",

  blue: "#4556EE",
  lightBlue: "#8FD3DF",

  orange: "#CE5E41",
  yellow: "#F6DD66",
  grey: "#727272",
  helperGrey: "#808080",
  bgGrey: "#f2f2f2",

  nav_bg: "#222",
  nav_color: "#A6A9AE",

  buttonBlack_bg: "#181818",
  buttonGrey_bg: "#f3f3f3",
  buttonGrey_color: "#D6D6D6",

  register_bg: "#83BEA4",

  placeholder: "#B1B1B1",

  accentLight: "",
  accent: "",
  accentDark: "",

  inputBorder: "#E0E0E0",
  inputBorderHover: "",
  inputBorderFocus: "",

  activeNavBg: "#E9E9E9",

  disabled: "",
  disabled_bg: "",
};

export default theme;
