import styled from "styled-components";

export const Radio = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 28px;

  input[type="radio"] {
    width: 0;
    height: 0;
    position: absolute;
    left: -9999px;
  }

  input[type="radio"] + label {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;

    font-weight: 500;
    font-size: 20px;
    line-height: 36px;
    color: #9d9d9d;

    transition: color 300ms ease-in-out, font-weight 300ms ease-in-out;
    cursor: pointer;
  }

  input[type="radio"]:checked + label {
    color: #000;
    font-weight: 700;
    z-index: 1;
  }
`;
