import { useState, useEffect } from "react";
import { Input, Button, Self } from "components/analysis/SelfInput/styles";

const KeywordSelfInput = ({ typedKeywordArr, onChangeTypedKeyword, isMax, isViewMode }) => {
  const [isSelf, setIsSelf] = useState(false);

  const onClickSelfBtn = () => setIsSelf(true);

  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      onChangeTypedKeyword([...typedKeywordArr, e.target.value], e.target.value, false);
      setIsSelf(false);
    } else {
      return;
    }
  };
  const onBlur = (e) => {
    onChangeTypedKeyword([...typedKeywordArr, e.target.value], e.target.value, false);
    setIsSelf(false);
  };

  const onClickDelete = (e) => {
    const target = e.target.parentElement.firstChild.innerText;
    const arr = [...typedKeywordArr];
    const newArr = arr.filter((item) => item.keywordName !== target);
    onChangeTypedKeyword(newArr, target, true);
  };

  return (
    <>
      {typedKeywordArr &&
        typedKeywordArr.map((item, i) => (
          <Self key={i} className="self-input-div">
            <span>{item.keywordName}</span>
            <button type="button" onClick={onClickDelete} disabled={isViewMode}></button>
          </Self>
        ))}
      {!isSelf && (
        <Button type="button" onClick={onClickSelfBtn} disabled={isMax || isViewMode}>
          직접 입력
        </Button>
      )}
      {isSelf && <Input type="text" onKeyDown={onKeyDown} onBlur={onBlur} disabled={isViewMode} />}
    </>
  );
};

export default KeywordSelfInput;
