import { Wrapper, Radio } from "./styles";
import { TESTER } from "utils/data";
const FiveRadio = ({ name, arr, status, selectedValue, onChange }) => {
  const isDisabled = status === TESTER ? false : true;
  return (
    <Wrapper>
      {arr.map((item, i) => (
        <Radio key={item.text}>
          <input type="radio" name={name} id={`${name}${item.value}`} disabled={isDisabled} value={item.value} checked={item.value === selectedValue} onChange={onChange} />
          <label htmlFor={`${name}${item.value}`} disabled={isDisabled}>
            {item.text}
          </label>
        </Radio>
      ))}
    </Wrapper>
  );
};

export default FiveRadio;
