import { useState, useEffect, useRef } from "react";
import useSWR from "swr";
import { useParams, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { fetcherPrivate } from "utils/fetcher";
import useWarnUnsavedChange from "hooks/useWarnUnsaveChange";
import Layout from "layouts/Layout";
import Content from "layouts/Content";
import ContentHeading from "layouts/Content/ContentHeading";
import Aside from "layouts/Aside";
import AsideInfo from "layouts/Aside/AsideInfo";
import TagList from "components/production/TagList";
import popup_prod from "assets/popup_prod.svg";
import getRandomColor from "utils/getRandomColor";
import CustomModal from "components/common/CustomModal";
import HelpModalContent from "components/common/HelpModalContent";
import { TagWrapper, Tag, CardWrapper, StyledLink } from "./styles";
import InputInsightImage from "components/common/InputInsightImage";
import { CONCEPT_DESIGN, TEST_FORM, prodGuide } from "utils/data";
import { templateArr } from "utils/data";
import TemplateInfo from "components/production/TemplateInfo";
import axiosPrivate from "utils/axiosPrivate";
import convertImgToFile from "utils/convertImgToFile";
import ToastPopup from "components/common/ToastPopup";
import useViewMode from "hooks/useViewMode";
import { useReactToPrint } from "react-to-print";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import defaultImgProduct from "assets/defaultImgProduct.png";

const progressData = {
  prev: CONCEPT_DESIGN,
  next: TEST_FORM,
};

const defaultTemplate = {
  id: 0,
  templateId: 0,
  isNew: true,
  isModified: false,
  name: "",
  image: "",
  registerType: "MEMBER",
  produceLayouts: [
    {
      layoutId: 1,
      priority: 1,
      layoutImage: "",
      fileName: "",
      layoutName: "홈",
      fileDataUrl: "",
      type: "",
    },
    {
      layoutId: 2,
      priority: 2,
      layoutImage: "",
      fileName: "",
      layoutName: "탐색",
      fileDataUrl: "",
      type: "",
    },
    {
      layoutId: 3,
      priority: 3,
      layoutImage: "",
      fileName: "",
      layoutName: "상세",
      fileDataUrl: "",
      type: "",
    },
    {
      layoutId: 4,
      priority: 4,
      layoutImage: "",
      fileName: "",
      layoutName: "구매",
      fileDataUrl: "",
      type: "",
    },
    {
      layoutId: 5,
      priority: 5,
      layoutImage: "",
      fileName: "",
      layoutName: "리뷰",
      fileDataUrl: "",
      type: "",
    },
  ],
};

const Production = () => {
  let navigate = useNavigate();
  const scrollRef = useRef(null);
  const { id } = useParams();
  const [progressIng, setProgressIng] = useState(false);

  const isViewMode = useViewMode();

  // pdf 추출
  const printRef = useRef();

  const onClickPdf = useReactToPrint({ content: () => printRef.current });

  //디폴트 이미지 저장
  const [file, setFile] = useState(null);
  const defultImage = async () => {
    try {
      const data = {
        layoutImage: defaultImgProduct,
        fileName: "defaultImgProduct.jpg",
        type: "image/jpg",
      };
      const newFile = await convertImgToFile(data);
      setFile(newFile);
    } catch (err) {
      console.log(err);
      window.alert("이미지 저장 실패!");
    }
  };
  useEffect(() => {
    defultImage();
  }, []);

  // 키워드
  const { data: designKeywordArr, error: designKeywordArrError } = useSWR(
    `/api/v1/projects/${id}/concept/keywords?keywordType=DESIGN_CONCEPT`,
    (url) => fetcherPrivate(url),
    {
      revalidateOnFocus: false,
    }
  );
  const { data: mainPointKeywordArr, error: mainPointKeywordArrError } = useSWR(
    `/api/v1/projects/${id}/concept/keywords?keywordType=MAIN_POINT`,
    (url) => fetcherPrivate(url),
    {
      revalidateOnFocus: false,
    }
  );

  const [feature, setFeature] = useState([]);
  const [design, setDesign] = useState([]);

  useEffect(() => {
    if (designKeywordArr?.result?.length > 0) {
      setDesign(designKeywordArr.result);
    }
  }, [designKeywordArr]);

  useEffect(() => {
    if (mainPointKeywordArr?.result?.length > 0) {
      setFeature(mainPointKeywordArr.result);
    }
  }, [mainPointKeywordArr]);

  // 태그 랜덤 배경색
  const [featureRandomBgArr, setFeatureRandomBgArr] = useState([]);
  const [designRandomBgArr, setDesignRandomBgArr] = useState([]);
  useEffect(() => {
    setFeatureRandomBgArr(getRandomColor(feature?.length));
  }, [feature]);

  useEffect(() => {
    setDesignRandomBgArr(getRandomColor(design?.length));
  }, [design]);

  // 도움말 팝업
  const [isHelpVisible, setIsHelpVisible] = useState(false);
  const onClickCloseModal = () => setIsHelpVisible(false);
  const onClickHelp = () => setIsHelpVisible(true);

  // --------------------------------------------------------------------------------------------
  // 템플릿
  const {
    data: layoutData,
    error: layoutError,
    mutate: layoutMutate,
  } = useSWR(
    `/api/v1/projects/${id}/produce/layout`,
    (url) => fetcherPrivate(url),
    { revalidateOnFocus: false }
  );

  const [currentTemplate, setCurrentTemplate] = useState(defaultTemplate);

  useEffect(() => {
    if (layoutData?.result?.produceLayouts?.length > 0) {
      // 서버에 저장해 둔 데이터 있는 경우
      const modifiedData = {
        ...layoutData?.result,
        isNew: false,
        modifiedNameIdArr: [],
        modifiedImgIdArr: [],
      };
      setCurrentTemplate(modifiedData);
    } else {
      setCurrentTemplate(defaultTemplate);
    }
  }, [layoutData]);

  // aside 예시 템플릿 선택
  const onClickTemplateExample = (template, isChecked) => {
    if (isChecked) {
      // 다른 템플릿 선택
      const targetTemplate = templateArr.find(
        (item) => item.templateId === template.templateId
      );
      if (currentTemplate.isNew) {
        if (currentTemplate.isNameModified || currentTemplate.isImgModified) {
          if (
            window.confirm(
              "템플릿 변경 시 기존에 입력한 데이터가 모두 삭제됩니다. 계속 진행하시겠습니까?"
            )
          ) {
            setCurrentTemplate({ ...targetTemplate, isNew: true });
          } else {
            return;
          }
        } else {
          setCurrentTemplate({ ...targetTemplate, isNew: true });
        }
      } else {
        if (
          window.confirm(
            "템플릿 변경 시 기존에 입력한 데이터가 모두 삭제됩니다. 계속 진행하시겠습니까?"
          )
        ) {
          setCurrentTemplate({ ...targetTemplate, isNew: true });
        } else {
          return;
        }
      }
    } else {
      // 다시 자기 자신 선택 -> 선택 해제 -> default 상태로
      const newId = uuidv4();
      const newTemplate = {
        ...defaultTemplate,
        id: newId,
        templateId: 0,
      };

      if (currentTemplate.isNew) {
        // 그냥 바로 default로
        setCurrentTemplate(newTemplate);
      } else {
        // 확인 후 default로
        if (
          window.confirm(
            "템플릿 선택 해제 시 기존에 입력한 데이터가 모두 삭제됩니다. 계속 진행하시겠습니까?"
          )
        ) {
          // default로
          setCurrentTemplate(newTemplate);
        } else {
          return;
        }
      }
    }
  };

  const onChangeViewName = (imageId, viewName) => {
    const copiedLayoutArr = [...currentTemplate.produceLayouts];
    const targetLayout = copiedLayoutArr.find(
      (item) => item.layoutId === imageId
    );
    const modifiedLayout = { ...targetLayout, layoutName: viewName };
    const modifiedLayoutArr = copiedLayoutArr.map((item) =>
      item.layoutId === imageId ? modifiedLayout : item
    );
    let modifiedTemplate;
    if (!currentTemplate.isNew) {
      const newNameIdArr = [...currentTemplate.modifiedNameIdArr, imageId];
      modifiedTemplate = {
        ...currentTemplate,
        produceLayouts: modifiedLayoutArr,
        isNameModified: true,
        modifiedNameIdArr: newNameIdArr,
      };
    } else {
      modifiedTemplate = {
        ...currentTemplate,
        produceLayouts: modifiedLayoutArr,
        isNameModified: true,
      };
    }
    setCurrentTemplate(modifiedTemplate);
  };

  const onChangeFile = (imageId, file, fileDataUrl) => {
    const copiedLayoutArr = [...currentTemplate.produceLayouts];
    const targetLayout = copiedLayoutArr.find(
      (item) => item.layoutId === imageId
    );
    const modifiedLayout = { ...targetLayout, layoutImage: file, fileDataUrl };
    const modifiedLayoutArr = copiedLayoutArr.map((item) =>
      item.layoutId === imageId ? modifiedLayout : item
    );
    let modifiedTemplate;
    if (currentTemplate.isNew) {
      modifiedTemplate = {
        ...currentTemplate,
        produceLayouts: modifiedLayoutArr,
        isImgModified: true,
      };
    } else {
      if (file) {
        const newImgIdArr = [...currentTemplate.modifiedImgIdArr, imageId];
        modifiedTemplate = {
          ...currentTemplate,
          produceLayouts: modifiedLayoutArr,
          isImgModified: true,
          modifiedImgIdArr: newImgIdArr,
        };
      } else {
        const newImgIdArr = [...currentTemplate.modifiedImgIdArr].filter(
          (item) => item !== imageId
        );
        modifiedTemplate = {
          ...currentTemplate,
          produceLayouts: modifiedLayoutArr,
          isImgModified: true,
          modifiedImgIdArr: newImgIdArr,
        };
      }
    }
    setCurrentTemplate(modifiedTemplate);
  };

  // 토스트 팝업
  const [isToastVisible, setIsToastVisible] = useState(false);

  const onSubmit = (e, item) => {
    //e.preventDefault();
    setProgressIng(true);
    const formData = new FormData();

    if (currentTemplate.isNew) {
      // 새로 입력
      formData.append(`templateId`, currentTemplate.templateId);
      if (currentTemplate.registerType === "ADMIN") {
        let fileArr = [];
        return convertImgToFile(currentTemplate.produceLayouts[0]).then(
          (res) => {
            fileArr.push(res);
            convertImgToFile(currentTemplate.produceLayouts[1]).then((res) => {
              fileArr.push(res);
              convertImgToFile(currentTemplate.produceLayouts[2]).then(
                (res) => {
                  fileArr.push(res);
                  convertImgToFile(currentTemplate.produceLayouts[3]).then(
                    (res) => {
                      fileArr.push(res);
                      convertImgToFile(currentTemplate.produceLayouts[4])
                        .then((res) => {
                          fileArr.push(res);
                        })
                        .then(() => {
                          currentTemplate.produceLayouts.forEach((item, i) => {
                            const { layoutName, priority } = item;
                            formData.append(
                              `produceLayouts[${i}].image`,
                              fileArr[i]
                            );
                            formData.append(
                              `produceLayouts[${i}].imageName`,
                              layoutName
                            );
                            formData.append(
                              `produceLayouts[${i}].priority`,
                              priority
                            );
                          });
                          axiosPrivate
                            .put(
                              `/api/v1/projects/${id}/produce/layout`,
                              formData
                            )
                            .then((res) => {
                              setIsToastVisible(true);
                              setProgressIng(false);
                              layoutMutate();
                              navigate(item);
                            })
                            .catch((err) => {
                              console.log(err);
                              window.alert(
                                "화면 구성 저장에 실패했습니다. 다시 시도해주세요!"
                              );
                              navigate(item);
                            });
                        });
                    }
                  );
                }
              );
            });
          }
        );
      } else {
        // 사용자 직접 등록 템플릿 저장
        const layoutArr = [...currentTemplate.produceLayouts];
        layoutArr.forEach((item, i) => {
          const { layoutImage, layoutName, priority } = item;
          if (layoutImage) {
            formData.append(`produceLayouts[${i}].image`, layoutImage);
          } else {
            formData.append(`produceLayouts[${i}].image`, file);
          }
          formData.append(`produceLayouts[${i}].imageName`, layoutName);
          formData.append(`produceLayouts[${i}].priority`, priority);
        });
      }
    } else {
      // 이미 저장된 데이터 수정

      //템플릿 레이아웃 이미지가 없을 경우 디폴트 이미지 저장 및 imgIdArr에 추가
      currentTemplate.produceLayouts.map((item, index) => {
        if (item.layoutImage === "") {
          currentTemplate.modifiedImgIdArr.push(
            currentTemplate.produceLayouts[index].layoutId
          );
          currentTemplate.produceLayouts[index].layoutImage = file;
        }
      });
      const nameIdArr = [...currentTemplate.modifiedNameIdArr];
      const imgIdArr = [...currentTemplate.modifiedImgIdArr];
      if (nameIdArr?.length > 0 || imgIdArr?.length > 0) {
        const bothIdArr = nameIdArr.filter((item) => imgIdArr.includes(item));
        const onlyNameArr = nameIdArr.filter(
          (item) => !imgIdArr.includes(item)
        );
        const onlyImgArr = imgIdArr.filter((item) => !nameIdArr.includes(item));
        const unmodifiedArr = [...currentTemplate.produceLayouts].filter(
          (item) => {
            if (
              nameIdArr.includes(item.layoutId) ||
              imgIdArr.includes(item.layoutId)
            ) {
              return false;
            } else {
              return true;
            }
          }
        );

        const nameIndexStart = bothIdArr.length;
        const imgIndexStart = bothIdArr.length + onlyNameArr.length;
        const unmodifiedIndexStart =
          bothIdArr.length + onlyNameArr.length + onlyImgArr.length;

        bothIdArr.forEach((id, i) => {
          const targetItem = [...currentTemplate.produceLayouts].find(
            (item) => item.layoutId === id
          );
          const { layoutImage, layoutName, priority, layoutId } = targetItem;
          formData.append(`produceLayouts[${i}].imageId`, layoutId);
          formData.append(`produceLayouts[${i}].priority`, priority);
          formData.append(`produceLayouts[${i}].image`, layoutImage);
          formData.append(`produceLayouts[${i}].imageName`, layoutName);
        });

        onlyNameArr.forEach((id, i) => {
          const targetItem = [...currentTemplate.produceLayouts].find(
            (item) => item.layoutId === id
          );
          const { layoutName, priority, layoutId } = targetItem;
          formData.append(
            `produceLayouts[${nameIndexStart + i}].imageId`,
            layoutId
          );
          formData.append(
            `produceLayouts[${nameIndexStart + i}].priority`,
            priority
          );
          formData.append(
            `produceLayouts[${nameIndexStart + i}].imageName`,
            layoutName
          );
        });

        onlyImgArr.forEach((id, i) => {
          const targetItem = [...currentTemplate.produceLayouts].find(
            (item) => item.layoutId === id
          );
          const { layoutImage, priority, layoutId } = targetItem;
          formData.append(
            `produceLayouts[${imgIndexStart + i}].imageId`,
            layoutId
          );
          formData.append(
            `produceLayouts[${imgIndexStart + i}].priority`,
            priority
          );
          formData.append(
            `produceLayouts[${imgIndexStart + i}].image`,
            layoutImage
          );
        });

        unmodifiedArr.forEach((item, i) => {
          const { layoutId, priority } = item;
          formData.append(
            `produceLayouts[${unmodifiedIndexStart + i}].imageId`,
            layoutId
          );
          formData.append(
            `produceLayouts[${unmodifiedIndexStart + i}].priority`,
            priority
          );
        });
      }

      // if (currentTemplate.registerType === "ADMIN") {
      // }
    }

    for (let key of formData.keys()) {
      console.log(key, ":", formData.get(key));
    }

    axiosPrivate
      .put(`/api/v1/projects/${id}/produce/layout`, formData)
      .then((res) => {
        setIsToastVisible(true);
        setProgressIng(false);
        layoutMutate();
        navigate(item);
      })
      .catch((err) => {
        setProgressIng(false);
        console.log(err);
        window.alert("화면 이미지 파일 5개를 모두 업로드해주세요!");
        navigate(item);
      });
  };

  return (
    <>
      {progressIng && (
        <Box
          sx={{ position: "fixed", zIndex: "1000", left: "50%", top: "50%" }}>
          <CircularProgress size="100px" />
          {/*<Box sx={{ marginTop: "10px", fontSize: "30px", fontWeight: "500", color: "white", display: "flex", justifyContent: "center" }}>저장중</Box>*/}
        </Box>
      )}

      {isToastVisible && (
        <ToastPopup
          isToastVisible={isToastVisible}
          setIsToastVisible={setIsToastVisible}>
          저장에 성공했습니다!
        </ToastPopup>
      )}
      <Layout onClickPdf={onClickPdf} onSave={onSubmit} isViewMode={isViewMode}>
        <Content
          progressData={progressData}
          ref={scrollRef}
          onSave={onSubmit}
          isViewMode={isViewMode}>
          <form onSubmit={onSubmit} ref={printRef}>
            <ContentHeading>
              <h1>
                <span>화면을 직접 구성</span>하여 제품/서비스의 완성도를
                높여보세요.
              </h1>
              {/* 페이지 이동시 자동 저장으로 주석처리
              <button type="submit" disabled={isViewMode}>
                저장
              </button>
              */}
            </ContentHeading>
            <TagWrapper>
              <Tag>
                <h4>핵심 기능 키워드</h4>
                {feature?.length > 0 ? (
                  <TagList arr={feature} randomBgArr={featureRandomBgArr} />
                ) : (
                  <StyledLink to={`/concept/feature/${id}`}>
                    핵심 기능 키워드를 등록해보세요!
                  </StyledLink>
                )}
              </Tag>
              <Tag>
                <h4>디자인 컨셉 키워드</h4>
                {design?.length > 0 ? (
                  <TagList arr={design} randomBgArr={designRandomBgArr} />
                ) : (
                  <StyledLink
                    to={`/concept/design/${id}`}
                    className="is-design">
                    디자인 컨셉 키워드를 등록해보세요!
                  </StyledLink>
                )}
              </Tag>
            </TagWrapper>
            <CardWrapper>
              {currentTemplate.produceLayouts?.length > 0 &&
                currentTemplate.produceLayouts.map((item, i) => (
                  <InputInsightImage
                    key={`${currentTemplate.id}${i}`}
                    currentBench={currentTemplate}
                    item={currentTemplate.produceLayouts[i]}
                    isComment={currentTemplate.produceLayouts[i].commentCheck}
                    imageId={currentTemplate.produceLayouts[i].layoutId}
                    isTypeAdmin={currentTemplate.registerType === "ADMIN"}
                    image={currentTemplate.produceLayouts[i].layoutImage}
                    viewName={currentTemplate.produceLayouts[i]?.layoutName}
                    onChangeViewName={onChangeViewName}
                    onChangeFile={onChangeFile}
                    isFirst={i === 0}
                    isLast={i === 4}
                    isNew={currentTemplate.isNew}
                    isViewMode={isViewMode}
                  />
                ))}
            </CardWrapper>
          </form>
        </Content>
        <Aside>
          <AsideInfo
            heading="화면 구성 입력"
            hasNoBorder
            onClickHelp={onClickHelp}>
            주요 기능과 디자인 컨셉을 반영하여 테스트를 위한 실제 화면을
            제작하는 과정입니다.
          </AsideInfo>
          <TemplateInfo
            templateArr={templateArr}
            currentTemplate={currentTemplate}
            onClickTemplateExample={onClickTemplateExample}
            isViewMode={isViewMode}
          />
        </Aside>
      </Layout>
      {isHelpVisible && (
        <CustomModal isVisible={isHelpVisible}>
          <HelpModalContent
            headingText="화면 구성 템플릿 사용 방법"
            guideArr={prodGuide}
            img={popup_prod}
            onClick={onClickCloseModal}
          />
        </CustomModal>
      )}
    </>
  );
};

export default Production;
