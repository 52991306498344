import { useContext } from "react";
import { TabContext } from "context/tab-context";
import { Wrapper, PrevBtn, NextBtn } from "./styles";

const PrevNextBtn = ({ isIntro, isOutro }) => {
  const tabCtx = useContext(TabContext);
  const onClickPrev = () => {
    tabCtx.goPrev();
  };

  const onClickNext = () => {
    tabCtx.goNext();
  };

  const nextText = isIntro ? "시작" : isOutro ? "설문 완료" : "다음";

  return (
    <Wrapper>
      {!isIntro && <PrevBtn onClick={onClickPrev}>이전</PrevBtn>}
      <NextBtn isOutro={isOutro} onClick={onClickNext}>
        {nextText}
      </NextBtn>
    </Wrapper>
  );
};

export default PrevNextBtn;
