import { useState, useEffect } from "react";
import { Input } from "./styles";

const Title = ({ index, item, currentBench, onChangeCurrentBench, onChangeBenchName, onClickDeleteBench, isViewMode, combinedBenchListLength, nextIndex }) => {
  // 벤치마킹 명 클릭 ->  현재 보고 있는 벤치마킹 변경.
  const onClickTitle = () => onChangeCurrentBench(item);

  // 수정 버튼 클릭
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingView, setIsEditingView] = useState(combinedBenchListLength);
  const onClickEdit = () => {
    setIsEditing(true);
  };

  // 수정 클릭한 경우, 벤치마킹 이름 변경
  const [name, setName] = useState("");
  const onChangeName = (e) => {
    if (e.target.value.length > 10) {
      window.alert("최대 입력 가능한 글자수는 10자입니다.");
    } else {
      setName(e.target.value);
    }
  };
  useEffect(() => {
    setIsEditingView(combinedBenchListLength);
  }, [combinedBenchListLength]);

  const onBlurName = () => {
    setIsEditing(false);
    onChangeBenchName(item.benchmarkId, name);
  };

  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      setIsEditing(false);
      onChangeBenchName(item.benchmarkId, name);
    } else {
      return;
    }
  };

  useEffect(() => {
    setName(item.name);
  }, [item.name]);

  // 삭제 버튼 클릭
  const onClickDelete = (e) => {
    e.stopPropagation();
    onClickDeleteBench(item, index);
  };

  // return 시작 ------------------------------------
  // 서버에 저장한 벤치마킹

  if (!currentBench.isNew) {
    return (
      <div onClick={onClickTitle}>
        {index !== 0 && <div className={currentBench.benchmarkId === item.benchmarkId ? "divider is-active" : nextIndex == item.benchmarkId ? "divider is-active" : "divider"} />}
        <div className={currentBench.benchmarkId === item.benchmarkId ? "title is-active" : "title"}>
          {isEditing ? (
            <Input type="text" value={name} onChange={onChangeName} onBlur={onBlurName} onKeyDown={onKeyDown} maxLength="10" disabled={isViewMode} />
          ) : (
            <div className="name-wrapper">
              {item.benchmarkIcon && <img className="benchmark-icon" src={item.benchmarkIcon} referrerPolicy="no-referrer" alt="" />}
              <span>{item.name}</span>
            </div>
          )}
          {!isEditing && (
            <div className="btn-wrapper">
              {item.registerType === "ADMIN" ? "" : <button type="button" onClick={onClickEdit}></button>}
              <button type="button" onClick={onClickDelete} disabled={isViewMode || isEditingView == 1 ? true : false}></button>
            </div>
          )}
        </div>
      </div>
    );
  }

  if (item.registerType === "ADMIN") {
    return (
      <div onClick={onClickTitle}>
        {index !== 0 && <div className={currentBench.benchmarkId === item.benchmarkId ? "divider is-active" : "divider"} />}
        <div className={currentBench.benchmarkId === item.benchmarkId ? "title is-active" : "title"}>
          <div className="name-wrapper">
            {item.benchmarkIcon && <img className="benchmark-icon" src={item.benchmarkIcon} alt="" referrerPolicy="no-referrer" />}
            <span>{item.name}</span>
          </div>

          <div className="btn-wrapper">
            <button type="button" onClick={onClickDelete} disabled={isViewMode || isEditingView == 1 ? true : false}></button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div onClick={onClickTitle}>
      {index !== 0 && <div className={currentBench.benchmarkId === item.benchmarkId ? "divider is-active" : "divider"} />}
      <div style={{ padding: isEditing && "0px", paddingRight: isEditing && "5px" }} className={currentBench.benchmarkId === item.benchmarkId ? "title is-active" : "title"}>
        {isEditing ? (
          <Input style={{ marginRight: "0px" }} type="text" value={name} onChange={onChangeName} onBlur={onBlurName} onKeyDown={onKeyDown} maxLength="10" disabled={isViewMode} />
        ) : (
          <span>{name}</span>
        )}
        {!isEditing && (
          <div className="btn-wrapper">
            <button type="button" onClick={onClickEdit} disabled={isViewMode}></button>
            <button type="button" onClick={onClickDelete} disabled={isViewMode}></button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Title;
