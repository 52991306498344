import { Wrapper } from "./styles";
import Slider from "react-slick";

const RegisterSlider = ({ data, onChangeSlider }) => {
  const sliderSettings = {
    dots: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    customPaging: () => <div></div>,
    afterChange: (index) => onChangeSlider(index),
  };

  return (
    <Wrapper>
      <Slider {...sliderSettings}>
        {data?.map((item, i) => (
          <div key={i}>
            <img src={item.img} alt={item.name} referrerPolicy="no-referrer" />
          </div>
        ))}
      </Slider>
    </Wrapper>
  );
};

export default RegisterSlider;
