import React from "react";
import { Table, Thead, Tbody, StatusTag, ReplyBtn } from "./styles";

const FeedbackTable = React.forwardRef(({ data, itemCount, onClickGender, onClickDate, onClickStatus, onClickAnswerModalOpen }, ref) => {
  const scrollData = [...data].slice(0, itemCount);

  return (
    <Table>
      <Thead>
        <tr>
          <th>이름</th>
          <th>
            <button type="button" onClick={onClickGender}>
              성별
            </button>
          </th>
          <th>연령</th>
          <th>이메일</th>
          <th>
            <button type="button" onClick={onClickDate}>
              발송일자
            </button>
          </th>
          <th>
            <button type="button" onClick={onClickStatus}>
              상태
            </button>
          </th>
          <th>답변</th>
        </tr>
      </Thead>
      <Tbody>
        {scrollData?.map((item, i) => (
          <tr key={item.testerUid}>
            <td>{item.testerName}</td>
            <td>{item?.genderType === "WOMAN" ? "여" : item.genderType === "MAN" ? "남" : item.genderType === "UNDEFINED" ? "구분없음" : ""}</td>
            <td>{item.testerAge}</td>
            <td>{item.testerEmail}</td>
            <td>{item.testCaseEmailDate}</td>
            <td>
              <StatusTag className={item.testCaseStepType === "BEFORE_PROCEEDING" ? "before" : item.testCaseStepType === "END_OF_PROGRESS" ? "end" : "proceeding"}>
                {item.testCaseStepType === "BEFORE_PROCEEDING" ? "진행 전" : item.testCaseStepType === "END_OF_PROGRESS" ? "진행 완료" : "진행 중"}
              </StatusTag>
            </td>
            <td>
              <ReplyBtn type="button" onClick={() => onClickAnswerModalOpen(item.testerUid)} disabled={item.testCaseStepType === "BEFORE_PROCEEDING"} />
            </td>
          </tr>
        ))}
        {!scrollData &&
          Array(14)
            .fill()
            .map((_, i) => (
              <tr key={i}>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            ))}
        {scrollData?.length < 14 &&
          Array(14 - scrollData.length)
            .fill()
            .map((_, i) => (
              <tr key={i}>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            ))}
        <tr ref={ref}>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      </Tbody>
    </Table>
  );
});

// const FeedbackTable = ({ data, onClickAnswerModalOpen }) => {
//   return (
//     <Table>
//       <Thead>
//         <tr>
//           <th>이름</th>
//           <th>
//             <button type="button">성별</button>
//           </th>
//           <th>연령</th>
//           <th>이메일</th>
//           <th>
//             <button type="button">발송일자</button>
//           </th>
//           <th>
//             <button type="button">상태</button>
//           </th>
//           <th>답변</th>
//         </tr>
//       </Thead>
//       <Tbody>
//         {data?.map((item, i) => (
//           <tr key={item.testerUid}>
//             <td>{item.testerName}</td>
//             <td>{item?.genderType === "WOMAN" ? "여" : item.genderType === "MAN" ? "남" : item.genderType === "UNDEFINED" ? "구분없음" : ""}</td>
//             <td>{item.testerAge}</td>
//             <td>{item.testerEmail}</td>
//             <td>{item.testCaseEmailDate}</td>
//             <td>
//               <StatusTag className={item.testCaseStepType === "BEFORE_PROCEEDING" ? "before" : item.testCaseStepType === "END_OF_PROGRESS" ? "end" : "proceeding"}>
//                 {item.testCaseStepType === "BEFORE_PROCEEDING" ? "진행 전" : item.testCaseStepType === "END_OF_PROGRESS" ? "진행 완료" : "진행 중"}
//               </StatusTag>
//             </td>
//             <td>
//               <ReplyBtn type="button" onClick={() => onClickAnswerModalOpen(item.testerUid)} disabled={item.testCaseStepType === "BEFORE_PROCEEDING"} />
//             </td>
//           </tr>
//         ))}
//         {!data &&
//           Array(15)
//             .fill()
//             .map((_, i) => (
//               <tr key={i}>
//                 <td></td>
//                 <td></td>
//                 <td></td>
//                 <td></td>
//                 <td></td>
//                 <td></td>
//                 <td></td>
//               </tr>
//             ))}
//         {data?.length < 15 &&
//           Array(15 - data.length)
//             .fill()
//             .map((_, i) => (
//               <tr key={i}>
//                 <td></td>
//                 <td></td>
//                 <td></td>
//                 <td></td>
//                 <td></td>
//                 <td></td>
//                 <td></td>
//               </tr>
//             ))}
//         <tr>
//           <td></td>
//           <td></td>
//           <td></td>
//           <td></td>
//           <td></td>
//           <td></td>
//           <td></td>
//         </tr>
//       </Tbody>
//     </Table>
//   );
// };

export default FeedbackTable;
