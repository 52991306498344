import { useState } from "react";
import { ResponsivePie } from "@nivo/pie";
import PieCustomTooltip from "../PieCustomTooltip";
import { Container, PieWrapper, TotalNum, CustomLegend, CustomLegendItem } from "./styles";
import { answerTextArr } from "utils/data";

const Pie = ({ data }) => {
  const [isHovering, setIsHovering] = useState(false);
  const [currentHoverId, setCurrentHoverId] = useState();

  const modifiedData = data.map((item) => {
    const answerBaseInfo = answerTextArr.find((obj) => obj.id === item.answer);
    const { chartId, text, color } = answerBaseInfo;
    return { id: chartId, label: text, color, value: item.answerCount, ...item };
  });

  const totalCount = data.reduce((acc, cur) => acc + cur.answerCount, 0);

  //  data에 들어오는 label 값을 이용해서 파이 차트와 커스텀 레전드 연결
  // mouse enter, mouse leave
  const onMouseEnter = (props) => {
    setCurrentHoverId(props.id);
    setIsHovering(true);
  };

  const onMouseLeave = () => {
    setCurrentHoverId(null);
    setIsHovering(false);
  };

  return (
    <Container>
      <PieWrapper>
        {modifiedData?.length > 0 && (
          <ResponsivePie
            data={modifiedData}
            margin={{ top: 8, right: 8, bottom: 8, left: 8 }}
            padAngle={0}
            innerRadius={0.6}
            cornerRadius={0}
            enableArcLabels={false}
            enableArcLinkLabels={false}
            activeOuterRadiusOffset={8}
            isInteractive={true}
            colors={["#5A7CF8", "#70BECD", "#F6DD66", "#E2814A", "#CE5E41"]}
            tooltip={({ datum }) => <PieCustomTooltip datum={datum} />}
            // tooltip={({ datum: { id, value, color } }) => <PieCustomTooltip value={value} color={color} />}
            // motionConfig={{
            // mass: 1,
            // tension: 170,
            // friction: 26,
            // clamp: false,
            // precision: 0.01,
            // velocity: 0,
            // }}
            // transitionMode="centerRadius"
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          />
        )}
        <TotalNum>
          <div>
            <p>답변 인원</p>
            <strong>{totalCount}</strong>
          </div>
        </TotalNum>
      </PieWrapper>
      <CustomLegend>
        {modifiedData?.map((item, i) => (
          <CustomLegendItem key={i} id={item.id} isActive={currentHoverId === item.id} isHovering={isHovering}>
            <p>{item.label}</p>
            <p>{item.value}명</p>
          </CustomLegendItem>
        ))}
      </CustomLegend>
    </Container>
  );
};

export default Pie;
