import styled from "styled-components";
import { flexbox } from "styles/mixins";

export const Form = styled.form`
  padding: 58px 169px 91px 169px;

  > div {
    margin: 0 0 24px 0;
    &:last-of-type {
      margin: 0 0 41px 0;
    }
  }
`;

export const Label = styled.label`
  min-width: 80px;
  margin: 0 12px 0 0;
  font-weight: 700;
  font-size: 13px;
  line-height: 20px;
  color: #727682;
`;

export const AreaWrapper = styled.div`
  ${flexbox};

  label {
    display: block;
  }
`;

export const FlexWrapper = styled.div`
  ${flexbox};
`;

export const CheckboxWrapper = styled.div`
  ${flexbox};
  flex-wrap: wrap;
  gap: 8px 6px;

  label {
    min-width: 94px;
  }
`;

export const RadioWrapper = styled.div`
  label {
    min-width: 94px !important;
  }
`;
