import { useState, useEffect } from "react";
import { Container, TextInputWrapper, TextInput, InsightBtnWrapper, EditBtn, InsightBtn, UploadWrapper, UploadPreview } from "./styles";
import Comment from "./Comment";
import { Image } from "antd";
import ic_new_comment from "assets/ic_new_comment.png";
import axiosPrivate from "utils/axiosPrivate";
import { useLocation, useParams } from "react-router-dom";

const imageMimeType = /image\/(png|jpg|jpeg)/i;

// 1장당 1mb 이하
const MAX_FILE_SIZE = 1024 * 1024 * 4;

// isNew : 서버에 저장되어 있는 벤치마킹인지 아닌지

const InputInsightImage = ({ currentBench, item, imageId, isTypeAdmin, image, viewName, onChangeViewName, onChangeFile, isFirst, isLast, isBench, onClickSelf, isNew, isViewMode, isComment }) => {
  // 화면 이름 바꾸기
  const [isEditingViewName, setIsEditingViewName] = useState(false);
  const onClickEditViewName = () => setIsEditingViewName(true);
  const [isNewComment, setIsNewComment] = useState(false);

  useEffect(() => {
    setIsNewComment(isComment);
  }, [isComment]);

  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      onChangeViewName(imageId, e.target.value);
      setIsEditingViewName(false);
    } else {
      return;
    }
  };
  const onBlur = (e) => {
    onChangeViewName(imageId, e.target.value);
    setIsEditingViewName(false);
  };
  // 코멘트
  const { id } = useParams();
  const { pathname } = useLocation();
  const [commentArr, setCommentArr] = useState([]);

  // 인사이트 입력
  const [insight, setInsight] = useState("");
  const [isTyped, setIsTyped] = useState(false);
  const onChangeInsight = (e) => setInsight(e.target.value);

  // 인사이트 코멘트 입력 글자수>0 -> 버튼 활성화
  useEffect(() => {
    if (insight?.length > 0) {
      setIsTyped(true);
    } else {
      setIsTyped(false);
    }
  }, [insight]);

  // 인사이트 코멘트 조회
  const getCommentData = () => {
    const url = pathname.includes("benchmark")
      ? `/api/v1/projects/${id}/concept/benchmarks/${currentBench.benchmarkId}/images/${imageId}/comment`
      : `/api/v1/projects/${id}/produce/layout/${imageId}/comment`;
    axiosPrivate
      .get(url)
      .then((res) => {
        setIsInsightActive(true);
        setCommentArr(res.data.result);
      })
      .catch((err) => {
        console.log(err);
        setIsInsightActive(false);
        window.alert("코멘트를 불러오는데 실패했습니다. 다시 시도해주세요!");
      });
  };

  // 인사이트 코멘트 열기
  const [isInsightActive, setIsInsightActive] = useState(false);
  const onClickInsight = (e) => {
    e.preventDefault();
    getCommentData();
    setIsNewComment(false);
  };
  const onClickCloseInsight = () => setIsInsightActive(false);

  // 인사이트 코멘트 저장
  const onClickSaveInsight = () => {
    let url, data;

    if (pathname.includes("benchmarking")) {
      url = `/api/v1/projects/${id}/concept/benchmarks/${currentBench.benchmarkId}/images/${imageId}/comment`;
      data = {
        benchmarkComment: insight,
      };
    } else {
      url = `/api/v1/projects/${id}/produce/layout/${imageId}/comment`;
      data = {
        layoutComment: insight,
      };
    }

    axiosPrivate
      .post(url, data)
      .then((res) => {
        console.log(res);
        setInsight("");
        setIsTyped(false);
      })
      .catch((err) => {
        console.log(err);
        window.alert("인사이트 저장에 실패했습니다. 다시 시도해주세요!");
      })
      .finally(() => {
        getCommentData();
      });
  };

  // 인사이트 코멘트 수정
  const onClickEditInsight = (commentId, insight) => {
    let url, data;
    if (pathname.includes("benchmarking")) {
      url = `/api/v1/projects/concept/benchmarks/comment/${commentId}`;
      data = {
        benchmarkComment: insight,
      };
    } else {
      url = `/api/v1/projects/produce/layout/comment/${commentId}`;
      data = {
        layoutComment: insight,
      };
    }

    axiosPrivate
      .patch(url, data)
      .then((res) => {
        console.log(res);
        setInsight("");
      })
      .catch((err) => {
        console.log(err);
        window.alert("인사이트 수정에 실패했습니다. 다시 시도해주세요!");
      })
      .finally(() => {
        getCommentData();
      });
  };

  // 인사이트 코멘트 삭제
  const onClickDeleteInsight = (commentId) => {
    let url;
    if (pathname.includes("benchmarking")) {
      url = `/api/v1/projects/concept/benchmarks/comment/${commentId}`;
    } else {
      url = `/api/v1/projects/produce/layout/comment/${commentId}`;
    }

    axiosPrivate
      .delete(url)
      .then((res) => {
        console.log(res);
        setInsight("");
      })
      .catch((err) => {
        console.log(err);
        window.alert("인사이트 삭제에 실패했습니다. 다시 시도해주세요!");
      })
      .finally(() => {
        getCommentData();
      });
  };

  // ------------------------------------------------------------------
  // 이미지

  // 이미 한 번 올린 파일과 그 파일의 data url
  const [imgSrc, setImgSrc] = useState(image);
  const [itemFileDataUrl, setItemFileDataUrl] = useState(item?.fileDataUrl ? item.fileDataUrl : "");

  // 새로 input 하는 파일과 그 파일의 data url
  // 파일 상태 이후에 부모 컴포넌트로 올리고 여기서는 props 받아오기 (UploadIcon참고)
  const [file, setFile] = useState();
  // 이미지 파일
  const [fileDataURL, setFileDataURL] = useState(null);

  const onChange = (e) => {
    const file = e.target.files[0];
    if (!file.type.match(imageMimeType)) {
      // 파일 체크
      return;
    }

    if (file.size > MAX_FILE_SIZE) {
      alert("파일 사이즈가 4MB를 초과했습니다.");
      return;
    }
    setFile(file);
  };

  useEffect(() => {
    if (item.fileDataURL) {
      setItemFileDataUrl(item.fileDataUrl);
    }
  }, [item]);

  useEffect(() => {
    let fileReader,
      isCancel = false;

    if (file) {
      fileReader = new FileReader();
      fileReader.onload = (e) => {
        const { result } = e.target;
        if (result && !isCancel) {
          setFileDataURL(result);
          onChangeFile(imageId, file, result);
        }
      };
      fileReader.onabort = () => {
        console.log("abort!");
      };
      fileReader.onerror = () => {
        console.log("error!");
      };

      fileReader.readAsDataURL(file);
    }
    return () => {
      isCancel = true;
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort();
      }
    };
  }, [file]);

  const onClickLabel = () => {
    if (isBench) {
      return onClickSelf();
    }
  };
  const onClickDelete = () => {
    setFile(null);
    setFileDataURL();
    setItemFileDataUrl();
    setImgSrc();
    onChangeFile(imageId, "", "");
  };

  //  선택 입력인 경우
  if (isTypeAdmin) {
    return (
      <Container>
        {/* 상단  - 인풋, 인사이트 코멘트*/}
        <>
          <TextInputWrapper>
            <p>{viewName ? viewName : "화면 명"}</p>
            <InsightBtnWrapper>
              {isNewComment == true ? <img style={{ position: "relative", left: "25px", bottom: "5px" }} src={ic_new_comment} alt="comment" referrerPolicy="no-referrer" /> : ""}
              <InsightBtn type="button" onClick={onClickInsight} isActive={isInsightActive} disabled={isNew} />
              <Comment
                isInsightActive={isInsightActive}
                setIsInsightActive={setIsInsightActive}
                onClickCloseInsight={onClickCloseInsight}
                commentArr={commentArr}
                isLast={isLast}
                insight={insight}
                isTyped={isTyped}
                onChangeInsight={onChangeInsight}
                onClickSaveInsight={onClickSaveInsight}
                onClickEditInsight={onClickEditInsight}
                onClickDeleteInsight={onClickDeleteInsight}
              />
            </InsightBtnWrapper>
          </TextInputWrapper>
        </>
        <div>
          <UploadPreview>
            <Image src={image} alt="view example image" referrerPolicy="no-referrer" />
          </UploadPreview>
        </div>
      </Container>
    );
  }

  // 직접 입력인 경우
  return (
    <Container>
      {/* 상단  - 인풋, 인사이트 코멘트*/}
      <>
        {isEditingViewName ? (
          <TextInput type="text" placeholder="화면 명" defaultValue={viewName} onKeyDown={onKeyDown} onBlur={onBlur} maxLength="9" disabled={isViewMode} />
        ) : (
          <TextInputWrapper>
            <p>{viewName ? viewName : "화면 명"}</p>
            <InsightBtnWrapper>
              {isNewComment == true ? <img style={{ position: "relative", left: "42px", bottom: "5px" }} src={ic_new_comment} alt="comment" referrerPolicy="no-referrer" /> : ""}
              <EditBtn onClick={onClickEditViewName} disabled={isViewMode} />
              <InsightBtn onClick={onClickInsight} isActive={isInsightActive} disabled={isNew} />
              <Comment
                isInsightActive={isInsightActive}
                setIsInsightActive={setIsInsightActive}
                onClickCloseInsight={onClickCloseInsight}
                commentArr={commentArr}
                isLast={isLast}
                insight={insight}
                isTyped={isTyped}
                onChangeInsight={onChangeInsight}
                onClickSaveInsight={onClickSaveInsight}
                onClickEditInsight={onClickEditInsight}
                onClickDeleteInsight={onClickDeleteInsight}
                isViewMode={isViewMode}
              />
            </InsightBtnWrapper>
          </TextInputWrapper>
        )}
      </>

      {/* 하단 - 이미지 업로드 */}
      <>
        <div>
          {imgSrc ? (
            <>
              <UploadPreview>
                <Image src={itemFileDataUrl ? itemFileDataUrl : imgSrc} alt="view example image" referrerPolicy="no-referrer" />
                <button type="button" onClick={onClickDelete} disabled={isViewMode}></button>
              </UploadPreview>
            </>
          ) : (
            <>
              {!file && !itemFileDataUrl && (
                <UploadWrapper isBench={isBench} isFirst={isFirst}>
                  <input type="file" id={`${imageId}imageInput`} onChange={onChange} accept="image/*" disabled={isViewMode} />
                  <label htmlFor={`${imageId}imageInput`} onClick={onClickLabel}></label>
                </UploadWrapper>
              )}
              {!file && itemFileDataUrl && (
                <UploadPreview>
                  <Image src={itemFileDataUrl} alt="view example image" referrerPolicy="no-referrer" />
                  <button type="button" onClick={onClickDelete} disabled={isViewMode}></button>
                </UploadPreview>
              )}
              {file && (
                <UploadPreview>
                  <Image src={fileDataURL} alt="view example image" referrerPolicy="no-referrer" />
                  <button type="button" onClick={onClickDelete} disabled={isViewMode}></button>
                </UploadPreview>
              )}
            </>
          )}
        </div>
      </>
    </Container>
  );
};

export default InputInsightImage;
