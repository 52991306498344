import { useState, useEffect } from "react";
import InputInsightImage from "components/common/InputInsightImage";
import getRandomColor from "utils/getRandomColor";
import { Keywords, SelectedKeyword, VacantKeyword, ImageWrapper } from "./styles";

const BenchmarkingItem = ({ currentBench, onChangeViewName, onChangeFile, selectedKeywordArr, selectedBgArr, typedKeywordArr, exampleKeywordArr, onClickSelf, isViewMode }) => {
  const [exampleKeywordArrBg, setExampleKeywordArrBg] = useState([]);
  useEffect(() => {
    if (exampleKeywordArr) {
      setExampleKeywordArrBg(getRandomColor(3));
    }
  }, []);

  const getIndex = (item) => {
    const indexInSelectedKeywordArr = selectedKeywordArr.findIndex((obj) => obj.keywordName === item.keywordName);
    return indexInSelectedKeywordArr;
  };

  return (
    <>
      <Keywords>
        <h3>키워드</h3>
        <div>
          {exampleKeywordArr?.length > 0 ? (
            <ul>
              {exampleKeywordArr.map((item, i) => (
                <SelectedKeyword key={item.id} bg={exampleKeywordArrBg[i]}>
                  {item.keywordName}
                </SelectedKeyword>
              ))}
            </ul>
          ) : (
            <>
              <ul>
                {currentBench.benchmarkKeywords?.map((item, i) =>
                  item.isTyped ? (
                    <SelectedKeyword key={i} bg={"#4556EE"} isTyped>
                      {item.keywordName}
                    </SelectedKeyword>
                  ) : (
                    selectedBgArr && (
                      <SelectedKeyword key={i} bg={selectedBgArr[getIndex(item)]}>
                        {item.keywordName}
                      </SelectedKeyword>
                    )
                  )
                )}
              </ul>
              <ul>
                {Array(3 - currentBench.benchmarkKeywords?.length)
                  .fill()
                  .map((_, i) => (
                    <VacantKeyword key={i + 6}></VacantKeyword>
                  ))}
              </ul>
            </>
          )}
        </div>
      </Keywords>
      <ImageWrapper>
        <InputInsightImage
          key={`${currentBench.benchmarkId}1`}
          currentBench={currentBench}
          item={currentBench.benchmarkImages[0]}
          isComment={currentBench.benchmarkImages[0].benchmarkCommentCheck}
          imageId={currentBench.benchmarkImages[0]?.imageId}
          isTypeAdmin={currentBench.registerType === "ADMIN"}
          image={currentBench.benchmarkImages[0]?.image}
          viewName={currentBench.benchmarkImages[0]?.imageName}
          onChangeViewName={onChangeViewName}
          onChangeFile={onChangeFile}
          onClickSelf={onClickSelf}
          isBench
          isFirst
          isNew={currentBench.isNew}
          isViewMode={isViewMode}
        />
        <InputInsightImage
          key={`${currentBench.benchmarkId}2`}
          currentBench={currentBench}
          item={currentBench.benchmarkImages[1]}
          imageId={currentBench.benchmarkImages[1]?.imageId}
          isComment={currentBench.benchmarkImages[1].benchmarkCommentCheck}
          isTypeAdmin={currentBench.registerType === "ADMIN"}
          image={currentBench.benchmarkImages[1]?.image}
          viewName={currentBench.benchmarkImages[1]?.imageName}
          onChangeViewName={onChangeViewName}
          onChangeFile={onChangeFile}
          onClickSelf={onClickSelf}
          isBench
          isNew={currentBench.isNew}
          isViewMode={isViewMode}
        />
        <InputInsightImage
          key={`${currentBench.benchmarkId}3`}
          currentBench={currentBench}
          item={currentBench.benchmarkImages[2]}
          imageId={currentBench.benchmarkImages[2]?.imageId}
          isComment={currentBench.benchmarkImages[2].benchmarkCommentCheck}
          isTypeAdmin={currentBench.registerType === "ADMIN"}
          image={currentBench.benchmarkImages[2]?.image}
          viewName={currentBench.benchmarkImages[2]?.imageName}
          onChangeViewName={onChangeViewName}
          onChangeFile={onChangeFile}
          onClickSelf={onClickSelf}
          isBench
          isNew={currentBench.isNew}
          isViewMode={isViewMode}
        />
        <InputInsightImage
          key={`${currentBench.benchmarkId}4`}
          currentBench={currentBench}
          item={currentBench.benchmarkImages[3]}
          imageId={currentBench.benchmarkImages[3]?.imageId}
          isComment={currentBench.benchmarkImages[3].benchmarkCommentCheck}
          isTypeAdmin={currentBench.registerType === "ADMIN"}
          image={currentBench.benchmarkImages[3]?.image}
          viewName={currentBench.benchmarkImages[3]?.imageName}
          onChangeViewName={onChangeViewName}
          onChangeFile={onChangeFile}
          onClickSelf={onClickSelf}
          isBench
          isNew={currentBench.isNew}
          isViewMode={isViewMode}
        />
        <InputInsightImage
          key={`${currentBench.benchmarkId}5`}
          currentBench={currentBench}
          item={currentBench.benchmarkImages[4]}
          imageId={currentBench.benchmarkImages[4]?.imageId}
          isComment={currentBench.benchmarkImages[4].benchmarkCommentCheck}
          isTypeAdmin={currentBench.registerType === "ADMIN"}
          image={currentBench.benchmarkImages[4]?.image}
          viewName={currentBench.benchmarkImages[4]?.imageName}
          onChangeViewName={onChangeViewName}
          onChangeFile={onChangeFile}
          onClickSelf={onClickSelf}
          isBench
          isLast
          isNew={currentBench.isNew}
          isViewMode={isViewMode}
        />
      </ImageWrapper>
    </>
  );
};

export default BenchmarkingItem;
