import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const useCurrentLocation = () => {
	const { pathname } = useLocation();
	const [currentLocation, setCurrentLocation] = useState("");
	useEffect(() => {
		const page = pathname.split("/")[1];
		setCurrentLocation(page);
	}, [pathname]);

	return currentLocation;
};

export default useCurrentLocation;
