import { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import useCurrentLocation from "hooks/useCurrentLocation";
import AvatarBtn from "components/home/AvatarBtn";
import CustomModal from "components/common/CustomModal";
import axiosPrivate from "utils/axiosPrivate";
import { Header, HomeLink, HeadingWrapper, DropdownBtn, ProjectMenu, ProjectMenuList, NavWrapper, StyledNav, ProfileMenu, ProfileBtn, ShareBtn, AlarmBtn, ProfileMenuList } from "./styles";
import { ModalContent, ModalDesc, ModalBtnWrapper, ModalForm, ModalInputWrapper } from "pages/home/styles";
import useLogout from "hooks/useLogout";
import useViewMode from "hooks/useViewMode";
import ic_analysis from "assets/ic_analysis.svg";
import ic_analysis_active from "assets/ic_analysis_active.svg";
import ic_concept from "assets/ic_concept.svg";
import ic_concept_active from "assets/ic_concept_active.svg";
import ic_prod from "assets/ic_prod.svg";
import ic_prod_active from "assets/ic_prod_active.svg";
import ic_test from "assets/ic_test.svg";
import ic_test_active from "assets/ic_test_active.svg";
import ic_review from "assets/ic_review.svg";
import ic_review_active from "assets/ic_review_active.svg";

const Nav = ({ onSave }) => {
  let navigate = useNavigate();
  const { pathname } = useLocation();
  const { logout } = useLogout();
  const [isSaveModalVisible, setIsSaveModalVisible] = useState(false);
  const [pathTest, setPathTest] = useState("");

  const { id: projectId } = useParams();

  const isViewMode = useViewMode();

  const navTitle = [
    {
      id: 1,
      isIndex: "analysis",
      url: `/analysis/summary/`,
      title: "분석",
      image: ic_analysis,
      act_image: ic_analysis_active,
    },
    {
      id: 2,
      isIndex: "concept",
      url: `/concept/benchmarking/`,
      title: "컨셉",
      image: ic_concept,
      act_image: ic_concept_active,
    },
    {
      id: 3,
      isIndex: "production",
      url: `/production/`,
      title: "제작",
      image: ic_prod,
      act_image: ic_prod_active,
    },
    {
      id: 4,
      isIndex: "test",
      url: `/test/form/`,
      title: "테스트",
      image: ic_test,
      act_image: ic_test_active,
    },
    {
      id: 5,
      isIndex: "review",
      url: `/review/`,
      title: "리뷰",
      image: ic_review,
      act_image: ic_review_active,
    },
  ];

  const getProjectName = () => {
    return axiosPrivate.get(`/api/v1/projects/${projectId}/info`);
  };

  const getProjectVersion = () => {
    return axiosPrivate.get(`/api/v1/projects/${projectId}/version`);
  };

  // 프로젝트 정보 받아오기
  const [projectName, setProjectName] = useState();
  const [projectVersion, setProjectVersion] = useState();
  const [projectVersionArr, setProjectVersionArr] = useState([]);

  useEffect(() => {
    if (projectId) {
      Promise.allSettled([getProjectName(), getProjectVersion()]).then((result) => {
        if (result[0].status === "fulfilled") {
          if (result[0].value?.data?.result?.name) {
            setProjectName(result[0].value?.data?.result?.name);
          }
        } else {
          console.log("프로젝트 이름 조회 실패");
        }

        if (result[1].status === "fulfilled") {
          if (result[1].value?.data?.result?.length > 0) {
            const versionArr = [...result[1].value?.data?.result];
            const currentProject = versionArr.find((item) => item.projectId === +projectId);
            const currentVersion = currentProject?.version ? currentProject.version : "-";
            setProjectVersion(currentVersion);

            const otherVersionArr = versionArr.filter((item) => item.projectId !== +projectId);
            setProjectVersionArr(otherVersionArr);
          }
        } else {
          console.log("프로젝트 버전 조회 실패");
        }
      });
    }
  }, [projectId]);

  const onClickVersion = (item) => {
    const { projectId } = item;
    navigate(`/analysis/summary/${projectId}`);
  };

  // 1단 프로젝트 드롭다운 메뉴
  const [isProjectActive, setIsProjectActive] = useState(false);
  const onClickDropdown = () => setIsProjectActive((prev) => !prev);

  // 드롭다운 외부 영역 클릭 시 드롭다운 닫기
  const projectDropdownRef = useRef();
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isProjectActive && projectDropdownRef.current && !projectDropdownRef.current.contains(e.target)) {
        setIsProjectActive(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isProjectActive]);

  // 삭제 모달
  const [isDelModalVisible, setIsDelModalVisible] = useState(false);
  const onClickDelete = () => {
    setIsDelModalVisible(true);
  };
  const onCancelDel = () => {
    setIsDelModalVisible(false);
  };
  const onConfirmDel = () => {
    axiosPrivate
      .delete(`/api/v1/projects/${projectId}`)
      .then((res) => {
        console.log("프로젝트 삭제 성공", res);
        navigate("/home");
      })
      .catch((err) => {
        console.log("프로젝트 삭제 실패", err);
        window.alert("프로젝트 삭제에 실패하였습니다. 다시 시도해주세요!");
      })
      .finally(() => {
        setIsDelModalVisible(false);
      });
  };

  // 이름 변경 모달
  const [isNameConfirmDisabled, setIsNameConfirmDisabled] = useState(true);
  const [newName, setNewName] = useState("");
  const onChangeNewName = (e) => setNewName(e.target.value);

  useEffect(() => {
    if (newName?.length > 0) {
      setIsNameConfirmDisabled(false);
    } else {
      setIsNameConfirmDisabled(true);
    }
  }, [newName]);

  const [isNameModalVisible, setIsNameModalVisible] = useState(false);
  const onClickName = (e) => {
    e.preventDefault();
    setIsNameModalVisible(true);
  };

  const onCancelName = () => {
    setIsNameModalVisible(false);
    setNewName("");
  };

  const onConfirmName = (e) => {
    e.preventDefault();
    const data = { name: newName };
    axiosPrivate
      .put(`/api/v1/projects/${projectId}`, data)
      .then((res) => {
        console.log(res);
        setProjectName(newName);
      })
      .catch((err) => {
        console.log(err);
        window.alert("프로젝트 이름 변경에 실패했습니다. 관리자에게 문의해주세요!");
      })
      .finally(() => {
        setIsNameModalVisible(false);
      });
  };

  // 중단 모달
  const [isStopModalVisible, setIsStopModalVisible] = useState(false);
  const onClickStop = () => {
    setIsStopModalVisible(true);
  };

  const onCancelStop = () => {
    setIsStopModalVisible(false);
  };

  const onConfirmStop = (e) => {
    const data = {
      projectStatusType: "STOP",
    };
    axiosPrivate
      .put(`/api/v1/projects/${projectId}/status`, data)
      .then((res) => {
        console.log(res);
        navigate("/home");
      })
      .catch((err) => {
        console.log(err);
        window.alert("프로젝트 중단에 실패했습니다. 관리자에게 문의해주세요!");
      })
      .finally(() => {
        setIsStopModalVisible(false);
      });
  };

  const onChangeTab = (item) => {
    if (isViewMode) {
      navigate(item);
    } else {
      pathname.includes("feedback") ? navigate(item) : pathname.includes("review") ? navigate(item) : onSave("", item);
      /*setTimeout(() => {
        navigate(item);
      }, 300);*/
    }

    /* 2차버젼시 수정 유무에 따라 저장 시 사용
    setPathTest(item);
    if (isViewMode) {
      navigate(item);
    } else {
      setIsSaveModalVisible(true);
    }*/
  };
  {
    /*  2차버젼시 수정 유무에 따라 저장 시 사용 - 모달 이벤트 소스
  const onClickCancel = () => {
    setIsSaveModalVisible(false);
    navigate(pathTest);
  };

  const onClickSave = () => {
    pathname.includes("feedback") ? console.log("data") : pathname.includes("review") ? console.log("data") : onSave();
    setTimeout(() => {
      navigate(pathTest);
    }, 300);
  };*/
  }

  // 아바타 버튼 프로필 메뉴
  const [isProfileActive, setIsProfileActive] = useState(false);
  const onClickProfile = () => setIsProfileActive((prev) => !prev);

  // 상단 아바타 클릭
  const [isLogoutVisible, setIsLogoutVisible] = useState(false);
  const onClickLogout = () => {
    setIsLogoutVisible(true);
  };

  const onCancelLogout = () => setIsLogoutVisible(false);
  const onConfirmLogout = () => {
    logout();
    setIsLogoutVisible(false);
  };
  const currentLocation = useCurrentLocation();

  return (
    <>
      {/* 
      {isSaveModalVisible && (
        <CustomModal isVisible={isSaveModalVisible}>
          <ModalContent>
            <h2>변경된 내용 저장</h2>
            <ModalDesc>
              <p>
                저장하지 않고 페이지를 이동할 경우, <br />
                지금까지 변경된 내용이 반영되지 않습니다. <br />
                <strong style={{ color: "#4556EE" }}>내용을 저장할까요?</strong>
              </p>
              <ModalBtnWrapper>
                <button type="button" onClick={onClickCancel}>
                  취소
                </button>
                <button style={{ backgroundColor: "#4556EE" }} type="button" onClick={onClickSave}>
                  저장
                </button>
              </ModalBtnWrapper>
            </ModalDesc>
          </ModalContent>
        </CustomModal>
      )}*/}
      {/* 1단 */}
      <Header className="not-print">
        <HomeLink to="/home"></HomeLink>
        <HeadingWrapper>
          {isViewMode == true ? <p style={{ border: 0, paddingLeft: "0px" }}>View Olny</p> : ""}
          <h1>{projectName ? projectName : ""}</h1>
          <p>ver.{projectVersion ? projectVersion : ""}</p>
          <ProjectMenu>
            <DropdownBtn type="button" isActive={isProjectActive} onClick={onClickDropdown}></DropdownBtn>
            <ProjectMenuList isActive={isProjectActive} ref={projectDropdownRef}>
              {/* 공통 버튼 */}
              <ul>
                <li>
                  <button type="button" onClick={onClickDelete} disabled={isViewMode}>
                    프로젝트 삭제
                  </button>
                </li>
                <li>
                  <button type="button" onClick={onClickName} disabled={isViewMode}>
                    프로젝트 이름 변경
                  </button>
                </li>
                <li>
                  <button type="button" onClick={onClickStop} disabled={isViewMode}>
                    프로젝트 중단
                  </button>
                </li>
              </ul>
              {projectVersionArr?.length > 0 && (
                <ul>
                  {projectVersionArr.map((item, i) => (
                    <li key={item.projectId}>
                      <button type="button" onClick={() => onClickVersion(item)}>
                        ver.{item.version}
                      </button>
                    </li>
                  ))}
                </ul>
              )}
            </ProjectMenuList>
          </ProjectMenu>
        </HeadingWrapper>
      </Header>
      {/* 2단 */}
      <NavWrapper className="not-print">
        <StyledNav>
          <ul>
            {navTitle.map((item) => (
              <li key={item.url}>
                <div
                  style={{
                    display: "flex",
                    borderRight: "1px solid #f3f3f3",
                    padding: "0 16px 0 15px",
                    //marginTop: "0px",
                  }}
                  onClick={() => onChangeTab(item.url + projectId)}
                >
                  <img
                    style={{ display: "flex", flexDirection: "column", justifyContent: "center", marginRight: "5px", width: "15px", height: "15px", marginTop: "12px" }}
                    src={pathname.includes(item.isIndex) ? item.act_image : item.image}
                  ></img>
                  <button
                    style={{
                      width: "100%",
                      height: "40px",
                      //padding: "0 12px",
                      //backgroundImage: `url(${ic_analysis})`,
                      fontWeight: "500",
                      fontSize: "13px",
                      lineHeight: "30px",
                      color: pathname.includes(item.isIndex) ? "black" : "#a6a9ae",
                    }}
                  >
                    {item.title}
                  </button>
                </div>
              </li>
            ))}
          </ul>
        </StyledNav>
        {/* 1차 개발 제외 */}
        {/* <ShareBtn type="button">Share</ShareBtn>
          <AlarmBtn type="button"></AlarmBtn> */}
        <AvatarBtn onClickLogout={onClickLogout} />
      </NavWrapper>
      {isNameModalVisible && (
        <CustomModal isVisible={isNameModalVisible}>
          <ModalContent>
            <h2>프로젝트 이름 변경</h2>
            <ModalDesc>
              <ModalForm>
                <form onSubmit={onConfirmName}>
                  <ModalInputWrapper>
                    <label htmlFor="new-name">프로젝트 이름을 입력하세요.</label>
                    <input type="text" id="new-name" value={newName} onChange={onChangeNewName} />
                  </ModalInputWrapper>
                  <ModalBtnWrapper hasInput>
                    <button type="button" onClick={onCancelName}>
                      취소
                    </button>
                    <button type="submit" disabled={isNameConfirmDisabled}>
                      확인
                    </button>
                  </ModalBtnWrapper>
                </form>
              </ModalForm>
            </ModalDesc>
          </ModalContent>
        </CustomModal>
      )}
      {isDelModalVisible && (
        <CustomModal isVisible={isDelModalVisible}>
          <ModalContent>
            <h2>프로젝트 삭제</h2>
            <ModalDesc>
              <p>
                프로젝트 삭제 시 입력한 데이터가 모두 삭제됩니다. <br />
                <strong>그래도 프로젝트를 삭제하시겠습니까?</strong>
              </p>
              <ModalBtnWrapper>
                <button type="button" onClick={onCancelDel}>
                  취소
                </button>
                <button type="button" onClick={onConfirmDel}>
                  삭제
                </button>
              </ModalBtnWrapper>
            </ModalDesc>
          </ModalContent>
        </CustomModal>
      )}
      {isStopModalVisible && (
        <CustomModal isVisible={isStopModalVisible}>
          <ModalContent>
            <h2>프로젝트 중단</h2>
            <ModalDesc>
              <p>
                프로젝트 중단 시 더이상 Toolkit 데이터를 <br />
                입력할 수 없으며, 열람만 가능합니다.
                <br />
                <strong>그래도 프로젝트를 중단하시겠습니까?</strong>
              </p>
              <ModalBtnWrapper>
                <button type="button" onClick={onCancelStop}>
                  취소
                </button>
                <button type="button" onClick={onConfirmStop}>
                  중단
                </button>
              </ModalBtnWrapper>
            </ModalDesc>
          </ModalContent>
        </CustomModal>
      )}

      {isLogoutVisible && (
        <CustomModal isVisible={isLogoutVisible}>
          <ModalContent>
            <h2>로그아웃</h2>
            <ModalDesc>
              <p>
                로그아웃 시 현재 페이지에서 나가게 됩니다.
                <br />
                <strong>로그아웃 하시겠습니까?</strong>
              </p>
              <ModalBtnWrapper>
                <button type="button" onClick={onCancelLogout}>
                  취소
                </button>
                <button type="button" onClick={onConfirmLogout}>
                  로그아웃
                </button>
              </ModalBtnWrapper>
            </ModalDesc>
          </ModalContent>
        </CustomModal>
      )}
    </>
  );
};

export default Nav;
