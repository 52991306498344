import styled from "styled-components";
import { flexbox, scrollBase, whiteCardBase } from "styles/mixins";

export const WhiteCard = styled.div`
  ${whiteCardBase};
  width: 100%;
  min-height: 615px;
`;

export const HeadingWrapper = styled.div`
  margin: 0 0 72px 0;
  h2 {
    margin: 0 0 7px 0;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
  }
  p {
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    color: #000000;
  }
`;

export const Form = styled.form`
  width: 100%;

  @media screen and (max-width: 1399px) {
    width: max-content;
  }
`;

export const CardList = styled.ul`
  ${flexbox("space-around", "center")};
  width: 100%;
`;

// aside

export const AsideFeatureWrapper = styled.div`
  ${scrollBase};
  height: calc(100vh - 36px - 40px - 40px - 150px);
  margin: 20px 4px 10px 0;
  padding: 0 16px 20px 20px;
  overflow: auto;

  p {
    width: 100%;
    margin: 0 0 10px 0;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    background-color: #fff;
    color: #353535;
  }
`;
