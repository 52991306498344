import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axiosPrivate from "utils/axiosPrivate";
import { TabContainer } from "pages/test/form/styles";
import TabTag from "components/test/TabTag";
import ProjectInfo from "components/review/ProjectInfo";
import QuestionMain from "components/test/QuestionMain";
import ResponseBtn from "components/review/ResponseBtn";
import ResponseText from "components/review/ResponseText";
import { textDefaultData } from "utils/data";

const ReviewExperience = ({ isShowing, testInfo, onClickMoreResponse }) => {
  const { id: projectId } = useParams();

  const [data, setData] = useState();

  useEffect(() => {
    axiosPrivate
      .get(`api/v1/projects/${projectId}/review/dashboard`, {
        params: {
          testSurveyType: "SIMILAR_SERVICE",
        },
      })
      .then((res) => {
        if (res.data?.result?.answers?.length > 0) {
          setData(res.data.result.answers);
        } else {
          setData(textDefaultData);
        }
      })
      .catch((err) => {
        console.log(err);
        setData(textDefaultData);
      });
  }, []);

  // 답변 전체보기 모달
  const onClickResponse = () => onClickMoreResponse("SIMILAR_SERVICE");

  return (
    <TabContainer isShowing={isShowing}>
      <ProjectInfo testInfo={testInfo} />
      <TabTag text="유사 서비스" />
      <QuestionMain text="본 제품/서비스와 유사한 기능, 콘텐츠를 제공하는 다른 제품/ 서비스를 사용해 본 경험이 있으신가요? 어떤 제품/서비스인지 작성해 주세요!" />

      {data?.length > 0 && (
        <>
          <ResponseBtn onClick={onClickResponse} />
          <ResponseText data={data} />
        </>
      )}
    </TabContainer>
  );
};

export default ReviewExperience;
