import styled from "styled-components";
import { Link } from "react-router-dom";
import { Container } from "components/register/Card/styles";
import { flexbox, buttonBlueFilledBase } from "styles/mixins";

export const StyledCard = styled(Container)`
  padding: 30px 0 0 0;
`;

export const StyledLink = styled(Link)`
  ${buttonBlueFilledBase};
  width: 320px;
  height: 44px;
  margin: 20px 0 22px 0;
  font-weight: 700;
  font-size: 12px;
  line-height: 22px;

  &:hover,
  &:active {
    color: #fff;
  }
`;

export const SliderWrapper = styled.div`
  width: 730px;
  height: 388px;
  background-color: #f2f2f2;
`;

export const SliderHelper = styled.div`
  ${flexbox("center", "center")};
  width: 730px;
  height: 45px;
  background-color: #353535;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  strong {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */
    color: #ffffff;
  }

  > div {
    width: 1px;
    height: 13px;
    background-color: #d6d6d6;
    margin: 3px 8px 0;
  }

  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #d6d6d6;
  }
`;
