import { Wrapper, OpinionItem } from "components/test/TabList/styles";
import { typeNameDic } from "components/test/SortableItem";
const ReviewTabList = ({ tabs, onClick, currentTabId }) => {
  return (
    <Wrapper>
      {tabs?.map((item, i) => (
        <OpinionItem key={item.id} id={item.id} onClick={onClick} isCurrent={currentTabId === item.id} className={item.blocked ? "review disabled" : ""}>
          <div>
            <p>Q.{i + 1}</p>
            <p>{typeNameDic[item.testSurveyType]}</p>
          </div>
        </OpinionItem>
      ))}
      <OpinionItem id="9" onClick={onClick} isCurrent={currentTabId === 9}>
        <div>
          <p>Q.{tabs?.length + 1}</p>
          <p>종합 의견</p>
        </div>
      </OpinionItem>
    </Wrapper>
  );
};

export default ReviewTabList;
