const BarComponent = ({ bar, borderColor }) => {
  return (
    <g transform={`translate(${bar.x},${bar.y})`}>
      <rect width={bar.width} height={30} fill={bar.color} rx={16} ry={16}>
        <animate attributeName="width" from="0" to={bar.width} dur="0.3s" repeatCount="1"></animate>
      </rect>
      <text
        x={46}
        y={14}
        textAnchor="end"
        dominantBaseline="central"
        fill="white"
        style={{
          fontWeight: 400,
          fontSize: 15,
          lineHeight: 22,
        }}
      >
        {bar.data.indexValue === "yes" ? "있다" : "없다"}
      </text>
      {bar.data.value !== 0 && (
        <text
          x={bar.width - 16}
          y={14}
          textAnchor="end"
          dominantBaseline="central"
          fill="white"
          style={{
            fontWeight: 700,
            fontSize: 15,
            lineHeight: 22,
          }}
        >
          {bar.data.value}명
        </text>
      )}
    </g>
  );
};

export default BarComponent;
