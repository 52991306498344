import { personalityArr } from "utils/data";
import { Wrapper } from "./styles";

const Personality = ({ name, selectedArr, onChange, isMax, isViewMode }) => {
  const onChangeHandler = (e, id) => {
    onChange(e.target.checked, id);
  };

  return personalityArr.map((item, i) => (
    <Wrapper key={i} isMax={isMax}>
      <input type="checkbox" name={name} id={item.name} value={item.name} checked={selectedArr?.includes(item.name)} onChange={(e) => onChangeHandler(e, item.name)} disabled={isViewMode} />
      <label htmlFor={item.name}>{item.name}</label>
    </Wrapper>
  ));
};

export default Personality;
