import { Wrapper, Yes, No } from "./styeles";
import { TESTER } from "utils/data";

const YesNoCheck = ({ name, status, selectedValue, onChange }) => {
  const isDisabled = status === TESTER ? false : true;
  return (
    <Wrapper>
      <Yes>
        <input name={name} type="radio" id={`${name}yes`} disabled={isDisabled} value="Y" checked={"Y" === selectedValue} onChange={onChange} />
        <label htmlFor={`${name}yes`} disabled={isDisabled}>
          있다
        </label>
      </Yes>
      <No>
        <input name={name} type="radio" id={`${name}no`} value="N" checked={"N" == selectedValue} disabled={isDisabled} onChange={onChange} />
        <label htmlFor={`${name}no`} disabled={isDisabled}>
          없다
        </label>
      </No>
    </Wrapper>
  );
};

export default YesNoCheck;
