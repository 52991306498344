import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import DragHandle from "../DragHandle";
import ToggleBtn from "../ToggleBtn";
import { Item } from "./styles";

export const typeNameDic = {
  PRODUCT_USE_INTENTION: "제품/서비스 사용 의향",
  PAY_INTENTION: "비용 지불 의향",
  EXPECTED_FREQUENCY_USE: "예상 사용 빈도",
  DESIGN_SATISFACTION: "디자인 만족도",
  CONTENT_SATISFACTION: "콘텐츠만족도",
  FUNCTIONAL_SATISFACTION: "기능 만족도",
  SIMILAR_SERVICE: "유사 서비스",
  GENERAL_OPINION: "종합 의견",
};

const SortableItem = (props) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id: props.id });
  const { id, blocked, testSurveyType } = props.data;

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  // ㅇㅏ마 토글 버튼으로 변경해야 할
  // 삭제 버튼

  const onClickDelete = () => {
    props.onClickDel(id);
  };

  return (
    <>
      <Item id={id} ref={setNodeRef} style={style} onClick={props.onClick} isCurrent={props.currentTab === id} className={blocked ? "disabled" : ""}>
        <DragHandle {...attributes} {...listeners} isDragging={isDragging} isViewMode={props.isViewMode} />
        <div>
          <p>Q.{props.itemIndex + 1}</p>
          <p>{typeNameDic[testSurveyType]}</p>
        </div>
        <ToggleBtn id={id} onToggleTab={props.onToggleTab} blocked={blocked} isViewMode={props.isViewMode} />
      </Item>
    </>
  );
};

export default SortableItem;
