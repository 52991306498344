import { Wrapper } from "../Checkbox/styles";

const KeywordCheckbox = ({ array, randomBgArr, name, selectedArr, onChange, isMax, isViewMode }) => {
  const onChangeHandler = (e, item) => {
    onChange(e.target.checked, item);
  };

  // selected Array에서 해당 item의 keywordName. 그 keywordName을 포함한 obj가 array에 있으면 checked 아니면 not checked
  const isChecked = (item) => {
    // array 아이템 들어왔음
    const index = selectedArr.find((obj) => obj.keywordName === item.keywordName);
    if (index) return true;
    return false;
  };

  return array.map((item, i) => (
    <Wrapper key={`${i}keyword`} bg={randomBgArr[i]} isMax={isMax}>
      <input
        type="checkbox"
        name={name}
        id={item.keywordId ? item.keywordId : item.id}
        value={item.keywordId ? item.keywordId : item.id}
        checked={isChecked(item)}
        onChange={(e) => onChangeHandler(e, item)}
        disabled={isViewMode}
      />
      <label htmlFor={item.keywordId}>{item.keywordName}</label>
    </Wrapper>
  ));
};

export default KeywordCheckbox;
