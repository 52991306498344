import { useRef, useEffect } from "react";
import { ToastWrapper } from "./styles";

const ToastPopup = ({ isToastVisible, setIsToastVisible, children }) => {
  const timer = useRef(null);

  const hideToast = () => {
    timer.current = setTimeout(() => {
      setIsToastVisible(false);
    }, 2700);
  };

  const triggerHideToast = () => {
    if (!isToastVisible) return;
    if (isToastVisible) hideToast();
  };

  useEffect(() => {
    triggerHideToast();
    return () => {
      clearTimeout(timer.current);
    };
  }, [isToastVisible]);

  return (
    <>
      <ToastWrapper isToastVisible={isToastVisible}>{children}</ToastWrapper>
    </>
  );
};

export default ToastPopup;
