import styled from "styled-components";
import { flexbox } from "styles/mixins";

export const Tooltip = styled.div`
  ${flexbox("center", "center")};
  padding: 0 12px;
  min-width: 86px;
  height: 40px;
  background: #ffffff;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  color: ${(props) => props.color};
`;
