import React, { useRef, useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import useSWR from "swr";
import { fetcherPrivate } from "utils/fetcher";
import axiosPrivate from "utils/axiosPrivate";
import Layout from "layouts/Layout";
import Content from "layouts/Content";
import ContentHeading from "layouts/Content/ContentHeading";
import Aside from "layouts/Aside";
import AsideInfo from "layouts/Aside/AsideInfo";
import TabOutlet from "components/test/TabOutlet";
import popup_form from "assets/popup_form.svg";
import CustomModal from "components/common/CustomModal";
import ReviewTabList from "components/review/ReviewTabList";
import ResponseModalContent from "components/review/ResponseModalContent";
import { TestListHeader, DefaultItem } from "pages/test/form/styles";
import { TEST_RESULT, formGuide } from "utils/data";
import { FeedbackBtn, ScrollAside } from "./styles";
import FeedbackModalContent from "components/review/FeedbackModalContent";
import { USER_REVIEW, testStepIdDic } from "utils/data";
import { defaultList } from "pages/test/form";
import FinishModalContent from "components/review/FinishModalContent";
import useExpertMode from "hooks/useExpertMode";
import ToastPopup from "components/common/ToastPopup";
import useViewMode from "hooks/useViewMode";
import { useReactToPrint } from "react-to-print";
import { useRecoilState } from "recoil";
import { recoilTestState } from "states/recoilTestState";

const progressData = {
  prev: TEST_RESULT,
  next: TEST_RESULT,
};

const Review = () => {
  const scrollRef = useRef();
  const navigate = useNavigate();

  const { id: projectId } = useParams();

  const isViewMode = useViewMode();

  // pdf 추출
  const printRef = useRef();

  const onClickPdf = useReactToPrint({ content: () => printRef.current });

  // 상단 프로젝트 정보 조회
  // 테스트 정보 조회
  const [testInfo, setTestInfo] = useState();
  const { data: testData, error: testError, mutate: testMutate } = useSWR(`/api/v1/projects/${projectId}/info`, (url) => fetcherPrivate(url), { revalidateOnFocus: false });
  useEffect(() => {
    if (testData?.result) {
      setTestInfo(testData.result);
    }
  }, [testData]);

  // 화면 구성 페이지에서 저장한 레이아웃
  const { data: layoutData, error: layoutError, mutate: layoutMutate } = useSWR(`/api/v1/projects/${projectId}/produce/layout`, (url) => fetcherPrivate(url), { revalidateOnFocus: false });
  const [layoutArr, setLayoutArr] = useState([]);
  useEffect(() => {
    if (layoutData?.result?.produceLayouts?.length > 0) {
      setLayoutArr(layoutData.result.produceLayouts);
    }
  }, [layoutData]);

  // 객관식 답변 여부 상관 없이 전체 테스터 숫자 필요한데 해당 정보 api가 없어서 이 api 사용
  const { data: testerCountData } = useSWR(`/api/v1/projects/${projectId}/test/cases`, (url) => fetcherPrivate(url), { revalidateOnFocus: false });
  const [totalTesterNum, setTotalTesterNum] = useRecoilState(recoilTestState);

  useEffect(() => {
    if (testerCountData && testerCountData?.result?.length > 0) {
      const totalList = testerCountData.result;
      const modifiedList = totalList.filter((item) => item.testCaseStepType === "END_OF_PROGRESS");
      setTotalTesterNum(modifiedList?.length);
    } else {
      setTotalTesterNum(0);
    }
  }, [testerCountData]);

  // 테스트 정보 -> TabOutlet
  const [tabs, setTabs] = useState([]);
  const [greetings, setGreetings] = useState("");
  const [closingWords, setClosingWords] = useState("");

  const { data: surveyData, error: surveyError, mutate: surveyMutate } = useSWR(`/api/v1/projects/${projectId}/test/survey`, (url) => fetcherPrivate(url), { revalidateOnFocus: false });
  useEffect(() => {
    if (surveyData) {
      const { greetings, testSurveyIndices, closingWords } = surveyData.result;
      if (testSurveyIndices?.length > 0) {
        const targetTabs = [...testSurveyIndices].slice(0, testSurveyIndices.length - 1);
        const newTabs = targetTabs.map((item, i) => {
          const { testSurveyType, use } = item;
          const id = testStepIdDic[testSurveyType];
          return { id, testSurveyType, blocked: !use };
        });
        setTabs(newTabs);
      } else {
        setTabs(defaultList);
      }

      // greetings 있는 경우
      if (greetings?.length > 0) {
        setGreetings(greetings);
      }

      // closingWords 있는 경우
      if (closingWords?.length > 0) {
        setClosingWords(closingWords);
      }
    }
  }, [surveyData]);

  // 링크 데이터
  const [linkText, setLinkText] = useState();
  const { data: linkData, error: linkError, mutate: linkMutate } = useSWR(`/api/v1/projects/${projectId}/test/prototype/url`, (url) => fetcherPrivate(url), { revalidateOnFocus: false });
  useEffect(() => {
    if (linkData?.result?.length > 0) {
      setLinkText(linkData.result);
    }
  }, [linkData]);

  // 피드백 모달
  const isExpertMode = useExpertMode();

  const { data: feedback, error: feedbackError } = useSWR(`/api/v1/projects/${projectId}/review/dashboard/feedback`, (url) => fetcherPrivate(url), { revalidateOnFocus: false });
  const [hasFeedback, setHasFeedback] = useState(true);
  const [isFeedbackModalVisible, setIsFeedbackModalVisible] = useState(false);
  const [feedbackData, setFeedbackData] = useState();
  const [feedbackText, setFeedbackText] = useState("");
  const [isToastVisible, setIsToastVisible] = useState(false);

  useEffect(() => {
    if (feedback) {
      setFeedbackData(feedback.result);
      setFeedbackText(feedback.result.feedback);
      if (feedback.result.feedback?.length > 0) {
        setHasFeedback(true);
      } else {
        setHasFeedback(false);
      }
    } else if (!feedback && !isExpertMode) {
      setHasFeedback(false);
    }
  }, [feedback]);

  const onClickFeedback = () => setIsFeedbackModalVisible(true);
  const onClickCloseFeedback = () => {
    setIsFeedbackModalVisible(false);
  };
  const onChangeFeedbackText = (e) => setFeedbackText(e.target.value);
  const onSubmitFeedback = (e) => {
    e.preventDefault();
    const data = {
      feedback: feedbackText,
    };
    axiosPrivate
      .put(`/api/v1/projects/${projectId}/review/dashboard/feedback`, data)
      .then((res) => {
        console.log(res);
        setIsToastVisible(true);
      })
      .catch((err) => {
        console.log(err);
        window.alert("피드백 저장에 실패했습니다. 다시 시도해주세요!");
      });
  };

  // tab 전환
  const [currentTabId, setCurrentTabId] = useState(0);

  const onClickTab = (e) => setCurrentTabId(+e.target.id);

  // 테스터 응답 모달
  const getReviewList = (testSurveyType) => {
    return axiosPrivate.get(`/api/v1/projects/${projectId}/review/dashboard/list`, {
      params: {
        testSurveyType,
      },
    });
  };

  const [testerAnswerList, setTesterAnswerList] = useState([]);
  const [isResponseModalVisible, setIsResponseModalVisible] = useState(false);
  const [questionType, setQuestionType] = useState();
  const onClickMoreResponse = async (questionType) => {
    setTesterAnswerList([]);
    setQuestionType();

    try {
      const reviewList = await getReviewList(questionType);
      setQuestionType(questionType);
      setTesterAnswerList(reviewList?.data?.result?.listTestCaseAnswers);
      setIsResponseModalVisible(true);
    } catch (err) {
      console.log(err);

      window.alert("답변 전체 보기 조회에 실패했습니다. 관리자에게 문의해주세요!");
    }

    // setIsResponseModalVisible(true);
  };
  const onClickCloseMoreResponse = () => setIsResponseModalVisible(false);

  // 끝내기 모달
  const [projectStatus, setProjectStatus] = useState();
  const [isFinishVisible, setIsFinishVisible] = useState(false);

  const onClickFinish = () => setIsFinishVisible(true);

  const onClickCloseFinish = () => {
    setIsFinishVisible(false);
    setProjectStatus();
  };

  const onChangeProjectStatus = (e) => setProjectStatus(e.target.value);

  // projectStatus==="version-up" -> 현 프로젝트 '진행중' 상태 유지, 버전업한 프로젝트 생성, 홈으로 이동
  const makeNewVersion = () => {
    axiosPrivate
      .post(`/api/v1/projects/${projectId}/version`)
      .then((res) => {
        console.log(res);
        navigate("/home");
      })
      .catch((err) => {
        console.log(err);
        window.alert("프로젝트 버전 업에 실패했습니다. 관리자에게 문의해주세요!");
      })
      .finally(() => {
        setIsFinishVisible(false);
        setProjectStatus();
      });
  };

  // projectStatus==="complete" ->  현 프로젝트 '완료'로 상태 변경, 올리브스톤 홈페이지로 이동
  const completeProject = () => {
    const data = {
      projectStatusType: "COMPLETION",
    };

    axiosPrivate
      .put(`/api/v1/projects/${projectId}/status`, data)
      .then((res) => {
        console.log(res);
        navigate("/home");
        window.open("https://www.olivestonelab.com/", "_blank");
      })
      .catch((err) => {
        console.log(err);
        window.alert("프로젝트를 완료 상태로 변경하는데 실패했습니다. 관리자에게 문의해주세요!");
      })
      .finally(() => {
        setIsFinishVisible(false);
        setProjectStatus();
      });
  };

  // projectStatus==="stop" -> 현 프로젝트 '중단' 상태로 변경, 홈 화면으로 이동
  const stopProject = () => {
    const data = {
      projectStatusType: "STOP",
    };
    axiosPrivate
      .put(`/api/v1/projects/${projectId}/status`, data)
      .then((res) => {
        console.log(res);
        setIsFinishVisible(false);
        setProjectStatus();
        navigate("/home");
      })
      .catch((err) => {
        console.log(err);
        window.alert("프로젝트를 중단 상태로 변경하는데 실패했습니다. 관리자에게 문의해주세요!");
      });
  };

  const onClickConfrimStatus = () => {
    if (projectStatus === "version-up") {
      makeNewVersion();
    } else if (projectStatus === "complete") {
      completeProject();
    } else if (projectStatus === "stop") {
      stopProject();
    }
  };

  return (
    <>
      {isToastVisible && (
        <ToastPopup isToastVisible={isToastVisible} setIsToastVisible={setIsToastVisible}>
          저장에 성공했습니다!
        </ToastPopup>
      )}
      <Layout onClickPdf={onClickPdf}>
        <Content progressData={progressData} ref={scrollRef} onClickFinish={onClickFinish} isViewMode={isViewMode}>
          <div ref={printRef}>
            <ContentHeading>
              <h1>
                제품/서비스 <span>테스트 결과</span>를 확인하세요
              </h1>
            </ContentHeading>
            {/* 탭 들어갈 자리 */}
            <TabOutlet
              currentTabId={currentTabId}
              status={USER_REVIEW}
              isReview
              onClickMoreResponse={onClickMoreResponse}
              layoutArr={layoutArr}
              link={linkText}
              greetings={greetings}
              closingWords={closingWords}
              testInfo={testInfo}
            />
          </div>
        </Content>
        <Aside>
          <AsideInfo heading="결과 확인" isReview>
            실제 잠재 고객을 대상으로 제품/서비스를 테스트하기 위한 설문작성 과정입니다.
          </AsideInfo>

          <TestListHeader>설문 목록</TestListHeader>
          <ScrollAside>
            <DefaultItem id="0" isActive={currentTabId === 0} onClick={onClickTab}>
              <p>시작</p>
              <p>인사말</p>
            </DefaultItem>
            <ReviewTabList tabs={tabs} onClick={onClickTab} currentTabId={currentTabId} />
            <DefaultItem id="10" isActive={currentTabId === 10} onClick={onClickTab}>
              <p>맺음</p>
              <p>끝내는 말</p>
            </DefaultItem>
          </ScrollAside>
          {isExpertMode ? (
            <FeedbackBtn type="button" onClick={onClickFeedback}>
              피드백을 작성하세요
            </FeedbackBtn>
          ) : (
            <FeedbackBtn type="button" disabled={!hasFeedback} onClick={onClickFeedback}>
              {hasFeedback ? "멘토의 피드를 확인해보세요!" : "아직 피드백이 작성되지 않았어요"}
            </FeedbackBtn>
          )}
        </Aside>
      </Layout>
      {/* 멘토 피드백  */}
      {isFeedbackModalVisible && (
        <CustomModal isVisible={isFeedbackModalVisible}>
          <FeedbackModalContent
            feedbackData={feedbackData}
            feedbackText={feedbackText}
            onChangeFeedbackText={onChangeFeedbackText}
            isExpertMode={isExpertMode}
            onSubmitFeedback={onSubmitFeedback}
            onClickClose={onClickCloseFeedback}
            isViewMode={isViewMode}
          />
        </CustomModal>
      )}
      {/* 테스터 응답 */}
      {isResponseModalVisible && (
        <CustomModal isVisible={isResponseModalVisible}>
          <ResponseModalContent questionType={questionType} testerAnswerList={testerAnswerList} onClickClose={onClickCloseMoreResponse} />
        </CustomModal>
      )}

      {/* 프로젝트 종료 모달 */}
      {isFinishVisible && (
        <CustomModal isVisible={isFinishVisible}>
          <FinishModalContent selectedStatus={projectStatus} onChange={onChangeProjectStatus} onClickClose={onClickCloseFinish} onClickConfirm={onClickConfrimStatus} />
        </CustomModal>
      )}
    </>
  );
};

export default Review;
