import styled from "styled-components";
import { flexbox } from "styles/mixins";

export const Container = styled.div`
  ${flexbox("space-between", "center")};
  position: relative;
  margin: 61px 0 0 0;
  width: 652px;
  height: 190px;
`;

export const Wrapper = styled.div`
  width: 510px;
  height: 190px;
`;

export const NumberWrapper = styled.div`
  height: 100%;
  padding: 30px 0 0 0;
  font-weight: 400;
  font-size: 40px;
  line-height: 22px;
  p:first-of-type {
    color: #5a7cf8;
    margin: 0 0 45px 0;
  }
  p:nth-of-type(2) {
    color: #ce5e41;
  }
`;
