import { Card } from "./styles";

const ModelCard = ({ name, item, selectedValue, onChange, imgUrl, isViewMode }) => {
  const { name: modelName, description, templateImage, id } = item;

  const onChangeModel = () => onChange(id, modelName);
  return (
    <Card>
      <input type="radio" name={name} id={id} value={id} onChange={onChangeModel} checked={+id === +selectedValue} disabled={isViewMode} />
      <label htmlFor={id}>
        <img src={templateImage} alt={modelName} referrerPolicy="no-referrer" />
        <h3>{modelName}</h3>
        <p>{description}</p>
      </label>
    </Card>
  );
};

export default ModelCard;
